import { useAppDispatch, useAppSelector } from "../..";
import { Guide } from "../../../core/types/guide";
import { setSaveInProgress } from "../../slices/processingSlice";
import {
	selectGuide,
	setEditedVideoExists,
	setCurrentVersions,
	setUnsavedChanges,
	selectPublishingGuide,
	selectLoadingGeneratedVideo,
	selectExportingVideo,
	selectAiEnhancingArticle,
	selectAiEnhancingVideo,
	selectTrimmingVideo,
	selectAutoUpdatingGuide,
} from "../../slices/guideSlice";
import { selectVideoEdits } from "../../slices/guideSlice";
import { selectSaver } from "../../slices/backendSlice";
import useBackendBusy from "./useBackendBusy";
import { useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { GigaUserApi } from "@giga-user-fern/api";

const useSaveGuide: () => (incrementVersion: boolean, hardSave?: boolean) => Promise<boolean> =
	() => {
		const dispatch = useAppDispatch();
		const saver = useAppSelector(selectSaver);
		const isBackendBusyIgnoreSave = useBackendBusy(true);
		const isBackendBusyIncludeSave = useBackendBusy();
		const saveInProgress = useAppSelector(
			(state) => state.processing.value.saveInProgress,
		);

		const guide = useAppSelector(selectGuide) as Guide;


		return async (incrementVersion: boolean = true, hardSave: boolean = false) => {

			/**
			 * @param hardSave: hardSave should be true when user clicks File > Save. 
			 * If hardSave is true, we will actually check backend to see if save is in progress, and not rely on frontend state being accurate. 
			 */

			const isBackendBusy = hardSave ? isBackendBusyIncludeSave : isBackendBusyIgnoreSave;

			if (isBackendBusyIgnoreSave) {
				//Some operation other than save is running. 
				return false;
			}
			else if (saveInProgress) {
				//save operation is running
				if (!hardSave) {
					//hardsave is false, so we exit. 
					return false
				}
			}

			dispatch(setSaveInProgress(true));

			const finalGuide = { ...guide, guideData: JSON.parse(JSON.stringify(guide.guideData)) } as Guide;

			const new_version = await saver.guides.update.data(
				finalGuide,
				incrementVersion,
			);

			if (new_version) {
				dispatch(setCurrentVersions({ saved: new_version }));
			}

			dispatch(setSaveInProgress(false));
			dispatch(setUnsavedChanges(false));
			return true;
		};
	};

export default useSaveGuide;
