const folderIcon = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="36"
			height="28"
			viewBox="0 0 36 28"
			fill="none"
		>
			<g filter="url(#filter0_b_583_2684)">
				<path
					d="M10.1202 8.25598C9.03036 5.82057 10.8124 3.0708 13.4805 3.0708H28.6061C30.3014 3.0708 31.7776 4.22858 32.1815 5.8751C32.7505 8.19465 30.9944 10.4336 28.6061 10.4336H13.4805C12.0291 10.4336 10.7131 9.58081 10.1202 8.25598Z"
					fill="url(#paint0_radial_583_2684)"
					fill-opacity="0.14"
				/>
				<path
					d="M10.1202 8.25598C9.03036 5.82057 10.8124 3.0708 13.4805 3.0708H28.6061C30.3014 3.0708 31.7776 4.22858 32.1815 5.8751C32.7505 8.19465 30.9944 10.4336 28.6061 10.4336H13.4805C12.0291 10.4336 10.7131 9.58081 10.1202 8.25598Z"
					stroke="url(#paint1_linear_583_2684)"
					stroke-opacity="0.2"
				/>
			</g>
			<g filter="url(#filter1_b_583_2684)">
				<path
					d="M1 5C1 2.79086 2.79086 1 5 1H12.9383C14.6136 1 16.0491 2.19841 16.3483 3.84682C16.6474 5.49522 18.0829 6.69364 19.7582 6.69364H31C33.2091 6.69364 35 8.4845 35 10.6936V23C35 25.2091 33.2091 27 31 27H5C2.79086 27 1 25.2091 1 23V5Z"
					fill="url(#paint2_radial_583_2684)"
					fill-opacity="0.4"
				/>
				<path
					d="M1 5C1 2.79086 2.79086 1 5 1H12.9383C14.6136 1 16.0491 2.19841 16.3483 3.84682C16.6474 5.49522 18.0829 6.69364 19.7582 6.69364H31C33.2091 6.69364 35 8.4845 35 10.6936V23C35 25.2091 33.2091 27 31 27H5C2.79086 27 1 25.2091 1 23V5Z"
					stroke="url(#paint3_linear_583_2684)"
					stroke-opacity="0.2"
				/>
			</g>
			<defs>
				<filter
					id="filter0_b_583_2684"
					x="-14.7056"
					y="-21.4292"
					width="71.4951"
					height="56.3628"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
					<feComposite
						in2="SourceAlpha"
						operator="in"
						result="effect1_backgroundBlur_583_2684"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_backgroundBlur_583_2684"
						result="shape"
					/>
				</filter>
				<filter
					id="filter1_b_583_2684"
					x="-23.5"
					y="-23.5"
					width="83"
					height="75"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
					<feComposite
						in2="SourceAlpha"
						operator="in"
						result="effect1_backgroundBlur_583_2684"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_backgroundBlur_583_2684"
						result="shape"
					/>
				</filter>
				<radialGradient
					id="paint0_radial_583_2684"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(7.7998 3.0708) rotate(16.1055) scale(26.5417 14.1477)"
				>
					<stop stop-color="#B1ACFF" />
					<stop offset="1" stop-color="#1C1C28" />
				</radialGradient>
				<linearGradient
					id="paint1_linear_583_2684"
					x1="20.5498"
					y1="3.0708"
					x2="20.5498"
					y2="10.4336"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#B1ACFF" stop-opacity="0.7" />
					<stop offset="1" stop-color="#B1ACFF" stop-opacity="0.22" />
				</linearGradient>
				<radialGradient
					id="paint2_radial_583_2684"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(1 1) rotate(37.4054) scale(42.8019 41.3066)"
				>
					<stop stop-color="#B1ACFF" />
					<stop offset="1" stop-color="#1C1C28" />
				</radialGradient>
				<linearGradient
					id="paint3_linear_583_2684"
					x1="18"
					y1="1"
					x2="18"
					y2="27"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#B1ACFF" stop-opacity="0.7" />
					<stop offset="1" stop-color="#B1ACFF" stop-opacity="0.22" />
				</linearGradient>
			</defs>
		</svg>
	);
};
export default folderIcon;
