import {
	showLoadingMessagePopup,
	showMessagePopup,
	showErrorMessagePopup,
} from "../../../layouts/MessagePopup/messagePopupSlice";
import { editItemFrontend } from "@gigauser/common/src/redux/slices/collectionDataSlice";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { Item, unravelItem } from "@gigauser/common/src/types/files";
import logger from "../../../utils/logger";
import { selectSaver } from "../../slices/backendSlice";
import { useToast } from "@chakra-ui/react";

const useEditItem: () => (object: Item) => Promise<boolean> = () => {
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);
	const toast = useToast();

	return async (item: Item) => {
		//folder has all new details only. Whether details have changed or not are to be checked elsewhere.

		const { name, description } = unravelItem(item);

		const editItemPromise = new Promise<boolean>(async (resolve, reject) => {
			try {
				var resp;
				if (item.type === "Collection") {
					resp = await saver.collections.update(item.entry);
				} else if (item.type === "Guide") {
					resp = await saver.guides.preview.update(item.entry);
				}
				if (resp) {
					dispatch(editItemFrontend(item));

					resolve(true);
				}
			} catch {
				reject(false);
			}
		});

		toast.promise(editItemPromise, {
			success: { title: `Edited 1 ${item.type} successfully.` },
			error: {
				title: "Unexpected error while edited item",
				description: "Please try again later.",
			},
			loading: { title: "Working ..." },
		});

		return editItemPromise;
	};
};

export default useEditItem;
