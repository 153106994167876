import React, { useContext } from "react";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { SearchResult } from "../SearchBox";
import SearchResultElement from "./SearchResultElement/SearchResultElement";
import "./SearchResults.css";
import CTable, {
	CTableColumn,
} from "@gigauser/common/src/ui/table/CTable/CTable";
import { CTableRowProps } from "@gigauser/common/src/ui/table/CTable/CTableRow";
import GuidesTable from "@gigauser/common/src/components/guides/GuidesTable/GuidesTable";
import { GuidePreview } from "@giga-user-fern/api/types/api/resources/guides";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useCollectionNavigate } from "@gigauser/common/src/redux/hooks/FileHooks";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import { useAppDispatch } from "@gigauser/common/src/redux";
import { closeModal } from "@gigauser/common/src/layouts/CModal/modalSlice";

type SearchResultsProps = {
	searchResults: SearchResult[];
	searchInProgress: boolean;
	loaded: boolean;
	searchString: string;
};

const SearchResults: React.FC<SearchResultsProps> = ({
	searchResults,
	searchInProgress,
	loaded,
	...props
}) => {
	const { device } = useWindowSize();
	const env = useContext(EnvironmentContext);

	const navigate = useCollectionNavigate(env);
	const dispatch = useAppDispatch();

	const onClickGuide = (guide: GuidePreview) => {
		window.open("/guide/" + guide.id + "", "_blank");
		// alert("Please use the chrome extension to open guides. Opening on this platform is coming soon! ")
	};

	const onClickCollection = (collection: Collection) => {
		navigate(collection);
		dispatch(closeModal());
	};

	const collections = searchResults
		.filter(
			(searchResult) => searchResult.searchable.item.type === "Collection",
		)
		.map((g) => g.searchable.item.entry as Collection);

	const guides = searchResults
		.filter((searchResult) => searchResult.searchable.item.type === "Guide")
		.map((g) => g.searchable.item.entry as GuidePreview);

	return (
		<Flex className="SearchHomeFlex" gap={"0.5rem"} flexFlow={"column"}>
			{collections.length ? (
				<>
					<div className="subtitle">Folders</div>
					<GuidesTable
						// size="s"
						disableDragNDrop
						type="Collection"
						entries={collections}
						onClick={onClickCollection}
					/>
				</>
			) : null}

			{guides.length ? (
				<>
					<div className="subtitle">Projects</div>
					<GuidesTable
						// size="s"
						disableDragNDrop
						type="Guide"
						entries={guides}
						onClick={onClickGuide}
					/>
				</>
			) : null}

			{collections.length === 0 &&
			guides.length === 0 &&
			loaded &&
			props.searchString &&
			!searchInProgress ? (
				<div className="nr">No results</div>
			) : (
				<></>
			)}
		</Flex>
	);

	return (
		<div className={`searchResults-container ${device}`}>
			<div className={`searchResults-topbar ${device}`}>
				{searchResults.length ? (
					<div className="filled-topbar">
						<div className="type">Type</div>
						<div className="name">Name</div>
						<div className="location">Description</div>
					</div>
				) : null}
			</div>

			<div className="searchResults">
				{searchResults.length ? (
					<div className="searchResults-box">
						<div className="searchResults-content">
							{searchResults.map((searchResult: SearchResult) => (
								<SearchResultElement
									searchResult={searchResult}
								></SearchResultElement>
							))}
						</div>

						{/* <div className='no-more-results-text'>That's all we could find!</div> */}
					</div>
				) : (
					<div className="emptySearchResults">
						{loaded
							? "Type something to begin a search ..."
							: "Initializing search ..."}
					</div>
				)}
			</div>
		</div>
	);
};
export default SearchResults;
