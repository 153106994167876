import { useEffect, useState } from "react";
import AdminList, { AdminListTab } from "./AdminList";
import GridLoader from "@gigauser/common/src/ui/loaders/GridLoader/GridLoader";
import { Id, User, UserInOrg, UserRole } from "@giga-user-fern/api/types/api";
import { saver } from "@gigauser/common/src/network/saver";
import { useAppDispatch } from "@gigauser/common/src/redux";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import { showErrorMessagePopup } from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import usersIcon from "@gigauser/common/src/assets/icons/users.svg";
import creatorsIcon from "@gigauser/common/src/assets/svgs/home/creatorsIcon";
import {
	closeModal,
	openModal,
} from "@gigauser/common/src/layouts/CModal/modalSlice";
import newUserIcon from "@gigauser/common/src/assets/svgs/home/newUserIcon";
import { CreatorInCurrentOrganization } from "@giga-user-fern/api/types/api/resources/organizations";
import CreateUserOverlay from "../CreateUserOverlay/CreateUserOverlay";
import PageHeader from "../../Header/laptop/PageHeader/PageHeader";
import { useToast } from "@chakra-ui/react";

export const AdminPage = () => {
	const [currentTab, setCurrentTab] = useState<AdminListTab>("active");

	const [loading, setLoading] = useState(true);
	const toast = useToast();

	const [activeUsers, setActiveUsers] = useState<
		CreatorInCurrentOrganization[]
	>([]);
	const [pendingUsers, setPendingUsers] = useState<UserInOrg[]>([]);

	const dispatch = useAppDispatch();

	const initUsers = async () => {
		const active = await saver.settings.manageUsers.listOfOnboardedCreators();
		const pending = await saver.settings.manageUsers.listOfInvitedCreators();

		if (active.ok) {
			console.log("users: ", active.body);
			setActiveUsers(active.body);
		}
		if (pending.ok) {
			setPendingUsers(pending.body);
		}

		setLoading(false);
	};

	useEffect(() => {
		initUsers();
	}, []);

	const onSubmitCreateUser = async (email: string) => {
		const createUserPromise = new Promise<{ res: boolean; error?: string }>(
			async (resolve, reject) => {
				try {
					const x = await saver.settings.manageUsers.addUserToOrganization({
						userEmail: email,
					});

					if (x.ok) {
						//TODO: Test this.
						const newUser = x.body.creators.find((u) => u.email === email);
						if (newUser) setPendingUsers([...pendingUsers, newUser]);
						resolve({ res: true });
					} else {
						var err;
						if (x.error.error === "UnauthorizedError") {
							err = "Unauthorized action!";
						} else if (x.error.error === "UserAlreadyInOrganizationError") {
							err = "User already exists.";
						} else if (x.error.error === "LimitExceededError") {
							err = `Seat limit exceeded! The limit is ${x.error.content.limit}`;
						} else if (x.error.error === "UsageLimitExceededError") {
							err = `Seat limit exceeded! The limit is ${x.error.content.limitValue}`
						} else {
							err = "Couldn't create user.";
						}
						throw new Error(err);
					}
				} catch (e) {
					reject({ res: false, error: e });
				}
			},
		);

		toast.promise(createUserPromise, {
			success: {
				title: `Invited new user!`,
				description: "Ask them to check their email inbox.",
			},
			error: (res: any) => {
				return {
					title: "Error while creating user.",
					description: res.error.message,
				}
			},
			loading: { title: "Working ..." },
		});

		createUserPromise.then((res) => {
			dispatch(closeModal());
		}).catch((res) => {

		});

	};

	const openCreateUserModal = () => {
		dispatch(
			openModal({
				heading: {
					title: "Invite User",
					icon: newUserIcon(),
				},
				component: <CreateUserOverlay complete={onSubmitCreateUser} />,
			}),
		);
	};

	const onDeleteUser = async (email: string) => {
		const resp = await saver.settings.manageUsers.removeUserFromOrganization({
			email,
		});
		if (resp) {
			setActiveUsers(activeUsers.filter((x) => x.email !== email));
			setPendingUsers(pendingUsers.filter((x) => x.email !== email));
		} else {
			dispatch(showErrorMessagePopup("Couldn't delete user."));
		}
	};

	const onChangeUserRole = async (userId: Id, role: UserRole) => {
		const newActiveUsers = activeUsers.map((user) => {
			if (user.userId === userId) {
				return { ...user, role: role };
			}
			return user;
		});
		setActiveUsers(newActiveUsers);

		const newPendingUsers = pendingUsers.map((user) => {
			if (user.userId === userId) {
				return { ...user, role: role };
			}
			return user;
		});
		setPendingUsers(newPendingUsers);

		const resp = await saver.settings.manageUsers.changeRoleOfUser({
			userId: userId,
			role: role,
		});
		if (!resp.ok) {
			toast({
				title: `Unexpected error changing user role!`,
				description: "Something went wrong. Try again later.",
				status: "error",
				duration: 6000,
				isClosable: true,
				position: "top",
			});
		}
	};

	if (loading) {
		return <GridLoader center />;
	} else {
		return (
			<>
				<PageHeader
					title="Manage Users"
					description="Collaborate with your team on Clueso."
					icon={creatorsIcon}
				/>

				<AdminList
					self={saver.user}
					currentTab={currentTab}
					setCurrentTab={setCurrentTab}
					changeUserRole={onChangeUserRole}
					activeUsers={activeUsers}
					pendingUsers={pendingUsers}
					deleteUser={onDeleteUser}
					createUser={openCreateUserModal}
				/>
			</>
		);
	}
};
