import React, { useEffect, useRef, useState } from "react";
import { useToast } from "@chakra-ui/react";
import "./VoicePicker.css";
import playIcon from "../../assets/svgs/playIcon";
import Icon from "../../ui/Icon/Icon";
import { useAppDispatch, useAppSelector } from "../../redux";
import {
	filterVoicesByEngine,
	filterVoices,
	getFlag,
	getModel,
} from "../../utils/voices/voiceUtils";
import pauseIcon from "../../assets/svgs/pauseIcon";
import LoadingRing from "../../assets/gifs/LoadingRing/LoadingRing";
import {
	selectVoice,
	setUnsavedChanges,
	setVoice,
} from "../../redux/slices/guideSlice";

import Cutton from "../../ui/buttons/Cutton/Cutton";
import downArrowheadIcon from "../../assets/svgs/downArrowheadIcon";
import Clickout from "../../layouts/Clickout/Clickout";
import { defaultSpeed } from "../../utils/speedUtils";
import { selectSaver } from "../../redux/slices/backendSlice";
import LanguagePicker from "../LanguagePicker/LanguagePicker";
import { Language } from "@giga-user-fern/api/types/api";
import { Voice } from "../../core/types/guide";
import { VoiceEdits } from "@giga-user-fern/api/types/api/resources/guides";
import HoverTip from "../../ui/HoverTip/HoverTip";
import { closeModal } from "../../layouts/CModal/modalSlice";
import CSelect from "../../ui/select/CSelect";

type VoicePickerProps = {};

export function capitalizeFirstLetter(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

function decimalFixSpeed(num: number) {
	return `${num.toFixed(2)}x`;
}

const VoicePicker: React.FC<VoicePickerProps> = () => {
	const [showSpeedOptions, setShowSpeedOptions] = useState(false);
	const toast = useToast();

	const currentVoice: VoiceEdits = useAppSelector(selectVoice);

	const [currentLanguage, setCurrentLanguage] = useState<Language>({
		languageId: "en",
		name: "English",
	});

	const [voices, setVoices] = useState<{
		premiumVoices: Voice[];
		standardVoices: Voice[];
	} | null>(null);

	const [filterTags, setFilterTags] = useState<string[] | null>(null);
	const [gender, setGender] = useState<"Male" | "Female" | null>(null);
	const [filterAccents, setFilterAccents] = useState<string[] | null>(null);
	const allTags = useRef(new Set<string>());
	const allAccents = useRef(new Map<string, JSX.Element>());

	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);

	const speeds = [0.8, 0.9, 1.0, 1.1, 1.2];

	const [audioLoading, setAudioLoading] = useState<string | null>();

	const [currentAudio, setCurrentAudio] = useState<Voice | null>(null);
	const audioRef = React.useRef(new Audio());

	const setCurrentVoice = (voice: Voice) => {
		dispatch(
			setVoice({ voice: voice, speed: currentVoice.speed || defaultSpeed }),
		);
		dispatch(setUnsavedChanges(true));
	};

	const handlePlayAudio = (v: Voice) => {
		if (!v.sample) return;

		const preSignedURL = v.sample;
		if (currentAudio?.sample === preSignedURL) {
			// If the same URL is clicked again, pause the audio
			audioRef.current.pause();
			setCurrentAudio(null);
		} else {
			// If a new URL is clicked, set it as the current audio and play it
			setCurrentAudio(v);
			setAudioLoading(v.code);
			audioRef.current.src = preSignedURL;
			audioRef.current.play();
		}
	};

	const loadVoices = async () => {
		setVoices(null);
		const allVoices = await saver.getVoices();
		return allVoices;
	};

	const loadLanguage = async () => {
		const language = await saver.getLanguage(
			currentVoice.voice.language || "en",
		);
		if (language) setCurrentLanguage(language);
	};

	const initData = async () => {
		const allVoices = await loadVoices();

		// Get all tags and accents
		allVoices.forEach((v) => {
			if (v.language !== currentLanguage.languageId) return;
			if (v.tags) {
				v.tags.forEach((tag) => {
					allTags.current.add(tag);
				});
			}
			if (v.languageCountryId) {
				const { name, flag } = getFlag(v.languageCountryId);
				allAccents.current.set(name, flag);
			}
		});

		setVoices(filterVoicesByEngine(allVoices, currentLanguage.languageId));
	};

	useEffect(() => {
		initData();
		setFilterTags(null);
		setGender(null);
		setFilterAccents(null);
		allAccents.current = new Map<string, JSX.Element>();
		allTags.current = new Set<string>();
	}, [currentLanguage]);

	useEffect(() => {
		loadLanguage();
	}, []);

	const generateVoicesTable = (
		voices: Voice[],
		columnNames?: { name: string; gender: string; accent: string },
	) => {
		return (
			<table className="voices-table gigauser-dark">
				<tr className="voices-table-header">
					<th></th>
					<th>{columnNames?.name || "Name"}</th>
					<th>{columnNames?.gender || "Gender"}</th>
					<th>{columnNames?.accent || "Accent"}</th>
				</tr>

				{voices.map((v) => {
					const { flag, name } = getFlag(v.languageCountryId || "en");

					return (
						<tr
							key={v.code}
							onClick={() => {
								setCurrentVoice(v);
							}}
							className={`${
								currentVoice.voice.code === v.code &&
								currentVoice.voice.language === v.language
									? "active-voice"
									: ""
							}`}
						>
							<td>
								{
									<Icon
										style={{
											visibility: v.sample ? "visible" : "hidden",
										}}
										className={`voice-sample-playicon`}
										onClick={(e) => {
											handlePlayAudio(v);
											e.stopPropagation();
										}}
									>
										{audioLoading === v.code ? (
											<LoadingRing color="#d43f8c" />
										) : currentAudio?.sample === v.sample ? (
											pauseIcon("#d43f8c")
										) : (
											playIcon("#d43f8c")
										)}
									</Icon>
								}
							</td>
							<td>
								<div className="voice-displayname">{v.name}</div>
								{(v.tags || getModel(v)) && (
									<div className="voice-description">
										<span className=" voice-description-pill voice-model-pill">
											{getModel(v)}
										</span>
										{v.tags &&
											v.tags.map((tag) => (
												<span className="voice-description-pill">{tag}</span>
											))}
									</div>
								)}
							</td>
							<td>{capitalizeFirstLetter(v.gender || "male")}</td>
							<td className="td-accent">
								<div className="td-accent-contents">
									<Icon className="accent-flag">{flag}</Icon>
									{name}
								</div>
							</td>
						</tr>
					);
				})}
			</table>
		);
	};

	const handleLoaded = () => {
		setAudioLoading(null);
	};

	const onSetDefaultVoice = async () => {
		try {
			const response = await saver.setDefaultVoice(currentVoice.voice);

			const label = `All new videos in ${flag.language || flag.name} will use ${currentVoice.voice.name}`;

			if (response) {
				toast({
					title: `Default voice set!`,
					description: label,
					status: "success",
					duration: 6000,
					isClosable: true,
					position: "top",
				});
			} else {
				toast({
					title: `Unable to set default voice!`,
					description: "Something went wrong. Try again later.",
					status: "error",
					duration: 6000,
					isClosable: true,
					position: "top",
				});
			}
		} catch (err) {
			toast({
				title: `Unable to set default voice!`,
				description: "Something went wrong. Try again later.",
				status: "error",
				duration: 6000,
				isClosable: true,
				position: "top",
			});
		}
	};

	const flag = getFlag(currentVoice.voice?.languageCountryId || "en");

	const filteredStandardVoices = filterVoices(
		voices?.standardVoices || [],
		filterTags,
		filterAccents,
		gender,
	);

	const filteredPremiumVoices = filterVoices(
		voices?.premiumVoices || [],
		filterTags,
		filterAccents,
		gender,
	);

	return (
		<div className="VoicePicker-container gigauser-dark">
			<div className="voice-header">
				<div className="voice-header-top">
					<div className="voice-header-title">
						<div>Selected voice</div>

						<div className="voice-rounded disabled">
							<Icon className="currentvoice-flag">{flag.flag}</Icon>
							<div className="currentvoice-name">{currentVoice.voice.name}</div>
						</div>
					</div>
					<div className="set-default-language-container">
						<HoverTip
							className="voice-container"
							size="s"
							hoverTipPara={`All new videos in ${flag.language || flag.name} will use this voice`}
						>
							<p
								className="set-default-language-message"
								onClick={onSetDefaultVoice}
							>
								Set as default
							</p>
						</HoverTip>
					</div>
				</div>
				<div className="voice-filters">
					<CSelect
						mode="multiple"
						value={filterTags}
						placeholder="Tone"
						onChange={(value) => {
							if (value.length === 0) {
								setFilterTags(null);
							} else {
								setFilterTags(value);
							}
						}}
						options={Array.from(allTags.current).map((value) => {
							return { value: value, label: value };
						})}
						allowClear
					/>

					<CSelect
						mode="multiple"
						value={filterAccents}
						onChange={(value) => {
							if (value.length === 0) {
								setFilterAccents(null);
							} else {
								setFilterAccents(value);
							}
						}}
						allowClear
						placeholder="Accents"
						options={Array.from(allAccents.current.keys()).map((value) => {
							return { value: value, label: value };
						})}
						optionRender={(option) => {
							return (
								<td
									className="td-accent"
									style={{ display: "flex", flexDirection: "row" }}
								>
									<div
										className="td-accent-contents"
										style={{
											display: "flex",
											flexDirection: "row",
											gap: "1em",
										}}
									>
										<Icon className="accent-flag">
											{allAccents.current.get(option.value)}
										</Icon>
										{option.value}
									</div>
								</td>
							);
						}}
					/>

					<CSelect
						onChange={(value) => {
							setGender(value);
						}}
						options={[
							{ value: "male", label: "Male" },
							{ value: "female", label: "Female" },
						]}
						value={gender}
						placeholder="Gender"
						allowClear
					/>
				</div>
			</div>

			<div className="voice-tables-container">
				{filteredPremiumVoices.length > 0 && (
					<>
						<p className="voices-title">Premium Voices</p>
						<p className="voices-subtitle">
							Highly natural, human-like voices.
						</p>

						{generateVoicesTable(filteredPremiumVoices)}
					</>
				)}

				{filteredStandardVoices.length > 0 && (
					<>
						<p className="voices-title">Standard Voices</p>
						<p className="voices-subtitle">
							Slightly robotic, but more reliable pronunciations.
						</p>

						{generateVoicesTable(filteredStandardVoices)}
					</>
				)}

				{filteredStandardVoices.length === 0 &&
				filteredPremiumVoices.length === 0 ? (
					<p className="voices-title">No Voices Available</p>
				) : null}
			</div>

			<div style={{ display: "none" }}>
				<audio ref={audioRef} controls onLoadedData={handleLoaded} />
			</div>

			<div className="voicepicker-footer-buttons">
				<div className="voicepicker-footer-currvoice-container">
					<div className="voicepicker-footer-currvoice">
						<div className="voice-label">Language</div>

						<LanguagePicker
							currentLanguage={currentLanguage}
							setCurrentLanguage={setCurrentLanguage}
						/>
					</div>

					<div className="voicepicker-footer-currvoice">
						<div className="voice-label">Speed</div>
						<div>
							<div className="voice-rounded active select-speed-button">
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
									onClick={() => {
										setShowSpeedOptions(true);
									}}
								>
									<div className="currentvoice-speed">
										{decimalFixSpeed(currentVoice.speed || defaultSpeed)}
									</div>
									<div className="arrowhead">
										{downArrowheadIcon("#c7c7c7")}
									</div>
								</div>

								{showSpeedOptions ? (
									<Clickout
										closeFunction={() => {
											setShowSpeedOptions(false);
										}}
									>
										<div className="speeds-popup top">
											{speeds.map((s) => (
												<div
													className="speed-option"
													key={s}
													onClick={() => {
														dispatch(setVoice({ ...currentVoice, speed: s }));
														setShowSpeedOptions(false);
													}}
												>
													{decimalFixSpeed(s)}
												</div>
											))}
										</div>
									</Clickout>
								) : null}
							</div>
						</div>
					</div>
				</div>

				<Cutton
					onClick={() => {
						dispatch(closeModal());
					}}
				>
					Confirm
				</Cutton>
			</div>
		</div>
	);
};

export default VoicePicker;
