import React, { useRef, useState } from "react";
import "./SearchInput.css";
import searchIcon from "@gigauser/common/src/assets/legacy_assets/icons/search.svg";
import { Device } from "@gigauser/common/src/types/devices";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import logger from "@gigauser/common/src/utils/logger";

type SearchInputProps = {
	runSearch: (search: string) => void;
	setSearchString: React.Dispatch<React.SetStateAction<string>>;
	loaded: boolean;
};

const SearchInput: React.FC<SearchInputProps> = ({
	runSearch,
	setSearchString,
	...props
}) => {
	// let timer: ReturnType<typeof setTimeout>
	let timer = useRef<any>(null);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchString(e.target.value);

		if (timer.current !== undefined) {
			clearTimeout(timer.current);
		}
		timer.current = setTimeout(() => {
			logger.debug("running search!: ", e.target.value);
			runSearch(e.target.value);
		}, 300);
	};

	const [focus, setFocus] = useState<boolean>(false);

	const onFocus = () => {
		setFocus(true);
	};

	const onBlur = () => {
		setFocus(false);
	};

	const { device } = useWindowSize();

	return (
		<div className={`SearchInput ${focus ? "active" : ""}`}>
			{/* // <TitledInput value="" id='search' onChange={() =>{}} icon={searchIcon} /> */}

			<img className={`Searchbar-icon ${device}`} src={searchIcon}></img>
			<input
				onBlur={onBlur}
				onFocus={onFocus}
				className={`Searchbar-input`}
				onChange={onChange}
				placeholder={"Type the title of a project to search ..."}
				disabled={!props.loaded}
				autoFocus={true}
			></input>
		</div>
	);
};
export default SearchInput;
