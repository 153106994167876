import React, { useEffect } from "react";
import { navbarFooterButtons } from "../../data";
import { LabeledMenu } from "@gigauser/common/src/types/menus";
import "./NavbarFooter.css";
import { saver } from "@gigauser/common/src/network/saver";
import WorkspaceSwitcher from "@gigauser/common/src/auth/WorkspaceSwitcher/WorkspaceSwitcher";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import NavbarMenu, { NavbarMenuItem } from "../NavbarMenu/NavbarMenu";
import { useLocation } from "react-router-dom";
import NavbarSection from "../NavbarSection/NavbarSection";
import { Flex } from "@chakra-ui/react";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import premiumIcon from "@gigauser/common/src/assets/svgs/premiumIcon";
import analyticsIcon from "@gigauser/common/src/assets/svgs/analyticsIcon";
import { GigaUserApi } from "@giga-user-fern/api";
import { BasePlanStatus } from "@giga-user-fern/api/types/api/resources/organizations";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { openModal } from "@gigauser/common/src/layouts/CModal/modalSlice";
import crownIcon from "@gigauser/common/src/assets/svgs/crownIcon";
import LimitPopup from "@gigauser/common/src/overlays/LimitPopup/LimitPopup";
import { selectAnalytics } from "@gigauser/common/src/redux/slices/backendSlice";

const BasePlanType = GigaUserApi.organizations.BasePlanType;

type NavbarFooterProps = {};

const NavbarFooter: React.FC<NavbarFooterProps> = () => {
	const dispatch = useAppDispatch();
	const analytics = useAppSelector(selectAnalytics);
	const location = useLocation();
	const homePage = location.pathname;
	const [basePlanStatus, setBasePlanStatus] =
		React.useState<BasePlanStatus | null>(null);
	useEffect(() => {
		const fetchBasePlanStatus = async () => {
			const status = await saver.getBasePlanStatus();
			setBasePlanStatus(status);
		};
		fetchBasePlanStatus();
	});
	const homeMenu: NavbarMenuItem[] = [
		// {
		// 	label: "Analytics",
		// 	icon: analyticsIcon,
		// 	id: "home/analytics",
		// },
		{
			label: "Trash",
			icon: trashIcon,
			id: "home/trash",
		},
	];

	const onUpgradePlan = () => {
		analytics.captureEvent({ eventName: "UpgradePillClicked" , value: {} });
		dispatch(
			openModal({
				heading: {
					title: `Upgrade your plan`,
					icon: crownIcon(),
				},
				component: <LimitPopup hideDescription />,
			}),
		);
	};

	return (
		<div className="NavbarFooter laptop">
			{homePage.includes("/settings") ? null : (
				<NavbarSection noMargin>
					<NavbarMenu menu={homeMenu} />{" "}
				</NavbarSection>
			)}

			{basePlanStatus && basePlanStatus.status !== BasePlanType.Pro && (
				<Flex className="NavbarFooter FreeTrial" onClick={onUpgradePlan}>
					<Icon className="premiumIcon">{premiumIcon()}</Icon>
					<Flex
						className="text"
						alignItems={"left"}
						flexFlow={"column"}
						gap="0.25rem"
					>
						<div>{saver.basePlanStatus?.status === BasePlanType.None && "Your account is inactive"}
							{
								saver.basePlanStatus?.expirationDate && (() => {
									const daysLeft = Math.ceil((saver.basePlanStatus.expirationDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24));
									return daysLeft > 1 ? `Your trial expires in ${daysLeft} days` : "Your trial expires today";
								})()
							}</div>
						<div>
							<span className="demo-link" onClick={onUpgradePlan}>
								Upgrade
							</span>
							your plan
						</div>
					</Flex>
				</Flex>
			)}


			<WorkspaceSwitcher direction="up" />
			{/* {menu.map((menuItem) => (
				<NavbarFooterButton
					key={menuItem.label}
					label={menuItem.label}
					icon={menuItem.icon}
					handler={menuItem.handler as () => void}
				/>
			))} */}
		</div>
	);
};

type NavbarFooterButtonProps = {
	icon: string;
	label: string;
	handler: () => void;
};

const NavbarFooterButton: React.FC<NavbarFooterButtonProps> = ({
	icon,
	label,
	handler,
}) => {
	return (
		<div className="NavbarFooterButton" onClick={handler}>
			<img className="NavbarFooterButton-icon" src={icon}></img>
			<div className="NavbarFooterButton-label">{label}</div>
		</div>
	);
};

export default NavbarFooter;
