import { saver } from "@gigauser/common/src/network/saver";
import { useEffect, useState } from "react";
import PageHeader from "../../../home/components/Header/laptop/PageHeader/PageHeader";
import permissionsIcon from "@gigauser/common/src/assets/svgs/home/permissionsIcon";
import SectionHeader from "../../../home/components/Header/laptop/SectionHeader/SectionHeader";
import "./PermissionsSettings.css";
import { Flex, useToast } from "@chakra-ui/react";

export default function PermissionsSettings() {
	const [isOpenDoor, setIsOpenDoor] = useState(false);
	const toast = useToast();

	useEffect(() => {
		getOpenDoorPolicy();
	}, []);

	const getOpenDoorPolicy = async () => {
		const resp = await saver.settings.openDoorPolicy.getOpenDoorPolicy();
		if (resp.ok) {
			setIsOpenDoor(resp.body);
		} else {
			toast({
				title: `Unable to change policy!`,
				description: "Something went wrong. Try again later.",
				status: "error",
				duration: 6000,
				isClosable: true,
				position: "top",
			});
		}
	};

	const onChangeOpenDoorPolicy = async (value: boolean) => {
		setIsOpenDoor(value);
		const resp = await saver.settings.openDoorPolicy.changeOpenDoorPolicy({
			openDoor: value,
		});
		if (!resp.ok) {
			alert("Error while changing permissions!");
		}
	};

	const permissions = [
		{
			permission: "anyone",
			isOpenDoor: true,
			description: saver.organization?.domain ? (
				<div>
					Anyone with a
					<span className="permission-email">{saver.organization?.domain}</span>
					email address.
				</div>
			) : (
				<div>Anyone with a company email address.</div>
			),
		},
		{
			permission: "invited",
			isOpenDoor: false,
			description: (
				<div>Only people who are invited on the Manage Users page.</div>
			),
		},
	];

	return (
		<div className="PermissionsSettings">
			<div className="PermissionsSettings-container">
				<PageHeader
					icon={permissionsIcon}
					title="Permissions"
					description="Manage your team's access permissions"
				/>

				<div className="PermissionsSettings-content">
					{/* <PermissionsList /> */}
					<SectionHeader>
						Who can join {saver.organization?.name} ?
					</SectionHeader>

					<Flex
						flexFlow={"column"}
						className="Permissions-container"
						gap={"1rem"}
					>
						{permissions.map((permission, index) => {
							const isActive = permission.isOpenDoor === isOpenDoor;

							return (
								<Flex
									className={`permission-option 
                                        ${isActive ? "active" : "inactive"}
                                        ${saver.user?.role !== "admin" ? "disabled" : ""}
                                    `}
									justifyContent={"space-between"}
									onClick={
										saver.user?.role === "admin"
											? () => {
													onChangeOpenDoorPolicy(
														permission.permission === "anyone",
													);
												}
											: undefined
									}
								>
									<div className="permission-description">
										{permission.description}
									</div>
									<div className="toggle-button">.</div>
								</Flex>
							);
						})}
					</Flex>
				</div>
			</div>
		</div>
	);
}
