import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@gigauser/common/src/redux";
import {
	selectCollectionPath,
	updateCollectionPath,
} from "@gigauser/common/src/redux/slices/collectionPathSlice";
import { EnvironmentContextValue } from "@gigauser/common/src/contexts";

import {
	selectCurrentCollectionData,
	setCurrentCollectionData,
	loadingCurrentCollectionData,
} from "@gigauser/common/src/redux/slices/collectionDataSlice";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import { Collection } from "@gigauser/common/src/core/types/collections";
import logger from "@gigauser/common/src/utils/logger";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";

const useFilesData: (env: EnvironmentContextValue) => {
	collectionsData: Collection[];
	guidesData: GuidePreview[];
	loading?: Boolean;
} = (env) => {
	// This hook fetches all data for the files page.
	// This should be called only once - in the files component.
	// TODO: Electron support - fetch from some offline store (perhaps, but unlikely)

	const dispatch = useAppDispatch();

	const collectionPath = useAppSelector(selectCollectionPath);

	const saver = useAppSelector(selectSaver);

	const { collectionsData, guidesData, loading } = useAppSelector(
		selectCurrentCollectionData,
	);

	// We must update these whenever we navigate to a new folder.
	useEffect(() => {
		const getData = async (currentPath: [Collection, ...Collection[]]) => {
			logger.debug("getData currentPath: ", currentPath);

			const collection = currentPath.at(-1) as Collection;
			// Here, you call the API
			// For now, we go and read the data
			const parentID = collection.id;

			try {
				const allChildren = await saver.fetchAllChildren(parentID, false);
				const { collections, guides } = allChildren;

				// const resp = await API.graphql({query: getFolderData, variables: {parentID: parentID}}) as any

				// const {folders, readings, writings} = resp.data.getFolderData
				dispatch(
					setCurrentCollectionData({
						currentCollection: collection,
						collectionsData: collections.filter(
							(c) => c.deleted !== true,
						) as Collection[], //folders as Folder[],
						guidesData: guides.filter(
							(g) => g.deleted !== true,
						) as GuidePreview[],
					}),
				);
			} catch (e) {
				logger.error(e);
			}
		};

		if (collectionPath.loading) {
			dispatch(loadingCurrentCollectionData());
		} else {
			dispatch(loadingCurrentCollectionData());
			getData(collectionPath.segments);
		}
	}, [collectionPath, dispatch]);

	return { collectionsData, guidesData, loading };
};

export default useFilesData;
