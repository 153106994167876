import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "@gigauser/common/src/redux";
import React, { useEffect, useState } from "react";
import HomeLayout from "./home/main/HomeLayout";
import { saver } from "@gigauser/common/src/network/saver";
import SignIn from "@gigauser/common/src/auth/SignIn";
import EditorPlatform from "./editor/main/EditorPlatform";
import GridLoader from "@gigauser/common/src/ui/loaders/GridLoader/GridLoader";
import LogoIcon from "@gigauser/common/src/assets/logos/LogoIcon.svg";

import "../styles/index.css";
import {
	ContextMenuComponent,
	ContextMenuObject,
} from "@gigauser/common/src/layouts/ContextMenu/ContextMenu";
import { CMenuContext } from "@gigauser/common/src/contexts";
import logger from "@gigauser/common/src/utils/logger";
import { getOperatingSystem } from "@gigauser/common/src/utils/os";
import "../App.css";
import {
	AnalyticsEvent,
	AnalyticsLogger,
	captureEvent,
	initializeAnalytics,
} from "@gigauser/common/src/core/analytics/analytics";
import {
	setAnalytics,
	setSaver,
} from "@gigauser/common/src/redux/slices/backendSlice";
import "./AppInitializer.css";
import warningIcon from "@gigauser/common/src/assets/icons/warning.png";
import { useAuthInfo, useLogoutFunction } from "@propelauth/react";
import { propelAuthClient } from "@gigauser/common/src/auth/PropelAuthProvider";
import WelcomeFlow from "./welcome/WelcomeFlow";
import { useNavigate } from "react-router";
import Settings from "./settings/Settings";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import { Spinner } from "@chakra-ui/react";
import LogoLoader from "@gigauser/common/src/ui/loaders/LogoLoader/LogoLoader";
import useShowError from "@gigauser/common/src/redux/hooks/FileHooks/useShowError";
import { getOrGuessFullNameFromUser } from "@gigauser/common/src/ui/users/UserPreview/UserPreview";
import { UsageLimitExceededErrorBody } from "@giga-user-fern/api/types/api";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import posthog from "posthog-js";
import { openModal } from "@gigauser/common/src/layouts/CModal/modalSlice";
import PaymentResultPopup from "@gigauser/common/src/overlays/PaymentResultPopup/PaymentResultPopup";
import tickIcon from "@gigauser/common/src/assets/svgs/tickIcon";
import cross from "@gigauser/common/src/assets/svgs/cross";
import billingIcon from "@gigauser/common/src/assets/svgs/billingIcon";

export type AppInitializerPage =
	| "home"
	| "editor"
	| "home/projects"
	| "home/team"
	| "home/trash"
	| "welcome/personal-details"
	| "welcome/create-team"
	| "welcome/join-team"
	| "settings/profile"
	| "settings/appearance"
	| "settings/manage-users"
	| "settings/permissions"
	| "settings/pronunciation-dictionary"
	| "settings/branding-and-design"
	| "settings/billing"
	| "settings/manage-teams"
	| "settings/kb-config"
	| "home/analytics"
	| "home/trash";

type AppInitializerProps = {
	page: AppInitializerPage;
};

const AppInitializer: React.FC<AppInitializerProps> = (props) => {
	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const { showUsageLimitError, showActionNotPermittedError } = useShowError();
	const [contextMenu, setContextMenu] = useState<ContextMenuObject>({
		menuArray: [],
		show: false,
		X: 0,
		Y: 0,
	});

	const {
		isLoggedIn: isAuthenticated,
		accessToken,
		user,
		loading: isLoading,
	} = useAuthInfo();
	const logOut = useLogoutFunction();
	const [loadingSaver, setLoadingSaver] = useState(true);
	const [userNotAssociatedToAnyOrg, setUserNotAssociatedToAnyOrg] =
		useState(false);
	const [invalidUser, setInvalidUser] = useState(false);

	useEffect(() => {
		if (isAuthenticated) {
			initApp();

			//set the font size for rem based on os
			const os = getOperatingSystem();
			var root = document.documentElement;

			if (os === "Windows") {
				// Set the font size
				root.style.fontSize = "12px";
			} else {
				root.style.fontSize = "15px";
			}

			console.log("pylon: ", process.env.REACT_APP_PYLON_APP_ID);

			//@ts-ignore
			window.pylon = {
				chat_settings: {
					app_id: process.env.REACT_APP_PYLON_APP_ID,
					email: user.email,
					name: getOrGuessFullNameFromUser(user),
					avatar_url: user.pictureUrl,

					// (Optional) If you are using Pylon's identity verification
					email_hash: process.env.REACT_APP_PYLON_EMAIL_HASH,
				},
			};
		}
	}, [isAuthenticated]);

	const showContextMenu = (cont: ContextMenuObject | false) => {
		if (!cont) {
			setContextMenu({
				menuArray: [],
				show: false,
				X: 0,
				Y: 0,
			});
		} else {
			setContextMenu(cont);
		}
	};

	const isContextMenuVisible = () => {
		if (contextMenu.show) return true;
		else return false;
	};

	const initApp = async () => {
		// -----------Initializing the Access Token --------------
		try {
			// if(isAuthenticated && user){
			if (accessToken) {
				try {
					const org = await saver.initializePlatform(async () => {
						return propelAuthClient
							.getAuthenticationInfoOrNull()
							.then((d) => d?.accessToken as string);
					});
					const urlParams = new URLSearchParams(window.location.search);
					const success = urlParams.get("success");
					const paymentSessionId = urlParams.get("payment_session_id");

					if (paymentSessionId) {
						const resp = await saver.validateCheckout(paymentSessionId);
						if (resp.ok) {
							dispatch(
								openModal({
									component: (
										<PaymentResultPopup success={success === "true"} />
									),
									heading: {
										hideSeperator: true,
										title: success === "true" ? "Congratulations!" : "Error!",
										icon: billingIcon("white"),
									},
								}),
							);
						}
						urlParams.delete("success");
						urlParams.delete("payment_session_id");
						window.history.replaceState(
							{},
							document.title,
							`${window.location.pathname}?${urlParams.toString()}`,
						);
					}
					saver.handleErrors = (resp) => {
						if (resp.error && resp.error.error === "UsageLimitExceededError") {
							const randError: UsageLimitExceededErrorBody = {
								limitName: "Random Error",
								requiredUsage: 4,
								limitValue: 3,
								currentUsage: 3,
							};
							showUsageLimitError(resp.error.content);
							// handleUsageLimitError(randError);
						}
						if (resp.error && resp.error.error === "ActionNotPermittedError") {
							showActionNotPermittedError();
						}
					};
					console.log("replacing capture event function");
					saver.captureEvent = (event: AnalyticsEvent) => {
						captureEvent(event);
					};
					const analytics = new AnalyticsLogger(org, user?.email);

					initializeAnalytics(org);
					dispatch(setSaver(saver));
					dispatch(setAnalytics(analytics));
					setLoadingSaver(false);
				} catch (e: any) {
					if (e.userExists && !e.associatedToOrg) {
						initializeAnalytics(undefined, saver.user?.email);
						setUserNotAssociatedToAnyOrg(true);
						if (
							//Auth TODO: Fix this.
							!props.page.includes("welcome/")
						) {
							navigate("/welcome/personal-details");
						}
					} else {
						setInvalidUser(true);
					}
					throw new Error("Invalid User!");
				}
			}

			// }
		} catch (e) {
			console.log(e);
			logger.error("Could not get token: ", e);
		}
	};

	const onLogout = async () => {
		logOut(true);
		posthog.reset();
	};

	const getComponent = () => {
		// if (error) {
		// 	logger.error("Error with Auth0: ", error);

		// 	return (
		// 		<div className="platform-main-loading">
		// 			Oops... {error.message}. Please refresh the page.{" "}
		// 		</div>
		// 	);
		// }

		if (invalidUser) {
			return (
				<div className="platform-main-loading">
					<div className="platform-main-loading-header">
						<img className="platform-main-loading-icon" src={warningIcon}></img>
						<h1 className="oops-h1">Oops! You don't have a Clueso account.</h1>
					</div>

					<p className="platform-main-loading-p">
						<ul>
							<li>
								If your organization is already a Clueso customer, please
								contact the Clueso admin from your organization to add you to
								their team.
							</li>
							<li>
								If your organization is not yet a Clueso customer, please reach
								out to us at{" "}
								<a
									className="email-pin"
									href="mailto:hello@clueso.io"
									target="_blank"
								>
									hello@clueso.io
								</a>{" "}
								to set up Clueso for your company.
							</li>
						</ul>
						In case of any other questions, please reach out at{" "}
						<a
							className="email-pin"
							href="mailto:hello@clueso.io"
							target="_blank"
						>
							hello@clueso.io
						</a>
					</p>

					<p className="platform-main-loading-p">
						You are signed in as{" "}
						<span className="curr-email">{user?.email}</span>. Click the button
						below to use a different account.
					</p>

					<button className="platform-main-logout-button" onClick={onLogout}>
						Logout
					</button>
				</div>
			);
		}
		if (isLoading) {
			//Waiting to see if user is authenticated or not.

			return (
				<div className="platform-main-loading">
					{/* <GridLoader center /> */}
					<LogoLoader />
				</div>
			);
		}

		if (props.page.includes("welcome")) {
			return (
				<WelcomeFlow
					userAssociatedWithAnyOrg={!userNotAssociatedToAnyOrg}
					page={props.page}
				/>
			);
		}

		//We are done checking if any user is signed in or not
		else if (!user || !isAuthenticated) {
			//The user is not signed in.
			console.log("The user is not signed in : ", user, isAuthenticated);
			return (
				<div className="AppInitializer-auth">
					<div className="PlatformSignIn-container">
						<SignIn method="redirect" />
					</div>
				</div>
			);
		}

		//There is a signed in user
		else if (loadingSaver || !saver.organization) {
			return (
				<div>
					<LogoLoader />
					{/* <Splash/> */}
					{/* <button>Log out</button> */}
				</div>
			);
		} else {
			//We are done loading the saver
			if (props.page == "editor") {
				return <EditorPlatform />;
			} else return <HomeLayout />;
		}
	};

	return (
		<CMenuContext.Provider
			value={{
				showContextMenu,
				isContextMenuVisible,
			}}
		>
			<div className={`platform-parent ${getOperatingSystem()}`}>
				{getComponent()}
			</div>
			{contextMenu.show ? (
				<ContextMenuComponent
					visible={contextMenu.show}
					X={contextMenu.X}
					Y={contextMenu.Y}
					menuArray={contextMenu.menuArray}
					showContextMenu={showContextMenu}
					size={props.page === "editor" ? "s" : "n"}
				/>
			) : null}
		</CMenuContext.Provider>
	);
};
export default AppInitializer;
