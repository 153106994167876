//TODO: This file is completely broken.

import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "./FileExplorer.css";

import { rootCollection, Item } from "@gigauser/common/src/types/files";
import Button from "@gigauser/common/src/ui/buttons/Button/Button";

import MoveReading from "./items/MoveReading";
import MoveFolder from "./items/MoveFolder";
import upload_file from "@gigauser/common/src/assets/legacy_assets/icons/upload_file.svg";
import folder_color from "@gigauser/common/src/assets/legacy_assets/icons/folder_color.svg";
import close from "@gigauser/common/src/assets/legacy_assets/icons/close.svg";
import InputField from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import Clickout from "@gigauser/common/src/layouts/Clickout/Clickout";
import { CurrentCollectionData } from "@gigauser/common/src/redux/slices/collectionDataSlice";

import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	closeOverlay,
	openOverlay,
} from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import useCreateCollection from "@gigauser/common/src/redux/hooks/FileHooks/useCreateCollection";
import useMoveItem from "@gigauser/common/src/redux/hooks/FileHooks/useMoveItem";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import { selectCollectionPath } from "@gigauser/common/src/redux/slices/collectionPathSlice";
import Breadcrumbs from "@gigauser/common/src/ui/tabs/Breadcrumbs/Breadcrumbs";

import arrow_left_short from "@gigauser/common/src/assets/legacy_assets/icons/arrow_left_short.svg";
import tick from "@gigauser/common/src/assets/legacy_assets/icons/tick.svg";

import loading_sec from "@gigauser/common/src/assets/legacy_assets/icons/gifs/loading_secondary.gif";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { GuidePreview } from "@giga-user-fern/api/types/api/resources/guides";
import logger from "@gigauser/common/src/utils/logger";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { Flex, theme } from "@chakra-ui/react";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import folderIcon from "@gigauser/common/src/assets/svgs/home/folderIcon";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import { closeModal } from "@gigauser/common/src/layouts/CModal/modalSlice";

type FileExplorerProps = {
	//move for moving files around.
	action: "move";
	item: Item; //the object that is to be moved.
	theme?: "light" | "dark";
	initPath?: Collection[];
	onSuccessHandler?: (parentId: string) => void;
} & {
	// | {
	//       //open for opening a new file
	//       action: "openReading";
	//       object?: Folder; //The starting folder. Defaults to the root if not specified.
	//       onClick: (a: ReadingDocument, completedCallback?:()=>void) => void;
	//   }
	// | {
	//       //open for opening a new file
	//       action: "openWriting";
	//       object?: Folder; //The starting folder. Defaults to the root if not specified.
	//       onClick: (a: WritingDocument, completedCallback?:()=>void) => void;
	//   }
	// disabledItems: ("Reading" | "Writing")[];
};

const FileExplorer: React.FC<FileExplorerProps> = (props) => {
	var itemData: GuidePreview | Collection;

	if (props.action == "move") {
		itemData = props.item.entry;
	}

	const [disable, setDisable] = useState(true);
	const [createInProgress, setCreateInProgress] = useState<boolean>(false);
	const saver = useAppSelector(selectSaver);
	const collectionPath = useAppSelector(selectCollectionPath);

	const [currentPath, setCurrentPath] = useState<Collection[]>(
		props.initPath ?? collectionPath.segments,
	);

	const [newCollectionClicked, setNewCollectionClicked] = useState(false);
	const [newCollectionName, setNewCollectionName] =
		useState<string>("Untitled");

	const env = useContext(EnvironmentContext);

	const backendCreateCollection = useCreateCollection(env);
	const backendMoveItem = useMoveItem(env);

	const [currCollectionData, setCurrCollectionData] =
		useState<CurrentCollectionData>({
			currentCollection: rootCollection,
			collectionsData: [],
			guidesData: [],
			loading: true,
		});

	const dispatch = useAppDispatch();

	const updateCollectionData = () => {
		const currentParent = currentPath.at(-1) as Collection;
		if (currentParent.id === itemData.parentId) {
			setDisable(true);
		} else {
			setDisable(false);
		}

		// Fetch new data
		const respPromise = saver.fetchAllChildren(currentParent.id, false);
		respPromise.then((resp: any) => {
			const collections = resp.collections;
			const guides = resp.guides;

			setCurrCollectionData({
				currentCollection: currentParent,
				collectionsData: collections.filter(
					(x: Collection) => x.id !== itemData.id && !x.deleted,
				),
				guidesData: guides.filter((x: GuidePreview) => !x.deleted),
				loading: false,
			});
		});
	};

	useEffect(() => {
		// Set inner folder data
		updateCollectionData();
	}, [currentPath, props.item]); //objectData was here.

	const onChangeName = (e: any) => {
		const target = e.target as HTMLInputElement;
		logger.debug("onChangeName: ", target.value);
		setNewCollectionName(target.value);
	};

	const createFolder = async () => {
		const name = newCollectionName;
		logger.debug("new Folder name: ", name);
		//Add the code to create folder here
		const currentParent = currentPath.at(-1) as Collection;

		logger.debug("currentParent: ", currentParent);

		if (name === "" || name == null) {
			logger.debug("Please enter something");
		} else {
			setCreateInProgress(true);
			try {
				const resp = await backendCreateCollection({
					name: name,
					parentId: currentParent.id,
					private: true,
				});
				if (resp) {
					setCurrCollectionData({
						currentCollection: currentParent,
						collectionsData: currCollectionData.collectionsData.concat([resp]),
						guidesData: currCollectionData.guidesData,
						loading: false,
					});
				}
				// TODO: add error handling
			} catch (e) {
				return;
			} finally {
				setCreateInProgress(false);
			}

			logger.debug(name, "Folder Created");
			logger.debug("setNewFolder", false);
			setNewCollectionClicked(false);
			setNewCollectionName("Untitled");
		}
	};

	const moveFolder = async () => {
		// Edit object move
		const currentParent = currentPath.at(-1) as Collection;
		logger.debug("currentParent: ", currentParent);

		dispatch(closeModal());

		if (props.item.type === "Collection" || true) {
			//CODE HERE
			try {
				const resp = await backendMoveItem({
					item: props.item,
					newParentID: currentParent.id,
				});
				if (!resp) {
					logger.error("There was some error!");
				}
			} catch (e) {
				logger.error("Error while moving folder: ", e);
			}

			if (props.onSuccessHandler) {
				props.onSuccessHandler(currentParent.id);
			}
		}
	};

	const setRootFolder = () => {
		setCurrentPath([rootCollection]);
	};

	const closeInputField = () => {
		setNewCollectionClicked(false);
		logger.debug("closeInputField", false);
		// setNewFolderName("Untitled")
	};

	const openInputField = () => {
		logger.debug("openInputField", true);

		setNewCollectionClicked(true);
	};

	const currentParent = currentPath.at(-1) as Collection;

	const navigationHandler = (segment: Collection) => {
		const tempPath = [...currentPath];
		const index = tempPath.indexOf(segment);
		tempPath.length = index + 1;
		setCurrentPath(tempPath);
	};

	var isAtRoot =
		currentPath.length == 1 && currentPath[0].id === "Collection_root";

	const goBack = () => {
		if (isAtRoot) {
			return;
		} else if (currentParent !== rootCollection) {
			logger.debug(currentPath.slice(0, -1));
			if (Array.isArray(currentPath)) setCurrentPath(currentPath.slice(0, -1));
		} else {
			//go back root
			setRootFolder();
		}
	};

	return (
		<div className={`FileExplorer FileExplorer-${theme}`}>
			<div className="navigator">
				<img
					src={arrow_left_short}
					className="back-button"
					onClick={goBack}
				></img>

				<Breadcrumbs
					rootHandler={setRootFolder}
					segments={currentPath as [Collection, ...Collection[]]}
					navigationHandler={navigationHandler}
				/>
			</div>

			<div className="files-list show-scroll">
				{newCollectionClicked ? (
					// <Clickout closeFunction={() => {createFolder(newCollectionName)}}>
					<div className="create-folder">
						<div className="new-folder-name">
							<img className="file-icon" src={folder_color}></img>
							<div className="folder-name-input">
								<InputField
									id="newCollectionName"
									value={newCollectionName}
									onChange={onChangeName}
									onEnter={createFolder}
									edges="sharp"
									autoFocus
								/>
							</div>
						</div>
						{!createInProgress ? (
							<>
								<img
									className="tick-icon"
									src={tick}
									onClick={createFolder}
								></img>
								<img
									className="close-icon"
									src={close}
									onClick={closeInputField}
								></img>
							</>
						) : (
							<img className="loading-create" src={loading_sec}></img>
						)}
					</div>
				) : // </Clickout>
				null}
				<div className="folders">
					{currCollectionData.loading ? "Loading..." : null}

					{currCollectionData.collectionsData.map((collection) => (
						<MoveFolder
							collection={collection}
							setDisable={setDisable}
							setCurrentParent={(x) => {
								setCurrentPath(currentPath.concat([x]));
							}}
						/>
					))}
				</div>

				<div className="readings">
					{currCollectionData.guidesData.map((guidePreview) => (
						<MoveReading
							onClick={(completedCallback: () => void) => {}}
							name={guidePreview.header.name}
							disabled={true}
						/>
					))}
				</div>
			</div>
			{props.action === "move" ? (
				<Flex className="buttons" gap="1rem">
					<Cutton
						fullWidth
						leftIcon={plusIcon()}
						rank="quaternary"
						onClick={openInputField}
					>
						Create new folder
					</Cutton>
					<Cutton
						fullWidth
						size="l"
						leftIcon={folderIcon()}
						rank="primary"
						onClick={moveFolder}
						// isDisabled={disable || isAtRoot}
					>
						Move Here
					</Cutton>
				</Flex>
			) : null}
			{
				// props.action==="openReading"
				false ? (
					<div className="buttons">
						<div
							className={`createNewFolder ${isAtRoot ? "disabled" : ""}`}
							onClick={openInputField}
						>
							Create new folder
						</div>
					</div>
				) : null
			}
		</div>
	);
};
export default FileExplorer;
