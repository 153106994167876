const convertToUrl = (string: string) => {
	const url = string
		.toLowerCase()
		.replace(/[^a-z0-9]+/g, "-")
		.replace(/^-+/g, "");
	if (string) return url;
	else return "";
};
function isValidDomain(domain: string) {
	const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)*[a-zA-Z0-9][a-zA-Z0-9-_]{0,62}\.[a-zA-Z]{2,}$/;
	return domainRegex.test(domain);
}
export { convertToUrl, isValidDomain };
