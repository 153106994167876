import LoadingRing from "@gigauser/common/src/assets/gifs/LoadingRing/LoadingRing";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import React, { useEffect, useState } from "react";
import "./ExportArticle.css";
import copyIcon from "@gigauser/common/src/assets/svgs/copyIcon";
import { useToast } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	closeOverlay,
	openOverlay,
} from "@gigauser/common/src/layouts/Overlay";
import { useEffectOnce } from "@gigauser/common/src/hooks/useEffectOnce";
import { eventEmitter } from "@gigauser/common/src/utils/events";

import { selectGuide } from "@gigauser/common/src/redux/slices/guideSlice";
import { Guide } from "@gigauser/common/src/core/types/guide";
import {
	copyArticleToClipboard,
	copyMarkdownToClipboard,
	copyRawHTMLToClipboard,
} from "@gigauser/common/src/components/formats/RichText/utils/exportProcessing";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import googledocs from "../../../assets/images/export-logos/googledocs.svg";
import notion from "../../../assets/images/export-logos/notion.svg";
import gitbook from "../../../assets/images/export-logos/gitbook.svg";
import IBox from "@gigauser/common/src/ui/messages/iBox/iBox";
import useSaveGuide from "@gigauser/common/src/redux/hooks/FileHooks/useSaveGuide";
import SubscribePopup from "@gigauser/common/src/overlays/SubscribePopup/SubscribePopup";
import { User, UserInOrg } from "@giga-user-fern/api/types/api";

type ExportArticleProps = {};

const ExportArticle: React.FC<ExportArticleProps> = () => {
	const toast = useToast();

	const [html, setHTML] = useState<string>("");
	const [text, setText] = useState<string>("");
	const [markdown, setMarkdown] = useState<string>("");
	const [loading, setLoading] = useState(true);

	const saver = useAppSelector(selectSaver);

	const onSaveGuide = useSaveGuide();

	const dispatch = useAppDispatch();

	const guide = useAppSelector(selectGuide);

	useEffectOnce(() => {
		if (!guide) return;

		eventEmitter.emit("getHTML", async (html: string, markdown: string) => {
			setHTML(html);
			// setLoading(true);
			const save = await onSaveGuide(true);
			var closeOverlayFlag = true;
			const output = await saver.guides.export.article(
				guide,
				html,
				markdown,
				(admins?: UserInOrg[]) => {
					closeOverlayFlag = false;
					dispatch(
						openOverlay({
							component: <SubscribePopup admins={admins} />,
						}),
					);
				},
			);

			if (output) {
				const { htmlOutput, textOutput, markdownOutput } = output;
				setHTML(htmlOutput);
				setText(textOutput);
				setMarkdown(markdownOutput);
				// setVideoUrl(videoOutput);
				setLoading(false);
			} else {
				toast({
					title: "Export article failed!",
					description: "Please contact support or try again later.",
					status: "error",
					duration: 80000,
					isClosable: true,
					position: "top",
				});

				if (closeOverlayFlag) dispatch(closeOverlay());
			}
		});
	});

	const copyLink = async () => {
		dispatch(closeOverlay());

		const res = await copyArticleToClipboard(html, text);

		toast({
			title: "Copied your article",
			description: "Now paste it in your text editor.",
			status: "success",
			duration: 5000,
			isClosable: true,
			position: "top",
		});
	};

	const copyMarkdown = async () => {
		dispatch(closeOverlay());

		const res = await copyMarkdownToClipboard(markdown);

		toast({
			title: "Copied as Markdown",
			status: "success",
			duration: 5000,
			isClosable: true,
			position: "top",
		});
	};
	const copyHTML = async () => {
		dispatch(closeOverlay());

		const res = await copyRawHTMLToClipboard(html);

		toast({
			title: "Copied as HTML",
			status: "success",
			duration: 5000,
			isClosable: true,
			position: "top",
		});
	};

	return (
		<div className={`ExportArticle ${loading ? "loading-export" : ""}`}>
			{loading || false ? (
				<>
					<center>
						<h2>Exporting Article ...</h2>

						<p>This will take about a minute.</p>

						<div className="LoadingRingContainer">
							<LoadingRing noMargin color="pink"></LoadingRing>
						</div>

						<Cutton
							onClick={() => {
								dispatch(closeOverlay());
							}}
							rank="secondary"
						>
							Cancel
						</Cutton>
					</center>
				</>
			) : (
				<>
					<IBox type="success">
						Export successful! Copy in the format of your choice and paste
						anywhere.
					</IBox>

					<div className="Export-Article-Formats">
						<div className="ExportArticle-Format-column">
							<div>
								<h2>Rich text</h2>

								<div className="export-logos-container">
									<ul>
										<li>G Docs</li>
										<li>Intercom</li>
										<li>Zendesk</li>
										<li>Hubspot</li>
										<li>Freshdesk</li>
										<li>Word</li>
									</ul>
								</div>
							</div>
							<center>
								<Cutton
									rank="secondary"
									onClick={copyLink}
									leftIcon={copyIcon("white")}
								>
									Copy Article
								</Cutton>
							</center>
						</div>

						<div className="ExportArticle-Format-column">
							<div>
								<h2>Markdown</h2>
								<div className="export-logos-container">
									{/* <img className='export-logo' src={notion}></img>
                                    <img className='export-logo' src={gitbook}></img> */}
									<ul>
										<li>Notion</li>
										<li>Gitbook</li>
										<li>Readme</li>
									</ul>
								</div>
							</div>
							<center>
								<Cutton
									rank="secondary"
									onClick={copyMarkdown}
									leftIcon={copyIcon("white")}
								>
									Copy Article
								</Cutton>
							</center>
						</div>

						<div className="ExportArticle-Format-column">
							<div className="export-logos-container">
								<h2>Raw HTML</h2>
								<p>For self-hosted websites</p>
							</div>
							<center>
								<Cutton
									rank="secondary"
									onClick={copyHTML}
									leftIcon={copyIcon("white")}
								>
									Copy Article
								</Cutton>
							</center>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
export default ExportArticle;
