import React, { useState } from "react";
import { CMenuContext } from "@gigauser/common/src/contexts";
import InfoMessage from "@gigauser/common/src/layouts/MessagePopup/MessagePopup";

import {
	ContextMenuComponent,
	ContextMenuObject,
} from "@gigauser/common/src/layouts/ContextMenu/ContextMenu";
import FullScreenOverlay from "@gigauser/common/src/layouts/FullScreenOverlay/FullScreenOverlay";
import Overlay from "@gigauser/common/src/layouts/Overlay/Overlay";
import SectionScroller from "@gigauser/common/src/layouts/SectionScroller/SectionScroller";
import Files from "../../components/Files/Files";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar/";

import "./Home.mobile.css";

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
	return (
		<div className="Home mobile">
			{<FullScreenOverlay />}

			{<Overlay />}

			<InfoMessage />

			<div className="Header-row">
				<Header />
			</div>

			<div className="PageContents-row">
				<Files />
			</div>

			<div className="Navbar-row">
				<Navbar />
			</div>

			{/* <Files /> */}
		</div>
	);
};
export default Home;
