import React, { useContext, useEffect, useRef, useState } from "react";
import "./TrimmerTimeline.css";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import TimelineClip, {
	ClipTheme,
} from "../../Timeline/components/TimelineClip/TimelineClip";
import TimelineContainer from "../../Timeline/main/TimelineContainer";
import { Flex } from "@chakra-ui/react";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import scissorsIcon from "@gigauser/common/src/assets/svgs/videoeditor/scissorsIcon";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import circledPauseIcon from "@gigauser/common/src/assets/svgs/videoeditor/circledPauseIcon";
import circledPlayIcon from "@gigauser/common/src/assets/svgs/videoeditor/circledPlayIcon";
import { GigaUserApi } from "@giga-user-fern/api";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectGuide,
	selectVideo,
} from "@gigauser/common/src/redux/slices/guideSlice";
import { VideoClip } from "@gigauser/common/src/core/types/guide";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { setDisableClickoutOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import ZoomSlider from "@gigauser/common/src/ui/sliders/ZoomSlider/ZoomSlider";
import FormattedPlayTime from "@gigauser/common/src/ui/video/components/FormattedPlayTime/FormattedPlayTime";
import FileUpload from "@gigauser/common/src/ui/inputs/file/FileUpload/FileUpload";
import { uploadToPresignedXhr } from "@gigauser/common/src/network/saver";
import HoverTip from "@gigauser/common/src/ui/HoverTip/HoverTip";
import { useUndoRedoListener } from "../../../../../../utils/listenerUtils";

type TrimmerTimelineProps = {
	uploading: number | null;
	setUploading: (progress: number | null) => void;
};

const MIN_CLIP_LENGTH = 0.2;

const TrimmerTimeline: React.FC<TrimmerTimelineProps> = (props) => {
	const cp = useContext(CanvasPlayerContext);

	const _clips = cp.clips;
	var clips = _clips ? [..._clips] : [];

	const [zoomSliderValue, setZoomSliderValue] = useState(50);

	const timelineContainerRef = useRef<HTMLDivElement | null>(null);

	const video = useAppSelector(selectVideo);
	const guide = useAppSelector(selectGuide);
	const saver = useAppSelector(selectSaver);
	const dispatch = useAppDispatch();

	const [loadingNewClip, setLoadingNewClip] = useState(false);

	const allClipThemes = ["slide", "zoom", "shape"];
	var unusedClipThemes = useRef([...allClipThemes]);

	const sourceClipThemes = useRef<{ [key: string]: ClipTheme }>({
		dummy: "clip",
	});

	const [clipsHistory, setClipHistory] = useState<VideoClip[][]>([]);
	const clipHistoryIndex = useRef(1);

	useEffect(() => {
		// remove the past future changes if a new change is made after undo
		let tempHistory = clipsHistory;
		if (clipHistoryIndex.current !== 1) {
			tempHistory = clipsHistory.slice(
				0,
				clipsHistory.length - clipHistoryIndex.current + 1,
			);
		}
		clipHistoryIndex.current = 1;
		setClipHistory(tempHistory);
	}, [clipsHistory.length]);

	const undoRedoShortcut = useUndoRedoListener(
		() => undo(),
		() => redo(),
	);
	useEffect(() => {
		document.addEventListener("keydown", undoRedoShortcut);
		return () => {
			document.removeEventListener("keydown", undoRedoShortcut);
		};
	}, [undoRedoShortcut]);

	useEffect(() => {
		dispatch(setDisableClickoutOverlay(true));
		let clips = [
			{
				srcId: GigaUserApi.Id("dummy"),
				startTime: 0,
				endTime: cp.videoDuration,
			},
		];
		if (
			(!originalEdits && cp.videoDuration) ||
			(originalEdits && originalEdits.clips?.length == 0) ||
			(originalEdits && !originalEdits.clips)
		) {
			// The clips are not available from backend
			cp.setClips(clips);
			setClipHistory([clips]);
		} else if (originalEdits && originalEdits.clips) {
			clips = originalEdits.clips;
			cp.setClips(originalEdits.clips);
			setClipHistory([...clipsHistory, originalEdits.clips]);
		}
		//TODO: Make this filtering more consistent at some point in the lifecycle
		//Perhaps within canvas player itself
		const newSources = originalEdits?.sources || [];
		// Filter out the sources that are not in the clips
		const sourcesInClips = clips.map((clip) => clip.srcId);
		const newSourcesFiltered = newSources.filter((source) =>
			sourcesInClips.includes(source.id),
		);
		cp.setSources(newSourcesFiltered);
	}, [cp.videoDuration]);

	if (!video?.originalSrc) return <></>;

	const { originalEdits } = video;

	// if(clips) {
	//     clips = clips.sort((a, b) => a.startTime - b.startTime)
	// };

	const updateClip = (
		clipIndex: number,
		newStartTime: number,
		newEndTime: number,
	) => {
		// dispatch(setUnsavedChanges(true))

		const newClips = clips.map((clip, index) => {
			if (index === clipIndex) {
				return {
					srcId: clip.srcId,
					startTime: newStartTime,
					endTime: newEndTime,
				};
			}
			return clip;
		});

		cp.setClips(newClips);
		setClipHistory([...clipsHistory, newClips]);
	};

	// const resizeClip = (clipIndex: number, _t: number, handle: "left" | "right") => {
	//     // dispatch(setUnsavedChanges(true))

	//     const origClip = clips[clipIndex]

	//     var newEndTime = origClip.endTime
	//     var newStartTime = origClip.startTime

	//     const t = cp.convertFrontendClipsTimeToBackendClipsTime(_t)

	//     if(handle=="right"){

	//         newEndTime = Math.max(t, origClip.startTime + 0.2)

	//         if(clipIndex == clips.length - 1){
	//             //this is the last clip.
	//             newEndTime = Math.min(newEndTime, cp.videoDuration)
	//         }
	//         else{
	//             newEndTime = Math.min(newEndTime, clips[clipIndex + 1].startTime)
	//         }

	//     }
	//     else if(handle ==="left"){

	//         newStartTime = Math.min(t, origClip.endTime - 0.2)
	//         if(clipIndex == 0){
	//             //this is the last zoom.
	//             newStartTime = Math.max(newStartTime, 0)
	//         }
	//         else{
	//             newStartTime = Math.max(newStartTime, clips[clipIndex - 1].endTime)
	//         }

	//     }

	//     updateClip(clipIndex, newStartTime, newEndTime)

	// }

	// const dragClip = (clipIndex: number, dt: number) => {
	//     const origClip = clips[clipIndex]

	//     const duration = origClip.endTime - origClip.startTime
	//     var newStartTime = origClip.startTime + dt

	//     if(dt>0){
	//         //moving forward
	//         if(clipIndex == clips.length - 1){
	//             //last
	//             newStartTime = Math.min(newStartTime, cp.videoDuration - duration)
	//         }
	//         else{
	//             //There's another clip ahead
	//             const nextClip = clips[clipIndex + 1]
	//             newStartTime = Math.min(newStartTime, nextClip.startTime - duration)
	//         }
	//     }

	//     else{
	//         //moving backward
	//         if(clipIndex == 0){
	//             //first Clip
	//             newStartTime = Math.max(0, newStartTime)
	//         }
	//         else{
	//             //theres another clip before
	//             const prevClip = clips[clipIndex - 1]
	//             newStartTime = Math.max(prevClip.endTime, newStartTime)
	//         }
	//     }

	//     var newEndTime = newStartTime + duration

	//     updateClip(clipIndex, newStartTime, newEndTime)

	// }

	const getFrontendClipsFromClips = (clips: VideoClip[]) => {
		const frontendClips: VideoClip[] = [];

		clips.forEach((clip, index) => {
			if (index == 0) {
				const newClip = {
					srcId: clip.srcId,
					startTime: 0,
					endTime: clip.endTime - clip.startTime,
				};

				frontendClips.push(newClip);
			} else {
				const prevNewClip = frontendClips[index - 1];

				const newClip = {
					srcId: clip.srcId,
					startTime: prevNewClip.endTime,
					endTime: prevNewClip.endTime + (clip.endTime - clip.startTime),
				};

				frontendClips.push(newClip);
			}
		});

		return frontendClips;
	};

	const splitClip = (clipIndex: number, _t: number) => {
		/**
		 * here t is the time wrt frontendClips.
		 *
		 */

		// dispatch(setUnsavedChanges(true))

		//convert t from frontendClips to backendClips.
		//then the rest of the logic should work
		const videoTimeObject = cp.timelineToVideoTime(_t);
		const t_dash = videoTimeObject.time;

		const origClip = clips[clipIndex];

		const clip1: VideoClip = {
			srcId: origClip.srcId,
			startTime: origClip.startTime,
			endTime: t_dash,
		};
		const clip2: VideoClip = {
			srcId: origClip.srcId,
			startTime: t_dash,
			endTime: origClip.endTime,
		};

		if (
			clip1.endTime - clip1.startTime < MIN_CLIP_LENGTH ||
			clip2.endTime - clip2.startTime < MIN_CLIP_LENGTH
		)
			return;

		const updatedClips = [
			...clips.slice(0, clipIndex),
			clip1,
			clip2,
			...clips.slice(clipIndex + 1),
		];

		cp.setClips(updatedClips);
		setClipHistory([...clipsHistory, updatedClips]);
	};

	const reorderClipRight = () => {
		if (!cp.paused) return;

		const clipIndex = cp.activeClipIndex;
		if (!cp.clips) return;
		const tempClips = [...cp.clips];

		const currentVideoTimeObj = cp.timelineToVideoTime(cp.currentTime);

		if (clipIndex >= clips.length - 1) {
			// If the index is out of valid range or at the end, do nothing
			console.error("Invalid clip index or clip is at the last position.");
			return clips;
		} else {
			// Swap the positions of the clip at clipIndex with the one to its right
			const temp = tempClips[clipIndex];
			tempClips[clipIndex] = tempClips[clipIndex + 1];
			tempClips[clipIndex + 1] = temp;
		}

		cp.setClips(tempClips);
		setClipHistory([...clipsHistory, tempClips]);

		const newTime = cp.videoToTimelineTime(
			currentVideoTimeObj.time,
			currentVideoTimeObj.sourceId,
			tempClips,
		);
		cp.hardsetCurrentTime(newTime, true, {
			skipSettingActiveClipIndex: true,
		});

		if (cp.activeClipIndex >= 0) {
			cp.setActiveClipIndex(cp.activeClipIndex + 1);
		}
	};

	const reorderClipLeft = () => {
		if (!cp.paused) return;

		const clipIndex = cp.activeClipIndex;
		if (!cp.clips) return;
		const tempClips = [...cp.clips];

		const currentVideoTimeObj = cp.timelineToVideoTime(cp.currentTime);

		if (clipIndex <= 0) {
			// If the index is out of valid range or at the end, do nothing
			console.error("Invalid clip index or clip is at the last position.");
			return clips;
		} else {
			// Swap the positions of the clip at clipIndex with the one to its right
			const temp = tempClips[clipIndex];
			tempClips[clipIndex] = tempClips[clipIndex - 1];
			tempClips[clipIndex - 1] = temp;
		}

		cp.setClips(tempClips);
		setClipHistory([...clipsHistory, tempClips]);

		cp.hardsetCurrentTime(
			cp.videoToTimelineTime(
				currentVideoTimeObj.time,
				currentVideoTimeObj.sourceId,
				tempClips,
			),
			true,
			{
				skipSettingActiveClipIndex: true,
			},
		);

		if (cp.activeClipIndex >= 0) cp.setActiveClipIndex(cp.activeClipIndex - 1);
	};

	const assignThemeToSource = (newSourceId: GigaUserApi.Id) => {
		if (unusedClipThemes.current.length == 0)
			unusedClipThemes.current = [...allClipThemes];
		sourceClipThemes.current[newSourceId.toString()] =
			unusedClipThemes.current.pop() as ClipTheme;
	};

	const onAddClip = async (file: File) => {
		if (!guide) return;

		setLoadingNewClip(true);

		var mime = file.type; // Access the file type

		const post = await saver.guides.sources.upload.initiate(guide?.id, mime);

		if (post.ok) {
			const res = await uploadToPresignedXhr(
				file,
				post.body.url,
				saver.captureEvent,
				(progress: number) => {
					if (progress >= 100) {
						props.setUploading(null);
					} else {
						props.setUploading(progress);
					}
				},
			);
			const newRes = await saver.guides.sources.upload.finish({
				guideId: guide.id,
				source: { id: post.body.id, src: post.body.src },
			});
			const video = document.createElement("video");
			video.src = post.body.getUrl as string;

			video.onloadedmetadata = () => {
				const duration = video.duration;

				const newSource = cp.addNewSource(
					post.body.id,
					post.body.getUrl as string,
				);
				assignThemeToSource(newSource.id);

				const videoTimeObject = cp.timelineToVideoTime(cp.currentTime);
				const t_dash = videoTimeObject.time;

				const activeClipIndex = cp.activeClipIndexRef?.current || 0;

				const origClip = clips[activeClipIndex];

				const clip_prev: VideoClip = {
					srcId: origClip.srcId,
					startTime: origClip.startTime,
					endTime: t_dash,
				};

				const clip_new: VideoClip = {
					srcId: newSource.id,
					startTime: 0,
					endTime: duration,
				};

				const clip_next: VideoClip = {
					srcId: origClip.srcId,
					startTime: t_dash,
					endTime: origClip.endTime,
				};

				const updatedClips = [
					...clips.slice(0, activeClipIndex),
					clip_prev,
					clip_new,
					clip_next,
					...clips.slice(activeClipIndex + 1),
				];

				cp.setClips(updatedClips);
				setClipHistory([...clipsHistory, updatedClips]);

				setLoadingNewClip(false);
			};
		} else {
			//errored out
			setLoadingNewClip(false);
		}
	};

	const undo = () => {
		if (
			!cp.paused ||
			clipsHistory.length <= 1 ||
			clipHistoryIndex.current >= clipsHistory.length
		) {
			return;
		}

		clipHistoryIndex.current++;
		cp.setClips(clipsHistory[clipsHistory.length - clipHistoryIndex.current]);
	};

	const redo = () => {
		if (
			!cp.paused ||
			clipsHistory.length <= 1 ||
			clipHistoryIndex.current === 1
		) {
			return;
		}

		clipHistoryIndex.current--;
		cp.setClips(clipsHistory[clipsHistory.length - clipHistoryIndex.current]);
	};

	const deleteClip = (clipIndex: number) => {
		if (clipIndex == clips.length - 1) {
			//deleting the last clip. go to start of prev clip
			const prevClip = clips[clipIndex - 1];
			cp.hardsetCurrentTime(
				cp.videoToTimelineTime(prevClip.startTime + 0.0001, prevClip.srcId),
				true,
			);
		} else {
			// const nextClip = clips[clipIndex + 1]
			// cp.hardsetCurrentTime(nextClip.startTime, true)
		}

		const updatedClips = [...clips];
		updatedClips.splice(clipIndex, 1);
		cp.setClips(updatedClips);
		setClipHistory([...clipsHistory, updatedClips]);
	};

	const getSourceClipTheme: (sourceId: string) => ClipTheme = (sourceId) => {
		if (!sourceId.includes("clip_")) {
			return "clip";
		} else if (sourceClipThemes.current[sourceId]) {
			return sourceClipThemes.current[sourceId];
		} else return "zoom";
	};

	if (!clips.length)
		return <div className="TrimVideo-loading">Loading ...</div>;

	const iconColor = "hsl(217, 19%, 79%)";

	return (
		<div>
			<div className="TrimVideo-buttons">
				<Flex gap="1rem" className="left-buttons">
					<Cutton
						rank="secondary"
						leftIcon={scissorsIcon(iconColor)}
						onClick={() => {
							splitClip(cp.activeClipIndex, cp.currentTime);
						}}
					>
						Split here
					</Cutton>
					<Cutton
						rank="secondary"
						disabled={clips.length == 1}
						onClick={() => {
							deleteClip(cp.activeClipIndex);
						}}
						leftIcon={trashIcon("#ffa69e")}
					>
						Delete clip
					</Cutton>

					<div className="TrimVideo-buttons-split">.</div>

					<FileUpload fileTypes={[".mp4, .mov"]} onChange={onAddClip}>
						<Cutton
							betaLabel
							isLoading={loadingNewClip}
							loadingText="Adding ..."
							rank="secondary"
							leftIcon={plusIcon(iconColor)}
						>
							Add clip
						</Cutton>
					</FileUpload>
				</Flex>

				<Flex gap="1rem">
					<Icon
						hoverTip={!cp.paused ? "Pause" : "Play"}
						hoverPosition="top"
						className={`VideoPlayer-icon play-icon ${!cp.pausedRef ? "pause-icon" : ""}`}
						onClick={cp.paused ? cp.play : cp.pause}
					>
						{!cp.paused
							? circledPauseIcon("#bfc7d4")
							: circledPlayIcon("#bfc7d4")}
					</Icon>

					<FormattedPlayTime
						currentTime={cp.currentTime}
						totalTime={cp.getVideoDuration()}
					/>
				</Flex>

				<div className="Trim-right-buttons">
					<Flex>
						<Cutton
							rank="secondary"
							disabled={
								!cp.paused ||
								clipsHistory.length <= 1 ||
								clipHistoryIndex.current >= clipsHistory.length
							}
							onClick={undo}
						>
							Undo
						</Cutton>

						<Cutton
							rank="secondary"
							disabled={
								!cp.paused ||
								clipsHistory.length <= 1 ||
								clipHistoryIndex.current === 1
							}
							onClick={redo}
						>
							Redo
						</Cutton>
					</Flex>

					<Flex>
						<HoverTip hoverTipTitle={"Reorder clip left"}>
							<Cutton
								rank="secondary"
								disabled={
									!cp.paused || cp.activeClipIndex < 1 || cp.clips?.length === 1
								}
								onClick={reorderClipLeft}
							>
								←
							</Cutton>
						</HoverTip>

						<HoverTip hoverTipTitle={"Reorder clip right"}>
							<Cutton
								rank="secondary"
								disabled={
									!cp.paused ||
									cp.activeClipIndex >= clips.length - 1 ||
									cp.clips?.length === 1
								}
								onClick={reorderClipRight}
							>
								→
							</Cutton>
						</HoverTip>
					</Flex>

					<div className="TrimSlider-container">
						<ZoomSlider
							timelineSliderValue={zoomSliderValue}
							setTimelineSliderValue={setZoomSliderValue}
						/>
					</div>
				</div>
			</div>

			<TimelineContainer
				alwaysScroll
				width={"fixed-gap"}
				// unsmoothSolidLine
				timelineSliderValue={zoomSliderValue}
			>
				<div className="TrimmerTimeline" ref={timelineContainerRef}>
					{getFrontendClipsFromClips(clips).map((clip, index) => (
						<TimelineClip
							key={index}
							index={index}
							startTime={clip.startTime}
							endTime={clip.endTime}
							theme={getSourceClipTheme(clip.srcId.toString())}
							// resizeClip={resizeClip}
							// dragClip={dragClip}
							splitClip={splitClip}
							onDelete={clips.length > 1 ? deleteClip : undefined}
							active={cp.activeClipIndex == index}
						/>
					))}
				</div>
			</TimelineContainer>
		</div>
	);
};
export default TrimmerTimeline;
