// cModal.tsx
import React from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
    Flex
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { closeModal } from "./modalSlice";
import "./CModal.css";
import Icon from "../../ui/Icon/Icon";

const CModal: React.FC = () => {
	const dispatch = useAppDispatch();
	const { isOpen, content } = useAppSelector((state) => state.modal);

	const page = window.location.pathname;

	return (
		<div className={`CModal`}>
			<Modal
				isCentered
				isOpen={isOpen}
				onClose={() => dispatch(closeModal())}
				motionPreset="slideInBottom"
			>
				<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(2px)" />
				<ModalContent>
					<ModalHeader>
						<div className="modal-title">
							<div className="modal-icon-border">
								<div className="modal-icon-container">
									<Icon className="modal-icon">{content?.heading?.icon}</Icon>
								</div>
							</div>

							<Flex flexFlow={"column"} gap={"0.5rem"}>
								<div className="title-text">{content?.heading?.title}</div>
								{content?.heading?.subtitle ? (
									<div className="subtitle-text">
										{content?.heading?.subtitle}
									</div>
								) : null}
							</Flex>
						</div>
					</ModalHeader>
					<hr />
					<ModalCloseButton />
					<ModalBody>
						<div className="gigauser-dark modal-body">{content?.component}</div>
					</ModalBody>
				</ModalContent>
			</Modal>
		</div>
	);
};

export default CModal;
