import React, { useContext } from "react";

import "./Navbar.mobile.css";
import { LabeledMenu, LabeledMenuItem } from "@gigauser/common/src/types/menus";
import library from "@gigauser/common/src/assets/legacy_assets/icons/library.svg";
import star from "@gigauser/common/src/assets/legacy_assets/icons/star.svg";

import { EnvironmentContext } from "@gigauser/common/src/contexts";
import useCollectionNavigate from "@gigauser/common/src/redux/hooks/FileHooks/useCollectionNavigate";
import logger from "@gigauser/common/src/utils/logger";

type NavbarProps = {};

const Navbar: React.FC<NavbarProps> = () => {
	const env = useContext(EnvironmentContext);
	const navigate = useCollectionNavigate(env);

	const navigateToMyLibrary = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		logger.debug("function running");
		//TODO: Put this line back
		// navigate(myLibrary)
	};

	const navigateToSharedFiles = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		logger.debug("function run");
		//TODO: Put this line back
		// navigate(sharedFiles)
	};

	const navbarMenu: LabeledMenu = [
		// {
		//     label: 'Home', icon: home,
		//     handler: () =>{}
		// },

		
		// {
		//     label: 'Folders', icon: folder,
		//     exclusiveTo: 'mobile',
		//     handler: () =>{}
		// },
		// {
		// 	label: "Shared Files",
		// 	icon: star,
		// 	handler: navigateToSharedFiles,
		// },
		// {
		//     label: 'Trash', icon: trash,
		//     handler: () =>{}
		// }
	];

	return (
		<div className="Navbar mobile">
			<div className="Navbar-container">
				{navbarMenu.map((item) => {
					if (item.exclusiveTo !== "laptop")
						return (
							<NavbarMenuItem
								icon={item.icon}
								label={item.label}
								handler={item.handler}
							/>
						);
				})}
			</div>
		</div>
	);
};

const NavbarMenuItem: React.FC<LabeledMenuItem> = ({
	icon,
	label,
	handler,
}) => {
	return (
		<div className="NavbarMenuItem" onClick={handler}>
			{/* <img src={icon} className="NavbarMenuItem-icon"></img> */}
			<div className="NavbarMenuItem-label">{label}</div>
		</div>
	);
};

export default Navbar;
