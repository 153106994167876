import React from "react";
import new_doc from "../../../../../assets/images/new/new_doc.png";
import screen_record from "../../../../../assets/images/new/screen_record.png";
import upload_video from "../../../../../assets/images/new/upload.png";
import ppt from "../../../../../assets/images/new/ppt.png";
import record from "../../../../../assets/images/new/rec.png";
import article from "../../../../../assets/images/new/article.png";
import video_upload from "../../../../../assets/images/new/video_upload.png";

import "./NewGuideOverlay.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectAnalytics,
	selectSaver,
} from "@gigauser/common/src/redux/slices/backendSlice";
import { selectCollectionPath } from "@gigauser/common/src/redux/slices/collectionPathSlice";
import { Collection } from "@gigauser/common/src/core/types/collections";
import folder from "@gigauser/common/src/assets/legacy_assets/icons/folder.svg";
import PreparingGuide from "../PreparingGuide/PreparingGuide";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import { setVideoUploadProgress } from "@gigauser/common/src/redux/slices/platformUiSlice";
import { showErrorMessagePopup } from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import { Flex, useToast } from "@chakra-ui/react";
import {
	closeOverlay,
	openOverlay,
} from "@gigauser/common/src/layouts/Overlay";
import UploadGuideOverlay from "../UploadGuideOverlay/UploadGuideOverlay";
import {
	closeModal,
	openModal,
} from "@gigauser/common/src/layouts/CModal/modalSlice";
import fireIcon from "@gigauser/common/src/assets/svgs/home/fireIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";

type NewGuideOverlayProps = {};

const NewGuideOverlay: React.FC<NewGuideOverlayProps> = () => {
	const saver = useAppSelector(selectSaver);
	const collectionPath = useAppSelector(selectCollectionPath);
	const currentFolder = collectionPath.segments.at(-1) as Collection;
	const analytics = useAppSelector(selectAnalytics);
	const dispatch = useAppDispatch();
	const toast = useToast();

	const onCreateGuide = async () => {
		console.log("hohohoho");
		dispatch(
			openModal({
				heading: {
					icon: plusIcon(),
					title: "Preparing ...",
					data: "",
				},
				component: <PreparingGuide />,
			}),
		);

		const newGuide = await saver.guides.create.emptyTextGuide(currentFolder.id);
		if (newGuide) {
			dispatch(closeModal());
			window.open("/guide/" + newGuide, "_blank");
		}
	};

	const onUploadVideo = async (format: "video" | "pdf") => {
		dispatch(closeModal());
		dispatch(
			openModal({
				heading: {
					icon: plusIcon(),
					title: `Upload  ${format === "pdf" ? "slide deck" : "video"} `,
					data: "",
				},
				component: (
					<UploadGuideOverlay
						format={format}
						currentFolderId={currentFolder.id}
					/>
				),
			}),
		);

		return;
	};

	return (
		<div className="NewGuideOverlay">
			<Flex flexFlow={"column"} gap={"1rem"}>
				<div className="section-text"> VIDEO + ARTICLE</div>

				<NewGuideOption
					title="Capture screen-recording"
					subtitle="Record any process on your screen"
					tagline="Recommended"
					image={record}
					handler={async () => {
						console.log("Extension clicked 1");
						analytics.captureEvent({
							eventName: "CaptureScreenRecordingClicked",
							value: {},
						});
						try {
							const resp = await chrome.runtime.sendMessage(
								"hnfgakkbiefcdbkonflgfkadncclmpme",
								{ type: "ping" },
							);
							console.log(resp);
							if (!resp) {
								window.open(
									"https://chrome.google.com/webstore/detail/clueso-extension/hnfgakkbiefcdbkonflgfkadncclmpme",
									"_blank",
								);
								return;
							}
							if (!resp.version) {
								const resp = await chrome.runtime.sendMessage(
									"hnfgakkbiefcdbkonflgfkadncclmpme",
									{
										type: "NewRecording",
										token: await saver.getTokenPlatform(),
										currentCollectionID: currentFolder.id,
									},
								);
								console.log(resp);
								if (!resp) {
									toast({
										title: `Click on the Clueso chrome extension to start a recording.`,
										description:
											"You'll find it under the puzzle icon on your browser topbar.",
										status: "info",
										duration: 4000,
										isClosable: true,
										position: "top",
									});
								}

								return;
							}
						} catch (e) {
							window.open(
								"https://chrome.google.com/webstore/detail/clueso-extension/hnfgakkbiefcdbkonflgfkadncclmpme",
								"_blank",
							);
							return;
						}

						chrome.runtime.sendMessage("hnfgakkbiefcdbkonflgfkadncclmpme", {
							type: "SetOrganizationId",
							organizationId: saver.organization?.id,
						});

						console.log("Extension clicked");
						window.postMessage(
							{ msg: "ExtensionClicked", id: currentFolder.id },
							window.origin,
						);
					}}
				/>

				<NewGuideOption
					title="Upload a video"
					subtitle="Upload a screen-recording from your computer"
					image={video_upload}
					handler={() => {
						analytics.captureEvent({
							eventName: "UploadVideoClicked",
							value: {},
						});
						onUploadVideo("video");
					}}
				/>

				<NewGuideOption
					title="Upload a slide deck"
					subtitle="Turn any PDF slide deck into a narrated video"
					image={ppt}
					handler={() => {
						analytics.captureEvent({
							eventName: "UploadPDFClicked",
							value: {},
						});
						onUploadVideo("pdf");
					}}
				/>

				<div className="section-text">ARTICLE ONLY (NO VIDEO)</div>

				<NewGuideOption
					title="Type out a guide"
					subtitle="If you don't want a video, type out an article from scratch."
					image={article}
					handler={onCreateGuide}
				/>
			</Flex>
		</div>
	);
};

export default NewGuideOverlay;

type NewGuideOptionProps = {
	title: string;
	subtitle: string;
	tagline?: string;
	image: string;
	handler: () => void;
};

const NewGuideOption: React.FC<NewGuideOptionProps> = (props) => {
	const dispatch = useAppDispatch();

	const handler = () => {
		dispatch(closeModal());
		props.handler();
	};

	return (
		<div
			className={`NewGuideOption ${props.tagline ? "special" : ""}`}
			onClick={handler}
		>
			<img
				className={`NewGuideOption-image ${props.tagline == "MOST COMMON" ? "screen" : ""}`}
				src={props.image}
			></img>

			<div className="NewGuideOption-text">
				<div className="NewGuideOption-title-container">
					<h1 className="NewGuideOption-title">{props.title}</h1>
					{props.tagline ? (
						<div className="NewGuideOption-tagline">
							<Flex gap="0.5rem">
								<Icon className="fireIcon">{fireIcon()}</Icon>
								<div>{props.tagline}</div>
							</Flex>
						</div>
					) : undefined}
				</div>
				<h2 className="NewGuideOption-subtitle">{props.subtitle}</h2>
			</div>
		</div>
	);
};
