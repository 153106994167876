import React, { useEffect, useState } from "react";
import "./WorkspaceSwitcher.css";
import { useAuthInfo, useLogoutFunction, User } from "@propelauth/react";
import UserPreview from "../../ui/users/UserPreview/UserPreview";
import { saver } from "../../network/saver";
import downArrowheadIcon from "../../assets/svgs/downArrowheadIcon";
import Icon from "../../ui/Icon/Icon";
import Clickout from "../../layouts/Clickout/Clickout";
import Menu from "../../ui/menus/Menu/Menu";
import { Organization } from "@giga-user-fern/api/types/api/resources/organizations";
import { InitResponse } from "@giga-user-fern/api/types/api";
import ProfilePreview from "../ProfilePreview/ProfilePreview";
import { Flex } from "@chakra-ui/react";
import CSearch from "../../ui/inputs/CSearch/CSearch";
import logoutIcon from "../../assets/svgs/logoutIcon";
import tickIconCircled from "../../assets/svgs/tickIconCircled";

type WorkspaceSwitcherProps = {
	logout?: () => void;
	direction: "up" | "down";
};

const WorkspaceSwitcher: React.FC<WorkspaceSwitcherProps> = (props) => {
	const [loading, setLoading] = useState(true);
	const logout = useLogoutFunction();
	const [showDropdown, setDropdown] = useState<boolean>(false);
	const { user } = useAuthInfo();

	const [profileOptions, setProfileOptions] = useState<InitResponse[] | null>(
		null,
	);

	const setOrganization = (organization: Organization) => {
		localStorage.setItem("organization_id", organization.id);
		window.location.assign("/");
	};

	const init = async () => {
		const orgs = (await saver.getMyUsers()).filter(
			(initOption) => initOption.userWithOrg.org.id !== saver.organization?.id,
		);
		setProfileOptions(orgs);
		setLoading(false);
	};

	useEffect(() => {
		init();
	}, []);

	const onLogOut = () => {
		if (props.logout) {
			//logout override from props
			props.logout();
		} else {
			logout(false);
		}
		// window.close();
	};

	if (!user) return null;

	if (loading) return <>Loading ...</>;

	return (
		<div className="WorkspaceSwitcher">
			<div
				className="WorkspaceSwitcher-profile"
				onClick={() => {
					setDropdown(!showDropdown);
				}}
			>
				<UserPreview
					size="l"
					user={{
						firstName: saver.organization?.name,
						email: user.email,
					}}
					description={user.email}
				/>
				{user.pictureUrl ? (
					<img
						className="WorkspaceSwitcher-profilePicture"
						src={user.pictureUrl}
					></img>
				) : null}
				<Icon className="WorkspaceSwitcher-downarrow">
					{downArrowheadIcon()}
				</Icon>
			</div>
			{showDropdown ? (
				<div
					className={`WokspaceSwitcher-dropdown-container slide-in-up ${props.direction} `}
				>
					<Clickout
						closeFunction={() => {
							setDropdown(false);
						}}
					>
						{/* <ArrowDropdown> */}

						<div className="UserPreview-container">
							<UserPreview
								size="l"
								user={{
									firstName: user.firstName,
									lastName: user.lastName,
									email: user.email,
									pictureUrl: user.pictureUrl,
								}}
							/>
						</div>

						<hr />

						{profileOptions && profileOptions.length > 0 ? (
							<div className="Teams-container">
								<div className="section-text">Teams</div>
								<Flex
									flexFlow={"column"}
									marginBottom={"1rem"}
									className="orgs-container"
								>
									<div
										className={`UserPreview-container TeamPreview-container active`}
									>
										<UserPreview
											user={{
												firstName: saver.organization?.name,
												email: saver.organization?.domain || "",
											}}
										/>

										<Icon className="tickIcon-circled">
											{tickIconCircled()}
										</Icon>
									</div>
									{profileOptions?.map(
										(initOption) => {
											return (
												<div
													onClick={() => {
														setOrganization(initOption.userWithOrg.org);
														chrome.runtime.sendMessage(
															"hnfgakkbiefcdbkonflgfkadncclmpme",
															{
																type: "SetOrganizationId",
																organizationId: initOption.userWithOrg.org.id,
															},
														);
														chrome.runtime.sendMessage(
															"hnfgakkbiefcdbkonflgfkadncclmpme",
															{
																type: "ClearElements",
															},
														);
													}}
													className={`UserPreview-container TeamPreview-container `}
												>
													<UserPreview
														user={{
															firstName: initOption.userWithOrg.org.name,
															email: initOption.userWithOrg.org.domain,
														}}
													/>
												</div>
											);
										},
										// <ProfilePreview
										// 	active={false}
										// 	onClick={() => setOrganization(initOption.userWithOrg.org)}
										// 	organization={initOption.userWithOrg.org}
										// />
									)}
								</Flex>
							</div>
						) : null}

						<hr />

						<div className="buttons">
							<Flex className="button" gap={"1.5rem"} onClick={onLogOut}>
								<Icon className="logout-icon">{logoutIcon()}</Icon>
								<div>Sign out</div>
							</Flex>
						</div>
					</Clickout>
				</div>
			) : null}
		</div>
	);
};
export default WorkspaceSwitcher;
