import React, { useContext, useState } from "react";
import CToggle from "@gigauser/common/src/ui/video/components/CToggle/CToggle";
import {
	Switch,
	Textarea,
	background,
	border,
	useToast,
} from "@chakra-ui/react";
import backgroundIcon from "@gigauser/common/src/assets/svgs/videoeditor/backgroundIcon";
import CSlider from "@gigauser/common/src/ui/video/components/CSlider/CSlider";
import CSection from "../../layout/CSection/CSection";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectBackgroundEdits,
	selectIntroEdits,
	selectOutroEdits,
	updateBackgroundEdits,
} from "@gigauser/common/src/redux/slices/guideSlice";
import musicIcon from "@gigauser/common/src/assets/svgs/videoeditor/musicIcon";
import "./BackgroundCustomizer.css";

import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import {
	addAudioTrack,
	addBackground,
	deleteBackground,
	selectAudioTracks,
} from "@gigauser/common/src/redux/slices/platformDetailsSlice";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import UploadMusic from "../../../UploadMusic/UploadMusic";
import { CBackgrounds } from "@gigauser/common/src/ui/video/components/CBackgrounds/CBackgrounds";
import { BackgroundOption } from "@giga-user-fern/api/types/api";
import { uploadToPresignedXhr } from "@gigauser/common/src/network/saver";
import { Modal } from "antd";
import IBox from "@gigauser/common/src/ui/messages/iBox/iBox";
import orgSetupCanvas from "@gigauser/common/src/core/canvas/orgSetupCanvas";
type BackgroundCustomizerProps = {};

const BackgroundCustomizer: React.FC<BackgroundCustomizerProps> = () => {
	const dispatch = useAppDispatch();
	const backgroundState = useAppSelector(selectBackgroundEdits);
	const cp = useContext(CanvasPlayerContext);
	const saver = useAppSelector(selectSaver);
	const backgroundOptions =
		useAppSelector(
			(state) => state.platformDetails.value?.background?.options,
		) || [];

	const introEdits = useAppSelector(selectIntroEdits);
	const outroEdits = useAppSelector(selectOutroEdits);

	const standardizeDims =
		(introEdits.visible && introEdits.fixDimensions) ||
		(outroEdits.visible && outroEdits.fixDimensions);

	const onHideBackground = (value: boolean) => {
		if (standardizeDims) {
			dispatch(
				updateBackgroundEdits({
					...backgroundState,
					visible: true,
				}),
			);
		} else {
			dispatch(
				updateBackgroundEdits({
					...backgroundState,
					visible: !value,
				}),
			);
		}
	};

	const onUpdateBackground: (object: {
		padding?: number;
		borderRadius?: number;
		shadow?: number;
	}) => void = (updates) => {
		const { padding, borderRadius, shadow } = updates;

		dispatch(
			updateBackgroundEdits({
				...backgroundState,
				padding: padding || backgroundState.padding,
				borderRadius: borderRadius ?? backgroundState.borderRadius,
				shadow: shadow || backgroundState.shadow,
			}),
		);
	};

	const toast = useToast();
	const onUpdatePadding = (value: number) =>
		onUpdateBackground({ padding: value });
	const onUpdateBorderRadius = (value: number) => {
		if (value < 0) {
			value = 0;
		}
		onUpdateBackground({ borderRadius: Math.round(value * 10) / 10 });
	};
	const onUpdateShadow = (value: number) =>
		onUpdateBackground({ shadow: value });

	const hideBackground = !backgroundState.visible;

	const onCreateBackground = () => {
		const fileInput = document.createElement("input");
		fileInput.accept = ".png,.jpg"; // Accept only .png files
		fileInput.type = "file";

		// Attach a change event listener to the file input element
		fileInput.addEventListener("change", async (event) => {
			const input = event.target as HTMLInputElement;
			if (input.files && input.files[0]) {
				// Resolve the promise with the selected Blob
				const blob = input.files[0];
				const post = await saver.uploadAsset();
				if (post.ok) {
					const res = await uploadToPresignedXhr(
						blob,
						post.body.url,
						saver.captureEvent,
					);
					const backgroundOption = await saver.createBackground({
						visible: true,
						src: post.body.src,
						padding: 8,
						borderRadius: 10,
						shadow: 30,
					});
					if (backgroundOption.ok) {
						dispatch(addBackground(backgroundOption.body));
					} else {
						if (backgroundOption.error.error === "CorruptedFileError") {
							toast({
								title: "Failed to create background",
								description: "Please upload a valid image file",
								status: "error",
								duration: 2000,
								isClosable: true,
							});
						} else {
							toast({
								title: "Failed to create background",
								description: "Please try again",
								status: "error",
								duration: 2000,
								isClosable: true,
							});
						}
					}
				}
			}
		});

		// Trigger the click event on the file input to open the file picker
		fileInput.click();
	};

	const onDeleteBackground = async (bg: BackgroundOption) => {
		if (backgroundOptions.length <= 1) {
			toast({
				title: "Can't delete this background!",
				description: "This is your last background. You cannot delete it.",
				status: "warning",
				duration: 2000,
				isClosable: true,
			});
			return;
		}

		const res = saver.deleteBackground(bg.id);
		dispatch(deleteBackground(bg));
	};

	var introDimsFixed = introEdits.visible && introEdits.fixDimensions;
	var outroDimsFixed = outroEdits.visible && outroEdits.fixDimensions;

	const sanitizedPadding = backgroundState.padding || 0;

	return (
		<div className="BackgroundCustomizer">
			{
				<>
					<CSection>
						<CToggle
							disabled={introDimsFixed || outroDimsFixed}
							label="Hide background slide"
							color="pink"
							value={hideBackground}
							setterFunction={onHideBackground}
							explanation={
								introDimsFixed || outroDimsFixed
									? `Can't hide backgroud, since ${introEdits.fixDimensions ? "intro" : "outro"} has fixed dimensions.`
									: undefined
							}
						/>
					</CSection>
					<CSection icon={backgroundIcon} label="Background Image" noMarginTop>
						{/* <CToggle 
                            disabled = {introDimsFixed || outroDimsFixed}
                            label="Hide background slide" value={hideBackground} setterFunction={onHideBackground} 
                                explanation={introDimsFixed || outroDimsFixed ? `Can't hide backgroud, since ${introEdits.fixDimensions?"intro":"outro"} has fixed dimensions.`: undefined}
                        /> */}

						{/* <CSection disabled={hideBackground} noMarginTop> */}

						<CBackgrounds
							onClickNew={onCreateBackground}
							onClickDelete={onDeleteBackground}
						/>

						<></>
					</CSection>
				</>
			}

			<CSection disabled={hideBackground} icon={backgroundIcon} label="Design">
				<CSlider
					disabled={hideBackground}
					label="Padding"
					unit="%"
					value={sanitizedPadding}
					setValue={onUpdatePadding}
					min={0}
					max={20}
				/>

				<CSlider
					disabled={hideBackground}
					label="Border radius"
					unit="%"
					value={backgroundState.borderRadius || 0}
					setValue={onUpdateBorderRadius}
					min={0}
					max={15}
				/>

				{backgroundState.borderRadius && backgroundState.borderRadius > 0 ? (
					<IBox size="s">
						Note: Adding a border radius can double export duration
					</IBox>
				) : null}

				{/* <CSlider disabled={hideBackground}  label='Border radius' unit="px" value={backgroundState.borderRadius || 0} setValue={onUpdateBorderRadius} /> */}
			</CSection>
		</div>
	);
};
export default BackgroundCustomizer;
