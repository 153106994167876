const tickIcon = (color = "currentColor") => {
	return (
		<svg height="100%" viewBox="0 -960 960 960" width="100%">
			<path
				fill={color}
				d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z"
			/>
		</svg>
	);
};

export default tickIcon;
