import React from "react";
import "./PageHeader.css";

type PageHeaderProps = {
	title: string;
	description?: string;
	icon?: (color: string) => React.ReactElement;
};

const PageHeader: React.FC<PageHeaderProps> = (props) => {
	return (
		<div className="PageHeader">
			{props.icon && (
				<div className="PageHeader-icon">{props.icon("white")}</div>
			)}

			<div className="PageHeader-text">
				<div className="PageHeader-title">{props.title}</div>

				{props.description && (
					<div className="PageHeader-description">{props.description}</div>
				)}
			</div>
		</div>
	);
};
export default PageHeader;
