import React, { useContext } from "react";
import "./Navbar.laptop.css";

import NavbarSection from "./NavbarSection/NavbarSection";
import NavbarMenu, { NavbarMenuItem } from "./NavbarMenu/NavbarMenu";
import NavbarFooter from "./NavbarFooter/NavbarFooter";
import { useLocation, useNavigate } from "react-router-dom";
import homeIcon from "@gigauser/common/src/assets/svgs/home/homeIcon";
import useCollectionNavigate from "@gigauser/common/src/redux/hooks/FileHooks/useCollectionNavigate";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import { rootCollection } from "@gigauser/common/src/types/files";
import folderIcon from "@gigauser/common/src/assets/svgs/home/folderIcon";
import creatorsIcon from "@gigauser/common/src/assets/svgs/home/creatorsIcon";
import settingsIcon from "@gigauser/common/src/assets/svgs/settingsIcon";
import analyticsIcon from "@gigauser/common/src/assets/svgs/analyticsIcon";

import profileIcon from "@gigauser/common/src/assets/svgs/home/profileIcon";
import { Flex, Icon } from "@chakra-ui/react";
import downArrowheadIcon from "@gigauser/common/src/assets/svgs/downArrowheadIcon";
import manageUsersIcon from "@gigauser/common/src/assets/svgs/home/manageUsersIcon";
import permissionsIcon from "@gigauser/common/src/assets/svgs/home/permissionsIcon";
import voiceIcon from "@gigauser/common/src/assets/svgs/voiceIcon";
import Logo from "@gigauser/common/src/assets/svgs/logo";
import LogoPro from "@gigauser/common/src/assets/svgs/logoPro";

import Searchbar from "../../Header/laptop/Searchbar/Searchbar";
import useSuperAdmin from "@gigauser/common/src/hooks/useSuperAdmin";
import { saver } from "@gigauser/common/src/network/saver";
import billingIcon from "@gigauser/common/src/assets/svgs/billingIcon";
import { GigaUserApi } from "@giga-user-fern/api";

const BasePlanType = GigaUserApi.organizations.BasePlanType;

const Navbar: React.FC = () => {
	const isSuperAdmin = useSuperAdmin();
	const location = useLocation();
	const navigateRouter = useNavigate();
	const homePage = location.pathname;

	const env = useContext(EnvironmentContext);
	const navigate = useCollectionNavigate(env);

	var homeMenu: NavbarMenuItem[] = [
		// {
		// 	label: "Home",
		// 	icon: homeIcon,
		// 	id: "home",
		// 	handler: () => {
		// 		navigate(rootCollection);
		// 	},
		// },
		{
			label: "All Projects",
			icon: folderIcon,
			id: "home/projects",
			handler: () => {
				navigate(rootCollection);
			},
		},
		{
			label: "Team",
			icon: creatorsIcon,
			id: "home/team",
		},

		{
			label: "Settings",
			icon: settingsIcon,
			id: "settings/profile",
			arrow: true,
		},
		// {
		// 	label: "Analytics",
		// 	icon: analyticsIcon,
		// 	id: "home/analytics",
		// },

		// {
		//     label: 'Analytics', icon: chart, id: 'anal'
		// }
	];

	const profileMenu: NavbarMenuItem[] = [
		{
			label: "Profile",
			icon: profileIcon,
			id: "settings/profile",
		},
	];

	const teamMenu: NavbarMenuItem[] = [
		{
			label: "Manage users",
			icon: manageUsersIcon,
			id: "settings/manage-users",
		},

		// {
		// 	label: "Billing",
		// 	icon: billingIcon,
		// 	id: "settings/billing",
		// },
		// {
		// 	label: "Manage Teams",
		// 	icon: manageTeamsIcon,
		// 	id: "settings/manage-teams",
		// },
	];

	if (saver.user?.role === "admin") {
		teamMenu.push({
			label: "Permissions",
			icon: permissionsIcon,
			id: "settings/permissions",
		});

		teamMenu.push({
			label: "Billing & plans",
			icon: billingIcon,
			id: "settings/billing",
		});
	}

	teamMenu.push({
		label: "Pronunciations",
		icon: voiceIcon,
		id: "settings/pronunciation-dictionary",
	});

	const productMenu: NavbarMenuItem[] = [
		{
			label: "KB Config",
			icon: settingsIcon,
			id: "settings/kb-config",
		},
	];

	return (
		<div className="Navbar laptop">
			{homePage.includes("settings/") ? (
				<div>
					<Flex
						className="SettingsTitle"
						alignItems={"center"}
						gap={"0.5rem"}
						onClick={() => {
							navigateRouter(`/folders/${rootCollection.id}`);
						}}
					>
						<Icon className="backarrow">{downArrowheadIcon("white")}</Icon>
						<div>Settings</div>
					</Flex>

					<hr />

					<NavbarSection header="Account Settings">
						<NavbarMenu menu={profileMenu} />
					</NavbarSection>

					<hr />

					<NavbarSection header="Team Settings">
						<NavbarMenu menu={teamMenu} />
					</NavbarSection>
					{isSuperAdmin("kbconfig") && (
						<>
							<hr />
							<NavbarSection header="Product Settings">
								<NavbarMenu menu={productMenu} />
							</NavbarSection>
						</>
					)}
				</div>
			) : (
				<div>
					{/* <NavbarSection>
						<WorkspaceSwitcher />
					</NavbarSection> */}

					<Flex
						className="Logo-container"
						justifyContent={"space-between"}
						alignItems={"center"}
						onClick={() => window.open("/", "_blank")}
					>
						<div
							className="Navbar-Logo"
							onClick={() => {
								navigateRouter(`/folders/${rootCollection.id}`);
							}}
						>
							{saver.basePlanStatus?.status !== BasePlanType.Pro
								? Logo
								: LogoPro}
						</div>

						{/* <Icon className="icon">
                            {downArrowheadIcon()}
                        </Icon> */}
					</Flex>

					{/* <div className="WorkspaceSwitcher-container">
						<WorkspaceSwitcher direction="down" />
					</div> */}

					<div className="SearchBar-container Topbar-item">
						<Searchbar />
					</div>

					<NavbarSection header="Menu">
						<NavbarMenu menu={homeMenu} />
					</NavbarSection>
				</div>
			)}

			<NavbarFooter />
		</div>
	);
};
export default Navbar;
