import React from "react";
import "./PreparingGuide.css";
import { Progress, Spinner } from "@chakra-ui/react";
import { useAppSelector } from "@gigauser/common/src/redux";
import LoadingTip from "../../../../editor/video/components/LoadingTip/LoadingTip";

type PreparingGuideProps = {
	progressable?: boolean;
	env?: "web" | "chrext";
	tip?: boolean;
	theme?: "light" | "dark";
};

const PreparingGuide: React.FC<PreparingGuideProps> = (props) => {
	const videoUploadProgress = useAppSelector(
		(state) => state.platformUi.value.videoUploadProgress,
	);

	const [currentIndex, setCurrentIndex] = React.useState(0);

	const rotatingTexts = ["article", "video"];

	React.useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % rotatingTexts.length);
		}, 2500); // Rotate every 2.5 seconds

		return () => clearInterval(interval);
	}, []);

	return (
		<div className="PreparingGuide">
			<div className={`CreatingGuide-box ${props.tip ? "margin" : ""}`}>
				{!props.tip && <GearsLoader theme={props.theme} />}

				<div className="gigauser-CreatingGuide-text">
					<div className="rotating-text-container gigauser-CreatingGuide-text-h2">
						{props.tip && <Spinner color="#d43f8c" marginRight={"1rem"} />}
						Preparing your
						{rotatingTexts.map((word, index) => (
							<span
								key={index}
								className={`rotating-text ${index === currentIndex ? "visible" : "invisible"}`}
							>
								<span className="highlight">&nbsp;{word}</span>
							</span>
						))}
					</div>

					{/* <div className='gigauser-CreatingGuide-text-subtitle'>
                        {
                            props.env === "web"?
                            "This could take two minutes."
                            :
                            "This should just take a few seconds"
                        }
                        
                    </div> */}
				</div>

				{props.progressable && videoUploadProgress != null ? (
					<div className="UploadContainer">
						<Progress
							colorScheme="pink"
							background={"#1C1F2A"}
                            borderRadius={"0.25rem"}
							hasStripe
							value={videoUploadProgress}
                            // value={50}
						/>
					</div>
				) : null}

				{props.tip ? <LoadingTip /> : null}
			</div>
		</div>
	);
};

type GearsLoaderProps = {
	theme?: "light" | "dark";
};

const GearsLoader: React.FC<GearsLoaderProps> = (props) => {
	return (
		<div className="gigauser-load-gear">
			<div className="gigauser-gear gigauser-one">
				<svg id="blue" viewBox="0 0 100 100" fill="#94DDFF">
					{/* //viewbox */}
					<path d="M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6              c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3              l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9              c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z"></path>
				</svg>
			</div>
			<div className="gigauser-gear gigauser-two">
				<svg id="pink" viewBox="0 0 100 100" fill="#FB8BB9">
					<path d="M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6              c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3              l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9              c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z"></path>
				</svg>
			</div>
			<div className="gigauser-gear gigauser-three">
				<svg id="yellow" viewBox="0 0 100 100" fill="#FFCD5C">
					<path d="M97.6,55.7V44.3l-13.6-2.9c-0.8-3.3-2.1-6.4-3.9-9.3l7.6-11.7l-8-8L67.9,20c-2.9-1.7-6-3.1-9.3-3.9L55.7,2.4H44.3l-2.9,13.6              c-3.3,0.8-6.4,2.1-9.3,3.9l-11.7-7.6l-8,8L20,32.1c-1.7,2.9-3.1,6-3.9,9.3L2.4,44.3v11.4l13.6,2.9c0.8,3.3,2.1,6.4,3.9,9.3              l-7.6,11.7l8,8L32.1,80c2.9,1.7,6,3.1,9.3,3.9l2.9,13.6h11.4l2.9-13.6c3.3-0.8,6.4-2.1,9.3-3.9l11.7,7.6l8-8L80,67.9              c1.7-2.9,3.1-6,3.9-9.3L97.6,55.7z M50,65.6c-8.7,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.6S58.7,65.6,50,65.6z"></path>
				</svg>
			</div>
			{/* <div className="gigauser-lil-circle"></div> */}
			{/* <svg className="gigauser-blur-circle">
				<filter id="blur">
					<feGaussianBlur in="SourceGraphic" stdDeviation="13"></feGaussianBlur>
				</filter>
				<circle
					cx="70"
					cy="70"
					r="66"
					fill="transparent"
					stroke={props.theme==="dark"?"#0e1015":"white"}
					stroke-width="40"
					filter="url(#blur)"
				></circle>
			</svg> */}
		</div>
	);
};

export default PreparingGuide;
