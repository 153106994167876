import { Flex, Spinner } from "@chakra-ui/react";
import speakerIcon from "@gigauser/common/src/assets/svgs/speakerIcon";
import trashIcon from "@gigauser/common/src/assets/svgs/trashSimpleIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import React from "react";
import "./PronCard.css";
import penIcon from "@gigauser/common/src/assets/svgs/screenshot/penIcon";
import { Language, PronDictEntry } from "@giga-user-fern/api/types/api";
import { saver } from "@gigauser/common/src/network/saver";
import useConfirmDelete from "@gigauser/common/src/hooks/useConfirmDelete/useConfirmDelete";

type PronCardProps = {
	entry: PronDictEntry;
	playWord: (word: string, languageName: string) => Promise<void>;
	deleteWord: (entry: PronDictEntry, applyToAll: boolean) => Promise<void>;
};

const PronCard: React.FC<PronCardProps> = (props) => {
	const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
	const confirmDelete = useConfirmDelete();

	const { actualWord, phoneticSpelling, language } = props.entry;

	const onPlay = async () => {
		setIsPlaying(true);
		await props.playWord(phoneticSpelling, language);
		setIsPlaying(false);
	};

	const onDeleteWord = async () => {
		confirmDelete(() => props.deleteWord(props.entry, true), {
			item: actualWord,
			description: `Are you sure you want to delete ${actualWord}? If you continue, this word will be deleted from your dictionary across all languages. `,
		});
	};

	return (
		<div className="PronCard">
			<div className="language">{language}</div>

			<div className="actualWord">{actualWord.toLowerCase()}</div>
			<div className="phoneticSpelling">{phoneticSpelling.toLowerCase()}</div>

			<hr />

			<Flex
				className={`proncard-icons-container ${isPlaying ? "playing" : "notplaying"}`}
				justifyContent={"space-between"}
			>
				<Flex className="pronounce" alignItems={"center"}>
					<Icon className={`proncard-icon playIcon `} onClick={onPlay}>
						{isPlaying ? (
							<Spinner size={"sm"} color="#E179AF" />
						) : (
							speakerIcon("#DA589B")
						)}
					</Icon>
					{/* <Icon className="proncard-icon penIcon">{penIcon("#888b9c")}</Icon> */}
				</Flex>
				<Icon className="proncard-icon trash" onClick={onDeleteWord}>
					{trashIcon("#DC6060")}
				</Icon>
			</Flex>
		</div>
	);
};
export default PronCard;
