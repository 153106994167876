import React, { useEffect, useState } from "react";
import "./EditorPlatform.css";

import { Guide } from "@gigauser/common/src/core/types/guide";
import EditorTopbar from "../components/EditorTopbar/EditorTopbar";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectCreatingGuide,
	selectGuide,
	selectGuidePreview,
	selectUnsavedChanges,
	setGuideLoading,
} from "@gigauser/common/src/redux/slices/guideSlice";
import TextEditorPlatform from "../text/TextEditorPlatform";
import { useEffectOnce } from "@gigauser/common/src/hooks/useEffectOnce";
import GridLoader from "@gigauser/common/src/ui/loaders/GridLoader/GridLoader";
import Overlay from "@gigauser/common/src/layouts/Overlay/Overlay";
import CModal from "@gigauser/common/src/layouts/CModal/CModal";

import InfoMessage from "@gigauser/common/src/layouts/MessagePopup/MessagePopup";

import VideoEditorMaster from "../video/main/VideoEditorMaster";
import useInitGuideEditor from "@gigauser/common/src/redux/hooks/FileHooks/useInitGuideEditor";
import useShowUpdatingGuide from "@gigauser/common/src/redux/hooks/FileHooks/useShowUpdatingGuide";
import CreatingGuide from "../components/CreatingGuide/CreatingGuide";
import FullScreenOverlay from "@gigauser/common/src/layouts/FullScreenOverlay/FullScreenOverlay";
import {
	ActiveFormat,
	setActiveFormat,
} from "@gigauser/common/src/redux/slices/platformUiSlice";
import LogoLoader from "@gigauser/common/src/ui/loaders/LogoLoader/LogoLoader";

type EditorPlatformProps = {};

export type EditorFormat = "Article" | "Video";

const EditorPlatform: React.FC<EditorPlatformProps> = () => {
	const [publish, setPublish] = useState(false);

	// const [activeTabIndex, setActiveTabIndex] = useState(process.env.NODE_ENV === "development" ? 0: 0);
	const activeFormat = useAppSelector(
		(state) => state.platformUi.value.activeFormat,
	);
	const changeActiveFormat = (format: ActiveFormat) => {
		dispatch(setActiveFormat(format));
	};

	const fullScreen = useAppSelector((state) => state.fullscreenOverlay.value);

	const dispatch = useAppDispatch();

	const guidePreview = useAppSelector(selectGuidePreview);
	const guide = useAppSelector(selectGuide) as Guide;
	const creatingGuide = useAppSelector(selectCreatingGuide);

	const [loadingGuide, setLoadingGuide] = useState(true);

	const unsavedChanges = useAppSelector(selectUnsavedChanges);

	const initGuideEditor = useInitGuideEditor();

	const initEditor = async () => {
		dispatch(setGuideLoading());

		const resp = await initGuideEditor();

		if (resp) {
			const { guidePreview, guideData } = resp;
			document.title = guidePreview.header.name;
			document.body.style.backgroundColor = "hsl(225, 21%, 7%)";
			setPublish(guidePreview.header.published || false);

			if (!guideData.video.originalSrc) {
				dispatch(setActiveFormat("Article"));
			}
		}

		setLoadingGuide(false);
	};

	useEffectOnce(() => {
		initEditor();
	});
	useShowUpdatingGuide();
	useEffect(() => {
		const handleBeforeUnload = (event: any) => {
			// if(process.env.NODE_ENV==="development") return

			event.preventDefault();
			event.returnValue =
				"You have unsaved changes. Are you sure you want to leave?";
		};

		if (unsavedChanges) {
			window.addEventListener("beforeunload", handleBeforeUnload);
		} else {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		}

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [unsavedChanges]);

	if (loadingGuide) {
		return <LogoLoader />;
	} else if (creatingGuide) {
		return <CreatingGuide />;
	} else if (guidePreview)
		return (
			<div className="EditorPlatform gigauser-dark">
				<Overlay />
				<CModal />
				<FullScreenOverlay />
				<InfoMessage />

				<EditorTopbar
					publish={publish}
					setPublish={setPublish}
					activeFormat={activeFormat}
					setActiveFormat={changeActiveFormat}
					videoTab={guide.guideData.video.originalSrc ? true : false}
				/>

				<div
					className="EditorInterface"
					style={{
						overflow: activeFormat == "Video" ? "hidden" : undefined,
						visibility: fullScreen ? "hidden" : "visible",
					}}
				>
					{guide.guideData.video.originalSrc && (
						<VideoEditorMaster
							visible={activeFormat == "Video" ? true : false}
						/>
					)}

					<TextEditorPlatform
						visible={activeFormat == "Video" ? false : true}
					/>
				</div>
			</div>
		);
	else {
		return <h1>Invalid Guide ID</h1>;
	}
};
export default EditorPlatform;
