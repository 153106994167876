function getFullDateWithTime(x: Date | undefined) {
  if (x) {
    // Get the formatted time and date separately
    const formattedTime = x.toLocaleTimeString('en-US', {
      hour: "numeric",
      minute: "numeric",
      hour12: true       // Use "false" for 24-hour format
    });
    const formattedDate = x.toLocaleDateString('en-US', {
      year: "numeric",
      month: "long",    // Full month name
      day: "numeric"    // Numeric day
    });

    // Combine them into the desired format
    const formattedDateTime = `${formattedTime} on ${formattedDate}`;
    return formattedDateTime
  }
  else {
    return ""
  }
}

export { getFullDateWithTime }