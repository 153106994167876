import React, { useState } from "react";
import "../SubscribePopup/SubscribePopup.css";
import Cutton from "../../ui/buttons/Cutton/Cutton";
import { useAppSelector } from "../../redux/useAppRedux";
import { selectSaver } from "../../redux/slices/backendSlice";
import { GigaUserApi } from "@giga-user-fern/api";
import { limit_dictionary } from "../../redux/hooks/FileHooks/useShowError";
import "./LimitPopup.css";
import { Flex } from "@chakra-ui/react";
import Icon from "../../ui/Icon/Icon";
import trashIcon from "../../assets/svgs/trashIcon";
import tickIcon from "../../assets/svgs/tickIcon";
import puzzleStartup from "../../assets/svgs/puzzle_startups";
import puzzleGrowth from "../../assets/svgs/puzzle_growth";
import puzzleEnterprise from "../../assets/svgs/puzzle_enterprise";
import { saver } from "../../network/saver";
import infoIcon from "../../assets/svgs/infoIcon";
import HoverTip from "../../ui/HoverTip/HoverTip";

const BasePlanType = GigaUserApi.organizations.BasePlanType;

type LimitPopupProps = {
	limitName?: string;
	hideDescription?: boolean;
};

type PlanName = "Starter" | "Growth" | "Enterprise";
type Plan = {
	name: PlanName;
	monthlyId: string;
	annualId: string;
	monthlyPrice: string;
	annualPrice: string;
};

const plans: Plan[] = [
	{
		name: "Starter",
		monthlyId: "34",
		annualId: "35",
		monthlyPrice: "$150",
		annualPrice: "$120",
	},
	{
		name: "Growth",
		monthlyId: "36",
		annualId: "37",
		monthlyPrice: "$250",
		annualPrice: "$200",
	},
	{
		name: "Enterprise",
		monthlyId: "none",
		annualId: "none",
		monthlyPrice: "Let's talk.",
		annualPrice: "Let's talk.",
	},
];

const LimitPopup: React.FC<LimitPopupProps> = (props) => {
	const saver = useAppSelector(selectSaver);
	const [loadingPlan, setLoadingPlan] = useState<PlanName | null>(null);
	const [interval, setInterval] = useState<"annually" | "monthly">("annually");

	const isNotSubscribed = saver.basePlanStatus?.status !== BasePlanType.Pro;

	const description =
		props.limitName && limit_dictionary.hasOwnProperty(props.limitName)
			? limit_dictionary[props.limitName].description
			: "You have reached the limit for using this feature. Upgrade to keep using.";

	if (isNotSubscribed)
		return (
			<div className="LimitPopup">
				{props.hideDescription ? null : (
					<Flex className="limit-description" alignItems={"center"}>
						<div className="exclamation">!</div>
						<div className="limit-description">{description}</div>
					</Flex>
				)}

				<Flex
					className="plans-header"
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<div>Compare plans</div>

					<Flex alignItems={"center"} gap={"0.5rem"}>
						<div>Billing period</div>

						<Flex
							className="switch-container"
							alignItems={"center"}
							gap="0.5rem"
						>
							<Flex
								className={`switch-item ${interval === "annually" ? "active" : "inactive"}`}
								onClick={() => setInterval("annually")}
								alignItems={"center"}
								gap="0.5rem"
							>
								<div className="discount">Save 20%</div>
								<div>Annually</div>
							</Flex>
							<Flex
								className={`switch-item ${interval === "monthly" ? "active" : "inactive"}`}
								alignItems={"center"}
								onClick={() => setInterval("monthly")}
							>
								Monthly
							</Flex>
						</Flex>
					</Flex>
				</Flex>

				<div className="PlanBoxes">
					<PlanBox
						icon={puzzleStartup}
						plan={plans[0]}
						interval={interval}
						priceDescription={`/mo. billed ${interval}`}
						featureDescription="Includes"
						features={[
							{ desc: "2 users" },
							{
								desc:
									interval === "monthly"
										? "30 mins of video exports / month"
										: "6 hours of video exports / year",
								infoBox: {
									title: "How is usage calculated?",
									desc: [
										"For each video, we count the minutes based on the longest version of that video that you export/publish",
										"Even if you export/publish the same video multiple times, we only count the longest version, so you're not penalized for re-exports",
									],
								},
							},
							{
								desc:
									interval === "monthly"
										? "30 articles / month"
										: "360 articles / year",
							},
						]}
						buttonTitle="Upgrade now"
					/>

					<PlanBox
						icon={puzzleGrowth}
						plan={plans[1]}
						interval={interval}
						priceDescription={`/mo. billed ${interval}`}
						featureDescription="Includes"
						features={[
							{ desc: "4 users" },
							{
								desc:
									interval === "monthly"
										? "60 mins of video exports / month"
										: "12 hours of video exports / year",
								infoBox: {
									title: "How is usage calculated?",
									desc: [
										"For each video, we count the minutes based on the longest version of that video that you export/publish",
										"Even if you export/publish the same video multiple times, we only count the longest version, so you're not penalized for re-exports",
									],
								},
							},
							{
								desc:
									interval === "monthly"
										? "60 articles / month"
										: "720 articles / year",
							},
						]}
						mostPopular
						buttonTitle="Upgrade now"
					/>

					<PlanBox
						icon={puzzleEnterprise}
						plan={plans[2]}
						interval={interval}
						featureDescription="Custom users, videos, articles, and"
						features={[
							{
								desc: "Voice cloning",
							},
							{
								desc: "Priority support",
							},
							{
								desc: "MSA, security review, SSO",
							},
						]}
						buttonTitle="Book a demo"
					/>
				</div>

				{/* {saver.basePlanStatus?.status != BasePlanType.Pro && (
				<Cutton
					onClick={async () => {
						alert("Path is "+ window.location.pathname)
						const resp = await saver.checkout({ planId: "22" , path: window.location.pathname});
						if (resp.ok) {
							window.location.href = resp.body.checkoutUrl;
						} else {
							alert("Failed to checkout");
						}
					}}
				>
					Upgrade
				</Cutton>
			)} */}
			</div>
		);

	return (
		<div className="SubscribePopup PreUpdate LimitPopup">
			<h1>You have hit a usage limit</h1>

			<p>Upgrade your plan to keep using Clueso without interruption.</p>
			<p>
				To upgrade your subscription:
				<a
					href="https://calendly.com/clueso_intro/neel"
					target="_blank"
					rel="noreferrer"
					className="email-pin dark"
				>
					book a meeting
				</a>
				or reach out to us at{" "}
				<a href="mailto:founders@clueso.io" className="email-pin dark">
					founders@clueso.io
				</a>
				.
			</p>
		</div>
	);
};

type PlanFeature = {
	desc: string;
	infoBox?: {
		title: string;
		desc: string | string[];
	};
};

type PlanBoxProps = {
	icon: () => React.ReactElement;
	plan: Plan;
	priceDescription?: string;
	featureDescription: string;
	features: PlanFeature[];
	mostPopular?: boolean;
	buttonTitle: string;
	interval: "annually" | "monthly";
};

const PlanBox: React.FC<PlanBoxProps> = (props) => {
	const [loading, setLoading] = useState(false);

	const onClickPlan = async () => {
		const { plan, interval } = props;
		if (plan.name === "Enterprise") {
			window.location.href = "https://calendly.com/clueso_intro/neel";
			return;
		} else {
			setLoading(true);

			const planId = interval === "annually" ? plan.annualId : plan.monthlyId;

			const resp = await saver.checkout({
				planId: process.env.NODE_ENV === "development" ? "22" : planId,
				path: window.location.pathname,
			});
			if (resp.ok) {
				window.location.href = resp.body.checkoutUrl;
				setLoading(false);
			} else {
				alert("Failed to checkout");
			}
		}
	};

	return (
		<div
			className={`most-popular-container ${props.mostPopular ? "most-popular" : ""}`}
		>
			<div className="most-popular-text">Most popular</div>

			<div className={`PlanBox ${props.mostPopular ? "most-popular" : ""}`}>
				<Icon className="plan-icon">{props.icon()}</Icon>
				<div className="PlanTitle">{props.plan.name}</div>

				<Flex alignItems={"center"} className="price-container">
					<div className="price">
						{props.interval === "annually"
							? props.plan.annualPrice
							: props.plan.monthlyPrice}
					</div>
					<div className="priceDescription">{props.priceDescription}</div>
				</Flex>

				<div className="featureDescription">{props.featureDescription}</div>

				<div className="features-list">
					{props.features.map((feature) => {
						return (
							<div className="feature-item">
								<Icon className="tick-icon">{tickIcon()}</Icon>
								<div>{feature.desc}</div>
								{feature.infoBox && (
									<HoverTip
										hoverTipTitle={feature.infoBox.title}
										hoverTipPara={feature.infoBox.desc}
									>
										<Icon className="info-icon">{infoIcon("grey ")}</Icon>
									</HoverTip>
								)}
							</div>
						);
					})}
				</div>

				<Cutton isLoading={loading} fullWidth onClick={onClickPlan}>
					{props.buttonTitle}
				</Cutton>
			</div>
		</div>
	);
};

export default LimitPopup;
