import React, { useContext, useState } from "react";
import useMoveItem from "@gigauser/common/src/redux/hooks/FileHooks/useMoveItem";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import { Collection } from "@gigauser/common/src/core/types/collections";
import logger from "../../../utils/logger";
import { Item, rootCollection } from "../../../types/files";
import homeIcon from "../../../assets/svgs/home";

type BreadcrumbSectionProps = {
	segment: Collection;
	activeSegment?: boolean;
	navigationHandler: (segment: Collection) => void | null;
};

const BreadcrumbSection: React.FC<BreadcrumbSectionProps> = (props) => {
	const [isOnDrop, setIsOnDrop] = useState<boolean>(false); //true if something is being dropped on the folder

	const env = useContext(EnvironmentContext);
	const backendMoveItem = useMoveItem(env);

	const onDropFolder = (e: React.DragEvent<HTMLDivElement>) => {
		setIsOnDrop(false);

		const data = JSON.parse(e.dataTransfer.getData("text")) as Item;

		if (
			data.entry.id === props.segment.id ||
			data.entry.parentId === props.segment.id
		)
			return;

		backendMoveItem({
			item: data,
			newParentID: props.segment.id,
		});
	};

	const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		logger.debug("onDragLeave");
		setIsOnDrop(false);
	};

	const onDragOverCapture = (e: React.DragEvent<HTMLDivElement>) => {
		var data = e.dataTransfer.getData("text");
		setIsOnDrop(true);
	};

	const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	return (
		<div
			className={`gigauser-PageTitle-pathSegment 
                ${props.activeSegment && !(props.segment.id === rootCollection.id) ? "gigauser-activeSegment" : ""}
            platform-segment
            droppable-pathSegment ${isOnDrop ? "onDrop" : ""}
            ${props.segment.id === rootCollection.id ? "gigauser-home-segment" : "gigauser-text-segment"}
            `}
			onClick={() => {
				props.navigationHandler(props.segment);
			}}
			onDragOver={onDragOver}
			onDragOverCapture={onDragOverCapture}
			onDragLeave={onDragLeave}
			onDrop={onDropFolder}
		>
			{props.segment.id === rootCollection.id ? (
				<div
					className="gigauser-breadcrumbs-home"
					// onMouseEnter={onHomeHover}
					// onMouseLeave={onHomeLeave}
				>
					{homeIcon(
						props.activeSegment
							? "#d43f8c"
							: "#6a6c7b",
					)}
				</div>
			) : (
				props.segment.name
			)}
			{props.segment.id === rootCollection.id || !props.segment.id ? null : (
				<div
					className={`segment-status ${props.segment.private ? "segment-private" : "segment-public"}`}
				>
					.
				</div>
			)}
		</div>
	);
};
export default BreadcrumbSection;
