import React from "react";
import Navbar from "../../components/Navbar";

import Overlay from "@gigauser/common/src/layouts/Overlay/Overlay";

import "./Home.laptop.css";
import Files from "../../components/Files/Files";
import InfoMessage from "@gigauser/common/src/layouts/MessagePopup/MessagePopup";
import { useAppSelector } from "@gigauser/common/src/redux";
import Bin from "../../components/Bin/Bin";
import Analytics from "../../components/Analytics/Analytics";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import UsagePage from "../../components/Admin/UsagePage";
import HelpCenterSettings from "../../components/Admin/HelpCenterSettings";
import Topbar from "../../components/Header/laptop/Topbar/Topbar";
import { useLocation } from "react-router-dom";
import { AppInitializerPage } from "../../../AppInitializer";
import Settings from "../../../settings/Settings";
import CModal from "@gigauser/common/src/layouts/CModal/CModal";
import { AdminPage } from "../../components/Admin/ManageUsers/AdminPage";

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
	const location = useLocation();
	const homePage: AppInitializerPage = location.pathname as AppInitializerPage;

	return (
		<div className="Home laptop gigauser-dark ">
			<Overlay />
			<CModal />
			<InfoMessage />

			{/* <div className="Topbar-container row">
				<Topbar />
			</div> */}
			{/* else if(props.page.startsWith("settings/")) {
				return (
					<Settings page={props.page} />
				)
			} */}
			<div className="MainHome-contents row">
				<div className="Navbar-container">
					<Navbar />
				</div>
				<div className="HomeContents-container column">
					{homePage.includes("home/analytics") ? (
						<Analytics />
					) : homePage.includes("home/team") ? (
						<AdminPage />
					) : homePage.includes("settings") ? (
						<Settings />
					) : (
						// : homePage === "help-center-config" ? (
						// 	<HelpCenterSettings />
						// )
						// : homePage === "usage" ? (
						// 	<UsagePage />
						// )
						// : homePage === "settings" ? (
						// 	<SettingsPage />
						// )
						<>
							{/* <div className="row">
								<Header showTitle={true} />
							</div>
							{homePage === "projects" ? (
								<div className="Home-ActionButtons row">
									<NewButton />
								</div>
							) : null} */}

							<div className="Home-Files vertical-scroll show-scroll">
								<div
									style={{
										display: homePage.includes("folder") ? "block" : "none",
									}}
								>
									<Files />
								</div>
								<div
									style={{
										display: homePage.includes("home/trash") ? "block" : "none",
									}}
								>
									<Bin />
								</div>
								{/* <div style={{display: homePage=== "anal"?"block":"none"}}><Analytics></Analytics></div> */}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
export default Home;
