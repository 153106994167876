import React, { useContext } from "react";
import CToggle from "@gigauser/common/src/ui/video/components/CToggle/CToggle";
import CSection from "../../layout/CSection/CSection";
import textIcon from "@gigauser/common/src/assets/svgs/videoeditor/textIcon";
import templateIcon from "@gigauser/common/src/assets/svgs/videoeditor/templateIcon";

import CSlider from "@gigauser/common/src/ui/video/components/CSlider/CSlider";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectBackgroundEdits,
	selectIntroEdits,
	selectOutroEdits,
	updateBackgroundEdits,
	updateIntroEdits,
} from "@gigauser/common/src/redux/slices/guideSlice";
import clockIcon from "@gigauser/common/src/assets/svgs/clockIcon";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import CTemplates from "@gigauser/common/src/ui/video/components/CTemplates/CTemplates";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import TemplateCreator from "../../../TemplateCreator/TemplateCreator";
import { CoverOption } from "@giga-user-fern/api/types/api";
import { setActiveIntroTemplate } from "@gigauser/common/src/redux/slices/platformUiSlice";
import { GigaUserApi } from "@giga-user-fern/api";
import { Input } from "@chakra-ui/react";
import { v4 } from "uuid";

type IntroCustomizerProps = {};

const IntroCustomizer: React.FC<IntroCustomizerProps> = () => {
	const dispatch = useAppDispatch();

	const cp = useContext(CanvasPlayerContext);

	const introState = useAppSelector(selectIntroEdits);
	const outroState = useAppSelector(selectOutroEdits);
	const backgroundState = useAppSelector(selectBackgroundEdits);

	const onHideIntro = (value: boolean) => {
		dispatch(
			updateIntroEdits({
				...introState,
				visible: !value,
			}),
		);

		const standardizeDims =
			(!value && introState.fixDimensions) ||
			(outroState.visible && outroState.fixDimensions);
		if (!value && (standardizeDims === undefined || standardizeDims)) {
			dispatch(
				updateBackgroundEdits({
					...backgroundState,
					visible: true,
				}),
			);
		}

		dispatch(setActiveIntroTemplate(GigaUserApi.Id("")));
	};

	const onHideLogo = (value: boolean) => {
		dispatch(
			updateIntroEdits({
				...introState,
				showLogo: !value,
			}),
		);
	};

	const setLogoSize = (value: number) => {
		dispatch(
			updateIntroEdits({
				...introState,
				logoSize: value,
			}),
		);
	};

	const setIntroDuration = (value: number) => {
		dispatch(
			updateIntroEdits({
				...introState,
				duration: value,
			}),
		);
	};

	const openIntroCreator = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: templateIcon("white"),
					title: "Create Intro Template",
					data: "",
				},
				component: <TemplateCreator coverType="intro" operation="create" />,
			}),
		);
	};

	const onChangeTitle = (e: any) => {
		dispatch(
			updateIntroEdits({
				...introState,
				text1: e.target.value,
			}),
		);
	};

	const onUpdateIntroTemplate = (coverOption: CoverOption) => {
		dispatch(
			openOverlay({
				heading: {
					icon: templateIcon("white"),
					title: "Update Intro Template",
					data: "",
				},
				component: (
					<TemplateCreator
						coverType="intro"
						operation="edit"
						coverOption={coverOption}
					/>
				),
			}),
		);
	};

	const onUpdatePremadeTemplate = (coverOption: CoverOption) => {
		const coverOptionCopy = { ...coverOption };
		coverOptionCopy.id = GigaUserApi.Id(v4());

		const onPremadeCreate = (creteadCoverOption: CoverOption) => {
			dispatch(setActiveIntroTemplate(creteadCoverOption.id));
			dispatch(
				updateIntroEdits({
					...introState,
					...creteadCoverOption.cover,
				}),
			);
		};

		dispatch(
			openOverlay({
				heading: {
					icon: templateIcon("white"),
					title: `Update intro Template`,
					data: "",
				},
				component: (
					<TemplateCreator
						coverType="intro"
						operation="create"
						coverOption={coverOptionCopy}
						onCreate={onPremadeCreate}
					/>
				),
			}),
		);
	};

	const hideIntro = !introState.visible;
	const hideLogo = !introState.showLogo;

	const hasIntroClip = cp.videoEdits.intro?.type == "video";

	return (
		<div className="IntroCustomizer ">
			<CSection>
				<CToggle
					color="pink"
					label="Hide Intro"
					value={hideIntro}
					setterFunction={onHideIntro}
				/>
			</CSection>

			<>
				<CSection disabled={hideIntro} label="Template" icon={templateIcon}>
					<CTemplates
						coverType="intro"
						onClickNew={openIntroCreator}
						onClickEdit={onUpdateIntroTemplate}
						onClickEditPremade={onUpdatePremadeTemplate}
					/>
				</CSection>

				{!introState.hideText && (
					<>
						<CSection disabled={hideIntro} label="Title Text" icon={textIcon}>
							<div className="Outro-TextLine">
								<Input
									id="line1"
									value={introState.text1}
									onChange={onChangeTitle}
									placeholder="For more help visit ..."
								/>
							</div>
						</CSection>
					</>
				)}

				<CSection
					disabled={hideIntro || introState.type == "video"}
					label="Timing"
					icon={clockIcon}
				>
					{/* <CToggle disabled={hideIntro}  label='Hide logo' value={hideLogo} setterFunction={onHideLogo} /> */}

					<CSlider
						disabled={hideIntro || introState.type == "video"}
						value={introState.duration || 1.5}
						min={1}
						max={6}
						setValue={setIntroDuration}
						label="Intro Duration"
						unit="s"
					/>
				</CSection>
			</>
		</div>
	);
};
export default IntroCustomizer;
