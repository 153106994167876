import { useLocation } from "react-router-dom";
import { AppInitializerPage } from "../AppInitializer";
import SettingsSidebar from "./components/SettingsSidebar";
import AppearanceSettings from "./myaccount/AppearanceSettings";
import BillingAndPlansSettings from "./team/BillingAndPlans/BillingAndPlansSettings";
import BrandingAndDesignSettings from "./team/BrandingAndDesignSettings";
import ManageTeamsSettings from "./team/ManageTeams/ManageTeams";
import ManageUsersSettings from "./team/ManageUsersSettings";
import PermissionsSettings from "./team/PermissionsSettings/PermissionsSettings";
import PronunciationDictionarySettings from "./team/PronunciationDictionary/PronunciationDictionary";
import ProfileSettings from "./myaccount/ProfileSettings/ProfileSettings";
import HelpCenterSettings from "../home/components/Admin/HelpCenterSettings";

type SettingsProps = {};

export default function Settings(props: SettingsProps) {
	const location = useLocation();
	const homePage: AppInitializerPage = location.pathname as AppInitializerPage;

	return (
		<>
			{homePage.includes("settings/profile") && <ProfileSettings />}
			{homePage.includes("settings/appearance") && <AppearanceSettings />}
			{homePage.includes("settings/manage-users") && <ManageUsersSettings />}
			{homePage.includes("settings/permissions") && <PermissionsSettings />}
			{homePage.includes("settings/pronunciation-dictionary") && (
				<PronunciationDictionarySettings />
			)}
			{homePage.includes("settings/branding-and-design") && (
				<BrandingAndDesignSettings />
			)}
			{homePage.includes("settings/billing") && <BillingAndPlansSettings />}
			{homePage.includes("settings/manage-teams") && <ManageTeamsSettings />}
			{homePage.includes("settings/kb-config") && <HelpCenterSettings />}
		</>
	);
}
