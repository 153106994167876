import { saver } from "@gigauser/common/src/network/saver";
import { useEffect, useState } from "react";
import "./PronunciationDictionary.css";
import PageHeader from "../../../home/components/Header/laptop/PageHeader/PageHeader";
import voiceIcon from "@gigauser/common/src/assets/svgs/voiceIcon";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import { Flex, useToast } from "@chakra-ui/react";
import TitledInput from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import { Language, PronDictEntry } from "@giga-user-fern/api/types/api";
import PronCard from "./PronCard/PronCard";
import searchIcon from "@gigauser/common/src/assets/svgs/searchIcon";
import search from "@gigauser/common/src/assets/legacy_assets/icons/search.svg";
import { useAppDispatch } from "@gigauser/common/src/redux";
import {
	closeModal,
	openModal,
} from "@gigauser/common/src/layouts/CModal/modalSlice";
import NewPronCard from "./NewPronCard/NewPronCard";
import LanguagePicker from "@gigauser/common/src/overlays/LanguagePicker/LanguagePicker";
import CSearch from "@gigauser/common/src/ui/inputs/CSearch/CSearch";

export default function PronunciationDictionary() {
	const [searchableItems, setSearchableItems] = useState<PronDictEntry[]>([]);
	const [searchResults, setSearchResults] = useState<PronDictEntry[]>([]);

	const toast = useToast();

	const [currentFilterLanguage, setCurrentFilterLanguage] = useState<Language>({
		languageId: "en",
		name: "English",
	});

	useEffect(() => {
		getAllLanguages();
		getDictionaryForLanguage();
	}, []);

	useEffect(() => {
		getDictionaryForLanguage();
	}, [currentFilterLanguage]);

	const dispatch = useAppDispatch();

	const getAllLanguages = async () => {
		const resp = await saver.settings.pronunciationDictionary.getAllLanguages();
		console.log(resp);
	};

	const getDictionaryForLanguage = async () => {
		//pass selected language for filtration else pass empty object
		const resp =
			await saver.settings.pronunciationDictionary.getPronunciationDictionary({
				language: currentFilterLanguage.name,
			});
		if (resp.ok) {
			setSearchableItems(resp.body);
			setSearchResults(resp.body);
		} else {
			alert("Error while fetching pronunciation dictionary!");
		}
	};

	const openAddWordModal = () => {
		dispatch(
			openModal({
				heading: {
					icon: plusIcon(),
					title: "Add a new word",
				},
				component: (
					<NewPronCard
						onAddWord={onAddWord}
						playWord={playWord}
						language={currentFilterLanguage}
					/>
				),
			}),
		);
	};

	const playWord = async (word: string, languageName: string) => {
		const res =
			await saver.settings.pronunciationDictionary.returnPreviewAudioBytes({
				actualWord: word,
				phoneticSpelling: word,
				language: languageName,
				applyToAll: false,
			});

		if (res.ok) {
			const preview = new Audio("data:audio/wav;base64," + res.body);
			preview.play();
		}
	};

	const onAddWord = (newEntries: PronDictEntry[]) => {
		const newDict = [...searchableItems, ...newEntries];
		setSearchableItems(
			newDict.filter((entry) => entry.language === currentFilterLanguage.name),
		);
	};

	const onDeleteWord = async (entry: PronDictEntry, applyToAll: boolean) => {
		const res =
			await saver.settings.pronunciationDictionary.deleteWordFromPronunciationDictionary(
				{
					phoneticSpelling: entry.phoneticSpelling,
					actualWord: entry.actualWord,
					language: entry.language,
					applyToAll: applyToAll,
				},
			);

		if (res.ok) {
			const remainingEntries = searchableItems.filter((entry) =>
				applyToAll
					? entry.actualWord !== entry.actualWord
					: !(
							entry.actualWord === entry.actualWord &&
							entry.language === entry.language
						),
			);
			setSearchableItems(remainingEntries);
		} else {
			toast({
				title: `Could not delete word`,
				description: "Please try again later.",
				status: "error",
				duration: 6000,
				isClosable: true,
				position: "top",
			});
		}
	};

	return (
		<div className="PronunciationDictionary">
			<PageHeader
				title="Pronunciation Dictionary"
				icon={voiceIcon}
				description="Specify pronunciations of tricky brand names or industry terms"
			/>

			<div className="PronunciationDictionary-container">
				<Flex
					marginBottom={"1rem"}
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<Cutton onClick={openAddWordModal} leftIcon={plusIcon("white")}>
						New word
					</Cutton>

					<LanguagePicker
						newStyle
						currentLanguage={currentFilterLanguage}
						setCurrentLanguage={setCurrentFilterLanguage}
						position="bottom left"
					/>
				</Flex>
				<form className="pron-search" autoComplete={"off"}>
					<CSearch
						searchableItems={searchableItems}
						searchKey={"actualWord"}
						handleResults={setSearchResults}
						placeholder={"Search for a word ..."}
						size="l"
					/>
				</form>

				<div className="PronDictGrid">
					{searchResults.map((entry, index) => {
						return (
							<PronCard
								entry={entry}
								playWord={playWord}
								deleteWord={onDeleteWord}
							/>
						);
					})}
					{searchableItems.length && searchResults.length === 0 ? (
						<div className="nr"> No Results </div>
					) : null}
					{searchableItems.length === 0 ? (
						<div className="nr"> No words added yet. </div>
					) : null}
				</div>
			</div>
		</div>
	);
}
