import React, { useRef, useState } from "react";
import "./TemplateCreator.css";
import CSection from "../VideoCustomizer/layout/CSection/CSection";
import FileUpload from "@gigauser/common/src/ui/inputs/file/FileUpload/FileUpload";
import CToggle from "@gigauser/common/src/ui/video/components/CToggle/CToggle";
import CSlider from "@gigauser/common/src/ui/video/components/CSlider/CSlider";
import CColor from "@gigauser/common/src/ui/video/components/CColor/CColor";
import { Modal, Progress } from "antd";
import {
	Cover,
	CoverOption,
	CoverType,
	Logo,
	TextAlignment,
} from "@giga-user-fern/api/types/api";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { v4 as uuidv4 } from "uuid";
import { GigaUserApi } from "@giga-user-fern/api";
import { Flex, Input, Spinner, useToast } from "@chakra-ui/react";
import {
	addCover,
	addLogo,
	deleteCover,
	selectLogos,
	updateCover,
} from "@gigauser/common/src/redux/slices/platformDetailsSlice";

import { uploadToPresignedXhr } from "@gigauser/common/src/network/saver";
import type { CollapseProps } from "antd";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import tickIcon from "@gigauser/common/src/assets/svgs/tickIcon";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import CAlign from "@gigauser/common/src/ui/video/components/CAlign/CAlign";
import {
	selectIntroEdits,
	selectOutroEdits,
	updateIntroEdits,
	updateOutroEdits,
} from "@gigauser/common/src/redux/slices/guideSlice";
import { closeOverlay } from "@gigauser/common/src/layouts/Overlay";
import CoverCard from "@gigauser/common/src/ui/video/components/CoverCard/CoverCard";
import useSuperAdmin from "@gigauser/common/src/hooks/useSuperAdmin";
import FontDropdown from "../../../components/FontDropdown/FontDropdown";
import { getFont } from "@gigauser/common/src/utils/fontsUtils";
import orgSetupCanvas from "@gigauser/common/src/core/canvas/orgSetupCanvas";

type TemplateCreatorProps = {
	operation: "create" | "edit";
	coverType: CoverType;
	coverOption?: CoverOption;
	onCreate?: (coverOption: CoverOption) => void;
};

const TemplateCreator: React.FC<TemplateCreatorProps> = (props) => {
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [deleting, setDeleting] = useState(false);

	const toast = useToast();

	const [backgroundFile, setBackgroundFile] = useState<File | null>(null);

	const [loading, setLoading] = useState(false);
	const [uploading, setUploading] = useState<number | null>(null);
	const [submitting, setSubmitting] = useState(false);

	const saver = useAppSelector(selectSaver);

	const introCovers = useAppSelector(
		(state) => state.platformDetails.value?.intro?.options || [],
	);
	const outroCovers = useAppSelector(
		(state) => state.platformDetails.value?.outro?.options || [],
	);

	const introEdits = useAppSelector(selectIntroEdits);
	const outroEdits = useAppSelector(selectOutroEdits);

	const activeIntroTemplate = useAppSelector(
		(state) => state.platformUi.value.activeIntroTemplate,
	);
	const activeOutroTemplate = useAppSelector(
		(state) => state.platformUi.value.activeOutroTemplate,
	);

	const defaultIntroCover: Cover = {
		visible: true,
		backgroundSrc: "",
		showLogo: true,
		logoSize: 75,
		fontColor: saver.organization?.brandColor || "#ffffff",
		font: "League Spartan",
		alignment: "left",
		text1: "My Video Title",
		duration: 1.5,
		fixDimensions: true,
	};

	const defaultOutroCover: Cover = {
		visible: true,
		backgroundSrc: "",
		showLogo: true,
		logoSize: 75,
		fontColor: saver.organization?.brandColor || "#ffffff",
		font: "League Spartan",
		alignment: "left",
		text1: outroEdits.text1 || "For more help visit",
		text2: outroEdits.text2 || "help.clueso.io",
		duration: 1.5,
		fixDimensions: true,
	};

	const initCover = props.coverOption?.cover;

	const logos = useAppSelector(selectLogos);

	const [logoList, setLogoList] = useState<{ logo: Logo; loading: boolean }[]>(
		logos.map((logo) => ({ logo, loading: false })),
	);

	const dispatch = useAppDispatch();

	const backgroundRef = useRef<HTMLImageElement>(new Image());
	const videoCoverRef = useRef<HTMLVideoElement>(null);

	const [coverState, setCoverState] = useState<Cover>({
		...(props.coverType === "intro" ? defaultIntroCover : defaultOutroCover),
		...initCover,
	});

	const onPickFile = () => {
		const fileInput = document.createElement("input");
		fileInput.accept = ".png"; // Accept only .mp3 and .wav files
		fileInput.type = "file";

		// Attach a change event listener to the file input element
		fileInput.addEventListener("change", async (event) => {
			const input = event.target as HTMLInputElement;
			if (input.files && input.files[0]) {
				// Resolve the promise with the selected Blob
				const blob = input.files[0];
				uploadLogoFile(blob);
			}
		});

		// Trigger the click event on the file input to open the file picker
		fileInput.click();
	};

	const betaFeaturesAccessible = useSuperAdmin();

	const onChangeBackground = async (file: File) => {
		setLoading(true);
		setBackgroundFile(file);

		const post = await saver.uploadAsset();

		if (post.ok) {
			const res = await uploadToPresignedXhr(
				file,
				post.body.url,
				saver.captureEvent,
				(progress: number) => {
					if (progress >= 100) {
						setUploading(null);
					} else {
						setUploading(progress);
					}
				},
			);

			if (file.type.includes("video")) {
				if (!videoCoverRef.current) {
					console.error("error while loading video!");
					return;
				}

				videoCoverRef.current.src = post.body.src;

				videoCoverRef.current.onerror = (e) => {
					setLoading(false);
					setBackgroundFile(null);
					console.log("error while loading video: ", e);
					toast({
						title: "Error",
						description: "Failed to load video",
						status: "error",
						duration: 10000,
						position: "top",
						isClosable: true,
					});
				};

				videoCoverRef.current.onloadeddata = () => {
					// Event when video metadata is loaded
					if (!videoCoverRef.current) return;

					setLoading(false);
					setCoverState({
						...coverState,
						type: "video",
						backgroundSrc: post.body.src,
						naturalWidth: videoCoverRef.current.videoWidth,
						naturalHeight: videoCoverRef.current.videoHeight,
						duration: videoCoverRef.current.duration,
						text1: undefined,
						hideText: true,
					});
				};
			} else {
				backgroundRef.current = new Image();
				backgroundRef.current.src = post.body.src;

				backgroundRef.current.onload = () => {
					setLoading(false);
					setCoverState({
						...coverState,
						type: "image",
						backgroundSrc: post.body.src,
					});
				};

				backgroundRef.current.onerror = (e) => {
					setLoading(false);
					setBackgroundFile(null);
					console.log("error while loading image: ", e);
					toast({
						title: "Error",
						description: "Failed to load image",
						status: "error",
						duration: 10000,
						position: "top",
						isClosable: true,
					});
					console.log("error while loading image!");
				};
			}
		}
	};

	const uploadLogoFile = async (file: File) => {
		const tempLogo: Logo = {
			id: GigaUserApi.Id(uuidv4()),
			src: "",
		};

		setLogoList([...logoList, { logo: tempLogo, loading: true }]);

		const resp = await saver.uploadLogo(file);
		const updatedLogoList = logoList.filter(
			(logoItem) => logoItem.logo.id !== tempLogo.id,
		);

		if (resp) {
			// Filter out the tempLogo from the logoList
			// Add the new logo to the logoList
			dispatch(addLogo(resp));
			setLogoList([...updatedLogoList, { logo: resp, loading: false }]);
		} else {
			toast({
				title: "Error",
				description: "Failed to upload logo",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
			setLogoList([...updatedLogoList]);
		}
	};

	const onSubmitCreate = async () => {
		setSubmitting(true);

		const coverToCreate = { ...coverState };
		if (props.coverType === "intro") {
			coverToCreate.text1 = "Untitled";
		}

		const coverOption = await saver.createCover(props.coverType, coverToCreate);

		if (coverOption.ok) {
			dispatch(
				addCover({ coverOption: coverOption.body, coverType: props.coverType }),
			);

			if (props.onCreate) {
				props.onCreate(coverOption.body);
			}
		} else {
			if (coverOption.error.error === "CorruptedFileError") {
				toast({
					title: `Could not create template.`,
					description: "Please upload a valid image file",
					status: "error",
					duration: 10000,
					position: "top",
				});
			} else {
				toast({
					title: `Could not create template.`,
					description: "An unknown error was encountered",
					status: "error",
					duration: 10000,
					position: "top",
				});
			}
		}

		dispatch(closeOverlay());

		setSubmitting(false);
	};

	const onDelete = () => {
		if (props.operation === "create") {
			dispatch(closeOverlay());
		} else {
			setOpenDeleteModal(true);
		}
	};

	const onSubmitUpdate = async () => {
		setSubmitting(true);

		if (!props.coverOption) return;

		const res = await saver.updateCover(props.coverType, {
			id: props.coverOption.id,
			cover: coverState,
		});

		if (res.ok) {
			dispatch(
				updateCover({ coverOption: res.body, coverType: props.coverType }),
			);
			dispatch(closeOverlay());

			if (
				props.coverType === "intro" &&
				activeIntroTemplate === props.coverOption.id
			) {
				dispatch(
					updateIntroEdits({
						...introEdits,
						...coverState,
						text1: introEdits.text1,
					}),
				);
			} else if (
				props.coverType === "outro" &&
				activeOutroTemplate === props.coverOption.id
			) {
				dispatch(
					updateOutroEdits({
						...outroEdits,
						...coverState,
					}),
				);
			}
		} else {
			if (res.error.error === "CorruptedFileError") {
				toast({
					title: `Could not update template.`,
					description: "Please upload a valid image file.",
					status: "error",
					duration: 10000,
					isClosable: true,
					position: "top",
				});
			} else {
				toast({
					title: `Could not update template.`,
					description: "Unkown error while updating template. Try again later.",
					status: "error",
					duration: 4000,
					isClosable: true,
					position: "top",
				});
			}
		}

		setSubmitting(false);
	};

	const onSubmitDelete = async () => {
		if (props.coverOption) {
			setDeleting(true);

			if (
				(props.coverType === "intro" && introCovers.length <= 1) ||
				(props.coverType === "outro" && outroCovers.length === 1)
			) {
				//Can't delete the last template
				toast({
					title: `Can't delete this template.`,
					description: "This is your last template! We can't delete it.",
					status: "warning",
					duration: 4000,
					isClosable: true,
					position: "top",
				});
			}

			//Delete backend

			const res = await saver.deleteCover(
				props.coverType,
				props.coverOption.id,
			);

			if (res) {
				setOpenDeleteModal(false);
				setDeleting(false);
				dispatch(
					deleteCover({
						coverOption: props.coverOption,
						coverType: props.coverType,
					}),
				);
				dispatch(closeOverlay());
			} else {
			}

			return;
		}

		toast({
			title: `Could not delete template.`,
			description: "Unkown error while deleting template. Try again later.",
			status: "error",
			duration: 4000,
			isClosable: true,
			position: "top",
		});
	};

	const onChangeInput = (e: any) => {
		if (e.target.id === "line1") {
			setCoverState({ ...coverState, text1: e.target.value });
		} else {
			setCoverState({ ...coverState, text2: e.target.value });
		}
	};

	let isCustomOrg = false;
	if (orgSetupCanvas[saver.getOrganization()?.id ?? ""]?.font) {
		isCustomOrg = true;
	}

	var items: CollapseProps["items"] = [
		{
			label: "Background",
			key: "Background",
			children: (
				<CSection noMarginTop noPadding label="Background">
					<FileUpload
						fileTypes={[".png, .mp4"]}
						file={backgroundFile}
						onChange={onChangeBackground}
					/>
					<p className="C-explanation">
						Suggested size: <b>1920 x 1080</b>
					</p>
				</CSection>
			),
		},
		{
			label: "Text",
			key: "Text",
			children: (
				<CSection defaultCollapsed noMarginTop noPadding label="Text">
					{!isCustomOrg && (
						<FontDropdown
							defaultValue={coverState.font ?? "League Spartan"}
							onChange={async (e) => {
								let newFontFamily = e.value;
								const res = await getFont(false, newFontFamily);
								if (!res) {
									toast({
										title: "Font not working!",
										description: "Please try again later.",
										status: "error",
										duration: 10000,
										isClosable: true,
										position: "top",
									});
									const res = await getFont(false);
									newFontFamily = res.toString().split("-")[0];
								}
								setCoverState({ ...coverState, font: newFontFamily });
							}}
						/>
					)}
					<CColor
						label="Font color"
						value={coverState.fontColor || "white"}
						setterFunction={(val: string) => {
							setCoverState({ ...coverState, fontColor: val });
						}}
					/>
					{props.coverType === "intro" ? (
						<CAlign
							label="Alignment"
							value={coverState.alignment || "left"}
							setterFunction={(al: TextAlignment) => {
								setCoverState({
									...coverState,
									alignment: al,
								});
							}}
							disabledOptions={["right"]}
						/>
					) : null}
					{props.coverType === "outro" && (
						<div className="VideoCustomizer templatecreator">
							<div className="Outro-TextLine">
								<div className="C-label">Line 1</div>
								<Input
									id="line1"
									value={coverState.text1}
									onChange={onChangeInput}
									placeholder="For more help visit ..."
								/>
							</div>

							<div className="Outro-TextLine">
								<div className="C-label">Line 2</div>
								<Input
									className="cInput"
									id="line2"
									value={coverState.text2 || ""}
									onChange={onChangeInput}
									placeholder="help.myapp.com"
								/>
							</div>
						</div>
					)}
				</CSection>
			),
		},
		{
			label: "Logo",
			key: "Logo",
			children: (
				<CSection defaultCollapsed noPadding label="Logo">
					<CSection subsection noPadding>
						<CToggle
							disabled={coverState.type === "video"}
							label="Hide logo"
							value={!coverState.showLogo}
							setterFunction={(b: boolean) => {
								setCoverState({
									...coverState,
									showLogo: !b,
								});
							}}
						/>
					</CSection>

					<CSection
						subsection
						disabled={!coverState.showLogo || coverState.type === "video"}
					>
						<div className="Logopicker">
							<div className="Logopicker-title">
								<div className="C-label">Logo</div>

								<div className="C-new" onClick={onPickFile}>
									Add New
								</div>
							</div>

							<div className="LogoList">
								{logoList.map((logo) => (
									<div
										className={`LogoList-logo ${logo.logo.id === coverState.logo ? "active-logo" : "inactive-logo"}`}
										onClick={() => {
											setCoverState({
												...coverState,
												logo: logo.logo.id,
											});
										}}
									>
										{logo.loading ? (
											<Spinner className="logo-spin" size={"sm"} />
										) : (
											<img src={logo.logo.src} className="logo" alt="" />
										)}
									</div>
								))}
							</div>
						</div>

						<CSlider
							disabled={!coverState.showLogo || coverState.type === "video"}
							value={coverState.logoSize || 75}
							setValue={(logoSize: number) => {
								setCoverState({
									...coverState,
									logoSize: logoSize,
								});
							}}
							label="Logo size"
							unit="px"
						/>
					</CSection>
				</CSection>
			),
		},
		{
			label: "Timing",
			key: "Timing",
			children: (
				<CSection defaultCollapsed noPadding label="Timing">
					<CSlider
						disabled={coverState.type === "video"}
						value={coverState.duration || 1.5}
						min={1}
						max={6}
						setValue={(d: number) => {
							setCoverState({
								...coverState,
								duration: d,
							});
						}}
						label="Duration"
						unit="s"
					/>
				</CSection>
			),
		},
		{
			label: "Size",
			key: "Size",
			children: (
				<CSection defaultCollapsed label="Size" noPadding>
					<CToggle
						label="Lock dimensions"
						value={!!coverState.fixDimensions}
						setterFunction={(b: boolean) => {
							setCoverState({
								...coverState,
								fixDimensions: b,
							});
						}}
						explanation={
							coverState.fixDimensions
								? "If unlocked, the intro slide will auto-resize to match dimensions of the screen recording."
								: "If locked, the intro slide will not auto-resize to match dimensions of the screen recording."
						}
					/>
				</CSection>
			),
		},
	];

	if (coverState.type === "video") {
		items = items.filter(
			(item) =>
				typeof item.label === "string" &&
				!["Text", "Logo", "Timing"].includes(item.label),
		);
	}
	if (betaFeaturesAccessible("introvideotext") && coverState.type === "video") {
		items = [
			...items,
			{
				key: "Text",
				label: "Video Text (Super Admin)",
				children: (
					<CSection noMarginTop noPadding label="Text">
						<CToggle
							label="Hide Title"
							value={coverState.hideText || false}
							setterFunction={(b: boolean) => {
								setCoverState({
									...coverState,
									hideText: b,
								});
							}}
						/>

						<CColor
							label="Font color"
							value={coverState.fontColor || "white"}
							setterFunction={(val: string) => {
								setCoverState({
									...coverState,
									fontColor: val,
								});
							}}
						/>

						<CSlider
							value={coverState.textStartTime || 0}
							setValue={(textStartTime: number) => {
								setCoverState({
									...coverState,
									textStartTime: textStartTime,
								});
							}}
							label="Start Time"
							unit="s"
						/>

						<FontDropdown
							defaultValue={coverState.font || "League Spartan"}
							onChange={async (e) => {
								let newFontFamily = e.value;
								const res = await getFont(false, newFontFamily);
								if (!res) {
									toast({
										title: "Font not working!",
										description: "Please try again later.",
										status: "error",
										duration: 10000,
										isClosable: true,
										position: "top",
									});
									const res = await getFont(false);
									newFontFamily = res.toString().split("-")[0];
								}
								setCoverState({ ...coverState, font: newFontFamily });
							}}
						/>
					</CSection>
				),
			},
		];
	}

	const onLoadDimensions = (w: number, h: number) => {
		if (w !== coverState.naturalWidth || h !== coverState.naturalHeight) {
			setCoverState({
				...coverState,
				naturalHeight: h,
				naturalWidth: w,
			});
		}
	};

	const changingSrc = loading || uploading !== null;

	return (
		<div className="TemplateCreator ">
			<div className="CanvasColumn">
				<div
					style={{
						display:
							coverState.backgroundSrc && !changingSrc ? undefined : "none",
					}}
				>
					<Flex gap={"0.5rem"}>
						<div className="Cover-dimensions">
							{coverState.naturalWidth} x {coverState.naturalHeight}
						</div>
						<div
							className={`Cover-dimensions ${coverState.fixDimensions ? "locked" : "unlocked"}`}
						>
							{" "}
							{coverState.fixDimensions
								? "Locked Dimensions"
								: "Auto-resize"}{" "}
						</div>
					</Flex>

					<div>
						<video
							style={{
								display: coverState.type === "video" ? "block" : "none",
							}}
							ref={videoCoverRef}
							className="CoverVideo"
							controls
							src={coverState.backgroundSrc}
						></video>

						{coverState.type !== "video" ? (
							<CoverCard
								noMargin
								coverType={props.coverType}
								cover={coverState}
								onLoadDimensions={onLoadDimensions}
							/>
						) : null}
					</div>

					<Flex
						marginTop={"2rem"}
						gap={"0.5rem"}
						width="100%"
						justify={"center"}
					>
						<Cutton
							isLoading={submitting}
							loadingText="Creating ..."
							leftIcon={tickIcon("white")}
							onClick={
								props.operation === "create" ? onSubmitCreate : onSubmitUpdate
							}
						>
							{props.operation === "create" ? "Create" : "Update"}
						</Cutton>
						<Cutton
							leftIcon={trashIcon("white")}
							rank="secondary"
							onClick={onDelete}
						>
							{props.operation === "create" ? "Discard" : "Delete"}
						</Cutton>
					</Flex>
				</div>

				{!changingSrc && !coverState.backgroundSrc && (
					<div className="CanvasPlaceholder CoverCreatorCanvas">
						<i>Upload a background ... </i>
					</div>
				)}

				<div
					className="Spinner"
					style={{ display: loading && uploading == null ? "block" : "none" }}
				>
					<Spinner size={"xl"} color="#d43f8c"></Spinner>
				</div>

				<div
					className="Spinner Progress"
					style={{ display: uploading !== null ? "block" : "none" }}
				>
					<Progress
						strokeColor="#d43f8c"
						percent={Math.floor(uploading || 0)}
						type="circle"
					/>
				</div>
			</div>

			<div className="EditorColumn">
				{/* <Collapse items={items} defaultActiveKey={['Background']} /> */}

				{items.map((item) => item.children)}
			</div>

			<Modal
				title="Delete Template"
				open={openDeleteModal}
				onOk={onSubmitDelete}
				confirmLoading={deleting}
				onCancel={() => {
					setOpenDeleteModal(false);
				}}
			>
				<p>{"Are you sure? You can't undo this action afterwards."}</p>
			</Modal>
		</div>
	);
};

export default TemplateCreator;
