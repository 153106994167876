import { RequiredAuthProvider } from "@propelauth/react"
import GridLoader from "../ui/loaders/GridLoader/GridLoader"
import { createClient } from "@propelauth/javascript"
import LogoLoader from "../ui/loaders/LogoLoader/LogoLoader"

const PropelAuthProvider = ({children}:{children: React.ReactNode})=>{
  return <RequiredAuthProvider authUrl={process.env.REACT_APP_DEV_PROPELAUTH_URL as string} displayWhileLoading={<LogoLoader />} >
    {children}
  </RequiredAuthProvider>
}
export const propelAuthClient = createClient({
  authUrl: process.env.REACT_APP_DEV_PROPELAUTH_URL as string
})

export default PropelAuthProvider