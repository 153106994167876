import TitledInput from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import "./HelpCenterConfig.css";
import { useEffect, useState } from "react";
import ToggleSwitch from "@gigauser/common/src/ui/switches/ToggleSwitch/ToggleSwitch";
import { Button } from "@gigauser/common/src/ui/buttons";
import { saver } from "@gigauser/common/src/network/saver";
import { HelpCenterConfig, HelpCenterTab } from "@giga-user-fern/api/types/api";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import { useAppDispatch } from "@gigauser/common/src/redux";
import {
	closeOverlay,
	openOverlay,
} from "@gigauser/common/src/layouts/Overlay";
import useSuperAdmin from "@gigauser/common/src/hooks/useSuperAdmin";
import CColorPicker from "@gigauser/common/src/ui/inputs/CColorPicker/CColorPicker";
import GridLoader from "@gigauser/common/src/ui/loaders/GridLoader/GridLoader";
const AddHelpCenterTab = ({
	tabList,
	setTabList,
	addTabInList,
}: {
	tabList: HelpCenterTab[];
	setTabList: React.Dispatch<HelpCenterTab[]>;
	addTabInList: (newTab: HelpCenterTab) => void;
}) => {
	const betaFeaturesAccessible = useSuperAdmin();
	const [newTabInput, setNewTabInput] = useState<HelpCenterTab>({
		url: "",
		label: "",
		readFromLocalstorage: false,
		localStorageLabel: "",
	});
	return (
		<div className="helpCenterConfig-editorColumn">
			<div className="helpCenterConfig-editorRow">
				<TitledInput
					className="helpCenterConfig-editorInputs"
					label="Label"
					id="newLeftTabLabel"
					onChange={(e) => {
						setNewTabInput({
							...newTabInput,
							label: (e.target as any).value,
						});
					}}
					value={newTabInput.label}
				/>
				{!newTabInput.readFromLocalstorage && (
					<TitledInput
						className="helpCenterConfig-editorInputs"
						label="URL"
						id="newLeftTabUrl"
						onChange={(e) => {
							setNewTabInput({
								...newTabInput,
								url: (e.target as any).value,
							});
						}}
						value={newTabInput.url}
					/>
				)}
				{betaFeaturesAccessible("kbconfig") &&
					newTabInput.readFromLocalstorage && (
						<TitledInput
							className="helpCenterConfig-editorInputs"
							label="Local Storage Label"
							id="newLeftTabLSlabel"
							onChange={(e) => {
								setNewTabInput({
									...newTabInput,
									localStorageLabel: (e.target as any).value,
								});
							}}
							value={newTabInput.localStorageLabel || ""}
						/>
					)}
			</div>
			<div className="helpCenterConfig-editorRow helpCenterConfig-editorRow-justify">
				{betaFeaturesAccessible("kbconfig") && (
					<div className="helpCenterConfig-editorColumn helpCenterConfig-editorInputs">
						<p>Read From Local Storage</p>
						<ToggleSwitch
							toggleFunctions={{
								left: () => {
									setNewTabInput({
										...newTabInput,
										readFromLocalstorage: false,
										localStorageLabel: "",
									});
								},
								right: () => {
									setNewTabInput({
										...newTabInput,
										readFromLocalstorage: true,
										url: "",
									});
								},
							}}
							initialState={newTabInput.readFromLocalstorage || false}
						/>
					</div>
				)}
				<Cutton
					children={"Add Tab"}
					onClick={() => {
						if (newTabInput.label === "") {
							alert("Please Enter a valid Label!");
						} else {
							addTabInList(newTabInput);
							setNewTabInput({
								label: "",
								url: "",
								localStorageLabel: "",
								readFromLocalstorage: false,
							});
						}
					}}
				/>
			</div>
		</div>
	);
};
const HelpCenterTabs = ({
	tabList,
	setTabList,
	type,
}: {
	tabList: HelpCenterTab[];
	setTabList: React.Dispatch<HelpCenterTab[]>;
	type: string;
}) => {
	const dispatch = useAppDispatch();
	const betaFeaturesAccessible = useSuperAdmin();

	const addHelpCenterTab = () => {
		dispatch(
			openOverlay({
				heading: {
					title: "Add Help Center tab",
					icon: <></>,
				},
				component: (
					<>
						<AddHelpCenterTab
							tabList={tabList}
							setTabList={setTabList}
							addTabInList={(newTab: HelpCenterTab) => {
								setTabList([...tabList, newTab]);
								dispatch(closeOverlay());
							}}
						/>
					</>
				),
			}),
		);
	};
	return (
		<div className="helpCenterConfig-editorColumn">
			<div className="helpCenterConfig-editorRow helpCenterConfig-editorRow-justify">
				<p
					style={{
						fontSize: "0.9em",
						fontWeight: "700",
					}}
				>
					{type} Tabs
				</p>
				<Cutton
					children="Add Tab"
					onClick={() => {
						addHelpCenterTab();
					}}
				/>
			</div>
			<div className="helpCenterConfig-editorRow">
				<table>
					<tr>
						<th>Label</th>
						<th>URL</th>
						{betaFeaturesAccessible("kbconfig") && <th>Local Storage Label</th>}
						{betaFeaturesAccessible("kbconfig") && (
							<th>Read from Local Storage</th>
						)}
						<th></th>
					</tr>
					{tabList.map((tab, i) => {
						return (
							<tr key={JSON.stringify(tab) + i}>
								<td>{tab.label}</td>
								<td>{tab.url}</td>
								{betaFeaturesAccessible("kbconfig") && (
									<td>
										{tab.readFromLocalstorage ? tab.localStorageLabel : "NA"}
									</td>
								)}
								{betaFeaturesAccessible("kbconfig") && (
									<td>{tab.readFromLocalstorage ? "Yes" : "No"}</td>
								)}
								<td>
									<Cutton
										onClick={() => {
											setTabList(
												tabList.filter((item, j) => {
													return i !== j;
												}),
											);
										}}
										children="Delete"
									/>
								</td>
							</tr>
						);
					})}
				</table>
			</div>
		</div>
	);
};

const HelpCenterConfigEditor = () => {
	const betaFeaturesAccessible = useSuperAdmin();

	const [topbarTitle, setTopBarTitle] = useState<string>("");
	const [pageTitle, setPageTitle] = useState<string>("");
	const [homePageName, setHomePageName] = useState<string>("");
	const [logoSize, setLogoSize] = useState<number>(0);
	const [openDocKeyword, setOpenDocKeyword] = useState<string>("");
	const [subcollectionsKeyword, setSubcollectionsKeyword] =
		useState<string>("");
	const [guidesKeyword, setGuidesKeyword] = useState<string>("");
	const [searchTitle, setSearchTitle] = useState<string>("");
	const [createdByKeyword, setCreatedByKeyword] = useState<string>("");
	const [feedbackPrompt, setFeedbackPrompt] = useState<string>("");
	const [feedbackQuestion, setFeedbackQuestion] = useState<string>("");
	const [feedbackPlaceholder, setFeedbackPlaceholder] = useState<string>("");
	const [feedbackSubmitText, setfeedbackSubmitText] = useState<string>("");
	const [feedbackRevertText, setfeedbackRevertText] = useState<string>("");
	const [disableDownload, setdisableDownload] = useState<boolean>(true);
	const [videoBrandingFlag, setVideoBrandingFlag] = useState<boolean>(true);
	const [feedbackFlag, setFeedbackFlag] = useState<boolean>(true);
	const [themeToggleFlag, setThemeToggleFlag] = useState<boolean>(true);
	const [creatorVisibilityFlag, setCreatorVisibilityFlag] =
		useState<boolean>(true);
	const [orgId, setOrgId] = useState<HelpCenterConfig["organizationId"]>();

	const [leftTabs, setLeftTabs] = useState<HelpCenterTab[]>([]);
	const [rightTabs, setRightTabs] = useState<HelpCenterTab[]>([]);
	const [brandColor, setBrandColor] = useState<string>("#D43F8C");
	const [backgroundColor, setBackgroundColor] = useState<string>("#FFFFFF");
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
	const [logoInput, setLogoInput] = useState<File>();
	const [faviconInput, setFaviconInput] = useState<File>();
	useEffect(() => {
		handleFetchHelpCenterConfig();
	}, []);
	const uploadLogoFile = () => {
		if (logoInput) {
			const fileReader = new FileReader();
			fileReader.onload = (event) => {
				saver.setLogo(event.target?.result?.toString() as string);
				document
					.getElementById("logoPreviewHelpCenter")
					?.setAttribute(
						"src",
						(event.target?.result as string) ||
							`https://clueso-public-assets.s3.ap-south-1.amazonaws.com/${orgId}.svg`,
					);
			};
			fileReader.readAsDataURL(logoInput);
		}
	};
	const uploadFaviconFile = () => {
		if (faviconInput) {
			const fileReader = new FileReader();
			fileReader.onload = (event) => {
				saver.setFavicon(event.target?.result?.toString() as string);
				document
					.getElementById("faviconPreviewHelpCenter")
					?.setAttribute(
						"src",
						(event.target?.result as string) ||
							`https://clueso-public-assets.s3.ap-south-1.amazonaws.com/${orgId}/logos/favicon`,
					);
			};
			fileReader.readAsDataURL(faviconInput);
		}
	};
	const handleFetchHelpCenterConfig = () => {
		saver.fetchHelpCenterConfig().then((body) => {
			if (body && (body satisfies HelpCenterConfig)) {
				setOrgId(body.organizationId);
				setTopBarTitle(body.topbarTitle);
				setHomePageName(body.homePageName);
				setLogoSize(body.logoSize);
				setOpenDocKeyword(body.openDocKeyword);
				setSubcollectionsKeyword(body.subcollectionsKeyword);
				setGuidesKeyword(body.guidesKeyword);
				setSearchTitle(body.searchTitle);
				setCreatedByKeyword(body.createdByKeyword);
				setFeedbackPrompt(body.feedbackPrompt);
				setFeedbackQuestion(body.feedbackQuestion);
				setFeedbackPlaceholder(body.feedbackPlaceholder);
				setfeedbackSubmitText(body.feedbackSubmitText);
				setfeedbackRevertText(body.feedbackRevertText);
				setdisableDownload(body.disableDownload);
				setVideoBrandingFlag(body.videoBrandingFlag);
				setFeedbackFlag(body.feedbackFlag);
				setThemeToggleFlag(body.themeToggleFlag);
				setCreatorVisibilityFlag(body.creatorVisibilityFlag);
				setLeftTabs(body.leftTabs);
				setRightTabs(body.rightTabs);
				setBackgroundColor(body.backgroundColor || "#FFFFFF");
				setBrandColor(body.brandColor || "#D43F8C");
			}
			isInitialLoading && setIsInitialLoading(false);
			setIsLoading(false);
		});
	};

	const previewLogo = (file: File) => {
		if (file) {
			const fileReader = new FileReader();
			fileReader.onload = (event) => {
				document
					.getElementById("logoPreviewHelpCenter")
					?.setAttribute(
						"src",
						(event.target?.result as string) ||
							`https://clueso-public-assets.s3.ap-south-1.amazonaws.com/${orgId}.svg`,
					);
			};
			fileReader.readAsDataURL(file);
		}
	};
	const previewFavicon = (file: File) => {
		if (file) {
			const fileReader = new FileReader();
			fileReader.onload = (event) => {
				document
					.getElementById("faviconPreviewHelpCenter")
					?.setAttribute(
						"src",
						(event.target?.result as string) ||
							`https://clueso-public-assets.s3.ap-south-1.amazonaws.com/${orgId}/logos/favicon`,
					);
			};
			fileReader.readAsDataURL(file);
		}
	};

	if (isInitialLoading) {
		return <GridLoader center />;
	}
	return (
		<div>
			<h2 className="helpCenterConfig-title">Help Center Customizations</h2>

			<div className="helpCenterConfig-mainContainer">
				<div className="helpCenterConfig-editor ">
					<p
						style={{
							fontSize: "0.9em",
							fontWeight: "700",
						}}
					>
						Theme Customizations
					</p>
					<div className="helpCenterConfig-editorRow helpCenterConfig-ColorPickers">
						<div className="helpCenterConfig-editorColumn">
							<p>Brand Color</p>
							<div>
								<CColorPicker
									color={brandColor}
									disableOpacity
									onChange={(color) => {
										color && setBrandColor(color);
									}}
									children={undefined}
								/>
							</div>
						</div>
						<div className="helpCenterConfig-editorColumn">
							<p>Background Color</p>
							<div>
								<CColorPicker
									color={backgroundColor}
									disableOpacity
									onChange={(color) => {
										color && setBackgroundColor(color);
									}}
									children={undefined}
								/>
							</div>
						</div>
					</div>
					{betaFeaturesAccessible("kbconfig") && (
						<p
							style={{
								fontSize: "0.9em",
								fontWeight: "700",
							}}
						>
							Topbar Customizations
						</p>
					)}
					<div className="helpCenterConfig-editorRow">
						{betaFeaturesAccessible("kbconfig") && (
							<>
								<TitledInput
									className="helpCenterConfig-editorInputs"
									label="Topbar Title"
									id="topbarTitle"
									onChange={(e) => setTopBarTitle((e.target as any).value)}
									value={topbarTitle}
								/>
								<TitledInput
									className="helpCenterConfig-editorInputs"
									label="Page Title"
									id="pageTitle"
									onChange={(e) => setPageTitle((e.target as any).value)}
									value={pageTitle}
								/>
								<TitledInput
									className="helpCenterConfig-editorInputs"
									label="Homepage Name"
									id="homePageName"
									onChange={(e) => setHomePageName((e.target as any).value)}
									value={homePageName}
								/>
							</>
						)}
						<TitledInput
							className="helpCenterConfig-editorInputs"
							label="Logo Size"
							id="logoSizeInput"
							type="number"
							onChange={(e) => setLogoSize(Number((e.target as any).value))}
							value={logoSize.toString()}
						/>
						<div className="helpCenterConfig-editorInputs-LogoUpload">
							<div className="helpCenterConfig-editorColumn">
								<img
									id="faviconPreviewHelpCenter"
									src={`https://clueso-public-assets.s3.ap-south-1.amazonaws.com/${orgId}/logos/favicon`}
									alt="Brand Favicon"
									style={{
										height: `3em`,
									}}
								/>
							</div>
							<input
								type="file"
								style={{ display: "none" }}
								id="helpCenterFaviconInput"
								accept=".png, .svg"
								onChange={(e) => {
									if (e.target?.files) {
										setFaviconInput(e.target.files[0]);
										previewFavicon(e.target.files[0]);
									}
								}}
							/>
							<Cutton
								children="Select Favicon"
								onClick={() => {
									document.getElementById("helpCenterFaviconInput")?.click();
								}}
							/>
						</div>
						<div className="helpCenterConfig-editorInputs-LogoUpload">
							<div className="helpCenterConfig-editorColumn">
								<img
									id="logoPreviewHelpCenter"
									src={`https://clueso-public-assets.s3.ap-south-1.amazonaws.com/${orgId}.svg`}
									alt="Brand Logo"
									style={{
										width: `${logoSize}em`,
									}}
								/>
							</div>
							<input
								type="file"
								style={{ display: "none" }}
								id="helpCenterLogoInput"
								accept=".png, .svg"
								onChange={(e) => {
									if (e.target?.files) {
										setLogoInput(e.target.files[0]);
										previewLogo(e.target.files[0]);
									}
								}}
							/>
							<Cutton
								children="Select Logo"
								onClick={() => {
									document.getElementById("helpCenterLogoInput")?.click();
								}}
							/>
						</div>
					</div>

					{betaFeaturesAccessible("kbconfig") && (
						<>
							<p
								style={{
									fontSize: "0.9em",
									fontWeight: "700",
								}}
							>
								Feedback Customizations
							</p>
							<div className="helpCenterConfig-editorRow">
								<TitledInput
									className="helpCenterConfig-editorInputs"
									label="Feedback Prompt"
									id="feedbackPrompt"
									onChange={(e) => setFeedbackPrompt((e.target as any).value)}
									value={feedbackPrompt}
								/>
								<TitledInput
									className="helpCenterConfig-editorInputs"
									label="Feedback Question"
									id="feedbackQuestion"
									onChange={(e) => setFeedbackQuestion((e.target as any).value)}
									value={feedbackQuestion}
								/>
							</div>

							<div className="helpCenterConfig-editorRow">
								<TitledInput
									className="helpCenterConfig-editorInputs"
									label="Feedback Placeholder"
									id="feedbackPlaceholder"
									onChange={(e) =>
										setFeedbackPlaceholder((e.target as any).value)
									}
									value={feedbackPlaceholder}
								/>
								<TitledInput
									className="helpCenterConfig-editorInputs"
									label="Feedback Submit Text"
									id="feedbackSubmitText"
									onChange={(e) =>
										setfeedbackSubmitText((e.target as any).value)
									}
									value={feedbackSubmitText}
								/>
							</div>
							<TitledInput
								className="helpCenterConfig-editorInputs"
								label="Feedback Revert Text"
								id="feedbackRevertText"
								onChange={(e) => setfeedbackRevertText((e.target as any).value)}
								value={feedbackRevertText}
							/>
							<p
								style={{
									fontSize: "0.9em",
									fontWeight: "700",
								}}
							>
								Generic Customizations
							</p>
							<div className="helpCenterConfig-editorRow">
								<TitledInput
									className="helpCenterConfig-editorInputs"
									label="Open Doc Keyword"
									id="openDocKeywordInput"
									onChange={(e) => setOpenDocKeyword((e.target as any).value)}
									value={openDocKeyword}
								/>
								<TitledInput
									className="helpCenterConfig-editorInputs"
									label="Created By Keyword"
									id="createdByKeyword"
									onChange={(e) => setCreatedByKeyword((e.target as any).value)}
									value={createdByKeyword}
								/>
								<TitledInput
									className="helpCenterConfig-editorInputs"
									label="Sub-collections Keyword"
									id="subcollectionsKeyword"
									onChange={(e) =>
										setSubcollectionsKeyword((e.target as any).value)
									}
									value={subcollectionsKeyword}
								/>
								<TitledInput
									className="helpCenterConfig-editorInputs"
									label="Guides Keyword"
									id="guidesKeyword"
									onChange={(e) => setGuidesKeyword((e.target as any).value)}
									value={guidesKeyword}
								/>
								<TitledInput
									className="helpCenterConfig-editorInputs "
									label="Search Title"
									id="searchTitle"
									onChange={(e) => setSearchTitle((e.target as any).value)}
									value={searchTitle}
								/>
							</div>
							<p
								style={{
									fontSize: "0.9em",
									fontWeight: "700",
								}}
							>
								Feature Flags
							</p>
							<div className="helpCenterConfig-editorRow">
								<div className="helpCenterConfig-editorColumn helpCenterConfig-editorInputs">
									<p>Allow Downloads</p>
									<ToggleSwitch
										toggleFunctions={{
											left: () => {
												setdisableDownload(false);
											},
											right: () => {
												setdisableDownload(true);
											},
										}}
										initialState={disableDownload}
									/>
								</div>
								{/* <div className="helpCenterConfig-editorColumn helpCenterConfig-editorInputs">
            <p>Video Branding</p>
            <ToggleSwitch toggleFunctions={{
              left: () => {
                setVideoBrandingFlag(false)
              },
              right: () => {
                setVideoBrandingFlag(true)
              }
            }} initialState={videoBrandingFlag} />
          </div> */}
								<div className="helpCenterConfig-editorColumn helpCenterConfig-editorInputs">
									<p>Enable Feedback</p>
									<ToggleSwitch
										toggleFunctions={{
											left: () => {
												setFeedbackFlag(false);
											},
											right: () => {
												setFeedbackFlag(true);
											},
										}}
										initialState={feedbackFlag}
									/>
								</div>
								<div className="helpCenterConfig-editorColumn helpCenterConfig-editorInputs">
									<p>Allow Theme Toggle</p>
									<ToggleSwitch
										toggleFunctions={{
											left: () => {
												setThemeToggleFlag(false);
											},
											right: () => {
												setThemeToggleFlag(true);
											},
										}}
										initialState={themeToggleFlag}
									/>
								</div>
								<div className="helpCenterConfig-editorColumn helpCenterConfig-editorInputs">
									<p>Show who created a post?</p>
									<ToggleSwitch
										toggleFunctions={{
											left: () => {
												setCreatorVisibilityFlag(false);
											},
											right: () => {
												setCreatorVisibilityFlag(true);
											},
										}}
										initialState={creatorVisibilityFlag}
									/>
								</div>
							</div>
						</>
					)}
					<div className="helpCenterConfig-editorColumn helpCenterConfig-editorColumn-gap-8">
						{/* <HelpCenterTabs tabList={leftTabs} setTabList={setLeftTabs} type="Left" /> */}
						<HelpCenterTabs
							tabList={rightTabs}
							setTabList={setRightTabs}
							type="Right"
						/>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							paddingTop: "1em",
						}}
					>
						<Button
							edges="round"
							rank="secondary"
							fill="solid"
							loading={isLoading}
							isDisabled={isLoading}
							onClick={() => {
								setIsLoading(true);
								setLogoInput(undefined);
								var logoInput = document.getElementById(
									"helpCenterLogoInput",
								) as HTMLInputElement;
								logoInput.value = "";

								handleFetchHelpCenterConfig();
							}}
							children={"Reset"}
						/>
						<Button
							edges="round"
							rank="primary"
							fill="solid"
							loading={isLoading}
							isDisabled={isLoading}
							onClick={() => {
								setIsLoading(true);
								uploadLogoFile();
								uploadFaviconFile();
								saver
									.setHelpCenterConfig({
										organizationId: orgId as string,
										topbarTitle,
										homePageName,
										logoSize,
										openDocKeyword,
										subcollectionsKeyword,
										guidesKeyword,
										searchTitle,
										createdByKeyword,
										feedbackPrompt,
										feedbackQuestion,
										feedbackPlaceholder,
										feedbackSubmitText,
										feedbackRevertText,
										disableDownload,
										videoBrandingFlag,
										feedbackFlag,
										themeToggleFlag,
										creatorVisibilityFlag,
										leftTabs,
										rightTabs,
										brandColor,
										backgroundColor,
										pageTitle,
									})
									.then(() => {
										handleFetchHelpCenterConfig();
									});
							}}
							children={"Submit"}
						/>
					</div>
				</div>
				{/* 
        <div className="helpCenterConfig-realTimeViewer">
          Hi
        </div> */}
			</div>
		</div>
	);
};

export default HelpCenterConfigEditor;
