import React, { useContext, useState } from "react";
import "./Files.css";
import folder from "@gigauser/common/src/assets/legacy_assets/icons/folder_color.svg";
import pages from "@gigauser/common/src/assets/legacy_assets/icons/papers_color.svg";
import notebooks from "@gigauser/common/src/assets/legacy_assets/icons/notebook_color.svg";
import GuidesTable from "@gigauser/common/src/components/guides/GuidesTable/GuidesTable";

// import {foldersData, readingData, writingData} from './data'
import SectionScroller from "@gigauser/common/src/layouts/SectionScroller/SectionScroller";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import useFilesData from "./useFilesData";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";

import { openReading, openWriting } from "../../../../features/files/openFile";
import empty_folder from "@gigauser/common/src/assets/legacy_assets/images/empty-folder.png";
import empty_shared from "@gigauser/common/src/assets/legacy_assets/images/empty_shared.png";

import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import useCollectionNavigate from "@gigauser/common/src/redux/hooks/FileHooks/useCollectionNavigate";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import { GigaUserApi } from "@giga-user-fern/api";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import DeletePopup from "../../features/files/DeletePopup/DeletePopup";
import trash from "@gigauser/common/src/assets/icons/trash.svg";
import useItemContextMenu from "../../../../features/files/useItemContextMenu";
import useReorderItem from "@gigauser/common/src/redux/hooks/FileHooks/useReorderItem";
import logger from "@gigauser/common/src/utils/logger";
import Header from "../Header";
import { Flex, useToast } from "@chakra-ui/react";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import moveFolderIcon from "@gigauser/common/src/assets/svgs/moveFolderIcon";
import folderIcon from "@gigauser/common/src/assets/svgs/home/folderIcon";
import GridListToggle, {
	GridListState,
} from "../SortAndFilters/GridListToggle/GridListToggle";
import FileSortDropdown from "../SortAndFilters/FileSortDropdown/FileSortDropdown";
import CreateCollection from "../NewButton/laptop/CreateCollection/CreateCollection";
import { openModal } from "@gigauser/common/src/layouts/CModal/modalSlice";
import NewGuideOverlay from "../NewButton/NewGuideOverlay/NewGuideOverlay";
import useConfirmDelete from "@gigauser/common/src/hooks/useConfirmDelete/useConfirmDelete";
import { rootCollection } from "@gigauser/common/src/types/files";
import {
	deleteCollectionFrontend,
	deleteGuideFrontend,
} from "@gigauser/common/src/redux/slices/collectionDataSlice";
import { saver } from "@gigauser/common/src/network/saver";
import PaymentResultPopup from "@gigauser/common/src/overlays/PaymentResultPopup/PaymentResultPopup";
import tickIcon from "@gigauser/common/src/assets/svgs/tickIcon";
import closeIcon from "@gigauser/common/src/assets/svgs/closeIcon";
import billingIcon from "@gigauser/common/src/assets/svgs/billingIcon";
import { selectAnalytics } from "@gigauser/common/src/redux/slices/backendSlice";

type FilesProps = {};

const Files: React.FC<FilesProps> = () => {
	const env = useContext(EnvironmentContext);

	const navigate = useCollectionNavigate(env);
	const dispatch = useAppDispatch();
	const backendReorderItem = useReorderItem();
	const confirmDelete = useConfirmDelete();
	const analytics = useAppSelector(selectAnalytics)
	const toast = useToast();

	const [viewState, setViewState] = useState<GridListState>("list");

	const onClickCollection = (collection: Collection) => {
		logger.debug("collection clicked: ", collection);
		navigate(collection);
	};

	const onClickGuide = (guide: GuidePreview) => {
		window.open("/guide/" + guide.id + "", "_blank");
		// alert("Please use the chrome extension to open guides. Opening on this platform is coming soon! ")
	};

	const onDeleteGuide = (guide: GuidePreview) => {
		const submitDeleteFunction =
			async (resolve: any, reject: any) => {
				// deleteResult = await saver.deleteGuide(item.entry.id)
				const guidePreview = { ...guide };
				guidePreview.header = {
					...guidePreview.header,
					published: false,
				};
				guidePreview.deleted = true;
				guidePreview.isPinned = false;

				const deleteResult = await saver.guides.preview.update(guidePreview);

				var currentURL = window.location.href;

				// Check if the URL contains "/guide"
				if (currentURL.indexOf("/guide") !== -1) {
					navigate(rootCollection);
				}

				if (deleteResult) {
					resolve(true);
				} else {
					reject(false);
				}
			}

		confirmDelete(
			() => {
				const submitDeletePromise = new Promise<boolean>(submitDeleteFunction)
				toast.promise(submitDeletePromise, {
					success: { title: `Deleted 1 item successfully.` },
					error: {
						title: "Unexpected error while deleting item",
						description: "Please try again later.",
					},
					loading: { title: "Deleting ..." },
				});
				submitDeletePromise
					.then(() => {
						dispatch(deleteGuideFrontend(guide));
					})
					.catch(() => {
						logger.error("Error deleting guide");
					});
			},
			{
				item: guide.header.name,
			},
		);
	};

	const onDeleteCollection = (c: Collection) => {
		const submitDeleteFunction =
			async (resolve: any, reject: any) => {
				const deleteResult = await saver.collections.delete(c.id);

				var currentURL = window.location.href;

				// Check if the URL contains "/guide"
				if (currentURL.indexOf("/guide") !== -1) {
					navigate(rootCollection);
				}

				if (deleteResult) {
					resolve(true);
				} else {
					reject(false);
				}
			}

		confirmDelete(
			() => {
				const submitDeletePromise = new Promise<boolean>(submitDeleteFunction)
				toast.promise(submitDeletePromise, {
					success: { title: `Deleted 1 item successfully.` },
					error: {
						title: "Unexpected error while deleting item",
						description: "Please try again later.",
					},
					loading: { title: "Deleting ..." },
				});
				submitDeletePromise
					.then(() => {
						dispatch(deleteCollectionFrontend(c));
					})
					.catch(() => {
						logger.error("Error deleting guide");
					});
			},
			{
				item: c.name,
			},
		);
	};

	const onReorderGuide = (sourceIndex: number, destinationIndex: number) => {
		const succeedingGuide = guidesData[sourceIndex];
		var precedingGuide: undefined | GuidePreview;

		if (destinationIndex > 0) {
			precedingGuide = guidesData[destinationIndex - 1];
		}

		if (precedingGuide) {
			backendReorderItem({
				type: "Guide",
				succeedingItem: succeedingGuide,
				precedingItem: precedingGuide,
			});
		} else {
			backendReorderItem({ type: "Guide", succeedingItem: succeedingGuide });
		}
	};

	const onReorderCollection = (
		sourceIndex: number,
		destinationIndex: number,
	) => {
		const succeedingCollection = collectionsData[sourceIndex];
		var precedingCollection: undefined | Collection;

		if (destinationIndex > 0) {
			precedingCollection = collectionsData[destinationIndex - 1];
		}

		if (precedingCollection) {
			backendReorderItem({
				type: "Collection",
				succeedingItem: succeedingCollection,
				precedingItem: precedingCollection,
			});
		} else {
			backendReorderItem({
				type: "Collection",
				succeedingItem: succeedingCollection,
			});
		}
	};

	const openCreateFolderOverlay = () => {
		dispatch(
			openModal({
				heading: {
					icon: folderIcon(),
					title: "Create Folder",
					data: "",
				},
				component: <CreateCollection />,
			}),
		);
	};

	const openCreateGuideOverlay = () => {
		analytics.captureEvent({eventName: "NewProjectClicked", value: {}})
		dispatch(
			openModal({
				heading: {
					icon: plusIcon(),
					title: "New project",
					subtitle: "Clueso creates stunning videos and step-by-step guides",
					data: "",
				},
				component: <NewGuideOverlay />,
			}),
		);
	};

	const showFileContextMenu = useItemContextMenu();

	const { collectionsData, guidesData, loading } = useFilesData(env);

	const currentCollection = useAppSelector(
		(state) => state.collectionData.currentCollectionData.currentCollection,
	);

	const pinnedGuides = guidesData.filter(
		(guidePreview) => guidePreview.isPinned,
	);

	const pinnedSection = (
		<GuidesTable
			type="Guide"
			entries={pinnedGuides}
			onClick={onClickGuide}
			onDelete={onDeleteGuide}
			showFileContextMenu={showFileContextMenu}
		/>
	);
	const collectionSection = collectionsData.length ? (
		<GuidesTable
			type="Collection"
			entries={collectionsData}
			onClick={onClickCollection}
			onDelete={onDeleteCollection}
			showFileContextMenu={showFileContextMenu}
		/>
	) : null;

	const nonPinnedGuides = guidesData.filter(
		(guidePreview) => !guidePreview.isPinned,
	);
	const guideSection = nonPinnedGuides.length ? (
		<GuidesTable
			type="Guide"
			entries={nonPinnedGuides}
			onClick={onClickGuide}
			onDelete={onDeleteGuide}
			showFileContextMenu={showFileContextMenu}
		/>
	) : null;

	const device = useWindowSize().device;

	const isEmpty = !(collectionSection || guideSection);

	var sections = [
		{
			icon: folder,
			header: "Folders",
			contents: collectionSection,
		},
		{
			icon: pages,
			header: "Projects",
			contents: guideSection,
		},
	];

	if (pinnedGuides.length) {
		sections = [
			{
				icon: pages,
				header: "Pinned Project",
				contents: pinnedSection,
			},
			...sections,
		];
	}

	return (
		<div className={`Files ${device} ${isEmpty ? "empty" : ""} `}>
			<div className="row">
				<Header showTitle={true} />
			</div>

			<div className="Files-contents">
				<Flex
					className="Files-buttons"
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<Flex alignItems={"center"} gap={"0.8rem"}>
						<Cutton
							onClick={openCreateGuideOverlay}
							leftIcon={plusIcon("white")}
						>
							New Video
						</Cutton>

						<Cutton
							rank="tertiary"
							leftIcon={folderIcon("white")}
							onClick={openCreateFolderOverlay}
						>
							New Folder
						</Cutton>
					</Flex>

					<Flex alignItems={"center"}>
						{/* <FileSortDropdown />
                        <GridListToggle state={viewState} setState={setViewState} /> */}
					</Flex>
				</Flex>
				{loading && "Loading ..."}
				{!loading && <SectionScroller sections={sections} />}
				{!loading && isEmpty ? (
					<div className="Files-empty-container">
						{/* <img
							src={
								currentCollection.id === "Folder_shared"
									? empty_shared
									: empty_folder
							}
							className="empty-folder"
						></img> */}
						<p>This folder is empty!</p>
						<p>Click the buttons above to create content in this folder.</p>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default Files;
