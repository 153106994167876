import React, { useEffect, useState } from "react";
import "./PublishHelpCenter.css";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import rocketIcon from "@gigauser/common/src/assets/svgs/rocketIcon";
import unpublishIcon from "@gigauser/common/src/assets/svgs/unpublishIcon";
import AdvancedSettings from "@gigauser/common/src/ui/video/components/AdvancedSettings/AdvancedSettings";
import CToggle from "@gigauser/common/src/ui/video/components/CToggle/CToggle";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import {
	selectGuide,
	selectGuidePreview,
	selectGuideSettings,
	selectUnsavedChanges,
} from "@gigauser/common/src/redux/slices/guideSlice";
import {
	Guide,
	GuidePreview,
	ValidateGuideUrlResponse,
} from "@giga-user-fern/api/types/api/resources/guides";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import ConfirmPublishPopup from "../ConfirmPublishPopup/ConfirmPublishPopup";
import {
	Flex,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";
import externalLinkIcon from "@gigauser/common/src/assets/svgs/externalLinkIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import { Collection } from "@giga-user-fern/api/types/api/resources/collections";
import { rootCollection } from "@gigauser/common/src/types/files";
import { LinkCopy } from "../LinkCopy/LinkCopy";
import TitledInput from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import { convertToUrl } from "@gigauser/common/src/utils/stringOperations";
import IBox from "@gigauser/common/src/ui/messages/iBox/iBox";

type PublishHelpCenterProps = {};

const PublishHelpCenter: React.FC<PublishHelpCenterProps> = () => {
	const [helpCenterDomain, setHelpCenterDomain] = useState<string>("");
	const [helpCenterLink, setHelpCenterLink] = useState<string>("");
	const saver = useAppSelector(selectSaver);

	const guidePreview = useAppSelector(selectGuidePreview) as GuidePreview;
	const guide = useAppSelector(selectGuide) as Guide;
	const [hasPrivateParent, setHasPrivateParent] = useState(false);
	const [fullPath, setFullPath] = useState<[Collection, ...Collection[]]>([
		rootCollection,
	]);
	const dispatch = useAppDispatch();

	const settings = useAppSelector(selectGuideSettings);

	const [showArticle, setShowArticle] = useState<boolean>(
		!settings?.hideArticle,
	);
	const [showVideo, setShowVideo] = useState<boolean>(
		settings?.publishedVideo !== "none",
	);
	const [guideUrlPath, setGuideUrlPath] = useState<string>(
		guidePreview.guideUrlPath || convertToUrl(guidePreview?.header.name),
	);
	const [isUrlValidationLoading, setIsUrlValidationLoading] =
		useState<boolean>(true);
	const [urlValidation, setUrlValidation] =
		useState<ValidateGuideUrlResponse>();
	const unsavedChanges = useAppSelector(selectUnsavedChanges);

	const isPrivate = () => {
		return hasPrivateParent || !guidePreview.header.published;
	};

	const currentVersion = unsavedChanges
		? guidePreview.versionSaved + 1
		: guidePreview.versionSaved;

	const initHelpCenterLink = async () => {
		const link = await saver.guides.shareableLink.get(guidePreview);
		setHelpCenterLink(link.sharingLink);
		setHasPrivateParent(link.hasPrivateParent);
		setFullPath(link.path);

		const url = new URL(link.sharingLink);
		const helpCenterDomain = url.hostname;
		setHelpCenterDomain(helpCenterDomain);
	};

	useEffect(() => {
		initHelpCenterLink();
	}, []);

	const onPublish = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: rocketIcon("white"),
					title: "Are you sure you want to publish?",
				},
				component: (
					<ConfirmPublishPopup
						path={fullPath}
						isPrivate={isPrivate()}
						source="helpcenter"
						articleVisible={showArticle}
						videoVisible={showVideo}
						hasPrivateParent={hasPrivateParent}
						guideUrlPath={guideUrlPath}
					/>
				),
			}),
		);
	};

	const onUnpublish = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: rocketIcon("white"),
					title: "Are you sure you want to unpublish?",
				},
				component: (
					<ConfirmPublishPopup
						isPrivate={isPrivate()}
						source="helpcenter"
						hasPrivateParent={hasPrivateParent}
						unpublish
						guideUrlPath={guideUrlPath}
					/>
				),
			}),
		);
	};

	const checkUrlConflict = () => {
		saver
			.validateGuideUrlPath({ guideUrlPath, currentGuideId: guidePreview.id })
			.then((body) => {
				if (body.ok) {
					setUrlValidation(body.body);
					setIsUrlValidationLoading(false);
				}
			});
	};

	useEffect(() => {
		checkUrlConflict();
	}, [guideUrlPath]);

	return (
		<div className="PublishHelpCenter">
			<div className="PublishHelpCenter-title">Help center</div>
			<Flex alignItems={"center"} gap={"1rem"}>
				<a
					className="helpcenterlinkcontainer"
					href={`https://${helpCenterDomain}`}
					target="_blank"
				>
					{helpCenterDomain}

					<Icon className="helpcenter-nav">{externalLinkIcon("white")}</Icon>
				</a>

				<div
					className={`publish-statuscontainer ${isPrivate() ? "not-live" : "live"}`}
				>
					<div className="status-circle">.</div>

					<div>
						This guide is {isPrivate() ? "not " : ""} live on your help center
					</div>
				</div>
			</Flex>

			<div className="publishUrlcontainer">
				<TitledInput
					id="editUrlPathInput"
					label="Guide URL"
					className="urlEdit-input"
					value={guideUrlPath}
					onChange={(e) => {
						setGuideUrlPath(convertToUrl((e.target as HTMLInputElement).value));
					}}
				/>
				<p>Preview: {`https://${helpCenterDomain}/guide/${guideUrlPath}`}</p>
				{guideUrlPath === "" ? (
					<span className="conflicting-url">
						Path URL can not be empty string.
					</span>
				) : isUrlValidationLoading ? (
					<Spinner />
				) : urlValidation?.available ? (
					<span className="available-url">
						URL is available to use for this guide.
					</span>
				) : (
					<span className="conflicting-url">
						This URL is conflicting with{" "}
						{urlValidation?.conflictingGuides ? (
							<a
								href={`/guide/${urlValidation.conflictingGuides[0].id}`}
								rel="noreferrer"
								target="_blank"
							>
								{urlValidation.conflictingGuides[0].header.name}
							</a>
						) : (
							"an existing guide"
						)}{" "}
						in your workspace.
					</span>
				)}
			</div>

			{!isPrivate() && (
				<div className="hc-LinkContainer">
					<h3>Link to this guide</h3>
					<LinkCopy link={helpCenterLink}></LinkCopy>
				</div>
			)}

			{!isPrivate() && currentVersion !== guidePreview.versionPublished && (
				<div className="CopyLink-IBox">
					<IBox>
						{`
                            ${isPrivate() ? "This link shows" : "These links show"} 
                            the last published version (v${guidePreview.versionPublished}). 
                        The latest version is v${currentVersion}. Click Publish to share your latest edits.`}
					</IBox>
				</div>
			)}

			<div className="AdvancedSettings-hc">
				<AdvancedSettings subtle>
					<div className="CopyLink-Advanced-Settings">
						<CToggle
							label="Video"
							setterFunction={(s: boolean) => {
								setShowVideo(s);
							}}
							value={showVideo}
						/>

						<CToggle
							label="Article"
							setterFunction={(s: boolean) => {
								setShowArticle(s);
							}}
							value={showArticle}
						/>
					</div>
				</AdvancedSettings>
			</div>

			<div className="publish-buttons">
				<Cutton
					leftIcon={rocketIcon("white")}
					onClick={onPublish}
					disabled={!urlValidation?.available}
				>
					{isPrivate() ? "Publish to help center" : "Publish changes"}
				</Cutton>

				{!isPrivate() && (
					<Cutton
						rank="secondary"
						onClick={onUnpublish}
						leftIcon={unpublishIcon("white")}
					>
						Unpublish
					</Cutton>
				)}
			</div>
		</div>
	);
};
export default PublishHelpCenter;
