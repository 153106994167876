import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthInfo } from "@propelauth/react";

type Feature =
	| "bin"
	| "analytics"
	| "outro"
	| "autoupdate"
	| "introvideotext"
	| "kbconfig"
	| "usage"
	| "homesettings"
	| "helpcenterhosting";

const useSuperAdmin: () => (feature: Feature) => boolean = () => {
	const { user } = useAuthInfo();

	const featureAccessibility: { [key in Feature]: string[] } = {
		bin: [],
		analytics: ["cloudeagle.ai", "spryhealth.care", "houseware.io"],
		outro: [],
		autoupdate: [],
		introvideotext: [],
		kbconfig: [],
		// helpcenterappearance: [],
		helpcenterhosting: [],
		usage: [],
		homesettings: [],
	};

	return (feature: Feature) => {
		var betaFeaturesAccessible = false;

		if (
			user &&
			user.email !== "prajwal@clueso.io" &&
			(user.email?.includes("@clueso") || user.email?.includes("desklamp"))
		) {
			//Seeing deleted guides is only possible for clueso internal team.
			betaFeaturesAccessible = true;
		} else {
			const allowed_domains = featureAccessibility[feature];
			for (let domain of allowed_domains) {
				if (user?.email?.includes(domain)) betaFeaturesAccessible = true;
			}
		}

		return betaFeaturesAccessible;
	};
};

export default useSuperAdmin;
