import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppInitializerPage } from "../AppInitializer";
import {
	AnalyticsForNewUser,
	OrganizationWithListOfUsers,
} from "@giga-user-fern/api/types/api/resources/organizations";
import { saver } from "@gigauser/common/src/network/saver";
import PersonalDetailsForm from "./PersonalDetailsForm/PersonalDetailsForm";
import { FormState } from "@gigauser/common/src/layouts/Form/Form";
import JoinOrganization from "./JoinOrganization/JoinOrganization";
import CreateOrganization from "./CreateOrganization/CreateOrganization";
import { selectAnalytics } from "@gigauser/common/src/redux/slices/backendSlice";
import { useAppSelector } from "@gigauser/common/src/redux/useAppRedux";
import LogoLoader from "@gigauser/common/src/ui/loaders/LogoLoader/LogoLoader";
import { captureEvent } from "@gigauser/common/src/core/analytics/analytics";

type WelcomeFlowProps = {
	page: AppInitializerPage;
	userAssociatedWithAnyOrg: boolean;
};

const WelcomeFlow: React.FC<WelcomeFlowProps> = (props) => {
	const navigate = useNavigate();
	const analytics = useAppSelector(selectAnalytics);

	const [personalDetails, setPersonalDetails] = useState<AnalyticsForNewUser>({
		roleInTeam: "",
		howDidYouHearAboutClueso: "",
	});

	const { userAssociatedWithAnyOrg, page } = props;
	useEffect(() => {
		if (saver.user && userAssociatedWithAnyOrg && !saver.user.email.endsWith("@clueso.io")) {
			window.location.replace("/folders/Collection_root")
		}
	}, [userAssociatedWithAnyOrg, saver.user])
	const [listOfEligibleOrganizations, setListOfEligibleOrganizations] =
		useState<OrganizationWithListOfUsers[]>([]);

	useEffect(() => {
		fetchListOfEligibleOrganizations();
	}, []);

	const fetchListOfEligibleOrganizations = async () => {
		const resp = await saver.listOfEligibleOrganizations();
		if (resp.ok) {
			setListOfEligibleOrganizations(resp.body);
		}
	};

	const onSubmitPersonalDetailsForm = (formState: FormState) => {
		const { name, role, source } = formState;

		setPersonalDetails({
			...personalDetails,
			roleInTeam: role.value,
			howDidYouHearAboutClueso: source.value,
		});

		captureEvent({ eventName: "PersonalDetailsFilled", value: {} })

		if (listOfEligibleOrganizations.length > 0) {
			navigate("/welcome/join-team");
		} else {
			navigate("/welcome/create-team");
		}

		// setCurrentTab("orgDetails");
	};
	if (saver.user && userAssociatedWithAnyOrg && !saver.user.email.endsWith("@clueso.io")) {
		return <LogoLoader />
	}
	if (props.page === "welcome/personal-details") {
		console.log("personal-details");
		return (
			<PersonalDetailsForm
				personalDetails={personalDetails}
				onSubmit={onSubmitPersonalDetailsForm}
			/>
		);
	} else if (props.page === "welcome/create-team") {
		return (
			<CreateOrganization
				personalDetails={personalDetails}
			/>
		);
	} else if (
		props.page === "welcome/join-team" &&
		listOfEligibleOrganizations?.length > 0
	) {
		return (
			<JoinOrganization
				personalDetails={personalDetails}
				listOfEligibleOrganizations={listOfEligibleOrganizations}
			/>
		);
	} else {
		// What to do here?
		return <div>Illegal auth page encountered!</div>;
	}
};
export default WelcomeFlow;
