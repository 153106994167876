import React, { useEffect, useRef, useState } from "react";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { IconMenu } from "@gigauser/common/src/types/menus";

import "./TopbarButtons.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { openFullScreenOverlay } from "@gigauser/common/src/layouts/FullScreenOverlay";
import SearchBox, {
	SearchResult,
} from "../../laptop/Searchbar/SearchBox/SearchBox";

import fuzzysort from "fuzzysort";

import {
	selectCollectionPath,
	editCollectionInPath,
} from "@gigauser/common/src/redux/slices/collectionPathSlice";
import { Collection } from "@gigauser/common/src/core/types/collections";
import logger from "@gigauser/common/src/utils/logger";
import settingsIcon from "@gigauser/common/src/assets/svgs/settingsIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import helpCircleIcon from "@gigauser/common/src/assets/svgs/helpCircleIcon";
import { useNavigate } from "react-router-dom";

type TopbarButtonsProps = {};
export const iconColor = "#bfc7d4";

const getAllQuery = /* GraphQL */ `
query MyQuery {
    getAll{
      parentID
      permission
      name
      objectID
    }
}
`;

const TopbarButtons: React.FC<TopbarButtonsProps> = () => {
	const dispatch = useAppDispatch();

	const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
	const navigateRouter = useNavigate();
	const filesToSearch = useRef();

	const collectionPath = useAppSelector(selectCollectionPath);
	const currFolder = collectionPath.segments.at(-1) as Collection;
	const previousFolder = collectionPath.segments.at(-2) as Collection;

	const runSearch = (searchString: string) => {
		logger.debug("filesToSearch: ", filesToSearch);

		if (!filesToSearch.current) return;

		const fuzzyResults = fuzzysort.go(searchString, filesToSearch.current, {
			key: "name",
		});

		const searchResults = fuzzyResults.map((res) => {
			return {
				highlightedName: fuzzysort.highlight(res, (m, i) => (
					<span className="bolden" key={i}>
						{m}
					</span>
				)),
				// highlightedName: fuzzysort.highlight(res) as string,
				objectUser: res.obj,
			};
		});

		logger.debug("searchResults: ", searchResults);

		// const fileResults: ObjectUser[] = results.map(res => res.obj as ObjectUser)

		// const test = results.map(result =>fuzzysort.highlight(result))
		// logger.debug("highlighted: ", test)

		// logger.debug("results: ", results)
		// setSearchResults(searchResults)
		// return fileResults;
	};

	useEffect(() => {
		// (API.graphql({query: getAllQuery}) as any)
		// .then((resp:any) =>{
		//     logger.debug("got all response: ", resp)
		//     filesToSearch.current = resp.data.getAll
		// })
		// .catch((err:any)=>{
		//     logger.error("couldn't getall: ", err)
		// })
	}, []);

	const editDetailsCallback = (file: Collection) => {
		logger.debug("editDetailsCallback   !", file);
		const { name, description } = file;
		const fileCopy = { ...file };
		fileCopy.name = name;
		fileCopy.description = description;

		// dispatch(editFolderInPath(fileCopy))
	};

	const openEditFolderOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
		event?.preventDefault();
		logger.debug("Need to open overlay");
		// dispatch(openOverlay({
		//     heading: {
		//         icon: edit,
		//         title: "Edit",
		//         data: "" ,
		//     },
		//     component: <EditItemDetails file={currFolder} editDetailsCallback={editDetailsCallback}/>
		// }))
	};

	const openSearchFullScreenOverlay = (
		event: React.MouseEvent<HTMLDivElement>,
	) => {
		event?.preventDefault();
		logger.debug("Need to open overlay");
		logger.debug(
			"Search Results in openSearchFullScreenOverlay: ",
			searchResults,
		);
		dispatch(
			openFullScreenOverlay({
				heading: {
					title: "Search macha",
				},
				component: <SearchBox />,
			}),
		);
	};

	const deviceType = useWindowSize().device;

	const topbarButtons: IconMenu = [
		{
			icon: settingsIcon,
			handler: () => {
				navigateRouter(`/settings/profile`);
			},
		},
		{
			icon: helpCircleIcon,
			url: "https://help.clueso.io",
			handler: () => {},
		},
	];

	return (
		<div className={`TopbarButtons ${deviceType}`}>
			{topbarButtons.map((item) => {
				if (item.exclusiveTo && item.exclusiveTo !== deviceType) return null;
				else {
					return (
						<a
							className="TopbarButtons-button-container"
							href={item.url ? item.url : undefined}
							target="_blank"
							onClick={item.handler}
						>
							<Icon className="TopbarButtons-icon" hoverTip={item.hoverTip}>
								{item.icon(iconColor)}
							</Icon>
						</a>
					);
				}
			})}
		</div>
	);
};

export default TopbarButtons;
