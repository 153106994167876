import { GuidePreview } from "../../../core/types/guide";
import {
	showLoadingMessagePopup,
	showMessagePopup,
} from "../../../layouts/MessagePopup/messagePopupSlice";
import { Item } from "../../../types/files";
import { selectSaver } from "../../slices/backendSlice";
import {
	editItemFrontend,
	setPinnedGuide,
} from "../../slices/collectionDataSlice";
import { useAppDispatch, useAppSelector } from "../../useAppRedux";
import { useToast } from "@chakra-ui/react";

const usePinGuide: () => (
	isPinned: boolean,
	guidePreview: GuidePreview,
) => Promise<boolean> = () => {
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);
	const toast = useToast();

	return async (isPinned: boolean, guidePreview: GuidePreview) => {
		const pinItemPromise = new Promise<boolean>(async (resolve, reject) => {
			try {
				const resp = await saver.guides.pin(isPinned, guidePreview);

				console.log("pinResp: ", resp);

				const updatedItem: Item = {
					type: "Guide",
					entry: {
						...guidePreview,
						isPinned: isPinned,
					},
				};
				dispatch(
					setPinnedGuide({
						isPinned: isPinned,
						guidePreview: guidePreview,
					}),
				);
				resolve(true);
			} catch {
				reject(false);
			}
		});

		toast.promise(pinItemPromise, {
			success: { title: `Pinned item successfully.` },
			error: {
				title: "Unexpected error while pinning item",
				description: "Please try again later.",
			},
			loading: { title: "Working ..." },
		});

		return pinItemPromise;
	};
};

export default usePinGuide;
