import React from "react";
import './SectionHeader.css';

type SectionHeaderProps = {
	children?: React.ReactNode;
};

const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
	return <div className="SectionHeader">{props.children}</div>;
};
export default SectionHeader;
