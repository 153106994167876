import { saver } from "@gigauser/common/src/network/saver";
import { useEffect, useState } from "react";
import PageHeader from "../../../home/components/Header/laptop/PageHeader/PageHeader";
import manageTeamsIcon from "@gigauser/common/src/assets/svgs/home/manageTeamsIcon";
import { Flex, list } from "@chakra-ui/react";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import newTeamGraphic from "@gigauser/common/src/assets/svgs/home/newTeamGraphic.svg";
import "./ManageTeams.css";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import SectionHeader from "../../../home/components/Header/laptop/SectionHeader/SectionHeader";
import CTable from "@gigauser/common/src/ui/table/CTable/CTable";
import CTableRow, {
	CTableRowProps,
} from "@gigauser/common/src/ui/table/CTable/CTableRow";
import UserPreview from "@gigauser/common/src/ui/users/UserPreview/UserPreview";
import { Organization } from "@gigauser/common/src/core/types/guide";

export default function ManageTeams() {
	const [loading, setLoading] = useState(false);

	const [listOfJoinedOrgs, setListOfJoinedOrgs] = useState<Organization[]>([]);
	const [listOfEligibleOpenDoorOrgs, setListOfEligibleOpenDoorOrgs] = useState<
		Organization[]
	>([]);

	useEffect(() => {
		getListOfJoinedOrgs();
		getListOfEligibleOpenDoorOrgs();
	}, []);

	const getListOfJoinedOrgs = async () => {
		const resp =
			await saver.settings.manageTeam.listOfOrgsCurrentUserIsAssociatedWith();
		if (resp.ok) {
			setListOfJoinedOrgs(resp.body);
		} else {
			alert("Error while fetching connected orgs!");
		}
	};

	const getListOfEligibleOpenDoorOrgs = async () => {
		const resp = await saver.settings.manageTeam.listOfEligibleOrganizations();
		if (resp.ok) {
			setListOfEligibleOpenDoorOrgs(resp.body);
		} else {
			alert("Error while fetching eligible orgs!");
		}
	};

	const columns = [
		{
			colName: "Team Name",
			colWidth: 70,
		},
		{
			colName: "",
			colWidth: 30,
		},
	];

	const rows: React.ReactElement<CTableRowProps>[] = listOfJoinedOrgs.map(
		(org, index) => {
			const entries = [
				<>
					<UserPreview
						user={{
							fullName: org.name,
							email: org.domain,
						}}
					/>
				</>,
				<Flex gap={"1rem"}>
					<div style={{
                        visibility: saver.organization?.id === org.id ? "hidden" : "visible"
                    }}>
						<Cutton rank="tertiary">Go to this team</Cutton>
					</div>
					<Cutton rank="quaternary" theme="danger">
						Leave Team
					</Cutton>
				</Flex>,
			];

			return (
				<CTableRow
					columns={columns}
					item={org}
					index={index}
					entries={entries}
				/>
			);
		},
	);

	return (
		<div className="ManageTeams">
			<PageHeader
				title="Manage Teams"
				description="Teams are collaborative environments with your colleagues"
				icon={manageTeamsIcon}
			/>

			<Flex
				className="newteam-graphic-container"
				justifyContent={"space-between"}
			>
				<Flex alignItems={"center"} gap={"1rem"}>
					<img src={newTeamGraphic} className="NewTeamGraphic"></img>

					<div>
						<div className="title">Bring in the people you work with!</div>
						<div className="subtitle">
							Create a new team and collaborate together
						</div>
					</div>
				</Flex>
				<div className="cutton-container">
					<Cutton leftIcon={plusIcon("white")}>Create team</Cutton>
				</div>
			</Flex>

			<div className="other-teams" style={{
                display: listOfEligibleOpenDoorOrgs.length === 0 ? "none" : "block"
            }}>
				<SectionHeader>Available teams you can join </SectionHeader>

				<div className="grid">
					{listOfEligibleOpenDoorOrgs.map((org) => {
						 return (
							<div className="org-card">
								<div className="org-prev">
									<UserPreview
										user={{
											fullName: org.name,
											email: org.domain,
										}}
									/>
								</div>

								<div className="footer">
									<Cutton fullWidth rank="tertiary">
										Join this team
									</Cutton>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<div className="your-teams">
				<SectionHeader>Your teams</SectionHeader>

				<div className="table">
					<CTable columns={columns}>{rows}</CTable>
				</div>
			</div>
		</div>
	);
}
