import billingIcon from "@gigauser/common/src/assets/svgs/billingIcon";
import React, { useEffect, useState } from "react";
import PageHeader from "../../../home/components/Header/laptop/PageHeader/PageHeader";
import SectionHeader from "../../../home/components/Header/laptop/SectionHeader/SectionHeader";
import "./BillingAndPlans.css";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import { saver } from "@gigauser/common/src/network/saver";
import { useToast } from "@chakra-ui/react";

type BillingAndPlansSettingsProps = {};

const BillingAndPlansSettings: React.FC<BillingAndPlansSettingsProps> = () => {
	const [loading, setLoading] = useState(true);
	const [link, setLink] = useState("");

	const toast = useToast();

	const initLink = async () => {
		const resp = await saver.viewStripePortal();
		if (resp.ok) {
			setLink(resp.body);
			setLoading(false);
		} else {
			toast({
				title: "Your account doesn't have an automated billing setup!",
				status: "error",
				description: "Please contact clueso to look at your invoicing.",
			});
		}
	};

	useEffect(() => {
		initLink();
	}, []);

	return (
		<div className="BillingAndPlans">
			<div className="BillingAndPlans-container">
				<PageHeader
					icon={billingIcon}
					title="Billing & Plans"
					description="Manage your team's billing"
				/>

				<div className="Billing-content">
					<SectionHeader>Go to Stripe to manage your billing</SectionHeader>

					<Cutton url={link} disabled={!link} isLoading={loading}>
						Take me to Stripe
					</Cutton>
				</div>
			</div>
		</div>
	);
};
export default BillingAndPlansSettings;
