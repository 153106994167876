import { Collection } from "../../../core/types/collections";
import {
	showErrorMessagePopup,
	showLoadingMessagePopup,
	showMessagePopup,
} from "../../../layouts/MessagePopup/messagePopupSlice";
import { Item } from "../../../types/files";
import { selectSaver } from "../../slices/backendSlice";
import { editItemFrontend } from "../../slices/collectionDataSlice";
import { useAppDispatch, useAppSelector } from "../../useAppRedux";
import { useToast } from "@chakra-ui/react";

const useSetCollectionVisibility: () => (
	collection: Collection,
	isprivate: boolean,
) => Promise<boolean> = () => {
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);
	const toast = useToast();

	return async (collection: Collection, isprivate: boolean) => {
		const collectionCopy = { ...collection };

		collectionCopy.private = !collection.private;

		const itemCopy: Item = {
			type: "Collection",
			entry: { ...collectionCopy },
		};

		const editCollectionVisibilityPromise = new Promise<boolean>(
			async (resolve, reject) => {
				try {
					const res = await saver.collections.visibility.set(
						collection,
						isprivate,
					);
					dispatch(editItemFrontend(itemCopy));
					resolve(true);
				} catch {
					reject(false);
				}
			},
		);

		toast.promise(editCollectionVisibilityPromise, {
			success: {
				title: `${isprivate ? "Unpublished" : "Published"} 1 item`,
			},
			error: {
				title: "Error",
				description: "Error while editing item.",
			},
			loading: {
				title: "Loading",
				description: "Updating item visibility...",
			},
		});

		return editCollectionVisibilityPromise;
	};
};

export default useSetCollectionVisibility;
