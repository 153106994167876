import React, { useContext } from "react";

import "./GuidesTable.css";
import { Guide, GuidePreview } from "../../../core/types/guide";
import Icon from "../../../ui/Icon/Icon";
import move_dots from "../../../assets/svgs/move_dots";
import { Collection } from "../../../core/types/collections";
import { Item } from "../../../types/files";
import { useAppSelector } from "../../../redux";
import CTable, { CTableColumn } from "../../../ui/table/CTable/CTable";
import CTableRow, {
	CTableRowIcon,
	CTableRowItem,
	CTableRowProps,
} from "../../../ui/table/CTable/CTableRow";
import gradientFolderIcon from "../../../assets/svgs/home/gradientFolderIcon";
import { useMoveItem } from "../../../redux/hooks/FileHooks";
import { EnvironmentContext } from "../../../contexts";
import { selectSaver } from "../../../redux/slices/backendSlice";
import restoreIcon from "../../../assets/svgs/restoreIcon";
import externalLinkIcon from "../../../assets/svgs/externalLinkIcon";
import useReorderItem from "../../../redux/hooks/FileHooks/useReorderItem";
import UserPreview from "../../../ui/users/UserPreview/UserPreview";
import { timeAgoFromDate, timeAgoFromString, timeRemainingToDate, } from "../../../utils/os";
import { Size } from "../../../types/sizes";

export type GuidesTableProps = (
	| {
		type: "Guide";
		entries: GuidePreview[];
	}
	| {
		type: "Collection";
		entries: Collection[];
	}
) & {
	disableDragNDrop?: boolean;
	onRestore?: (g: GuidePreview) => void;
	onClick: ((c: Collection) => void) | ((c: GuidePreview) => void);
	onDelete?: ((c: Collection) => void) | ((c: GuidePreview) => void);
	showFileContextMenu?: (file: Item, X: number, Y: number) => void;
	size?: Size;
};

const GuidesTable: React.FC<GuidesTableProps> = (props) => {
	const homePage = window.location.pathname;
	const env = useContext(EnvironmentContext);
	const saver = useAppSelector(selectSaver);
	const backendReorderItem = useReorderItem();

	const backendMoveItem = useMoveItem(env);

	const columns: CTableColumn[] = [
		{
			colName: props.type == "Guide" ? "Project" : "Folder" + " Name",
			colWidth: 35,
		},
		{
			colName: props.type === "Collection" ? "" : "Creator",
			colWidth: 20,
		},
		{
			colName:
				props.type === "Collection"
					? ""
					: "Updated",
			colWidth: 15,
		},
		{
			colName:
				props.type === "Collection"
					? ""
					: homePage.includes("trash")
						? "Created" //make deleted when working
						: "Created",
			colWidth: 15,
		},
		{
			colName: homePage.includes("trash") ? "Status" : "Help Center",
			colWidth: 15,
		},
	];

	const rows: React.ReactElement<CTableRowProps>[] = props.entries.map(
		(entry, index) => {
			const propsEntry: Item =
				props.type === "Collection"
					? {
						type: "Collection",
						entry: entry as Collection,
					}
					: {
						type: "Guide",
						entry: entry as GuidePreview,
					};

			let item: Collection | GuidePreview;
			let name: string = "";
			let description: string | undefined = "";

			let onClick = () => {
				//@ts-ignore
				props.onClick(propsEntry.entry);
			};
			let onDelete = () => {
				//@ts-ignore
				props.onDelete(propsEntry.entry);
			};

			let onReorder = (
				item: CTableRowItem,
				precedingItem?: CTableRowItem,
			) => { };

			let onRestore = () => {
				props.onRestore?.(propsEntry.entry as GuidePreview);
			};

			let openFileContextMenu = (event: React.MouseEvent<HTMLDivElement>) => { };

			if (propsEntry.type == "Collection") {
				item = propsEntry.entry as Collection;
				name = item.name;
				description = item.description;

				openFileContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
					props.showFileContextMenu?.(
						{
							type: "Collection",
							entry: propsEntry.entry as Collection,
						},
						event.clientX,
						event.clientY,
					);
				};

				onReorder = (item: any, precedingItem?: any) => {
					const succeedingCollection = item.entry as Collection;
					const precedingCollection = precedingItem
						? (precedingItem.entry as Collection)
						: undefined;

					if (precedingItem) {
						backendReorderItem({
							type: "Collection",
							succeedingItem: succeedingCollection,
							precedingItem: precedingCollection,
						});
					} else {
						backendReorderItem({
							type: "Collection",
							succeedingItem: succeedingCollection,
						});
					}
				};
			} else {
				item = propsEntry.entry as GuidePreview;
				name = item.header.name;
				description = item.header.description;

				openFileContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
					props.showFileContextMenu?.(
						{
							type: "Guide",
							entry: propsEntry.entry as GuidePreview,
						},
						event.clientX,
						event.clientY,
					);
				};

				onReorder = (item: any, precedingItem?: any) => {
					const succeedingGuide = item.entry as GuidePreview;
					const precedingGuide = precedingItem
						? (precedingItem.entry as GuidePreview)
						: undefined;

					if (precedingItem) {
						backendReorderItem({
							type: "Guide",
							succeedingItem: succeedingGuide,
							precedingItem: precedingGuide,
						});
					} else {
						backendReorderItem({
							type: "Guide",
							succeedingItem: succeedingGuide,
						});
					}
				};
			}

			const onDrop = (droppedItem: any) => {
				const data = droppedItem as Item;
				if (data.entry.id === propsEntry.entry.id) return;

				if (propsEntry.type === "Collection") {
					//We are in a collection
					backendMoveItem({
						item: data,
						newParentID: propsEntry.entry.id,
					});
				}
			};

			const published =
				propsEntry.type === "Guide"
					? (propsEntry.entry as GuidePreview).header.published
						? true
						: false
					: (propsEntry.entry as Collection).private
						? false
						: true;

			const entries = [
				<>
					<div className="gigauser-row-name">
						{propsEntry.type === "Collection" ? (
							<Icon className="gigauser-guidestablerow-folder">
								{gradientFolderIcon()}
							</Icon>
						) : null}

						<div className="gigauser-row-name-text">
							<div className="gigauser-table-name">{name}</div>
							<div className="gigauser-table-description">{description}</div>
							{propsEntry.type === "Guide" && propsEntry.entry.deleteAt && (
								<div className="gigauser-delete-at">
									Will be deleted in {timeRemainingToDate(propsEntry.entry.deleteAt)}
								</div>
							)}
						</div>
					</div>
				</>,
				<>
					{propsEntry.type === "Collection" ? null : propsEntry.entry.author ? (
						<UserPreview
							user={{
								fullName: propsEntry.entry.author.name,
								email: propsEntry.entry.author.email,
								pictureUrl: propsEntry.entry.author.pictureUrl
							}}
						// description={
						// 	propsEntry.entry.createdAt
						// 		? timeAgoFromString(propsEntry.entry.createdAt)
						// 		: ""
						// }
						/>
					) : (
						"-"
					)}
				</>,
				<>
					{propsEntry.type === "Collection"
						? ""
						: propsEntry.entry.lastModified
							? timeAgoFromDate(propsEntry.entry.lastModified)
							: // timeAgoFromDate(propsEntry.entry.lastModified)
							""}
				</>,
				<>
					{propsEntry.type === "Collection"
						? ""
						: propsEntry.entry.createdAt
							? timeAgoFromDate(propsEntry.entry.createdAt)
							: // timeAgoFromDate(propsEntry.entry.lastModified)
							""}
				</>,
				<div
					className={`gigauser-row-status 
                        ${propsEntry.entry.deleted ? "deleted" : published ? "published" : "unpublished"}
                    `}
				>
					<div
						className={`gigauser-status-pill ${published ? "published" : "unpublished"}`}
					>
						<div className={`gigauser-status-circle `}></div>
						{propsEntry.entry.deleted ? (
							<span>Deleted</span>
						) : published ? (
							<span>Published</span>
						) : (
							<span>Unpublished</span>
						)}
					</div>
				</div>,
			];

			const icons: CTableRowIcon[] = [];

			if (props.type === "Guide" && !homePage.includes("trash")) {
				icons.push({
					icon: externalLinkIcon,
					hoverTip: "View-only link",
					onClick: () => {
						window.open(
							`https://${saver.hostname}/${propsEntry.type === "Collection" ? "folder" : "guide"}/${propsEntry.entry.id}`,
							"_blank",
						);
					},
				});
			}

			if (props.type === "Guide" && homePage.includes("trash")) {
				icons.push({
					icon: restoreIcon,
					hoverTip: "Restore",
					onClick: onRestore,
				});
			}

			return (
				<CTableRow
					index={index}
					key={entry.id}
					columns={columns}
					// index={index}
					entries={entries}
					onClick={onClick}
					onDelete={homePage.includes("trash") ? undefined : onDelete}
					onContextMenu={openFileContextMenu}
					onReorder={props.disableDragNDrop ? undefined : onReorder}
					onDrop={props.disableDragNDrop ? undefined : onDrop}
					icons={icons}
					item={propsEntry}
				/>
			);
		},
	);

	return (
		<div className="GuidesTable">
			<CTable columns={columns} size={props.size}>
				{rows}
			</CTable>
		</div>
	);
};
export default GuidesTable;
