import { User } from "@propelauth/javascript";
import React from "react";
import "./UserPreview.css";
import { Size } from "../../../types/sizes";

type UserPreviewProps = {
	size?: Size;
	user: {
		firstName?: string;
		lastName?: string;
		fullName?: string;
		email: string;
		pictureUrl?: string;
	};
	description?: string;
	highlight?: string;
};

export const capitalize = (word?: string) => {
	if (!word) {
		return "";
	}
	return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export function guessFullNameFromEmail(email: string): string {
	// Extract the part of the email before the "@" symbol
	const emailNamePart = email.split("@")[0];
	// Remove any special characters except alphabets and spaces
	const sanitizedEmailPart = emailNamePart.replace(/[^a-zA-Z]/g, " ");

	// Split the name part into words by spaces
	const nameParts = sanitizedEmailPart.split(/\s+/).filter(Boolean);

	// Capitalize the first letter of each part and join them to form a full name
	const fullName = nameParts
		.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
		.join(" ");

	return fullName;
}

export function getOrGuessFullNameFromUser(user: User): string {
	if (user.firstName) {
		var fullName = `${capitalize(user.firstName)} ${capitalize(user.lastName)}`;
		return fullName;
	}

	return guessFullNameFromEmail(user.email);
}

const UserPreview: React.FC<UserPreviewProps> = ({ user, ...props }) => {
	function getName(): string {
		// Capitalize the first name and last name and join them

		var fullName = "";
		if (user.fullName) {
			//we have full name. Capitalize each part of the name
			fullName = user.fullName;
		} else if (user.firstName || user.lastName) {
			//we don't have full name. Capitalize the first name and last name
			fullName = `${capitalize(user.firstName)} ${capitalize(user.lastName)}`;
		} else {
			//we don't have full name, first name or last name. Guess the full name from email
			fullName = guessFullNameFromEmail(user.email);
		}
		fullName = fullName
			.split(" ")
			.map((part) => capitalize(part))
			.join(" ");
		return fullName;
	}

	const name = getName();

	return (
		<div
			className={`UserPreview ${props.highlight ? "highlight" : ""} ${props.size}`}
		>
			<div
				className="gigauser-author-icon"
				style={{
					backgroundImage: user.pictureUrl
						? `url(${user.pictureUrl})`
						: undefined,
				}}
			>
				{user.pictureUrl ? "" : name.charAt(0)}
			</div>
			<div className="gigauser-author-name">
				<div className="name-text">
					{name} {props.highlight ?? ""}
				</div>
				<div className="created-text">{props.description ?? user.email}</div>
			</div>
		</div>
	);
};
export default UserPreview;
