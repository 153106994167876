import { GigaUserApi } from "@giga-user-fern/api";
import magicwandIcon from "@gigauser/common/src/assets/svgs/magicwandIcon";
import { TextEditor } from "@gigauser/common/src/components/formats/RichText/TextEditor";
import { PlainDoc } from "@gigauser/common/src/core/types/guide";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectAiEnhancingVideo,
	selectGuidePreview,
	selectTrimmingVideo,
	setAiEnhancingVideo,
	setTranscriptEdited,
} from "@gigauser/common/src/redux/slices/guideSlice";
import {
	selectVideoTranscript,
	setVideoTranscript,
	selectLoadingGeneratedVideo,
} from "@gigauser/common/src/redux/slices/guideSlice";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import logger from "@gigauser/common/src/utils/logger";
import React, { useContext, useEffect, useRef, useState } from "react";
import transcriptIcon from "@gigauser/common/src/assets/svgs/videoeditor/transcriptIcon";

import "./TranscriptEditor.css";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import VoicePicker from "@gigauser/common/src/overlays/VoicePicker/VoicePicker";
import voiceIcon from "@gigauser/common/src/assets/svgs/voiceIcon";
import useSuperAdmin from "@gigauser/common/src/hooks/useSuperAdmin";
import { selectVoice } from "@gigauser/common/src/redux/slices/guideSlice";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import useSaveGuide from "@gigauser/common/src/redux/hooks/FileHooks/useSaveGuide";
import useFetchGuidePreviewAndCheck from "@gigauser/common/src/redux/hooks/FileHooks/useFetchGuidePreviewAndCheck";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import { useOriginalTimestamp } from "@gigauser/common/src/redux/hooks/FileHooks/useTimestamp";
import useBackendBusy from "@gigauser/common/src/redux/hooks/FileHooks/useBackendBusy";
import { useToast } from "@chakra-ui/react";
import { openModal } from "@gigauser/common/src/layouts/CModal/modalSlice";

type TranscriptEditorProps = {
	onGenerate?: () => void;
	isGenerating?: boolean;
};

const TranscriptEditor: React.FC<TranscriptEditorProps> = (props) => {
	const transcript = useAppSelector(selectVideoTranscript);
	const dispatch = useAppDispatch();
	const saveGuide = useSaveGuide();
	const id = useAppSelector((state) => state.guide.value.guide?.id);
	const generatingVideo = useAppSelector(selectLoadingGeneratedVideo);
	const fetchGuidePreviewAndCheck = useFetchGuidePreviewAndCheck();
	const aiEnhanceVideo = useAppSelector(selectAiEnhancingVideo);
	const [enhancing, setEnhancing] = useState(aiEnhanceVideo);
	const [localGenerating, setLocalGenerating] = useState(generatingVideo);
	const isBackendBusy = useBackendBusy();
	const toast = useToast();

	const editorRef = useRef<any>(null);
	const trimVideo = useAppSelector(selectTrimmingVideo);
	const [trimming, setTrimming] = useState(trimVideo);
	const cp = useContext(CanvasPlayerContext);
	const navigateToTime = useOriginalTimestamp((time) =>
		cp.hardsetCurrentTime(cp.getAdjustedTime(time), true, {
			waitTillSeek: true,
		}),
	);
	if (!transcript) throw new Error("Couldn't load transcript from redux!");

	const saver = useAppSelector(selectSaver);

	const customizerPage = useAppSelector(
		(state) => state.platformUi.value.customizerPage,
	);

	const doc = useRef<any>(transcript.data);

	const setDoc = (newJSON: any) => {
		doc.current = newJSON;
		dispatch(setVideoTranscript({ version: "2023-03-12", data: newJSON }));
		dispatch(setTranscriptEdited(true));
	};

	const scrollContainerRef = useRef<HTMLDivElement | null>(null);

	const onEnhance = async () => {
		if (isBackendBusy) {
			toast({
				title: `${isBackendBusy} is in progress!`,
				description: "Please wait for it to finish and then try again.",
				status: "warning",
				duration: 4000,
				isClosable: true,
				position: "top",
			});
			return false;
		}

		setEnhancing(true);
		const resp = await saveGuide(true);
		if (resp && id) {
			dispatch(setAiEnhancingVideo(true));
			saver.guides.enhance.videoTranscript({ guideId: id }).then((resp) => {
				if (!resp.ok) {
					if (resp.error.error === "UsageLimitExceededError") {
						dispatch(setAiEnhancingVideo(false));
					}
				}
			});
			setTimeout(() => fetchGuidePreviewAndCheck(), 3000);
		} else {
			setEnhancing(false);
		}
	};

	useEffect(() => {
		if (trimVideo && !trimming) setTrimming(true);
		if (!localGenerating && generatingVideo) setLocalGenerating(true);
		if (enhancing && !aiEnhanceVideo) {
			setEnhancing(false);
			if (editorRef.current) {
				editorRef.current.setContent(transcript.data);
			}
		}
		if ((localGenerating && !generatingVideo) || (trimming && !trimVideo)) {
			setLocalGenerating(false);
			setTrimming(false);
			if (editorRef.current) {
				// Rewrite history to prevent undo
				editorRef.current.manager.view.updateState(
					editorRef.current.manager.createState({ content: transcript.data }),
				);
				// editorRef.current.setContent(transcript.data)
			}
		}
	}, [aiEnhanceVideo, trimVideo, generatingVideo]);

	const onClickVoice = () => {
		dispatch(
			openModal({
				heading: {
					icon: voiceIcon("white"),
					title: `Choose a Voice`,
				},
				component: <VoicePicker />,
			}),
		);
	};

	const voice = useAppSelector(selectVoice);
	const betaFeaturesAccessible = useSuperAdmin();
	const guidePreview = useAppSelector(selectGuidePreview);

	return (
		<div className="TranscriptEditor" ref={scrollContainerRef}>
			<div
				className={`VideoTranscript-container gigauser-dark 
                ${customizerPage && false ? "mini-transcript" : "fullwidth-transcript"}
                `}
			>
				<TextEditor
					ref={editorRef}
					type="videoTranscript"
					initialContent={doc.current as any}
					stringHandler="html"
					save={setDoc}
					editable={!generatingVideo && !enhancing && !guidePreview?.deleted}
					onEnhance={onEnhance}
					onGenerate={props.onGenerate}
					scrollContainerRef={scrollContainerRef}
					voice={voice.voice}
					onClickVoice={onClickVoice}
					fixToolbar
					appTheme="gigauser-dark"
					enhanceLoading={enhancing}
					generateLoading={props.isGenerating}
					navigateToTime={
						navigateToTime
							? (metadata) => navigateToTime(metadata?.timestamp || 0)
							: undefined
					}
				></TextEditor>
			</div>
		</div>
	);
};

export default TranscriptEditor;
