import SimpleTabs from "@gigauser/common/src/ui/tabs/SimpleTabs/SimpleTabs";
import React, { useEffect, useRef, useState } from "react";
import OverlaySectionHeader from "@gigauser/common/src/ui/text/OverlaySectionHeader/OverlaySectionHeader";
import "./ExportVideo.css";
import scissorsIcon from "@gigauser/common/src/assets/svgs/scissorsIcon";
import videoIcon from "@gigauser/common/src/assets/svgs/videoeditor/videoIcon";
import backgroundIcon from "@gigauser/common/src/assets/svgs/videoeditor/backgroundIcon";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectExportingVideo,
	selectGuide,
	selectGuidePreview,
	selectUnsavedChanges,
	selectVideo,
	selectVideoEdits,
	setExportingVideo,
} from "@gigauser/common/src/redux/slices/guideSlice";
import {
	Box,
	ButtonGroup,
	Center,
	Flex,
	RangeSlider,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	RangeSliderTrack,
	Slider,
	SliderTrack,
	useToast,
} from "@chakra-ui/react";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import downloadIcon from "@gigauser/common/src/assets/svgs/downloadIcon";
import CInput from "@gigauser/common/src/ui/video/components/CInput/CInput";
import { calculateMediaDuration } from "@gigauser/common/src/core/canvas/canvasUtils/videoUtils";
import CToggle from "@gigauser/common/src/ui/video/components/CToggle/CToggle";
import CHexColor from "@gigauser/common/src/ui/video/components/CHexColor/CHexColor";
import IBox from "@gigauser/common/src/ui/messages/iBox/iBox";
import { closeOverlay } from "@gigauser/common/src/layouts/Overlay";
import {
	setExportingGif,
	setGifDownloadLink,
	setShowGifExportPopup,
	setShowVideoExportPopup,
} from "@gigauser/common/src/redux/slices/processingSlice";
import {
	downloadFromCloudFront,
	formatDateTime,
} from "@gigauser/common/src/utils/os";
import useFetchGuidePreviewAndCheck from "@gigauser/common/src/redux/hooks/FileHooks/useFetchGuidePreviewAndCheck";
import { Guide, GuidePreview } from "@gigauser/common/src/core/types/guide";
import { getBrandingColor } from "@gigauser/common/src/core/utils/styleUtils";
import { saver } from "@gigauser/common/src/network/saver";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { downloadStringAsFile } from "@gigauser/common/src/components/formats/RichText/utils/exportProcessing";
import {
	openOverlay,
	setDisableClickoutOverlay,
	setDisableCloseOverlay,
} from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import { setExportSrtFile } from "@gigauser/common/src/redux/slices/platformUiSlice";
import copyIcon from "@gigauser/common/src/assets/svgs/copyIcon";
import CanvasProvider from "@gigauser/common/src/core/canvas/CanvasProvider";
import ScreenshotEditor from "@gigauser/common/src/components/formats/RichText/components/EditScreenshot/ScreenshotEditor";
import GifMakerTimeline from "../../../pages/editor/text/GifMakerTimeline/GifMakerTimeline";
import { GigaUserApi } from "@giga-user-fern/api";
import { stripIntroAndOutro } from "@gigauser/common/src/core/canvas/canvasUtils/videoUtils";
import rocketIcon from "@gigauser/common/src/assets/svgs/rocketIcon";
import ConfirmPublishPopup from "../ConfirmPublishPopup/ConfirmPublishPopup";
import useBackendBusy from "@gigauser/common/src/redux/hooks/FileHooks/useBackendBusy";
import { error } from "console";
const Id = GigaUserApi.Id;

type ExportVideoProps = {
	format: "GIF" | "MP4" | "Embed";
};

export const round10 = (num: number) => {
	return Math.round(num * 10) / 10;
};

const ExportVideo: React.FC<ExportVideoProps> = (props) => {
	const toast = useToast();

	const [format, setFormat] = useState<string>(props.format || "MP4");
	const saver = useAppSelector(selectSaver);

	const [withCaptions, setWithCaptions] = useState(false);

	const exportSrtFile = useAppSelector(
		(state) => state.platformUi.value.exportSrtFile,
	);

	const [hideBackground, setHideBackground] = useState(false);
	const [color, setColor] = useState(
		saver.organization?.brandColor || "#4f46e5",
	);
	const [hasPrivateParent, setHasPrivateParent] = useState(false);

	const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

	const [startTime, setStartTime] = useState<number>(0);
	const [endTime, setEndTime] = useState<number>(0);
	const [playbackSpeed, setPlaybackSpeed] = useState<number>(1.0);

	const dispatch = useAppDispatch();

	const guide = useAppSelector(selectGuide) as Guide;

	const fetchGuidePreviewAndCheck = useFetchGuidePreviewAndCheck();

	const exportVideoInProgress = useAppSelector(selectExportingVideo);
	const [exportLoading, setExportLoading] = useState(false);
	const exportGifInProgress = useAppSelector(
		(state) => state.processing.value.exportingGif,
	);

	const ogVideoUrl = guide.guideData.video.originalSrc || "";

	const [videoDuration, setVideoDuration] = useState<number>(0);

	const onChangeColor = (color: string) => {
		setColor(color);
	};

	const setSrtFile = (value: boolean) => {
		dispatch(setExportSrtFile(value));
	};

	const initPrivateParent = async () => {
		const res = await saver.guides.shareableLink.get(guidePreview);
		setHasPrivateParent(res.hasPrivateParent);
	};

	useEffect(() => {
		initPrivateParent();
	}, []);

	const isPrivate = () => {
		return hasPrivateParent || !guidePreview.header.published;
	};

	const videoRef = useRef<HTMLVideoElement | null>(null);
	const videoEdits = useAppSelector(selectVideoEdits);
	const unsavedChanges = useAppSelector(selectUnsavedChanges);
	const guidePreview = useAppSelector(selectGuidePreview) as GuidePreview;

	const [downloading, setDownloading] = useState(false);

	const currentVersion = unsavedChanges
		? guidePreview.versionSaved + 1
		: guidePreview.versionSaved;

	const [range, setRange] = useState<[number, number]>([0, 0]);

	const video = useAppSelector(selectVideo);

	const videoSrc = video?.generatedSrc ?? video?.originalSrc;
	const isBackendBusy = useBackendBusy();

	const onChangeSlider = (values: number[]) => {
		const [start, end] = values;

		setStartTime(start);
		setEndTime(end);
	};

	const onExportVideo = async () => {
		if (guide) {
			dispatch(setDisableCloseOverlay(true));
			setExportLoading(true);
			await saver.guides.update.data(guide);
			saver.guides.export.video(guide, withCaptions).then((resp) => {
				if (!resp.ok) {
					if (resp.error.error === "UsageLimitExceededError") {
						dispatch(setShowVideoExportPopup({ showPopup: false }));
						setExportLoading(false);
						dispatch(setExportingVideo(false));
					}
				}
			});
			dispatch(setExportingVideo(true));
			dispatch(setShowVideoExportPopup({ showPopup: true }));
			setExportLoading(false);
			// This is because the actual export needs to go through and begin
			// exporting before we can start to check if it's done.
			setTimeout(() => fetchGuidePreviewAndCheck(), 3000);
			dispatch(setDisableCloseOverlay(false));
			dispatch(closeOverlay());
		}
	};

	const onCopyEmbedCode = async () => {
		setExportLoading(true);

		const embedLink = `https://${saver.hostname}/embed/${guidePreview.id}`;

		const embedCode = `
        
        <div style="position: relative; padding-bottom: 57%; height: 0;">
            <iframe src="${embedLink}" frameborder="0" 
            webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe></div>
        `;

		navigator.clipboard
			.writeText(embedCode)
			.then(() => {
				toast({
					title: `Copied embed code!`,
					description: "Now go paste it in any HTML editor.",
					status: "success",
					duration: 6000,
					isClosable: true,
					position: "top",
				});
			})
			.catch((error) => {
				toast({
					title: `Error getting embed code!`,
					description: "Please try again later.",
					status: "error",
					duration: 6000,
					isClosable: true,
					position: "top",
				});
			})
			.finally(() => {
				setExportLoading(false);
				dispatch(closeOverlay());
			});
	};

	const onExportGif = async () => {
		if (guide) {
			dispatch(setDisableCloseOverlay(true));
			setExportLoading(true);
			await saver.guides.update.data(guide);
			saver.guides.export.gif(guide, {
				start: startTime,
				end: endTime,
			}).then((resp) => {
				if (!resp.ok) {
					if (resp.error.error === "UsageLimitExceededError") {
						dispatch(setShowVideoExportPopup({ showPopup: false }));
						setExportLoading(false);
						dispatch(setExportingVideo(false));
					}
				}
			});
			dispatch(setExportingVideo(true));
			dispatch(setShowVideoExportPopup({ showPopup: true }));
			setExportLoading(false);
			// This is because the actual export needs to go through and begin
			// exporting before we can start to check if it's done.
			setTimeout(() => fetchGuidePreviewAndCheck(), 3000);
			dispatch(setDisableCloseOverlay(false));
			dispatch(closeOverlay());
		}
	};

	useEffect(() => {
		if (videoSrc) {
			const videoElement = document.createElement("video");
			videoElement.src = videoSrc;

			videoElement.addEventListener("loadedmetadata", function () {
				// setDuration(videoElement.duration);
				// setEndMinutes(Math.floor((videoElement.duration % 3600) / 60));
				setEndTime(videoElement.duration);
			});

			// Load the video's metadata
			videoElement.load();
		}
	}, [videoSrc]);

	const onPublish = () => {
		if (isBackendBusy) {
			toast({
				title: `${isBackendBusy} is in progress!`,
				description: "Please wait for it to finish and then try again.",
				status: "warning",
				duration: 4000,
				isClosable: true,
				position: "top",
			});
			return false;
		}

		dispatch(
			openOverlay({
				heading: {
					icon: rocketIcon("white"),
					title: "Confirm Publish",
				},
				component: (
					<ConfirmPublishPopup source="embed" isPrivate={isPrivate()} />
				),
			}),
		);
	};

	useEffect(() => {
		if (videoRef.current) {
			calculateMediaDuration(videoRef.current).then((duration) => {
				if (typeof duration == "number") {
					setEndTime(round10(duration));
					setVideoDuration(duration);

					if (duration > 15) {
						setPlaybackSpeed(round10(duration / 15));
					}
				}
			});
		}
	}, [videoRef.current]);

	const transcriptEdited = useAppSelector(
		(state) => state.guide.value.edits.transcriptEdited,
	);

	const onClickExport = async () => {
		if (isBackendBusy) {
			toast({
				title: `${isBackendBusy} is in progress!`,
				description: "Please wait for it to finish and then try again.",
				status: "warning",
				duration: 4000,
				isClosable: true,
				position: "top",
			});
			return false;
		}

		if (format == "MP4") {
			onExportVideo();
		} else if (format == "GIF") {
			await onExportGif();
		} else if (format == "Embed") {
			onCopyEmbedCode();
		}
	};

	const onClickDownload = async () => {
		dispatch(setShowVideoExportPopup({ showPopup: true, startExport: true }));
		dispatch(closeOverlay());
	};
	if (!videoSrc) {
		return null;
	}

	return (
		<>
			<div className={`ExportVideo ${format}`}>
				{props.format == "MP4" &&
					guide?.guideData.video.export?.exportedVideoSrc ? (
					<div className="Download-box">
						<IBox type="success">
							<>
								Your last export from&nbsp;
								<i className="date-download">
									{formatDateTime(guide?.guideData.video.export.createdAt)}
								</i>
								&nbsp;is ready!&nbsp;
								<>
									<span className="download-link" onClick={onClickDownload}>
										Click here
									</span>
									<span>&nbsp; to download.</span>
								</>
							</>
						</IBox>
					</div>
				) : null}
				{/* <OverlaySectionHeader icon={videoIcon("white")}
            >Format</OverlaySectionHeader>

            <SimpleTabs
                size='s'
                theme='dark'
                tabs = {[
                    {text: "MP4" }, 
                    {text: "GIF" },
                    {text: "Embed"}
                ]}
                activeTab={format}
                setActiveTab={setFormat}
            ></SimpleTabs> */}

				{format === "MP4" ? (
					<>
						<div className="bg-settings">
							<OverlaySectionHeader>Subtitles</OverlaySectionHeader>

							<CToggle
								label="Closed captions"
								subtitle="Add subtitles on your video"
								setterFunction={setWithCaptions}
								value={withCaptions}
							></CToggle>
							<CToggle
								label="Captions file "
								subtitle="Get subtitles as a separate SRT file"
								setterFunction={setSrtFile}
								value={exportSrtFile}
							></CToggle>
						</div>

						<div className="IBox-edits">
							{exportVideoInProgress ? (
								<IBox>
									An MP4 export is currently in progress. Please wait for it to
									complete before exporting again.
								</IBox>
							) : transcriptEdited ? (
								<IBox>
									You have made transcript edits which won't be in the exported
									video. Use 'Generate speech' to apply new changes before
									exporting.
								</IBox>
							) : null}
						</div>
					</>
				) : null}

				{format == "GIF" ? (
					<>
						{/* <Flex marginTop={"1rem"}>
                    <IBox>Max GIF length is 30s. If your video is longer than 30s, it will be sped up to create your GIF.  </IBox>
                </Flex> */}
						<div className="export-gif-container">
							<CanvasProvider
								videoSrc={videoSrc}
								videoEdits={
									stripIntroAndOutro(videoEdits) ?? { version: "oct162023" }
								}
								organization={saver.organization}
							// onError={refreshVideo}
							>
								<ScreenshotEditor
									rootSrc={videoSrc}
									srcType="video"
									metadata={{}}
									onMakeGif={onClickExport}
									saveLabel="Export GIF"
									size="s"
									removeControls={true}
									clip={{
										startTime: startTime,
										endTime: endTime,
										srcId: Id(""),
									}}
								/>

								<GifMakerTimeline
									clip={{
										startTime: startTime,
										endTime: endTime,
										srcId: Id(""),
									}}
									setClip={(clip) => {
										setStartTime(clip.startTime);
										setEndTime(clip.endTime);
									}}
								/>
							</CanvasProvider>
						</div>
					</>
				) : null}

				{format == "Embed" ? (
					<Flex marginTop={"1rem"} flexFlow={"column"}>
						<div className="embed-info">
							Get an iFrame embed code to embed your video in your webpage,
							product, or any other HTML editor.{" "}
						</div>
						<IBox>
							{`This code gives the last published version (v${guidePreview.versionPublished}). 
                The current version is v${currentVersion}. Click publish to get the latest embed code.`}
						</IBox>
					</Flex>
				) : null}

				{format !== "GIF" ? (
					<ButtonGroup marginTop={"2em"}>
						<Cutton
							onClick={onClickExport}
							leftIcon={
								format !== "Embed" ? downloadIcon("white") : copyIcon("white")
							}
							isLoading={exportLoading}
							loadingText="Exporting ..."
						>
							{format !== "Embed" ? "Export" : "Copy Code"}
						</Cutton>

						{format == "Embed" && (
							<Cutton
								onClick={onPublish}
								rank="secondary"
								leftIcon={rocketIcon("white")}
							>
								Publish changes
							</Cutton>
						)}

						{/* <Cutton rank='secondary' disabled={exportLoading} onClick={() => {dispatch(closeOverlay())}}>Cancel</Cutton> */}
					</ButtonGroup>
				) : null}
			</div>
		</>
	);
};

export default ExportVideo;
