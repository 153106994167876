const crownIcon = (color: string = "currentColor") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<g clip-path="url(#clip0_817_9511)">
				<path
					d="M1.96235 8.86116C1.96235 8.6674 1.90498 8.47798 1.79747 8.31678C1.68997 8.15559 1.53714 8.02983 1.35827 7.95536C1.17939 7.8809 0.982475 7.86106 0.79234 7.89835C0.602206 7.93564 0.427368 8.02839 0.289872 8.1649C0.152375 8.30142 0.0583743 8.47559 0.019722 8.66545C-0.0189303 8.85531 -0.00050405 9.05237 0.0726776 9.23177C0.145859 9.41118 0.27052 9.5649 0.430942 9.67355C0.591365 9.78221 0.780366 9.84094 0.974118 9.84233C1.10356 9.84326 1.23191 9.81857 1.35177 9.76968C1.47162 9.72079 1.58062 9.64866 1.67248 9.55746C1.76434 9.46626 1.83725 9.35778 1.887 9.23827C1.93675 9.11877 1.96236 8.9906 1.96235 8.86116Z"
					fill={color}
				/>
				<path
					d="M21.5929 10.6329C20.4793 10.8407 19.3941 11.1795 18.36 11.6423C17.4985 12.062 16.6764 12.5581 15.9035 13.1247C15.431 12.5058 15.0175 11.8441 14.6682 11.1482C13.855 9.39882 13.1937 7.58272 12.6918 5.71997C12.6504 5.56597 12.5581 5.43052 12.4299 5.33567C12.3018 5.24082 12.1452 5.19216 11.9859 5.19762C11.8265 5.19216 11.67 5.24082 11.5418 5.33567C11.4137 5.43052 11.3214 5.56597 11.28 5.71997C10.7813 7.58372 10.1199 9.40011 9.30353 11.1482C8.95464 11.8458 8.53855 12.5078 8.06118 13.1247C7.28833 12.5581 6.46621 12.062 5.60471 11.6423C4.56992 11.1814 3.48496 10.8427 2.37177 10.6329C2.24011 10.607 2.10378 10.6191 1.97876 10.6678C1.85374 10.7166 1.74521 10.8 1.66589 10.9082C1.58735 11.0136 1.53979 11.1388 1.52856 11.2697C1.51734 11.4007 1.5429 11.5322 1.60236 11.6494C3.09255 14.7516 4.12222 18.0546 4.65883 21.4541C4.6874 21.6198 4.77421 21.7699 4.90362 21.8773C5.03304 21.9847 5.19655 22.0424 5.36471 22.04H18.6353C18.8035 22.0424 18.967 21.9847 19.0964 21.8773C19.2258 21.7699 19.3126 21.6198 19.3412 21.4541C19.8623 18.0572 20.8753 14.7543 22.3482 11.6494C22.4077 11.5322 22.4333 11.4007 22.422 11.2697C22.4108 11.1388 22.3632 11.0136 22.2847 10.9082C22.2065 10.8024 22.1003 10.7206 21.9781 10.6719C21.8558 10.6233 21.7224 10.6098 21.5929 10.6329ZM18.0494 20.6282H5.94353C5.44866 17.7933 4.64384 15.0214 3.54353 12.3623C4.04769 12.5108 4.5407 12.6947 5.01883 12.9129C5.98773 13.3838 6.89954 13.9641 7.73648 14.6423C7.87618 14.7614 8.05677 14.8213 8.23994 14.8095C8.42312 14.7976 8.59447 14.7149 8.71765 14.5788C9.46127 13.72 10.0944 12.7714 10.6024 11.7553C11.1425 10.6344 11.614 9.48165 12.0141 8.3035C12.4113 9.48275 12.8829 10.6356 13.4259 11.7553C13.9356 12.7687 14.566 13.7168 15.3035 14.5788C15.427 14.7169 15.5998 14.801 15.7846 14.8129C15.9695 14.8248 16.1517 14.7635 16.2918 14.6423C17.1257 13.9599 18.038 13.3794 19.0094 12.9129C19.4866 12.6925 19.9798 12.5084 20.4847 12.3623C19.3691 15.0189 18.5523 17.7912 18.0494 20.6282Z"
					fill={color}
				/>
				<path
					d="M23.0188 9.8423C23.5607 9.8423 24 9.40301 24 8.86112C24 8.31923 23.5607 7.87994 23.0188 7.87994C22.4769 7.87994 22.0376 8.31923 22.0376 8.86112C22.0376 9.40301 22.4769 9.8423 23.0188 9.8423Z"
					fill={color}
				/>
				<path
					d="M12 3.96233C12.1943 3.96373 12.3847 3.90737 12.547 3.80041C12.7093 3.69345 12.8362 3.54071 12.9115 3.36155C12.9868 3.18239 13.0073 2.98489 12.9702 2.7941C12.9332 2.6033 12.8403 2.42782 12.7034 2.28989C12.5664 2.15196 12.3916 2.05781 12.2011 2.01938C12.0106 1.98094 11.8129 1.99996 11.6332 2.07401C11.4535 2.14806 11.2999 2.27381 11.1917 2.43531C11.0836 2.59682 11.0259 2.78679 11.0259 2.98115C11.0259 3.24016 11.1283 3.48866 11.3108 3.67246C11.4932 3.85627 11.741 3.96046 12 3.96233Z"
					fill={color}
				/>
				<path
					d="M12.0071 18.0447C11.8515 18.0433 11.699 18.0883 11.5691 18.1738C11.4392 18.2594 11.3377 18.3817 11.2775 18.5251C11.2173 18.6686 11.2012 18.8267 11.2312 18.9793C11.2612 19.132 11.336 19.2722 11.446 19.3822C11.556 19.4922 11.6962 19.567 11.8489 19.597C12.0015 19.627 12.1597 19.6109 12.3031 19.5507C12.4466 19.4906 12.5689 19.389 12.6544 19.2591C12.74 19.1292 12.7849 18.9767 12.7835 18.8212C12.7835 18.6152 12.7017 18.4177 12.5561 18.2721C12.4105 18.1265 12.213 18.0447 12.0071 18.0447Z"
					fill={color}
				/>
				<path
					d="M8.01882 18.0447C7.86326 18.0433 7.71081 18.0883 7.58088 18.1738C7.45095 18.2594 7.34944 18.3817 7.28926 18.5251C7.22908 18.6686 7.21297 18.8267 7.24298 18.9793C7.27299 19.132 7.34775 19.2722 7.45775 19.3822C7.56776 19.4922 7.70801 19.567 7.86066 19.597C8.0133 19.627 8.17142 19.6109 8.31488 19.5507C8.45834 19.4906 8.58063 19.389 8.66619 19.2591C8.75174 19.1292 8.79669 18.9767 8.79529 18.8212C8.79529 18.6152 8.71349 18.4177 8.56787 18.2721C8.42225 18.1265 8.22475 18.0447 8.01882 18.0447Z"
					fill={color}
				/>
				<path
					d="M16.0024 18.0447C15.8468 18.0433 15.6943 18.0883 15.5644 18.1738C15.4345 18.2594 15.333 18.3817 15.2728 18.5251C15.2126 18.6686 15.1965 18.8267 15.2265 18.9793C15.2565 19.132 15.3313 19.2722 15.4413 19.3822C15.5513 19.4922 15.6915 19.567 15.8442 19.597C15.9968 19.627 16.155 19.6109 16.2984 19.5507C16.4419 19.4906 16.5642 19.389 16.6497 19.2591C16.7353 19.1292 16.7802 18.9767 16.7788 18.8212C16.7788 18.6152 16.697 18.4177 16.5514 18.2721C16.4058 18.1265 16.2083 18.0447 16.0024 18.0447Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_817_9511">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default crownIcon;
