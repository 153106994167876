import { Flex, useToast } from "@chakra-ui/react";
import { GigaUserApi } from "@giga-user-fern/api";
import helpCircleIcon from "@gigauser/common/src/assets/svgs/helpCircleIcon";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import { closeFullScreenOverlay } from "@gigauser/common/src/layouts/FullScreenOverlay";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import useBackendBusy from "@gigauser/common/src/redux/hooks/FileHooks/useBackendBusy";
import useFetchGuidePreviewAndCheck from "@gigauser/common/src/redux/hooks/FileHooks/useFetchGuidePreviewAndCheck";
import useSaveGuide from "@gigauser/common/src/redux/hooks/FileHooks/useSaveGuide";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { selectGuide } from "@gigauser/common/src/redux/slices/guideSlice";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import CloseButton from "@gigauser/common/src/ui/buttons/CloseButton/CloseButton";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import React, { useContext, useEffect, useState } from "react";
import {
	usePlayPauseListener,
	useSeekVideoListener,
} from "../../../../utils/listenerUtils";
import HoverTip from "@gigauser/common/src/ui/HoverTip/HoverTip";

type TrimStitchTopbarProps = {};

const TrimStitchTopbar: React.FC<TrimStitchTopbarProps> = () => {
	const cp = useContext(CanvasPlayerContext);
	const [saving, setSaving] = useState(false);

	const dispatch = useAppDispatch();

	const isBackendBusy = useBackendBusy();
	const toast = useToast();
	const saveGuide = useSaveGuide();
	const guide = useAppSelector(selectGuide);
	const saver = useAppSelector(selectSaver);
	const id = useAppSelector(
		(state) => state.guide.value.guide?.id,
	) as GigaUserApi.Id;
	const fetchGuidePreviewAndCheck = useFetchGuidePreviewAndCheck();

	const revertToOriginal = () => {
		if (!cp.clips) {
			return;
		}
		cp.setClips([
			{
				srcId: GigaUserApi.Id("dummy"),
				startTime: 0,
				endTime: cp.videoDuration,
			},
		]);
	};

	const onClose = () => {
		dispatch(closeFullScreenOverlay());
	};

	const handleSpacePress = usePlayPauseListener();
	const handleArrowKeys = useSeekVideoListener();
	useEffect(() => {
		document.addEventListener("keydown", handleArrowKeys);
		document.addEventListener("keydown", handleSpacePress);
		return () => {
			document.removeEventListener("keydown", handleArrowKeys);
			document.removeEventListener("keydown", handleSpacePress);
		};
	}, [handleArrowKeys, handleSpacePress]);

	const submit = async () => {
		if (isBackendBusy) {
			toast({
				title: `${isBackendBusy} is in progress!`,
				description: "Please wait for it to finish and then try again.",
				status: "warning",
				duration: 4000,
				isClosable: true,
				position: "top",
			});
			return false;
		}

		setSaving(true);

		const resp = await saveGuide(true);

		if (!resp || !guide) {
			// dispatch(setDisableCloseOverlay(false))
			dispatch(closeFullScreenOverlay());
			return;
		}

		saver.guides.update.originalEdits(id, {
			sources: [{ id: GigaUserApi.Id("ignore"), src: "ignore" }],
			clips: cp.clips,
		});

		setTimeout(() => {
			fetchGuidePreviewAndCheck();
			// dispatch(setDisableCloseOverlay(false))
			dispatch(closeFullScreenOverlay());
		}, 3000);

		// if(trim_res){
		//     //Update the guide in frontend with the new edits.
		//     const fetch_res = await initGuideEditor(true)

		//     toast({
		//         title: 'Trim successful!',
		//         description: "Now click Generate Speech to make your video.",
		//         status: 'success',
		//         duration: 7000,
		//         isClosable: true,
		//         position: "top"
		//     })

		// }
	};

	return (
		<div className="TrimStitch-topbar">
			<Flex alignItems={"center"} gap="1rem">
				<CloseButton onClose={onClose} />
				Trim and stitch original recording
			</Flex>

			<HoverTip
				hoverTipTitle="Trim and Stitch"
				hoverTipPara={[
					"Here, you will reconstruct your original screen recording.",
					"You can trim out clips, or add clips from other videos.",
					"After clicking save and apply, you will have to regenerate the AI voiceover for your new video.",
				]}
				size="l"
			>
				<Flex className="helptiphover" gap={"0.6rem"}>
					<Icon className="Trim-helpCircleIcon">
						{helpCircleIcon("hsl(0, 0%, 75%)")}
					</Icon>
					<div className="helptip">What do I do here?</div>
				</Flex>
			</HoverTip>

			<Flex gap={"1rem"} alignItems={"center"}>
				<HoverTip hoverTipTitle="This will restore the timeline back to the original recorded video clip">
					<Cutton rank="quaternary" onClick={revertToOriginal}>
						Revert to original
					</Cutton>
				</HoverTip>

				<Cutton rank="tertiary" onClick={onClose}>
					Cancel
				</Cutton>

				<Cutton isLoading={saving} loadingText="Saving ..." onClick={submit}>
					Save and apply
				</Cutton>
			</Flex>
		</div>
	);
};
export default TrimStitchTopbar;
