import React, { useContext } from "react";

import "./NavbarMenu.css";
import useCollectionNavigate from "@gigauser/common/src/redux/hooks/FileHooks/useCollectionNavigate";
import { EnvironmentContext } from "@gigauser/common/src/contexts";

import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import home from "@gigauser/common/src/assets/icons/home.svg";
import trash from "@gigauser/common/src/assets/icons/trash.svg";
import chart from "@gigauser/common/src/assets/icons/chart.png";
import users from "@gigauser/common/src/assets/icons/users.svg";
import eye from "@gigauser/common/src/assets/icons/eye.svg";
import settings from "@gigauser/common/src/assets/icons/settings-cog.svg";
import usage_meter from "@gigauser/common/src/assets/icons/usage-meter.svg";
import useSuperAdmin from "@gigauser/common/src/hooks/useSuperAdmin";
import { saver } from "@gigauser/common/src/network/saver";
import { rootCollection } from "@gigauser/common/src/types/files";
import homeIcon from "@gigauser/common/src/assets/svgs/home/homeIcon";
import folderIcon from "@gigauser/common/src/assets/svgs/home/folderIcon";
import creatorsIcon from "@gigauser/common/src/assets/svgs/home/creatorsIcon";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import settingsIcon from "@gigauser/common/src/assets/svgs/settingsIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import { AppInitializerPage } from "../../../../../AppInitializer";
import arrowIcon from "@gigauser/common/src/assets/svgs/screenshot/arrowIcon";
import downArrowheadIcon from "@gigauser/common/src/assets/svgs/downArrowheadIcon";
import { Flex } from "@chakra-ui/react";

type NavbarMenuProps = {
	menu: NavbarMenuItem[];
};

export type NavbarMenuItem = {
	label: string;
	icon: (color: string) => React.ReactElement;
	id: AppInitializerPage;
	handler?: () => void;
	arrow?: boolean;
};

const NavbarMenu: React.FC<NavbarMenuProps> = (props) => {
	const location = useLocation();
	const navigateRouter = useNavigate();
	const homePage = location.pathname;

	const env = useContext(EnvironmentContext);
	const navigate = useCollectionNavigate(env);

	const betaFeaturesAccessible = useSuperAdmin();

	// if (!saver.organization?.trial) {
	// 	navbarMenuArray.push({
	// 		label: "Analytics",
	// 		icon: chart,
	// 		id: "anal",
	// 	});
	// }
	// if (saver.user?.role == "admin") {
	// }
	// if (saver.user?.role == "admin") {
	// 	navbarMenuArray.push({
	// 		label: "Usage",
	// 		icon: usage_meter,
	// 		id: "usage",
	// 	});
	// 	if (betaFeaturesAccessible("helpcenterappearance")) {
	// 		navbarMenuArray.push({
	// 			label: "Help Center Settings",
	// 			icon: eye,
	// 			id: "help-center-config",
	// 		});
	// 	}
	// }

	const dispatch = useAppDispatch();

	return (
		<div className="NavbarMenu laptop">
			{props.menu.map((menuItem) => {
				var isActive = homePage === "/" + menuItem.id;

				if (homePage.includes("folders") && menuItem.id === "home/projects") {
					isActive = true;
				}

				const getItem = () => (
					<>
						<Icon className="NavbarMenuItem-icon">
							{" "}
							{menuItem.icon(isActive ? "white" : "#888b9c")}{" "}
						</Icon>
						<span className="NavbarMenuItem-icon-label">{menuItem.label}</span>
					</>
				);

				return (
					<Flex
						className={`NavbarMenuItem laptop ${isActive ? "active" : ""}`}
						key={menuItem.label}
						onClick={() => {
							navigateRouter(`/${menuItem.id}`);
							if (menuItem.handler) {
								menuItem.handler();
							}
							//TODO: Put this line back
							// navigate(menuItem.label==="My Library" ? myLibrary: sharedFiles)
						}}
					>
						{!menuItem.arrow ? (
							getItem()
						) : (
							<Flex
								width={"100%"}
								justifyContent={"space-between"}
								className="NavbarMenuItem-arrow-container"
							>
								{getItem()}
								<Icon className="NavbarMenuItem-arrow">
									{downArrowheadIcon()}
								</Icon>
							</Flex>
						)}
					</Flex>
				);
			})}
		</div>
	);
};
export default NavbarMenu;
