import { Language, PronDictEntry } from "@giga-user-fern/api/types/api";
import LanguagePicker from "@gigauser/common/src/overlays/LanguagePicker/LanguagePicker";
import React, { useEffect } from "react";
import "./NewPronCard.css";
import { Flex, propNames, useToast } from "@chakra-ui/react";
import speakerIcon from "@gigauser/common/src/assets/svgs/speakerIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import tickIcon from "@gigauser/common/src/assets/svgs/tickIcon";
import { set } from "@giga-user-fern/api/types/core/schemas";
import { saver } from "@gigauser/common/src/network/saver";
import { closeModal } from "@gigauser/common/src/layouts/CModal/modalSlice";
import { useAppDispatch } from "@gigauser/common/src/redux";

type NewPronCardProps = {
	onAddWord: (newEntries: PronDictEntry[]) => void;
	language?: Language;
	playWord: (word: string, languageName: string) => Promise<void>;
};

const NewPronCard: React.FC<NewPronCardProps> = (props) => {
	const dispatch = useAppDispatch();
	const toast = useToast();
	const [adding, setAdding] = React.useState<boolean>(false);

	const [applyToAllLanguages, setApplyToAllLanguages] =
		React.useState<boolean>(true);

	const [loadingBytesOriginal, setLoadingBytesOriginal] =
		React.useState<boolean>(false);
	const [loadingBytesPhonetic, setLoadingBytesPhonetic] =
		React.useState<boolean>(false);

	const [originalWord, setOriginalWord] = React.useState<string>("");
	const [phoneticSpelling, setPhoneticSpelling] = React.useState<string>("");

	const [language, setLanguage] = React.useState<Language>(
		props.language ?? {
			languageId: "en",
			name: "English",
		},
	);

	const addEntry = async () => {
		if (originalWord === "" || phoneticSpelling === "") {
			console.error("Trying to add empty entry!");
		}

		setAdding(true);
		const res =
			await saver.settings.pronunciationDictionary.addWordToPronunciationDictionary(
				{
					actualWord: originalWord.toLowerCase(),
					phoneticSpelling: phoneticSpelling.toLowerCase(),
					language: language.name,
					applyToAll: applyToAllLanguages,
				},
			);

		if (!res.ok) {
			if (res.error.error === "EntryAlreadyExistsError") {
				toast({
					title: `Cannot add duplicate entry.`,
					description: `${originalWord} word already exists in your ${language.name} dictionary`,
					status: "error",
					duration: 6000,
					isClosable: true,
					position: "top",
				});
			} else {
				toast({
					title: `Cannot add duplicate entry`,
					description: "Please try again later",
					status: "error",
					duration: 6000,
					isClosable: true,
					position: "top",
				});
			}
		} else {
			const newEntries: PronDictEntry[] = [];
			if (!applyToAllLanguages) {
				newEntries.push({
					phoneticSpelling: phoneticSpelling,
					actualWord: originalWord,
					language: language.name,
				});
			} else {
				for (const language of res.body) {
					newEntries.push({
						phoneticSpelling: phoneticSpelling,
						actualWord: originalWord,
						language: language,
					});
				}
			}

			props.onAddWord(newEntries);
			dispatch(closeModal());
		}
		setAdding(false);
	};

	const onListenOriginal = async () => {
		setLoadingBytesOriginal(true);
		await props.playWord(originalWord, language.name);
		setLoadingBytesOriginal(false);
	};

	const onListenPhonetic = async () => {
		setLoadingBytesPhonetic(true);
		await props.playWord(phoneticSpelling, language.name);
		setLoadingBytesPhonetic(false);
	};

	return (
		<div className="NewPronCard">
			<Flex className="lang-container" justifyContent={"space-between"}>
				<LanguagePicker
					newStyle
					currentLanguage={language}
					setCurrentLanguage={setLanguage}
					position="bottom"
				/>

				<Flex gap="0.5rem" alignItems={"center"}>
					<div
						onClick={() => {
							setApplyToAllLanguages(!applyToAllLanguages);
						}}
						className={`check ${applyToAllLanguages ? "checked" : "unchecked"}`}
					>
						<Icon className="tick-icon">{tickIcon()}</Icon>
					</div>
					<div className="check-label">Apply to all languages</div>
				</Flex>
			</Flex>

			<Flex marginTop={"2rem"} gap={"1rem"}>
				<div className="input-container">
					<label>Original word</label>
					<textarea
						className="pron-input"
						placeholder="Adobe"
						value={originalWord}
						onChange={(e) => setOriginalWord(e.target.value)}
					></textarea>
					<Cutton
						disabled={originalWord === ""}
						isLoading={loadingBytesOriginal}
						loadingText="Listen"
						size="s"
						theme="pink"
						leftIcon={speakerIcon()}
						rank="quaternary"
						onClick={onListenOriginal}
					>
						Listen
					</Cutton>
				</div>

				<div className="input-container">
					<label>Phonetic spelling</label>
					<textarea
						value={phoneticSpelling}
						onChange={(e) => setPhoneticSpelling(e.target.value)}
						className="pron-input"
						placeholder="A doe bee"
					></textarea>

					<Cutton
						disabled={phoneticSpelling === ""}
						isLoading={loadingBytesPhonetic}
						loadingText="Listen"
						size="s"
						theme="pink"
						leftIcon={speakerIcon()}
						rank="quaternary"
						onClick={onListenPhonetic}
					>
						Listen
					</Cutton>
				</div>
			</Flex>

			<Flex
				className="buttons"
				marginTop={"2rem"}
				gap="1rem"
				width={"100%"}
				justifyContent={"right"}
			>
				<Cutton
					fullWidth
					rank="tertiary"
					onClick={() => {
						dispatch(closeModal());
					}}
				>
					Cancel
				</Cutton>
				<Cutton
					fullWidth
					isLoading={adding}
					loadingText="Adding word ..."
					onClick={addEntry}
				>
					Add word
				</Cutton>
			</Flex>
		</div>
	);
};
export default NewPronCard;
