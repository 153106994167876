import React from "react";

import "./NavbarSection.css";

type NavbarSectionProps = {
	header?: string;
	children: React.ReactNode;
    noMargin?: boolean;
};

const NavbarSection: React.FC<NavbarSectionProps> = ({ header, children, ...props }) => {
	return (
		<div className={ `NavbarSection ${props.noMargin ? "noMargin":""}`}>
            {header && <div className="NavbarSection-header">{header}</div>}
			<div className="NavbarSection-contents">{children}</div>
		</div>
	);
};
export default NavbarSection;
