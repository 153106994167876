const tickIconCircled = (color = "currentColor") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				opacity="0.5"
				d="M8.91667 16.8333C13.2889 16.8333 16.8333 13.2889 16.8333 8.91667C16.8333 4.54441 13.2889 1 8.91667 1C4.54441 1 1 4.54441 1 8.91667C1 13.2889 4.54441 16.8333 8.91667 16.8333Z"
				stroke={color}
			/>
			<path
				d="M6.14551 9.3125L7.72884 10.8958L11.6872 6.9375"
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default tickIconCircled;
