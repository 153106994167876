import React, { useEffect, useState } from "react";
import "./Bin.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import GuidesTable from "@gigauser/common/src/components/guides/GuidesTable/GuidesTable";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import trash from "@gigauser/common/src/assets/icons/trash.svg";
import SectionScroller from "@gigauser/common/src/layouts/SectionScroller/SectionScroller";
import { setDeletedGuidesFrontend } from "@gigauser/common/src/redux/slices/collectionDataSlice";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import PageHeader from "../Header/laptop/PageHeader/PageHeader";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import useConfirmDelete from "@gigauser/common/src/hooks/useConfirmDelete/useConfirmDelete";

type BinProps = {};

const Bin: React.FC<BinProps> = () => {
	const [loading, setLoading] = useState(true);

	const saver = useAppSelector(selectSaver);
	const dispatch = useAppDispatch();

	const confirmDelete = useConfirmDelete();

	const initDeletedGuides = async () => {
		const res = await saver.fetchAllGuidePreviews(false);
		const deletedGuides = res
			.filter((gp) => gp.deleted === true)
			.sort(
				(a, b) => Number(b.deleteAt?.getTime()) - Number(a.deleteAt?.getTime()),
			);
		dispatch(setDeletedGuidesFrontend(deletedGuides));
		setLoading(false);
	};

	function isYetToBeDeleted(dateToCheck: Date) {
		// Get the current date
		const currentDate = new Date();

		// Compare the two dates
		return dateToCheck > currentDate;
	}

	useEffect(() => {
		initDeletedGuides();
	}, []);

	const deletedGuides = useAppSelector((state) =>
		state.collectionData.allDeletedGuides.filter(
			(g) => {
				if (!saver.user?.email.endsWith("@clueso.io")) {
					return g.deleteAt && isYetToBeDeleted(g.deleteAt) && !g.author.email.endsWith("@clueso.io")
				}
				else {
					return g.deleteAt && isYetToBeDeleted(g.deleteAt)
				}
			},
		),
	);

	const onClickGuide = (guide: GuidePreview) => {
		window.open("/guide/" + guide.id + "", "_blank");
		// alert("Please use the chrome extension to open guides. Opening on this platform is coming soon! ")
	};

	const onRestoreGuide = (guide: GuidePreview) => {
		const submitRestore = async () => {
			try {
				// deleteResult = await saver.deleteGuide(item.entry.id)
				const guidePreview = { ...guide };
				guidePreview.header = {
					...guidePreview.header,
				};
				guidePreview.deleted = false;
				const restoreResult = await saver.guides.preview.update(guidePreview);

				window.location.reload();
			} catch (e) {
				console.error("Couldn't delete file: ", e);
			}
		};

		confirmDelete(submitRestore, {
			action: "restore",
			item: guide.header.name,
		});
	};

	return (
		<div className="Bin">
			<PageHeader
				title="Trash"
				description="Items in trash get cleared in 30 days."
				icon={trashIcon}
			/>

			{loading ? (
				<p>Loading ...</p>
			) : (
				<SectionScroller
					sections={[
						{
							icon: trash,
							// header: "Deleted Projects",
							contents: (
								<GuidesTable
									disableDragNDrop
									type="Guide"
									entries={deletedGuides}
									onDelete={() => { }}
									onClick={onClickGuide}
									showFileContextMenu={() => { }}
									onRestore={onRestoreGuide}
								/>
							),
						},
					]}
				/>
			)}
		</div>
	);
};
export default Bin;
