import { useEffectOnce } from "@gigauser/common/src/hooks/useEffectOnce";
import { saver } from "@gigauser/common/src/network/saver";
import GridLoader from "@gigauser/common/src/ui/loaders/GridLoader/GridLoader";
import { useState } from "react";
import Vizzly from "@vizzly/dashboard";
import "./Analytics.css";
import { GigaUserApi } from "@giga-user-fern/api";
import { APIResponse } from "@giga-user-fern/api/types/core";
import { Error } from "@giga-user-fern/api/types/api/resources/analytics/client/getEventData";

function Analytics() {
	const [loading, setLoading] = useState<boolean>(true);
	const [eventsData, setData] = useState<
		APIResponse<GigaUserApi.EventsResponse, Error> | false
	>(false);
	useEffectOnce(() => {
		saver.getEventData().then((resp) => {
			setData(resp);

			setLoading(false);
		});
	});
	if (loading) {
		return <GridLoader center></GridLoader>;
	} else {
		return (
			<div className="gigauser-vizzly-container">
				{" "}
				<Vizzly.Dashboard
					//@ts-ignore
					data={async (dataSet) => {
						const eventsDataResp = eventsData;
						if (eventsDataResp && eventsDataResp.ok) {
							const eventsData = eventsDataResp.body;
							if (dataSet.id === "searches") {
								return eventsData.searches;
							} else if (dataSet.id === "views") {
								return eventsData.views;
							} else if (dataSet.id === "ratings") {
								return eventsData.ratings;
							} else if (dataSet.id === "feedback") {
								return eventsData.feedback;
							}
						}
					}}
					identity={async () => {
						const tokensResp = await saver.getIdentity();
						if (tokensResp.ok) {
							const tokens = tokensResp.body;

							return {
								dashboardAccessToken: tokens.dashboardAccessToken,
								dataAccessToken: tokens.dataAccessTokens,
								queryEngineAccessToken: tokens.queryAccessToken,
							};
						} else {
							return null;
						}
					}}
					//@ts-ignore
					dataSets={async () => {
						return Promise.resolve([
							{
								id: "views",
								name: "Views",
								fields: [
									{
										dataType: "string" as const,
										publicName: "Event",
										id: "event",
										canBeDimension: false,
										canBeMeasure: true,
									},
									{
										dataType: "string" as const,
										publicName: "GuideID",
										id: "guideId",
										canBeDimension: true,
										canBeMeasure: false,
									},
									{
										dataType: "string" as const,
										publicName: "Title",
										id: "title",
										canBeDimension: true,
										canBeMeasure: false,
									},
									{
										dataType: "number" as const,
										publicName: "Count",
										id: "count",
										canBeDimension: false,
										canBeMeasure: true,
									},
									{
										dataType: "date_time" as const,
										publicName: "Date",
										id: "date",
										canBeDimension: false,
										canBeMeasure: true,
										allowedGranularities: ["hour", "day", "month", "year"],
									},
								],
							},
							{
								id: "searches",
								name: "Searches",
								fields: [
									{
										dataType: "string" as const,
										publicName: "Event",
										id: "event",
										canBeDimension: false,
										canBeMeasure: true,
									},
									{
										dataType: "string" as const,
										publicName: "Search",
										id: "search",
										canBeDimension: true,
										canBeMeasure: false,
									},
									{
										dataType: "date_time" as const,
										publicName: "Date",
										id: "date",
										canBeDimension: false,
										canBeMeasure: true,
										allowedGranularities: ["hour", "day", "month", "year"],
									},
								],
							},
							{
								id: "ratings",
								name: "Ratings",
								fields: [
									{
										dataType: "string" as const,
										publicName: "GuideID",
										id: "guideId",
										canBeDimension: true,
										canBeMeasure: true,
									},
									{
										dataType: "string" as const,
										publicName: "Title",
										id: "title",
										canBeDimension: true,
										canBeMeasure: false,
									},
									{
										dataType: "number" as const,
										publicName: "Rating",
										id: "rating",
										canBeDimension: true,
										canBeMeasure: true,
									},
									{
										dataType: "date_time" as const,
										publicName: "Date",
										id: "date",
										canBeDimension: false,
										canBeMeasure: true,
										allowedGranularities: ["hour", "day", "month", "year"],
									},
								],
							},
							{
								id: "feedback",
								name: "Feedback",
								fields: [
									{
										dataType: "string" as const,
										publicName: "GuideID",
										id: "guideId",
										canBeDimension: true,
										canBeMeasure: true,
									},
									{
										dataType: "string" as const,
										publicName: "Title",
										id: "title",
										canBeDimension: true,
										canBeMeasure: false,
									},
									{
										dataType: "string" as const,
										publicName: "Feedback",
										id: "feedback",
										canBeDimension: true,
										canBeMeasure: false,
									},
									{
										dataType: "date_time" as const,
										publicName: "Date",
										id: "date",
										canBeDimension: false,
										canBeMeasure: true,
										allowedGranularities: ["hour", "day", "month", "year"],
									},
								],
							},
						]);
					}}
				/>
			</div>
		);
	}
}

export default Analytics;
