const scissorsIcon = (color?: string) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 518 417"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M89.8571 262.467C85.0911 262.46 80.3395 262.987 75.6905 264.033C43.8075 271.487 22.5265 301.597 26.1385 334.137C29.7505 366.68 57.1158 391.387 89.8571 391.667C124.972 390.87 153.023 362.177 153.023 327.05C153.023 291.927 124.972 263.267 89.8571 262.467Z"
				stroke={color}
				stroke-width="50"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M89.8582 154.233C85.1148 154.237 80.3855 153.711 75.7582 152.667C43.8182 145.275 22.4629 115.152 26.0619 82.566C29.6605 49.98 57.0752 25.243 89.8582 25C124.974 25.7977 153.024 54.4927 153.024 89.6167C153.024 124.741 124.974 153.436 89.8582 154.233Z"
				stroke={color}
				stroke-width="50"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M481.2 380.673C493.537 386.87 508.564 381.893 514.76 369.557C520.96 357.22 515.984 342.193 503.647 335.993L481.2 380.673ZM193.89 208.333L205.114 185.993C198.247 182.543 190.178 182.443 183.228 185.72L193.89 208.333ZM65.0946 241.42C52.6059 247.31 47.2556 262.207 53.1439 274.697C59.0322 287.183 73.9296 292.533 86.4182 286.647L65.0946 241.42ZM503.647 80.672C515.984 74.473 520.96 59.4463 514.76 47.109C508.564 34.7717 493.537 29.7957 481.2 35.9947L503.647 80.672ZM193.89 208.333L183.233 230.947C190.182 234.223 198.25 234.12 205.114 230.673L193.89 208.333ZM86.4129 130.052C73.9232 124.166 59.0269 129.52 53.1416 142.01C47.2559 154.5 52.6099 169.397 65.0999 175.28L86.4129 130.052ZM503.647 335.993L205.114 185.993L182.666 230.673L481.2 380.673L503.647 335.993ZM183.228 185.72L65.0946 241.42L86.4182 286.647L204.55 230.947L183.228 185.72ZM481.2 35.9947L182.666 185.993L205.114 230.673L503.647 80.672L481.2 35.9947ZM204.547 185.72L86.4129 130.052L65.0999 175.28L183.233 230.947L204.547 185.72Z"
				fill={color}
			/>
		</svg>
	);
};

export default scissorsIcon;
