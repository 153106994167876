import { GuideData, GuidePreview } from "../../../core/types/guide";
import { showErrorMessagePopup } from "../../../layouts/MessagePopup/messagePopupSlice";
import { selectSaver } from "../../slices/backendSlice";
import {
	setDescription,
	setGuide,
	setGuidePlatformStatus,
	setTitle,
	setUnsavedChanges,
} from "../../slices/guideSlice";
import { setShowVideoExportPopup } from "../../slices/processingSlice";
import { useAppDispatch, useAppSelector } from "../../useAppRedux";
import { GigaUserApi } from "@giga-user-fern/api";
import useShowAsyncError from "./useShowAsyncError";
import useFetchGuidePreviewAndCheck from "./useFetchGuidePreviewAndCheck";
import orgSetupCanvas from "../../../core/canvas/orgSetupCanvas";
import { setPlatformDetails } from "../../slices/platformDetailsSlice";
import {
	setActiveBackgroundTemplate,
	setActiveIntroTemplate,
	setActiveOutroTemplate,
} from "../../slices/platformUiSlice";
import { sanitizedBackgroundEdits } from "../../../utils/videoEditsSanitizer";

const useInitGuideEditor: () => () => Promise<{
	guidePreview: GuidePreview;
	guideData: GuideData;
} | null> = () => {
	var url = new URL(window.location.href);
	var urlSplit = url.pathname.split("/");
	var guideId = urlSplit[urlSplit.length - 1];

	const saver = useAppSelector(selectSaver);
	const showError = useShowAsyncError();
	const fetchGuidePreviewAndCheck = useFetchGuidePreviewAndCheck();
	const dispatch = useAppDispatch();

	return async () => {
		let guidePreview = await saver.guides.preview.get(GigaUserApi.Id(guideId));
		if (!guidePreview) {
			// Go check if the guide belongs to some other workspace
			const user = await saver.guides.user.get(GigaUserApi.Id(guideId));
			if (user.ok) {
				saver.initializePlatformWithResp(user.body);
				guidePreview = await saver.guides.preview.get(GigaUserApi.Id(guideId));
			} else {
				return null;
			}
			if (!guidePreview) {
				return null;
			}
		}
		const platformDetails = await saver.getPlatformDetails();

		if (platformDetails.ok && platformDetails.body) {
			dispatch(setPlatformDetails(platformDetails.body));
			// if(platformDetails.body.intro?.default) {
			//     dispatch(setActiveIntroTemplate(platformDetails.body.intro?.default));
			// }
			// if(platformDetails.body.outro?.default) {
			//     dispatch(setActiveOutroTemplate(platformDetails.body.outro?.default));
			// }
			// if(platformDetails.body.background?.default) {
			//     dispatch(setActiveBackgroundTemplate(platformDetails.body.background?.default));
			// }
		}
		const guideStatus = await saver.guides.platformStatus.get(
			GigaUserApi.Id(guideId),
		);
		if (guideStatus.ok) {
			dispatch(setGuidePlatformStatus(guideStatus.body));
			if (guideStatus.body.operationError) {
				showError(guideStatus.body.operationError);
			}
			if (
				guideStatus.body.exportingVideo ||
				guideStatus.body.publishingGuide ||
				guideStatus.body.generatingVoiceover ||
				guideStatus.body.aiEnhancingArticle ||
				guideStatus.body.aiEnhancingVideo ||
				guideStatus.body.trimmingVideo ||
				guideStatus.body.autoUpdatingGuide ||
				guideStatus.body.creatingGuide
			) {
				fetchGuidePreviewAndCheck();
				if (guideStatus.body.creatingGuide) return null;
			}
		}
		const guideData = await saver.guides.data.get(GigaUserApi.Id(guideId));
		if (guidePreview && guideData) {
			const copyGuideData = { ...guideData };
			const setup = orgSetupCanvas[saver.organization?.id || ""];
			if (
				setup &&
				setup.introDuration &&
				setup.outroDuration &&
				copyGuideData.video.videoEdits?.intro?.duration &&
				copyGuideData.video.videoEdits?.outro?.duration
			) {
				copyGuideData.video.videoEdits.intro.duration = setup.introDuration;
				copyGuideData.video.videoEdits.outro.duration = setup.outroDuration;
			}

			if (copyGuideData.video.videoEdits?.background) {
				copyGuideData.video.videoEdits.background = sanitizedBackgroundEdits(
					copyGuideData.video.videoEdits.background,
					copyGuideData.video.videoEdits.version,
				);
			}

			if (copyGuideData.video.videoEdits)
				copyGuideData.video.videoEdits.version = "may012024";

			dispatch(
				setGuide({
					id: GigaUserApi.Id(guideId),
					guidePreview: guidePreview,
					guideData: copyGuideData,
				}),
			);

			console.log("init GuidePreview: ", guidePreview);
			if (guideStatus.ok && guideStatus.body.exportingVideo) {
				dispatch(setShowVideoExportPopup({ showPopup: true }));
			}

			dispatch(setUnsavedChanges(false));

			return {
				guidePreview: guidePreview,
				guideData: guideData,
			};
		} else return null;
	};
};

export default useInitGuideEditor;
