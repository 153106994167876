import { useEffect, useState } from "react";
import "./HelpCenterConfig.css";
import HelpCenterConfigEditor from "./HelpCenterConfig";
import { AccessManagementPage } from "./AccessManagementPage";

import useSuperAdmin from "@gigauser/common/src/hooks/useSuperAdmin";
import { HostingDetailsPage } from "./HostingDetailsPage";
import { useNavigate } from "react-router-dom";

const TabSelector = ({
	currentTab,
	setTab,
	availableTabs,
}: {
	currentTab: string;
	setTab: React.Dispatch<string>;
	availableTabs: { label: string; id: string }[];
}) => {
	return (
		<div className="helpCenterTabs">
			{availableTabs.map((tab) => (
				<div
					onClick={() => setTab(tab.id)}
					className={
						tab.id === currentTab
							? "helpCenterTabs-activeTab"
							: "helpCenterTabs-inActiveTab"
					}
				>
					<span>{tab.label}</span>
				</div>
			))}
		</div>
	);
};
const HelpCenterSettings = () => {
	const [subTab, setSubTab] = useState<string>("customizations");
	const isSuperAdmin = useSuperAdmin();
	const navigate = useNavigate();
	useEffect(() => {
		if (!isSuperAdmin("kbconfig")) {
			navigate("/folders/Collection_root");
		}
	}, [isSuperAdmin("kbconfig")]);
	if (isSuperAdmin("kbconfig")) {
		return (
			<div className="helpCenterContainer">
				<TabSelector
					currentTab={subTab}
					setTab={setSubTab}
					availableTabs={[
						{ id: "customizations", label: "Appearance" },
						{ id: "viewers", label: "Access" },
						{ id: "hosting-details", label: "Hosting Details" },
					]}
				/>
				{subTab === "customizations" && <HelpCenterConfigEditor />}
				{subTab === "viewers" && <AccessManagementPage />}
				{subTab === "hosting-details" && <HostingDetailsPage />}
			</div>
		);
	}
	return <></>;
};
export default HelpCenterSettings;
