import React, { useContext } from "react";
import { useState } from "react";
import TitledInput from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import Button from "@gigauser/common/src/ui/buttons/Button/Button";
import { closeOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";

import { selectCollectionPath } from "@gigauser/common/src/redux/slices/collectionPathSlice";
import useCreateCollection from "@gigauser/common/src/redux/hooks/FileHooks/useCreateCollection";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import { Device } from "@gigauser/common/src/types/devices";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { saver } from "@gigauser/common/src/network/saver";
import { User } from "@giga-user-fern/api/types/api";
import { APIResponse } from "@giga-user-fern/api/types/core";
import { Error } from "@giga-user-fern/api/types/api/resources/admin/client/createUser";
import "./CreateUserOverlay.css";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import { closeModal } from "@gigauser/common/src/layouts/CModal/modalSlice";

type CreateUserOverlayProps = {
	complete: (email: string) => void;
};

const CreateUserOverlay: React.FC<CreateUserOverlayProps> = (props) => {
	// const [userName, setUserName] = useState<string>("");
	const [userEmail, setUserEmail] = useState<string>("");
	const [creating, setCreating] = useState<boolean>(false);

	// const onNameChange = (e: any) => {
	// 	const target = e.target as HTMLInputElement;
	// 	setUserName(target.value);
	// };

	const onEmailChange = (e: any) => {
		const target = e.target as HTMLInputElement;
		setUserEmail(target.value);
	};

	const dispatch = useAppDispatch();

	const { device } = useWindowSize();

	const onCreate = async () => {
		setCreating(true);
		if (saver.organization) {
			//
			await props.complete(userEmail);
		}

		// TODO: Handle error on true or false

		dispatch(closeOverlay());
		setCreating(false);
	};

	return (
		<div className="CreateUserOverlay">
			{/* <div className={`input-field ${device}`}>
				<TitledInput
					id=""
					label="Name (Optional)"
					value={userName}
					onChange={onNameChange}
					placeHolder="Name of user"
					// position="left half"
					styleSize="xl"
					display={true}
					background="white"
					edges="sharp"
					autoFocus
				></TitledInput>
			</div> */}

			{/* <div className={`motivation ${device}`}>This collection is the start of something big. Let’s give it a memorable name.</div> */}

			<div className={`input-field ${device}`}>
				<TitledInput
					id=""
					label="Email"
					value={userEmail}
					onChange={onEmailChange}
					placeHolder="john.doe@acme.com"
					// position= "left half"
					styleSize="xl"
					display={true}
					background="white"
				></TitledInput>
			</div>

			<div className={`create-user-buttons ${device}`}>
				<Cutton
					fullWidth
					rank="tertiary"
					onClick={() => {
						dispatch(closeModal());
					}}
				>
					Cancel
				</Cutton>
				<Cutton
					fullWidth
					disabled={!userEmail.length}
					rank="primary"
					isLoading={creating}
					loadingText="Inviting ..."
					onClick={onCreate}
				>
					Send invite
				</Cutton>
			</div>
		</div>
	);
};
export default CreateUserOverlay;
// export const x = 3;
