import React, { useContext, useState } from "react";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { Device } from "@gigauser/common/src/types/devices";

import { useAppSelector } from "@gigauser/common/src/redux";
import {
	editCollectionInPath,
	selectCollectionPath,
} from "@gigauser/common/src/redux/slices/collectionPathSlice";
import "./PageTitle.css";
import useCollectionNavigate from "@gigauser/common/src/redux/hooks/FileHooks/useCollectionNavigate";
import { EnvironmentContext } from "@gigauser/common/src/contexts";
import Breadcrumbs from "@gigauser/common/src/ui/tabs/Breadcrumbs/Breadcrumbs";
import { useAppDispatch } from "@gigauser/common/src/redux";

import backButton from "@gigauser/common/src/assets/legacy_assets/icons/backButton.svg";

import more_button from "@gigauser/common/src/assets/legacy_assets/legacy_icons/more.png";
import edit from "@gigauser/common/src/assets/legacy_assets/icons/edit.svg";

import EditItemDetails from "../../../../../../features/files/EditItemDetails/EditItemDetails";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { Item, unravelItem } from "@gigauser/common/src/types/files";
import { openModal } from "@gigauser/common/src/layouts/CModal/modalSlice";
import penIcon from "@gigauser/common/src/assets/svgs/screenshot/penIcon";
import { Icon } from "@chakra-ui/react";

const PageTitle: React.FC = () => {
	const dispatch = useAppDispatch();
	const deviceType = useWindowSize().device;
	const collectionPath = useAppSelector(selectCollectionPath);

	const currCollection = collectionPath.segments.at(-1) as Collection;
	const previousFolder = collectionPath.segments.at(-2) as Collection;

	const env = useContext(EnvironmentContext);
	const navigationHandler = useCollectionNavigate(env);
	const navigate = env.navigator();

	// const [nameState, setNameState] = useState<string>(currCollection.name)
	// const [descriptionState, setDescriptionState] = useState<string>(currCollection.description?currCollection.description:"")

	const openFolderDetailsOverlay = (
		event: React.MouseEvent<HTMLDivElement>,
		fileType: string,
	) => {
		event?.preventDefault();
		// dispatch(openOverlay({
		//     heading: {
		//         icon: folder_icon,
		//         title: "Edit",
		//         data: currCollection?.name || "",
		//     },
		//     component: <DocumentInfo fileType={"Folder"} objectData={currCollection}/>,
		// }))
	};

	const [isHovering, setIsHovering] = useState(false);

	const handleMouseOver = () => {
		setIsHovering(true);
	};

	const handleMouseOut = () => {
		setIsHovering(false);
	};

	if (collectionPath.loading) {
		return (
			<div className={`PageTitle ${deviceType}`}>
				{deviceType === "laptop" ? (
					// <img src={FolderMain} className="PageTitle-icon" />
					<div
						className="PageTitle-icon"
						onContextMenu={(e) => {
							openFolderDetailsOverlay(e, "Folder");
						}}
					></div>
				) : null}
				<div className={`PageTitle-text`}>
					{deviceType === "laptop" ? (
						<div className="PageTitle-location">{"Loading ..."}</div>
					) : null}
					<div className="PageTitle-folder">{"Loading ..."}</div>
					{deviceType === "mobile" ? (
						<div className="PageTitle-location">{"Loading ..."}</div>
					) : null}
					<div className="PageTitle-description">{"Loading ..."}</div>
				</div>
			</div>
		);
	}

	const editDetailsCallback = (file: Item) => {
		if (file.type === "Guide") return;

		const { name, description } = unravelItem(file);
		const fileCopy = { ...file.entry };
		fileCopy.name = name;
		fileCopy.description = description;

		dispatch(editCollectionInPath(fileCopy));
	};

	return (
		<div className={`PageTitle ${deviceType}`}>
			{/* {deviceType === "laptop" ? (
				<div
					className="PageTitle-icon"
					onContextMenu={(e) => {
						openFolderDetailsOverlay(e, "Folder");
					}}
					onMouseOver={handleMouseOver}
					onMouseOut={handleMouseOut}
				></div>
			) : null} */}

			{deviceType === "mobile" && previousFolder != undefined ? (
				<div
					className="backButton"
					onClick={() => navigationHandler(previousFolder)}
				>
					<img src={backButton} alt="" />
				</div>
			) : null}
			<div className={`PageTitle-text`}>
				{deviceType === "laptop" ? (
					<div className="PageTitle-location">
						{
							<Breadcrumbs
								navigationHandler={navigationHandler}
								segments={collectionPath.segments}
							></Breadcrumbs>
						}
					</div>
				) : null}
				<div className="PageTitle-folder">
					{currCollection.name}

					<Icon
						style={{
							display:
								currCollection.id === "Collection_root" ||
								deviceType === "mobile"
									? "none"
									: "block",
						}}
						className="header-more-button"
						onClick={(e) => {
							dispatch(
								openModal({
									heading: {
										icon: penIcon(),
										title: "Edit",
										data: currCollection.name,
									},
									component: (
										<EditItemDetails
											file={{
												type: "Collection",
												entry: currCollection,
											}}
											editDetailsCallback={editDetailsCallback}
										/>
									),
								}),
							);

							e.stopPropagation();
						}}
					>
						{penIcon()}
					</Icon>
				</div>

				{deviceType === "mobile" ? (
					<div className="PageTitle-location">
						{
							<Breadcrumbs
								navigationHandler={navigationHandler}
								segments={collectionPath.segments}
							></Breadcrumbs>
						}
					</div>
				) : null}
				{currCollection.description ? (
					<div className="PageTitle-description">
						{currCollection.description}
					</div>
				) : null}
			</div>
		</div>
	);
};

export default PageTitle;
