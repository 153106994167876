import { Id, Language } from "@giga-user-fern/api/types/api";
import React, { useEffect, useState } from "react";
import LanguagePicker from "@gigauser/common/src/overlays/LanguagePicker/LanguagePicker";
import "./UploadGuideOverlay.css";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import FileUpload from "@gigauser/common/src/ui/inputs/file/FileUpload/FileUpload";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { setVideoUploadProgress } from "@gigauser/common/src/redux/slices/platformUiSlice";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import PreparingGuide from "../PreparingGuide/PreparingGuide";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import IBox from "@gigauser/common/src/ui/messages/iBox/iBox";
import { FileFormat } from "@giga-user-fern/api/types/api/resources/guides";
import { Flex, useToast } from "@chakra-ui/react";
import {
	closeModal,
	openModal,
} from "@gigauser/common/src/layouts/CModal/modalSlice";
import { format } from "path";
import TitledInput from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import LanguageInput from "@gigauser/common/src/overlays/LanguageInput/LanguageInput";

type UploadGuideOverlayProps = {
	currentFolderId: Id;
	format: "video" | "pdf";
};

const UploadGuideOverlay: React.FC<UploadGuideOverlayProps> = (props) => {
	const [file, setFile] = useState<File | null>(null);
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);

	const onChangeFile = (file: any) => {
		setFile(file);
	};

	const [currentLanguage, _setCurrentLanguage] = useState<Language>({
		languageId: "en",
		name: "English",
	});

	const initCurrentLanguage = async () => {
		var currentLanguage = localStorage.getItem("uploadVideoLanguage");
		if (currentLanguage) {
			const currentLanguageItem = JSON.parse(currentLanguage) as Language;
			_setCurrentLanguage(currentLanguageItem);
		}
	};

	const toast = useToast();

	useEffect(() => {
		initCurrentLanguage();
	}, []);

	const setCurrentLanguage = (language: Language) => {
		localStorage.setItem("uploadVideoLanguage", JSON.stringify(language));
		_setCurrentLanguage(language);
	};

	const onSubmit = () => {
		const onProgress = (progress: number) => {
			dispatch(setVideoUploadProgress(progress));
		};

		if (!file) return;
		const fileName = file.name; // Get the name of the file including extension

		// Extract the extension
		const extension = fileName.slice(fileName.lastIndexOf(".") + 1);
		let format: FileFormat = "video";
		if (extension === "pdf") {
			format = "pdf";
		}

		return new Promise((resolve, reject) => {
			dispatch(setVideoUploadProgress(0));

			dispatch(
				openModal({
					heading: {
						icon: plusIcon(),
						title: "Preparing ...",
						data: "",
					},
					component: <PreparingGuide progressable theme="dark" />,
				}),
			);

			saver
				.guides.create.upload(
					file,
					currentLanguage.languageId,
					format,
					props.currentFolderId,
					onProgress,
				)
				.then((res) => {
					if (res) {
						if (res === "CorruptedFileError") {
							toast({
								title: `Couldnt Create Guide`,
								description: "Something is wrong with the file",
								status: "error",
								duration: 6000,
								isClosable: true,
								position: "top",
							});
							dispatch(closeModal());
							return;
						}
						dispatch(closeModal());

						window.location.href = "/guide/" + res;
						resolve(true);
					} else {
						dispatch(closeModal());

						console.log("NO ID");
						reject(false);
					}
				});
		});
	};

	return (
		<div className="UploadGuideOverlay">
			<div className="instruction">
				Upload any{" "}
				{props.format === "pdf"
					? "PDF slide deck"
					: "screen recording with a voiceover. "}
				Clueso creates a studio-quality video and step-by-step article.
			</div>
			<div className="">
				{/* <div className="LanguagePicker-text videofile">Video file:</div> */}

				<div className="LanguagePicker-File">
					<FileUpload
						fileTypes={
							props.format === "video"
								? [".mp4", ".mov", ".wav", ".webm"]
								: [".pdf"]
						}
						file={file}
						onChange={onChangeFile}
					/>
				</div>

				<LanguageInput
					onlyTranscriptable
					currentLanguage={currentLanguage}
					setCurrentLanguage={setCurrentLanguage}
					description="Choose the language spoken in your uploaded video."
				/>

				<Flex className="buttons" gap="1rem">
					<Cutton
						fullWidth
						rank="tertiary"
						onClick={() => {
							dispatch(closeModal());
						}}
					>
						Cancel
					</Cutton>
					<Cutton fullWidth onClick={onSubmit} disabled={!file}>
						Upload
					</Cutton>
				</Flex>
			</div>
		</div>
	);
};
export default UploadGuideOverlay;
