import { GigaUserApi } from "@giga-user-fern/api";
import { ChangeRoleForUserRequest } from "@giga-user-fern/api/types/api/resources/organizations";
import { saver } from "@gigauser/common/src/network/saver";
import { useEffect } from "react";
import { AdminPage } from "../../home/components/Admin/ManageUsers/AdminPage";
const Id = GigaUserApi.Id;
export default function ManageUsersSettings() {
	useEffect(() => {
		getListOfInvitedUsers();
		getListOfOnboardedCreators();
		getCurrentOrg();
	}, []);
	const getListOfInvitedUsers = async () => {
		const resp = await saver.settings.manageUsers.listOfInvitedCreators();
		if (resp.ok) {
			console.log(resp.body);
		} else {
			alert("Error while fetching invited creators!");
		}
	};
	const getListOfOnboardedCreators = async () => {
		const resp = await saver.settings.manageUsers.listOfOnboardedCreators();
		if (resp.ok) {
			console.log(resp.body);
		} else {
			alert("Error while fetching onboarded creators!");
		}
	};
	const getCurrentOrg = async () => {
		console.log(await saver.settings.manageUsers.organizationDetails());
	};
	const handleChangeRoleForUser = async (details: ChangeRoleForUserRequest) => {
		const resp = await saver.settings.manageUsers.changeRoleOfUser(details);
		if (resp.ok) {
			console.log(resp.body);
		} else {
			alert("Error while change role for creator!");
		}
	};
	return <AdminPage />;
}
