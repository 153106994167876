import React, { useEffect, useState } from "react";
import "./CopyLinkPopup.css";
import { useToast } from "@chakra-ui/react";
import IBox from "@gigauser/common/src/ui/messages/iBox/iBox";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	selectGuidePreview,
	selectGuideSettings,
	selectUnsavedChanges,
	selectVideo,
} from "@gigauser/common/src/redux/slices/guideSlice";
import { GuidePreview } from "@giga-user-fern/api/types/api/resources/guides";
import CuttonGroup from "@gigauser/common/src/ui/buttons/CuttonGroup/CuttonGroup";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import rocketIcon from "@gigauser/common/src/assets/svgs/rocketIcon";
import AdvancedSettings from "@gigauser/common/src/ui/video/components/AdvancedSettings/AdvancedSettings";
import CToggle from "@gigauser/common/src/ui/video/components/CToggle/CToggle";
import copyIcon from "@gigauser/common/src/assets/svgs/copyIcon";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay";
import ConfirmPublishPopup from "../ConfirmPublishPopup/ConfirmPublishPopup";
import { LinkCopy } from "../LinkCopy/LinkCopy";
import { usePublishGuide } from "@gigauser/common/src/redux/hooks/FileHooks/usePublishGuide";

type CopyLinkPopupProps = {};

const CopyLinkPopup: React.FC<CopyLinkPopupProps> = () => {
	const toast = useToast();
	const unsavedChanges = useAppSelector(selectUnsavedChanges);

	const guidePreview = useAppSelector(selectGuidePreview) as GuidePreview;
	const settings = useAppSelector(selectGuideSettings);
	const saver = useAppSelector(selectSaver);
	const dispatch = useAppDispatch();

	const currentVersion = unsavedChanges
		? guidePreview.versionSaved + 1
		: guidePreview.versionSaved;

	const [isPublishing, setIsPublishing] = useState<boolean>(false);

	const [shareableLink, setShareableLink] = useState<string>("");
	const [helpCenterLink, setHelpCenterLink] = useState<string>("");

	const [hasPrivateParent, setHasPrivateParent] = useState(false);

	const [showArticle, setShowArticle] = useState<boolean>(
		!settings?.hideArticle,
	);
	const [showVideo, setShowVideo] = useState<boolean>(
		settings?.publishedVideo !== "none",
	);

	const hasVideo = useAppSelector(selectVideo)?.originalSrc;

	const isPrivate = () => {
		return hasPrivateParent || !guidePreview.header.published;
	};

	const publishGuide = usePublishGuide();

	const onPublish = async () => {
		if (guidePreview.versionPublished === 1) {
			setIsPublishing(true);
			publishGuide({
				isPrivate: isPrivate(),
				source: "viewlink",
				articleVisible: showArticle,
				videoVisible: showVideo,
				isFirstPublish: guidePreview.versionPublished === 1,
			});
		} else {
			dispatch(
				openOverlay({
					heading: {
						icon: rocketIcon("white"),
						title: "Confirm Publish",
					},
					component: (
						<ConfirmPublishPopup
							isPrivate={isPrivate()}
							source="viewlink"
							articleVisible={showArticle}
							videoVisible={showVideo}
						/>
					),
				}),
			);
		}
	};

	const initShareableLink = async () => {
		const link = await saver.guides.shareableLink.get(guidePreview);
		setHasPrivateParent(link.hasPrivateParent);
		const helpCenterLink = link.sharingLink.replace("/share/", "/guide/");

		setShareableLink(link.sharingLink);
		setHelpCenterLink(helpCenterLink);
	};

	useEffect(() => {
		initShareableLink();
	}, []);

	const onCopy = () => {
		navigator.clipboard
			.writeText(`${shareableLink}`)
			.then(() => {
				toast({
					title: `Link copied to clipboard.`,
					status: "success",
					duration: 3000,
					position: "bottom",
					isClosable: true,
				});
			})
			.catch((error) => {
				alert("Error copying to clipboard!");
			});

		// e.preventDefault()
		// e.stopPropagation()
	};

	return (
		<div className="CopyLinkPopup">
			<p className="CopyLink-p">
				{guidePreview.versionPublished !== 1
					? "Anyone with the link below can access a view-only version of this project. No sign-in required."
					: "Click the button below to create a view-only link for this project."}
			</p>

			{!isPrivate() && guidePreview.versionPublished !== 1 && (
				<>
					<div className="C-label">Link in help center</div>
					<LinkCopy link={shareableLink}></LinkCopy>

					<div className="C-label bottomlink">Link to stand-alone page</div>
				</>
			)}
			{guidePreview.versionPublished !== 1 && (
				<LinkCopy link={shareableLink}></LinkCopy>
			)}

			{currentVersion !== guidePreview.versionPublished &&
				guidePreview.versionPublished !== 1 && (
					<div className="CopyLink-IBox">
						<IBox>
							{`
                            ${isPrivate() ? "This link shows" : "These links show"} 
                            the last published version (v${guidePreview.versionPublished}). 
                        The latest version is v${currentVersion}. Click Publish to share your latest edits.`}
						</IBox>
					</div>
				)}

			<div className="CopyLink-advset-container">
				{hasVideo && (
					<AdvancedSettings>
						<div className="CopyLink-Advanced-Settings">
							<CToggle
								label="Video"
								setterFunction={(s: boolean) => {
									setShowVideo(s);
								}}
								value={showVideo}
							/>

							<CToggle
								label="Article"
								setterFunction={(s: boolean) => {
									setShowArticle(s);
								}}
								value={showArticle}
							/>
						</div>
					</AdvancedSettings>
				)}
			</div>

			<CuttonGroup>
				{isPrivate() && guidePreview.versionPublished !== 1 ? (
					<Cutton size="s" onClick={onCopy} leftIcon={copyIcon("white")}>
						Copy Link
					</Cutton>
				) : (
					<></>
				)}

				<Cutton
					size="s"
					onClick={isPublishing ? undefined : onPublish}
					rank={
						isPrivate() && guidePreview.versionPublished !== 1
							? "secondary"
							: "primary"
					}
					disabled={hasVideo ? !(showVideo || showArticle) : false}
					leftIcon={rocketIcon("white")}
					isLoading={isPublishing}
					loadingText="Publishing ..."
				>
					{guidePreview.versionPublished !== 1
						? "Publish changes"
						: "Create Link"}
				</Cutton>
			</CuttonGroup>
		</div>
	);
};
export default CopyLinkPopup;
