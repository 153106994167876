import React, { useState, useMemo, useEffect, useRef } from "react";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import tapeIcon from "@gigauser/common/src/assets/svgs/tapeIcon";

import SimpleTabs from "@gigauser/common/src/ui/tabs/SimpleTabs/SimpleTabs";
import readIcon from "@gigauser/common/src/assets/svgs/readIcon";
import moveFolderIcon from "@gigauser/common/src/assets/svgs/moveFolderIcon";
import move from "@gigauser/common/src/assets/legacy_assets/icons/move.svg";
import LogoIcon from "@gigauser/common/src/assets/logos/LogoIcon.svg";
import LogoPro from "@gigauser/common/src/assets/svgs/logoPro";

import "./EditorTopbar.css";
import { useAppSelector, useAppDispatch } from "@gigauser/common/src/redux";
import ProfileButton from "@gigauser/common/src/auth/ProfileButton/ProfileButton";
import rocketIcon from "@gigauser/common/src/assets/svgs/rocketIcon";
import shareIcon from "@gigauser/common/src/assets/svgs/shareIcon";

import downloadIcon from "@gigauser/common/src/assets/svgs/downloadIcon";
import updateIcon from "@gigauser/common/src/assets/svgs/updateIcon";

import {
	addVideoEditHistory,
	redoVideoEdits,
	selectExportingVideo,
	selectGuidePreview,
	selectPublishingGuide,
	selectUnsavedChanges,
	selectVideoEdits,
	setAutoUpdatingGuide,
	setDescription,
	setTitle,
	setUnsavedChanges,
} from "@gigauser/common/src/redux/slices/guideSlice";

import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";

import {
	closeOverlay,
	openOverlay,
} from "@gigauser/common/src/layouts/Overlay";
import DeletePopup from "../../../home/features/files/DeletePopup/DeletePopup";
import useSaveGuide from "@gigauser/common/src/redux/hooks/FileHooks/useSaveGuide";
import { Popover, useDisclosure, useToast } from "@chakra-ui/react";
import Clickout from "@gigauser/common/src/layouts/Clickout/Clickout";
import ExportVideoProgress from "../../../../features/export/ExportVideoProgress/ExportVideoProgress";
import versionsIcon from "@gigauser/common/src/assets/svgs/videoeditor/versionsIcon";
import translateIcon from "@gigauser/common/src/assets/svgs/videoeditor/translateIcon";

import { ActiveFormat } from "@gigauser/common/src/redux/slices/platformUiSlice";
import useBackendBusy from "@gigauser/common/src/redux/hooks/FileHooks/useBackendBusy";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import Menu, {
	DisplayMenu,
	DisplayMenuItem,
} from "@gigauser/common/src/ui/menus/Menu/Menu";
import useSuperAdmin from "@gigauser/common/src/hooks/useSuperAdmin";
import copyIcon from "@gigauser/common/src/assets/svgs/copyIcon";
import PreUpdate from "@gigauser/common/src/components/PreUpdate/PreUpdate";
import {
	closeMessagePopup,
	showErrorMessagePopup,
	showLoadingMessagePopup,
} from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import TranslateGuide from "../../video/components/TranslateGuide/TranslateGuide";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import helpCircleIcon from "@gigauser/common/src/assets/svgs/helpCircleIcon";
import savedIcon from "@gigauser/common/src/assets/svgs/videoeditor/savedIcon";
import savingIcon from "@gigauser/common/src/assets/svgs/videoeditor/savingIcon";
import unsavedIcon from "@gigauser/common/src/assets/svgs/videoeditor/unsavedIcon";

import videoIcon from "@gigauser/common/src/assets/svgs/videoeditor/videoIcon";
import gifIcon from "@gigauser/common/src/assets/svgs/gifIcon";
import shareableLinkIcon from "@gigauser/common/src/assets/svgs/shareableLinkIcon";
import embedIcon from "@gigauser/common/src/assets/svgs/embedIcon";
import { useEffectOnce } from "@gigauser/common/src/hooks/useEffectOnce";
import ExportArticle from "../../../../features/export/ExportArticle/ExportArticle";
import ExportVideo from "../../../../features/export/ExportVideo/ExportVideo";
import CopyLinkPopup from "../../../../features/export/CopyLinkPopup/CopyLinkPopup";
import PublishHelpCenter from "../../../../features/export/PublishHelpCenter/PublishHelpCenter";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import FileExplorer from "../../../home/features/FileExplorer/FileExplorer";
import { Item, rootCollection } from "@gigauser/common/src/types/files";
import PreviewOriginalVideo from "../../video/components/VideoPlayer/PreviewOriginalVideo";
import { openFullScreenOverlay } from "@gigauser/common/src/layouts/FullScreenOverlay";
import VersionHistory from "../VersionHistory/VersionHistory";
import { useAuth0 } from "@auth0/auth0-react";
import { openModal } from "@gigauser/common/src/layouts/CModal/modalSlice";
import useConfirmDelete from "@gigauser/common/src/hooks/useConfirmDelete/useConfirmDelete";
import logger from "@gigauser/common/src/utils/logger";
import { deleteGuideFrontend } from "@gigauser/common/src/redux/slices/collectionDataSlice";
import { useNavigate } from "react-router-dom";
import { GigaUserApi } from "@giga-user-fern/api";

type EditorTopbarProps = {
	// title: string,
	// setTitle: React.Dispatch<React.SetStateAction<string>>,
	// description: string,
	// setDescription: React.Dispatch<React.SetStateAction<string>>,
	publish: boolean;
	setPublish: React.Dispatch<React.SetStateAction<boolean>>;
	activeFormat: ActiveFormat;
	setActiveFormat: (format: ActiveFormat) => void;
	videoTab: boolean;
};

const BasePlanType = GigaUserApi.organizations.BasePlanType;

const EditorTopbar: React.FC<EditorTopbarProps> = (props) => {
	const navigate = useNavigate()

	const width = useWindowSize().width;

	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);
	const guidePreview = useAppSelector(selectGuidePreview);

	const saveInProgress = useAppSelector(
		(state) => state.processing.value.saveInProgress,
	);

	const { isOpen, onToggle, onClose } = useDisclosure();
	const id = useAppSelector((state) => state.guide.value.guide?.id);

	const isPublishing = useAppSelector(selectPublishingGuide);
	const isExporting = useAppSelector(selectExportingVideo);

	const backendBusy = useBackendBusy();
	const backendBusyIgnoreSave = useBackendBusy(true);

	const [showMoreOptions, setShowMoreOptions] = useState(false);
	const betaFeaturesAccessible = useSuperAdmin();

	const [showFileMenu, setShowFileMenu] = useState(false);
	const [showShareMenu, setShowShareMenu] = useState(false);

	const onSaveGuide = useSaveGuide();
	const confirmDelete = useConfirmDelete();
	const toast = useToast();

	const iconColor = "#bfc7d4";

	const parentId = useRef(guidePreview?.parentId || "");

	const showVideoExportPopup = useAppSelector(
		(state) => state.processing.value.showVideoExportPopup,
	);
	const showGifExportPopup = useAppSelector(
		(state) => state.processing.value.showGifExportPopup,
	);

	const saveIsQueued = useRef(false);
	const incrementNextSave = useRef(true);

	const onChangeTitle = (e: any) => {
		dispatch(setTitle(e.target.value));
		document.title = e.target.value;
		dispatch(setUnsavedChanges(true));
	};

	const unsavedChanges = useAppSelector(selectUnsavedChanges);
	const debounceSaveTimer = useRef<any>(null);

	const videoEdits = useAppSelector(selectVideoEdits);

	const onChangeDescription = (e: any) => {
		dispatch(setDescription(e.target.value));
		dispatch(setUnsavedChanges(true));
	};

	const file: Item = {
		entry: guidePreview as GuidePreview,
		type: "Guide",
	};

	const moveSuccessHandler = (newParentID: string) => {
		parentId.current = newParentID;
	};

	const openFileContextMenu = async () => {
		if (parentId.current === "Collection_root") {
			dispatch(
				openOverlay({
					heading: {
						icon: moveFolderIcon("white"),
						title: "Move",
						data: file.entry.header.name,
					},
					component: (
						<FileExplorer
							action="move"
							item={file}
							initPath={[rootCollection]}
							onSuccessHandler={moveSuccessHandler}
						/>
					),
				}),
			);
			return;
		}

		const res = await saver.getPath(parentId.current);
		if (res) {
			dispatch(
				openOverlay({
					heading: {
						icon: moveFolderIcon("white"),
						title: "Move",
						data: file.entry.header.name,
					},
					component: (
						<FileExplorer
							action="move"
							item={file}
							initPath={res}
							onSuccessHandler={moveSuccessHandler}
						/>
					),
				}),
			);
		} else {
			dispatch(
				openOverlay({
					heading: {
						icon: move,
						title: "Move",
						data: file.entry.header.name,
					},
					component: (
						<FileExplorer
							action="move"
							item={file}
							onSuccessHandler={moveSuccessHandler}
						/>
					),
				}),
			);
		}
	};

	if (!saver.organization) throw new Error("Organization is missing!");

	const runSave = async (
		incrementVersion?: boolean,
		hardSave: boolean = false,
	) => {
		var res = false;
		var increment = false;

		if (incrementVersion || incrementNextSave.current) {
			increment = true;
		}

		res = await onSaveGuide(increment, hardSave);

		if (!res) {
			//save could not run
			saveIsQueued.current = true;
		} else {
			saveIsQueued.current = false;
			incrementNextSave.current = false;
		}
	};

	useEffectOnce(() => {
		if (videoEdits) {
			dispatch(addVideoEditHistory(videoEdits));
		}
	});

	const autoSaveGuide = async () => {
		// if (user && user.email?.includes("clueso")) {
		// 	//autosave is disabled if a Clueso admin is working on some other org's guide.
		// 	if (
		// 		saver.organization?.name === "Acme" ||
		// 		saver.organization?.name.includes("Clueso")
		// 	) {
		// 		//continue to auto save.
		// 	} else return;
		// }

		if (saver.user) {
			if (saver.user.email?.endsWith("@clueso.io") && saver.organization?.id !== "c7c44004-d4fa-429c-80c2-c3092b5acd5a") {
				return
			}
			else {
				if (debounceSaveTimer.current !== undefined) {
					clearTimeout(debounceSaveTimer.current);
				}

				debounceSaveTimer.current = setTimeout(runSave, 3000);
			}
		}

	};

	useEffect(() => {
		if (unsavedChanges) {
			//this ensures that the guide is not saved on simply refreshing.
			autoSaveGuide();
		}
	}, [unsavedChanges]);

	useEffect(() => {
		if (!backendBusy && saveIsQueued.current) {
			incrementNextSave.current = true;
			runSave();
		} else {
			//Backend is busy, so it will save. Clear our current save.
			clearTimeout(debounceSaveTimer.current);
		}
	}, [backendBusy]);

	useEffect(() => {
		incrementNextSave.current = true;
	}, [backendBusyIgnoreSave]);

	const onClickExportArticle = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: downloadIcon("white"),
					title: "Export Article",
				},
				component: <ExportArticle />,
			}),
		);
	};

	const onClickExportVideo = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: downloadIcon("white"),
					title: "Export Video",
				},
				component: <ExportVideo format="MP4" />,
			}),
		);
	};

	const onClickExportGif = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: downloadIcon("white"),
					title: "Export Gif",
				},
				component: <ExportVideo format="GIF" />,
			}),
		);
	};

	const onClickGetEmbed = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: embedIcon("white"),
					title: "Video Embed Code",
				},
				component: <ExportVideo format="Embed" />,
			}),
		);
	};

	const onClickGetViewOnlyLink = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: shareableLinkIcon("white"),
					title: "Copy view-only link",
				},
				component: <CopyLinkPopup />,
			}),
		);
	};

	const onClickPublishToHelpCenter = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: rocketIcon("white"),
					title: "Publish to my help center",
				},
				component: <PublishHelpCenter />,
			}),
		);
	};

	const onClickVersionHistory = () => {
		dispatch(
			openFullScreenOverlay({
				component: <VersionHistory />,
			}),
		);
	};

	const onDeleteGuide = (guide: GuidePreview) => {
		const submitDeleteFunction =
			async (resolve: any, reject: any) => {
				// deleteResult = await saver.deleteGuide(item.entry.id)
				const guidePreview = { ...guide };
				guidePreview.header = {
					...guidePreview.header,
					published: false,
				};
				guidePreview.deleted = true;
				guidePreview.isPinned = false;

				const deleteResult = await saver.guides.preview.update(guidePreview);

				var currentURL = window.location.href;

				// Check if the URL contains "/guide"
				window.location.reload()


				if (deleteResult) {
					resolve(true);
				} else {
					reject(false);
				}
			}


		confirmDelete(
			() => {
				const submitDeletePromise = new Promise<boolean>(submitDeleteFunction)

				toast.promise(submitDeletePromise, {
					success: { title: `Deleted 1 item successfully.` },
					error: {
						title: "Unexpected error while deleting item",
						description: "Please try again later.",
					},
					loading: { title: "Deleting ..." },
				});


				submitDeletePromise
					.then(() => {
						dispatch(deleteGuideFrontend(guide));
					})
					.catch(() => {
						logger.error("Error deleting guide");
					});


			},


			{
				item: guide.header.name,
			},
		);
	};

	const onDelete = () => {
		if (guidePreview) {
			onDeleteGuide(guidePreview)
		}
	}

	const onRestoreGuide = () => {
		if (!guidePreview) return;

		const submitRestore = async () => {
			try {
				// deleteResult = await saver.deleteGuide(item.entry.id)
				const newGuidePreview = { ...guidePreview };
				newGuidePreview.header = {
					...newGuidePreview.header,
				};
				newGuidePreview.deleted = false;
				const restoreResult = await saver.guides.preview.update(newGuidePreview);
				window.location.reload();
			} catch (e) {
				console.error("Couldn't delete file: ", e);
			}
		};

		confirmDelete(submitRestore, {
			action: "restore",
			item: guidePreview.header.name,
		});
	};

	const previewOriginalVideo = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: tapeIcon("white"),
					title: "Preview Original Recording",
				},
				component: <PreviewOriginalVideo />,
			}),
		);
	};

	const onTranslate = () => {
		if (!guidePreview) return;

		dispatch(
			openModal({
				heading: {
					icon: translateIcon("white"),
					title: "Magic Translate",
				},
				component: <TranslateGuide />,
			}),
		);
	};

	const onUpdate = async () => {
		await onSaveGuide(true);
		await chrome.runtime.sendMessage("hnfgakkbiefcdbkonflgfkadncclmpme", {
			type: "TriggerUpdate",
			token: await saver.getTokenPlatform(),
			guideId: id,
		});
		dispatch(setAutoUpdatingGuide(true));

		dispatch(closeOverlay());
	};

	const makeCopy = async () => {
		if (!id) return;

		dispatch(showLoadingMessagePopup("Duplicating..."));

		saver.guides.duplicate(id).then((resp) => {
			if (resp.ok) {
				window.open("/guide/" + resp.body.id, "_blank");
				dispatch(closeMessagePopup());
			} else {
				dispatch(
					showErrorMessagePopup("Duplicate failed. Please try again later."),
				);
			}
		});

		setShowMoreOptions(false);
	};

	const preOnUpdate = () => {
		dispatch(
			openOverlay({
				heading: {
					icon: updateIcon("white"),
					title: "Has this guide gone out-of-date?",
				},
				component: <PreUpdate onUpdate={onUpdate} updateStarted={false} />,
			}),
		);
	};

	const exportMenuItem: DisplayMenuItem = {
		label: "Export",
		icon: downloadIcon(),
		submenu: [
			{
				label: "Article",
				icon: readIcon(),
				handler: onClickExportArticle,
				closeMenuOnClick: true,
			},
			{
				label: "Video",
				icon: videoIcon("black"),
				handler: onClickExportVideo,
				closeMenuOnClick: true,
			},
			{
				label: "GIF",
				icon: gifIcon(),
				handler: onClickExportGif,
				closeMenuOnClick: true,
			},
		],
	};

	const fileMenu: DisplayMenuItem[] = [
		{
			label: "Version history",
			icon: versionsIcon(),
			handler: onClickVersionHistory,
			closeMenuOnClick: true,
		},
		{
			label: "Magic translate",
			icon: translateIcon(),
			handler: onTranslate,
			closeMenuOnClick: true,
		},
		{
			type: "separator",
		},
		{
			label: "Make a copy",
			handler: makeCopy,
			icon: copyIcon("#000000"),
			closeMenuOnClick: true,
		},
		{
			label: "Move to folder",
			handler: openFileContextMenu,
			icon: moveFolderIcon("#000000"),
			closeMenuOnClick: true,
		},
		exportMenuItem,
		{
			label: "Save",
			icon: savedIcon("#000000"),
			description: saveInProgress
				? "Saving ..."
				: unsavedChanges
					? "Unsaved changes"
					: "All changes saved",
			closeMenuOnClick: true,
			handler: () => onSaveGuide(true, true),
		},
		{
			type: "separator",
		},
		{
			label: "View original recording",
			icon: tapeIcon("#000000"),
			handler: previewOriginalVideo,
			closeMenuOnClick: true,
		},
		{
			label: "Delete project",
			icon: trashIcon("#000000"),
			handler: onDelete,
			closeMenuOnClick: true,
		},
		{
			type: "separator",
		},
		{
			label: "Help center",
			icon: helpCircleIcon(),
			href: "https://help.clueso.io",
			closeMenuOnClick: true,
		},
	];

	const shareMenu: DisplayMenuItem[] = [
		{
			label: "Get view-only link",
			icon: shareableLinkIcon(),
			closeMenuOnClick: true,
			handler: onClickGetViewOnlyLink,
		},
		exportMenuItem,
		{
			label: "Publish to my help center",
			icon: rocketIcon(),
			handler: onClickPublishToHelpCenter,
			closeMenuOnClick: true,
		},
		{
			label: "Get video embed code",
			icon: embedIcon(),
			handler: onClickGetEmbed,
			closeMenuOnClick: true,
		},
	];

	const moreOptions = useMemo<DisplayMenu>(() => {
		const initialMenuItems: DisplayMenuItem[] = [
			{
				label: "Make a copy",
				icon: copyIcon(),
				handler: async () => {
					if (!id) return;

					dispatch(showLoadingMessagePopup("Duplicating..."));

					saver.guides.duplicate(id).then((resp) => {
						if (resp.ok) {
							window.open("/guide/" + resp.body.id, "_blank");
							dispatch(closeMessagePopup());
						} else {
							dispatch(
								showErrorMessagePopup(
									"Duplicate failed. Please try again later.",
								),
							);
						}
					});

					setShowMoreOptions(false);
				},
			},

			{
				label: "Version history",
				icon: versionsIcon(),
				handler: onClickVersionHistory,
			},
			{
				label: "Move",
				icon: moveFolderIcon(),
				handler: () => {
					openFileContextMenu();
					setShowMoreOptions(false);
				},
			},
			{
				label: "Update guide",
				icon: updateIcon(),
				handler: () => {
					preOnUpdate();
					setShowMoreOptions(false);
				},
			},
		];

		const newMenuItems: DisplayMenuItem[] = [
			{
				label: "Translate Project",
				icon: translateIcon(),
				handler: onTranslate,
			},
			{
				label: "Export",
				icon: downloadIcon(),
				handler: onToggle,
			},
		];

		const footerMenuItems: DisplayMenuItem[] = [
			{
				type: "separator",
			},
			{
				label: "Delete project",
				icon: trashIcon(),
				handler: onDelete,
			},
		];

		return {
			menu:
				width > 1200
					? [...initialMenuItems, ...footerMenuItems]
					: [...initialMenuItems, ...newMenuItems, ...footerMenuItems],
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showMoreOptions, width]);

	var disableShare = false;
	var disableShareText = "";

	if (isPublishing) {
		disableShare = true;
		disableShareText = "Publishing ...";
	} else if (isExporting) {
		disableShare = true;
		disableShareText = "Exporting ...";
	}

	return (
		<div className="EditorPlatform-topbar-container">
			<div className="EditorPlatform-topbar">
				<div className="EditorPlatform-left">
					<div className="EditorPlatform-logo-container">
						{saver.basePlanStatus?.status !== BasePlanType.Pro ? (
							<img
								alt="logo"
								className="EditorPlatform-logo"
								onClick={() => window.open("/", "_blank")}
								src={LogoIcon}
							></img>
						) : (
							<Icon
								alt="logo"
								onClick={() => window.open("/", "_blank")}
								className="EditorPlatform-logo"
							>
								{LogoPro}
							</Icon>
						)}
					</div>

					{!guidePreview?.deleted && (
						<div
							className={`EditorPlatform-FileButton`}
							onClick={() => {
								setShowFileMenu(true);
							}}
						>
							File
						</div>
					)}

					<div
						className={`FileMenuBlocker ${showFileMenu || showShareMenu ? "active" : "inactive"}`}
					>
						.
					</div>

					{showFileMenu && !guidePreview?.deleted && (
						<Clickout
							closeFunction={() => {
								setShowFileMenu(false);
							}}
						>
							<div className="EditorTopbarMenuContainer FileMenuContainer">
								<Menu
									size="n"
									menu={fileMenu}
									spacing="s"
									closeDisplayMenu={() => {
										setShowFileMenu(false);
									}}
								/>
							</div>
						</Clickout>
					)}

					<div className="EditorPlatform-leftborder">.</div>

					<div className="EditorPlatform-GuideTitle">
						<input
							className="EditorPlatform-input EditorPlatform-title-input"
							placeholder="Enter a title..."
							value={guidePreview?.header.name}
							// style={{
							//     width: guidePreview?.header.name?.length ? `${guidePreview?.header.name.length * 8}px` : '100px'
							// }}
							onChange={onChangeTitle}
							disabled={guidePreview?.deleted}
						></input>
						{/* <input className='EditorPlatform-input EditorPlatform-description-input' 
                            placeholder={'Enter a description'}
                            value={guidePreview?.header.description}
                            onChange={onChangeDescription}></input> */}
					</div>
				</div>

				<div className="EditorPlatform-center">
					<SimpleTabs
						style="fullheight"
						size="s"
						theme="dark"
						tabs={
							props.videoTab
								? [
									{ text: "Video", icon: videoIcon },
									{ text: "Article", icon: readIcon },
								]
								: [{ text: "Article", icon: readIcon }]
						}
						activeTab={props.activeFormat}
						setActiveTab={(format: string) => {
							props.setActiveFormat(format as ActiveFormat);
						}}
					></SimpleTabs>
				</div>

				<div className="EditorPlatform-right">
					<Popover placement="left" isOpen={isOpen} onClose={onClose}>
						<div className="EditorPlatform-right-icons">
							{/* <Icon hoverTip='Delete Project' className="Editor-Topbar-trash sm-hide" onClick={onDeleteGuide} >{trashIcon("#ffa69e")}</Icon> */}

							<div className="TopbarSaving">
								<Icon
									hoverTip={
										saveInProgress
											? "Saving ..."
											: unsavedChanges
												? "Unsaved changes"
												: "All changes saved"
									}
									className="  Editor-Topbar-saved Editor-Topbar-help sm-hide"
								>
									{saveInProgress
										? savingIcon("#7B8189")
										: unsavedChanges
											? unsavedIcon("#7B8189")
											: savedIcon("#7B8189")}
								</Icon>
							</div>

							<a href="https://help.clueso.io" target="_blank">
								<Icon
									hoverTip="Help Center"
									className=" Editor-Topbar-icon Editor-Topbar-help sm-hide"
								>
									{helpCircleIcon(iconColor)}
								</Icon>
							</a>

							{!guidePreview?.deleted && (
								<Icon
									hoverTip="Translate Guide"
									className=" Editor-Topbar-icon Editor-Topbar-settings sm-hide"
									onClick={onTranslate}
								>
									{translateIcon(iconColor)}
								</Icon>
							)}

							{/* <Icon hoverTip='Guide settings' className='Editor-Topbar-settings' onClick={onClickSettings}>{settingsIcon(iconColor)}</Icon> */}

							{/* <PopoverTrigger >
                            <Icon onClick={backendBusy?undefined:onToggle} hoverTip={isOpen?undefined:'Export'} 
                                className={`Editor-Topbar-settings download-icon sm-hide ${backendBusy?"disabled-icon":"enabled-icon"}`}
                            >{downloadIcon(iconColor)}</Icon>
                        </PopoverTrigger> */}
							{/* 
                        <div
                                style={{
                                    position: "absolute",
                                    zIndex: "999",
                                    right: width > 1200 ? "250px" : "-10px",
                                    top: width > 1200 ? "40px" : "180px",
                                }}
                            >
                            <PopoverContent 
                                border='0' background={"transparent"}
                                width={"250px"} zIndex={999}
                            >
                                    <ExportDropdown onClickSharingLink={onClickPublish} onToggle={onToggle} />
                            </PopoverContent>
                        </div>                        
                         */}
							<>
								{/* <Icon hoverTip={showMoreOptions?undefined:'More options'} className="Editor-Topbar-settings" onClick={() => {setShowMoreOptions(true)}} >{dotsIcon(iconColor)}</Icon> */}

								{showMoreOptions ? (
									<div className="EditorTopbar-moreoptions">
										<Clickout
											closeFunction={() => {
												setShowMoreOptions(false);
											}}
										>
											<h2>More options</h2>

											<Menu
												menu={moreOptions.menu}
												spacing="s"
												closeDisplayMenu={() => { }}
											/>
										</Clickout>
									</div>
								) : null}
							</>
						</div>
					</Popover>

					<div className="EditorTopbar-buttons">
						{!guidePreview?.deleted && (
							<Cutton
								magicShadow
								leftIcon={shareIcon("white")}
								rightArrow
								onClick={() => {
									setShowShareMenu(true);
								}}
								loadingText={disableShareText}
								isLoading={disableShare}
							// disabled={backendBusy===true}
							>
								Share
							</Cutton>
						)}
						{guidePreview?.deleted && (
							<Cutton
								magicShadow
								onClick={() => {
									onRestoreGuide();
								}}
								loadingText={disableShareText}
								isLoading={disableShare}
							// disabled={backendBusy===true}
							>
								Restore from Trash
							</Cutton>
						)}
					</div>

					{/* <ProfileButton
						organization={saver.organization}
						size="s"
						environment="platform"
						dropdownMenu={{
							menu: [
								// {
								//     label: "My Organization",
								//     icon: settingsIcon(),
								//     closeMenuOnClick: true,
								//     handler: () =>{
								//         dispatch(openOverlay({
								//             heading: {
								//                 icon: settingsIcon(),
								//                 title: `Edit Organization`,
								//             },
								//             component: <OrganizationSettings />
								//         }))
								//     }
								// },
							],
						}}
					/> */}
				</div>

				{showShareMenu && (
					<Clickout
						closeFunction={() => {
							setShowShareMenu(false);
						}}
					>
						<div className="EditorTopbarMenuContainer ShareMenuContainer">
							<Menu
								size="n"
								direction="rtl"
								menu={shareMenu}
								spacing="s"
								closeDisplayMenu={() => {
									setShowShareMenu(false);
								}}
							/>
						</div>
					</Clickout>
				)}

				{showVideoExportPopup.showPopup ? (
					<ExportVideoProgress format={"MP4"} />
				) : null}

				{showGifExportPopup ? <ExportVideoProgress format={"GIF"} /> : null}
			</div>
		</div>
	);
};
export default EditorTopbar;
