import React, { useContext, useEffect } from "react";
import blurIcon from "@gigauser/common/src/assets/svgs/screenshot/blurIcon";
import { v4 as uuidv4 } from "uuid";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";

import Icon from "@gigauser/common/src/ui/Icon/Icon";
import "./ElementsCustomizer.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import CanvasPlayerContext from "@gigauser/common/src/core/canvas/CanvasPlayerContext";
import {
	ElementEdit,
	ElementGeo,
	TextData,
} from "@giga-user-fern/api/types/api/resources/video";
import { GigaUserApi } from "@giga-user-fern/api";
import {
	addElement,
	deleteElement,
	selectGuide,
	selectVideo,
	updateElement,
} from "@gigauser/common/src/redux/slices/guideSlice";
import { round10 } from "../../../../../../../features/export/ExportVideo/ExportVideo";
import { selectAnalytics } from "@gigauser/common/src/redux/slices/backendSlice";

import { setActiveElement } from "@gigauser/common/src/redux/slices/platformUiSlice";
import CDuration from "@gigauser/common/src/ui/video/components/CDuration/CDuration";
import { Tooltip, useToast } from "@chakra-ui/react";
import textIcon from "@gigauser/common/src/assets/svgs/videoeditor/textIcon";
import TextCustomizer from "./TextCustomizer/TextCustomizer";
import { computeTextboxHeight } from "@gigauser/common/src/core/canvas/canvasUtils/elements/canvasTextbox";
import rectangleShapeIcon from "@gigauser/common/src/assets/svgs/screenshot/rectangleShapeIcon";
import RectangleCustomizer from "./RectangleCustomizer/RectangleCustomizer";
import CSection from "../../layout/CSection/CSection";
import clockIcon from "@gigauser/common/src/assets/svgs/clockIcon";

type ElementsCustomizerProps = {};

const ElementsCustomizer: React.FC<ElementsCustomizerProps> = () => {
	const activeElementId = useAppSelector(
		(state) => state.platformUi.value.activeElement,
	);
	const cp = useContext(CanvasPlayerContext);
	const dispatch = useAppDispatch();
	const toast = useToast();
	const analytics = useAppSelector(selectAnalytics);

	const video = useAppSelector(selectVideo);
	const videoEdits = video?.videoEdits;
	const elements = useAppSelector(
		(state) => state.guide.value.guide?.guideData.video.videoEdits?.elements,
	);
	const guide = useAppSelector(selectGuide);

	const activeElement = videoEdits?.elements
		? videoEdits.elements.find((e) => e.id === activeElementId)
		: null;

	useEffect(() => {
		return () => {
			dispatch(setActiveElement(null));
		};
	}, []);

	const checkCanAddShape: () => null | {
		startTime: number;
		endTime: number;
	} = () => {
		if (!cp.vidRef) return null;

		const currentTime = cp.getUnadjustedTime(cp.currentTime);

		if (currentTime < 0 || currentTime > cp.videoDuration) {
			const errorMessage =
				currentTime > cp.videoDuration
					? "Can't add element on outro slide!"
					: "Can't add element on intro slide!";
			toast({
				title: errorMessage,
				description:
					"Please navigate to a point in the video to add an element.",
				status: "error",
				duration: 4000,
				isClosable: true,
				position: "top",
			});
			return null;
		}

		const startTime = Math.max(currentTime - 0.1, 0);
		const endTime = Math.min(currentTime + 2, cp.videoDuration);

		return { startTime, endTime };
	};

	const addNewElement = (geoType: ElementGeo) => {
		const res = checkCanAddShape();
		if (!res) return;

		const { startTime, endTime } = res;

		const newElement: ElementEdit = {
			id: GigaUserApi.Id(uuidv4()),
			geo: geoType,
			startTime,
			endTime,
			position: [0.2, 0.2],
			size: [0.6, 0.6],
		};

		const strokeColor = localStorage.getItem("RectangleCustomizerStrokeColor");
		const strokeWidth = parseInt(
			localStorage.getItem("RectangleCustomizerStrokeWidth") || "12",
		);

		if (geoType === "rectangle") {
			newElement.shapedata = {
				strokeColor: strokeColor || "#d43f8c",
				strokeWidth: strokeWidth,
			};
		}

		dispatch(addElement(newElement));
		dispatch(setActiveElement(newElement.id));
		analytics.captureEvent({
			eventName: "ElementAdded",
			value: { guide_id: guide?.id.toString() || "", details: newElement },
		});
	};

	const addNewBlur = () => addNewElement("blur");
	const addNewRectangle = () => addNewElement("rectangle");

	const addNewText = () => {
		if (!cp.vidRef || !cp.canvasRef?.current) return;

		const res = checkCanAddShape();
		if (!res) return;
		const { startTime, endTime } = res;

		const defaultTextData = JSON.parse(
			localStorage.getItem("textData") || "{}",
		) as TextData;

		const text = "Hello world";
		const lines = [text];
		const fontSize = defaultTextData.fontSize || 84;
		const newHeight_f = computeTextboxHeight(
			cp.canvasRef.current,
			lines,
			fontSize,
		);

		const ctx = cp.canvasRef.current.getContext(
			"2d",
		) as CanvasRenderingContext2D;
		const newWidth = ctx.measureText(text).width + 100;
		const newWidth_f = newWidth / cp.canvasRef.current.width;

		const newText: ElementEdit = {
			id: GigaUserApi.Id(uuidv4()),
			geo: "text",
			startTime,
			endTime,
			position: [0.2, 0.2],
			size: [newWidth_f, newHeight_f],
			textdata: {
				text,
				lines,
				fontSize,
				alignment: defaultTextData.alignment || "left",
				backgroundColor: defaultTextData.backgroundColor || "#000000",
				backgroundOpacity:
					defaultTextData.backgroundOpacity == undefined
						? 50
						: defaultTextData.backgroundOpacity,
				textColor: defaultTextData.textColor || "#ffffff",
				font: defaultTextData.font || "Inter",
			},
		};

		analytics.captureEvent({
			eventName: "TextboxAdded",
			value: { guide_id: guide?.id.toString() || "" },
		});

		dispatch(addElement(newText));
		dispatch(setActiveElement(newText.id));
	};

	const setTimelineToElement = (element?: ElementEdit) => {
		if (!videoEdits) return;

		const targetElement = element || activeElement;
		if (!targetElement) return;

		const time = (targetElement.startTime + targetElement.endTime) / 2;
		cp.hardsetCurrentTime(cp.getAdjustedTime(time), true);
	};

	const onDeleteElement = () => {
		if (!activeElement) return;
		analytics.captureEvent({
			eventName: "ElementDeleted",
			value: { guide_id: guide?.id.toString() || "", details: activeElement },
		});
		dispatch(deleteElement(activeElement.id));
		dispatch(setActiveElement(null));
	};

	const onUpdateElementStart = (newStartTime: number) => {
		if (!activeElement) return;

		const newElement: ElementEdit = { ...activeElement };
		newElement.startTime = Math.max(newStartTime - 0.01, 0);
		analytics.captureEvent({
			eventName: "ElementUpdated",
			value: { guide_id: guide?.id.toString() || "", details: newElement },
		});
		dispatch(updateElement(newElement));
	};

	const onUpdateElementEnd = (newEndTime: number) => {
		if (!activeElement) return;

		const newElement: ElementEdit = { ...activeElement };
		newElement.endTime = Math.min(newEndTime + 0.01, cp.videoDuration);
		analytics.captureEvent({
			eventName: "ElementUpdated",
			value: { guide_id: guide?.id.toString() || "", details: newElement },
		});
		dispatch(updateElement(newElement));
	};

	const shapes = [
		{ label: "Blur", icon: blurIcon("white"), handler: addNewBlur },
		{
			label: "Rectangle",
			icon: rectangleShapeIcon("white"),
			handler: addNewRectangle,
		},
		{ label: "Text box", icon: textIcon("white"), handler: addNewText },
	];

	const icons: Record<
		GigaUserApi.video.ElementGeo,
		(color?: string) => JSX.Element
	> = {
		blur: blurIcon,
		text: textIcon,
		rectangle: textIcon,
	};

	if (!activeElement) {
		return (
			<CSection>
				<div className="ElementsCustomizer">
					<div className="C-label">+ New Element</div>

					<div className="newshape-buttons">
						{shapes.map((shape) => (
							<Tooltip
								key={shape.label}
								placement="bottom"
								borderRadius={6}
								bg="#303030"
								className="editor-button-tooltip"
								color="white"
								label={shape.label}
							>
								<div className="newshape" onClick={shape.handler}>
									<Icon className="new-blur-icon">{shape.icon}</Icon>
								</div>
							</Tooltip>
						))}
					</div>

					{elements?.length && (
						<>
							<div className="Zooms-List">
								{[...elements]
									.sort((a, b) => a.startTime - b.startTime)
									.map((ele) => (
										<div
											key={ele.id}
											className="zoom-item-container"
											onClick={() => {
												setTimelineToElement(ele);
												dispatch(setActiveElement(ele.id));
											}}
											onDoubleClick={() => {
												setTimelineToElement(ele);
											}}
										>
											<Icon className="Zooms-element-icon">
												{icons[ele.geo]("white")}
											</Icon>
											<b>{ele.geo?.toUpperCase()}&nbsp;</b>at
											<b>
												&nbsp;
												{round10(cp.getAdjustedTime(ele.startTime))}s
											</b>
										</div>
									))}
							</div>
						</>
					)}
				</div>
			</CSection>
		);
	} else {
		return (
			<div className="ElementsCustomizer">
				<CSection>
					<div
						className="ZoomCustomizer-esc"
						onClick={() => {
							dispatch(setActiveElement(null));
						}}
					>
						← Back
					</div>

					<div className="ZoomCustomizerDelete">
						<div className="C-label">Delete {activeElement.geo}</div>

						<Icon className="zoom-delete" onClick={onDeleteElement}>
							{trashIcon("#db5461")}
						</Icon>
					</div>
				</CSection>

				{activeElement.geo === "text" ? (
					<TextCustomizer element={activeElement} />
				) : activeElement.geo === "rectangle" ? (
					<RectangleCustomizer element={activeElement} />
				) : null}

				<CSection label="Duration" icon={clockIcon}>
					<CDuration
						startTime={activeElement.startTime}
						endTime={activeElement.endTime}
						setStartTime={onUpdateElementStart}
						setEndTime={onUpdateElementEnd}
					/>
				</CSection>
			</div>
		);
	}
};

export default ElementsCustomizer;
