import { useEffect, useState } from "react"
import "./HostingDetailsPage.css"
import { saver } from "@gigauser/common/src/network/saver"
import GridLoader from "@gigauser/common/src/ui/loaders/GridLoader/GridLoader"
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton"
import { CustomDomainSetupRequest, CustomDomainSetupResponse, GetDnsStatusResponse } from "@giga-user-fern/api/types/api/resources/helpcenter"
import { useAppDispatch } from "@gigauser/common/src/redux"
import { closeOverlay, openOverlay } from "@gigauser/common/src/layouts/Overlay"
import TitledInput from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput"
import { showErrorMessagePopup, showMessagePopup } from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice"
import { isValidDomain } from "@gigauser/common/src/utils/stringOperations"
import HoverTip from "@gigauser/common/src/ui/HoverTip/HoverTip"
import { CSVLink } from "react-csv"
import useSuperAdmin from "@gigauser/common/src/hooks/useSuperAdmin"
const ConfirmSubDomainChange = ({ newRecord, oldRecord, refreshDnsRecords }: { newRecord: GetDnsStatusResponse["hostname"]; oldRecord: GetDnsStatusResponse["hostname"]; refreshDnsRecords: () => void }) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const handleSubdomainChange = (newRecord: GetDnsStatusResponse["hostname"]) => {
    setIsLoading(true)
    saver.updateDnsRecord({ newRecord }).then((resp) => {
      if (resp.ok) {
        dispatch(showMessagePopup("Subdomain successfully updated!"))
      } else {
        dispatch(showErrorMessagePopup("Error while updating subdomain! Please try after some time."))
      }
      setIsLoading(false)
      refreshDnsRecords()
      dispatch(closeOverlay())
    })
  }
  return <>This is a breaking change!<br />Post this change all the old url's will stop working...<br />
    <Cutton disabled={(newRecord === oldRecord) && isLoading} onClick={() => handleSubdomainChange(newRecord)} children="Change Subdomain" isLoading={isLoading} />
  </>
}
const ChangeSubDomain = ({ record_name, refreshDnsRecords }: { record_name: GetDnsStatusResponse["hostname"]; refreshDnsRecords: () => void }) => {
  const [isSubDomainValid, setIsSubDomainValid] = useState<boolean>(true)
  const [isValidationLoading, setIsValidationLoading] = useState<boolean>(false)
  const [subDomain, setSubDomain] = useState<GetDnsStatusResponse["hostname"]>(record_name.split(".")[0])
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (subDomain !== record_name) {
      setSubDomain(record_name.split(".")[0])
    }
  }, [record_name])
  useEffect(() => {
    setIsValidationLoading(true)
    handleValidationForSubDomain(subDomain)
  }, [subDomain])
  const handleValidationForSubDomain = (subDomain: string) => {
    if (subDomain.length < 3) {
      setIsSubDomainValid(false)
      return
    }
    saver.validateDnsRecord({ newRecord: `${subDomain}.clueso.io` }).then((resp) => {
      if (resp.ok) {
        setIsSubDomainValid(resp.body)
      }
      setIsValidationLoading(false)
    })
  }
  const handleChangeSubdomain = (newRecord: string) => {
    dispatch(openOverlay({
      heading: {
        title: "Are you sure?",
        icon: <></>
      },
      component: <ConfirmSubDomainChange newRecord={newRecord} oldRecord={record_name.split(".")[0]} refreshDnsRecords={refreshDnsRecords} />
    }))
  }
  return <div className="changeSubDomain-mainContainer">
    <TitledInput label="Subdomain" id="" value={subDomain} onChange={(e) => { setSubDomain((e.target as any).value.trim().toLowerCase().replace(/[^a-z0-9-]+/g, '-')) }} />
    {
      isSubDomainValid ? <p className="validationForHostname-green">The subdomain is valid and available for use.</p> : <p className="validationForHostname-red">The subdomain is not available for use.</p>
    }
    <Cutton disabled={!isSubDomainValid || isValidationLoading || record_name.split(".")[0] === subDomain} isLoading={isValidationLoading} children="Confirm Change" onClick={() => handleChangeSubdomain(subDomain)} />
  </div>
}

const ShowVerificationDetails = ({ domain, details, refreshDnsRecords }: { domain: CustomDomainSetupRequest["domain"], details: CustomDomainSetupResponse, refreshDnsRecords: () => void }) => {
  const dispatch = useAppDispatch()
  const [isVerifyLoading, setIsVerifyLoading] = useState<boolean>(false)
  const handleVerifyNow = () => {
    setIsVerifyLoading(false)
    saver.verifyDnsSetup({ domain }).then((resp) => {
      if (resp.ok) {
        dispatch(showMessagePopup(resp.body))
        dispatch(closeOverlay())
      } else {
        dispatch(
          showErrorMessagePopup("Error while verifying DNS setup! Please contact someone at clueso.")
        )
      }
      setIsVerifyLoading(true)
      refreshDnsRecords()
      dispatch(closeOverlay())
    })
  }
  return <div className="verificationSteps-mainContainer">
    <div className="verificationSteps-Container">
      <p>Validation Record</p>
      <div className="verificationSteps-cnameConfigRow">
        <span>Name</span>
        <HoverTip hoverTipTitle="Click to copy">

          <div className="verificationSteps-cnameConfigRow-ClipBox" onClick={() => {
            navigator.clipboard.writeText(details.sslValidationEntry.name)
          }}>
            <span>
              {
                details.sslValidationEntry.name
              }
            </span>
          </div>
        </HoverTip>
      </div>
      <div className="verificationSteps-cnameConfigRow">
        <span>Value</span>
        <HoverTip hoverTipTitle="Click to copy">

          <div className="verificationSteps-cnameConfigRow-ClipBox" onClick={() => {
            navigator.clipboard.writeText(details.sslValidationEntry.value)
          }}>
            <span>
              {
                details.sslValidationEntry.value
              }
            </span>
          </div>
        </HoverTip>
      </div>
      <div className="verificationSteps-cnameConfigRow">
        <span>Type</span>
        <HoverTip hoverTipTitle="Click to copy">
          <div className="verificationSteps-cnameConfigRow-ClipBox" onClick={() => {
            navigator.clipboard.writeText(details.sslValidationEntry.type)
          }}>
            <span>
              {
                details.sslValidationEntry.type
              }
            </span>
          </div>
        </HoverTip>
      </div>
    </div>
    <div className="verificationSteps-Container">
      <p>Domain Record</p>
      <div className="verificationSteps-cnameConfigRow">
        <span>Name</span>
        <HoverTip hoverTipTitle="Click to copy">

          <div className="verificationSteps-cnameConfigRow-ClipBox" onClick={() => {
            navigator.clipboard.writeText(details.domainEntry.name)
          }}>
            <span>
              {
                details.domainEntry.name
              }
            </span>
          </div>
        </HoverTip>
      </div>
      <div className="verificationSteps-cnameConfigRow">
        <span>Value</span>
        <HoverTip hoverTipTitle="Click to copy">

          <div className="verificationSteps-cnameConfigRow-ClipBox" onClick={() => {
            navigator.clipboard.writeText(details.domainEntry.value)
          }}>
            <span>
              {
                details.domainEntry.value
              }
            </span>
          </div>
        </HoverTip>
      </div>
      <div className="verificationSteps-cnameConfigRow">
        <span>Type</span>
        <HoverTip hoverTipTitle="Click to copy">
          <div className="verificationSteps-cnameConfigRow-ClipBox" onClick={() => {
            navigator.clipboard.writeText(details.domainEntry.type)
          }}>
            <span>
              {
                details.domainEntry.type
              }
            </span>
          </div>
        </HoverTip>
      </div>
    </div>
    <div className="verficationSteps-VerifyNow">
      <div className="verificationSteps-RecordOptions">
        <CSVLink filename={`${domain}-${(new Date()).toLocaleString()}.csv`} data={[["name", "type", "value"], [details.sslValidationEntry.name, details.sslValidationEntry.type, details.sslValidationEntry.value], [details.domainEntry.name, details.domainEntry.type, details.domainEntry.value]]}> <Cutton children="Export to CSV" /></CSVLink>
        <Cutton onClick={() => {
          navigator.clipboard.writeText(`${details.sslValidationEntry.name} ${details.sslValidationEntry.type} ${details.sslValidationEntry.value}\n${details.domainEntry.name} ${details.domainEntry.type} ${details.domainEntry.value}`)
        }}>Copy Records</Cutton>
      </div>
      <Cutton onClick={() => { handleVerifyNow() }} isLoading={isVerifyLoading} disabled={isVerifyLoading} children="Verify Now" />
    </div>
  </div>
}

const SetupCustomDomain = ({ refreshDnsRecords }: { refreshDnsRecords: () => void }) => {
  const dispatch = useAppDispatch()

  const [isDomainValid, setIsDomainValid] = useState<boolean>(true)
  const [isValidationLoading, setIsValidationLoading] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [customDomain, setCustomDomain] = useState<CustomDomainSetupRequest["domain"]>("")
  useEffect(() => {
    setIsValidationLoading(true)
    validateCustomDomain()
  }, [customDomain])
  const validateCustomDomain = () => {
    if (isValidDomain(customDomain)) {
      saver.validateDnsRecord({ newRecord: customDomain }).then((resp) => {
        if (resp.ok) {
          setIsDomainValid(resp.body)
        } else {
          setIsDomainValid(false)
        }
      })
    }
    else {
      setIsDomainValid(false)
    }
    setIsValidationLoading(false)
  }

  const handleSetupCustomDomain = (custom_domain: CustomDomainSetupRequest["domain"]) => {
    if (isDomainValid) {
      setIsValidationLoading(true)
      saver.setupCustomDomain({ domain: custom_domain }).then((resp) => {
        if (resp.ok) {
          dispatch(showMessagePopup("Domain setup initiated successfully! Proceed to CNAME setup inorder to verify the domain ownership!"))
          refreshDnsRecords()
          dispatch(openOverlay({
            heading: {
              title: "Proceed with following steps...",
              icon: undefined
            },
            component: <ShowVerificationDetails domain={custom_domain} details={resp.body} refreshDnsRecords={refreshDnsRecords} />
          }))

        } else {
          if (resp.error.error === "RecordAlreadyInUseError") {
            dispatch(showErrorMessagePopup("Domain already in use, Please contact someone at clueso!"))
          }
          else {
            dispatch(showErrorMessagePopup("Encountered an error while setup! Please try again later."))
          }
        }
        setIsValidationLoading(false)
      })
    } else {
      dispatch(showErrorMessagePopup("Please enter a valid domain."))
    }
  }

  return <div className="changeSubDomain-mainContainer">
    <TitledInput label="Custom Domain" id="" value={customDomain} onChange={(e) => { setCustomDomain((e.target as any).value.trim().toLowerCase()) }} />
    {
      isDomainValid ? <p className="validationForHostname-green">The custom domain is valid and available for use.</p> : <p className="validationForHostname-red">{isValidDomain(customDomain) ? "The custom domain is not available for use." : "The custom domain is not valid."}</p>
    }
    <Cutton disabled={!isDomainValid || isValidationLoading} isLoading={isValidationLoading} children="Add Domain" onClick={() => handleSetupCustomDomain(customDomain)} />
  </div>
}

export const HostingDetailsPage = () => {
  const isSuperAdmin = useSuperAdmin()
  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [dnsStatusData, setDnsStatusData] = useState<GetDnsStatusResponse[]>([])
  const dispatch = useAppDispatch()
  useEffect(() => {
    handleGetDnsStatus()
  }, [])

  const handleGetDnsStatus = () => {
    saver.getDnsStatus().then((resp) => {
      if (resp.ok) {
        setDnsStatusData(resp.body)
        initialLoading && setInitialLoading(false)
        setIsLoading(false)
      }
    })
  }

  const handleSetupCustomDomain = () => {
    dispatch(
      openOverlay({
        heading: {
          title: "Setup Custom Domain",
          icon: <></>,
        },
        component: <SetupCustomDomain refreshDnsRecords={handleGetDnsStatus} />
      })
    )
  }
  const handleCluesoSubdomainChange = (record_name: GetDnsStatusResponse["hostname"]) => {
    dispatch(
      openOverlay({
        heading: {
          title: "Customize Subdomain",
          icon: <></>
        },
        component: <ChangeSubDomain record_name={record_name} refreshDnsRecords={handleGetDnsStatus} />
      })
    )
  }
  return <div>
    <h2 className="hostingDetailsPage-title">Hosting Details</h2>
    {
      isSuperAdmin("helpcenterhosting") ? <div className="hostingDetailsPage-mainContainer">
        {
          isLoading ? <GridLoader /> :
            <div className="hostingDetailsPage-mainContainerDiv">
              {
                !(dnsStatusData.find((entry) => entry.hostname.includes("clueso.io")) && dnsStatusData.find((entry) => !entry.hostname.includes("clueso.io"))) && <Cutton children="Setup Custom Domain" onClick={() => { handleSetupCustomDomain() }} />
              }
              <table>
                <tr>
                  <th>Hostname</th>
                  <th>Status</th>
                  <th></th>
                </tr>
                {
                  dnsStatusData.map((dnsStatusEntry, i) => {
                    return <tr key={dnsStatusEntry.hostname + i}>
                      <td>{dnsStatusEntry.hostname}</td>
                      <td>{dnsStatusEntry.status}</td>
                      <td>
                        {dnsStatusEntry.hostname.includes("clueso.io") && <Cutton size={"s"} children="Customize" onClick={() => handleCluesoSubdomainChange(dnsStatusEntry.hostname)} />}
                        {dnsStatusEntry.verificationDetails && <Cutton size={"s"} onClick={() => {
                          dispatch(openOverlay({
                            heading: {
                              title: "Proceed with following steps...",
                              icon: <></>
                            },
                            component: <ShowVerificationDetails domain={dnsStatusEntry.hostname} refreshDnsRecords={handleGetDnsStatus} details={dnsStatusEntry.verificationDetails as CustomDomainSetupResponse} />
                          }))
                        }} children="Show Details" />}
                      </td>
                    </tr>
                  })
                }
              </table>
            </div>
        }
      </div> : <div className="hostingDetailsPage-mainContainer">
        <div className="hostingDetailsPage-mainContainerDiv">
          <p>Your help center is public!</p>
          <p>The ability to view and manage your hosting configuration is soon coming to platform. Please reach out to Clueso for support.</p>
        </div>
      </div>
    }

  </div>
}