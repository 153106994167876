import { saver } from "@gigauser/common/src/network/saver";
import { useEffect, useState } from "react";
import "./ProfileSettings.css";
import PageHeader from "../../../home/components/Header/laptop/PageHeader/PageHeader";
import profileIcon from "@gigauser/common/src/assets/svgs/home/profileIcon";
import TitledInput from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import { Flex } from "@chakra-ui/react";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import penIcon from "@gigauser/common/src/assets/svgs/screenshot/penIcon";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import { useAppSelector } from "@gigauser/common/src/redux";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import { User } from "@giga-user-fern/api/types/api";

export default function ProfileSettings() {

	const saver = useAppSelector(selectSaver);
	const [user, setUser] = useState<User | null>(
		saver.settings.profile.userData(),
	);

    console.log("user: ", user)

	const [name, setName] = useState<string>(user?.name || "");

	const onChangeName = (e: any) => {
		setName(e.target.value);
	};

	return (
		<div className="ProfileSettings">
			<div className="ProfileSettings-container">
				<PageHeader
					title="Profile"
					description="Customize your personal profile"
					icon={profileIcon}
				/>

				<div className="ProfileSettings-contents">
					<div className={`ProfilePicture ${user?.pictureUrl ? "image":""}`} style={{
                        backgroundImage: `url(${user?.pictureUrl})`
                        
                    }}>
						{name.charAt(0).toUpperCase()}
						{/* <Icon className="edit-icon">{penIcon("white")}</Icon> */}
					</div>

					<form autoComplete="off" className="ProfileSettings-inputs">
						<Flex className="input-row">
							<label className="title">Name</label>
							<TitledInput
								disabled
								id="name"
								value={name}
								onChange={onChangeName}
							/>
						</Flex>

						<Flex className="input-row">
							<label className="title">Email</label>
							<TitledInput
								id="email"
								value={user?.email || ""}
								disabled
								onChange={() => {}}
							/>
						</Flex>

						<Flex className="input-row">
							<label className="title">Password</label>
							<Cutton disabled rank="tertiary" onClick={() => {}}>
								Change Password
							</Cutton>
						</Flex>
					</form>

					<div className="comment">
						Contact support to change these settings
					</div>

					{/* <Flex gap="0.5rem" justifyContent={"right"} marginTop={"2rem"}>
						<Cutton rank="tertiary" onClick={() => {}}>
							Restore
						</Cutton>

						<Cutton rank="primary" onClick={() => {}}>
							Save Changes
						</Cutton>
					</Flex> */}
				</div>
			</div>
		</div>
	);
}
