import { ConfigProvider, MappingAlgorithm, Select } from "antd";
import React from "react";
import downArrowheadIcon from "../../assets/svgs/downArrowheadIcon";
import "./CSelect.css";
import { ComponentToken } from "antd/es/select/style";
import { AliasToken } from "antd/es/theme/internal";

type CSelectProps = {
	disabled?: boolean;
	onChange: (value: any) => void;
	mode?: "multiple" | "single";
	value: any;
	optionRender?: (value: any) => React.ReactNode;
	placeholder?: string;
	allowClear?: boolean;
	suffixIcon?: React.ReactNode;
	options: {
		value: unknown;
		label: unknown;
	}[];
	theme?: "blue" | "green" | "red";
};

const CSelect: React.FC<CSelectProps> = (props) => {
	const mode = props.mode
		? props.mode === "single"
			? undefined
			: "multiple"
		: undefined;

	let backgroundColor;
	let colorPrimaryHover;

	switch (props.theme) {
		case "blue":
			backgroundColor = "rgba(97, 183, 255, 0.1)";
			colorPrimaryHover = "#61B7FF";
			break;
		case "red":
			backgroundColor = "#E179AF14";
			colorPrimaryHover = "#E179AF";
			break;
		default:
			backgroundColor = "rgb(13,17,23)";
			colorPrimaryHover = "hsl(230, 20%, 35%)";
			break;
	}

	return (
		<ConfigProvider
			theme={{
				components: {
					Select: {
						colorPrimary: "hsl(230, 20%, 35%)",
						colorPrimaryHover: colorPrimaryHover,
						selectorBg: backgroundColor,
						colorBorder: "hsl(226, 16%, 13%)",
						optionSelectedBg: "hsl(226, 16%, 13%)",
						colorBgElevated: "rgb(13,17,23)",
						borderRadius: 6,
						zIndexPopup: 99999,
						fontFamily: "Manrope",
					},
				},
			}}
		>
			<div className={`CSelect ${props.theme}`} style={{ width: "100%" }}>
				{!props.disabled ? (
					<Select
						mode={mode}
						value={props.value}
						options={props.options}
						placeholder={props.placeholder}
						onChange={props.onChange}
						style={{
							width: "100%",
						}}
						optionRender={props.optionRender}
						allowClear={props.allowClear}
						suffixIcon={downArrowheadIcon("white")}
					/>
				) : (
					<div
						className={`CSelect-disabled`}
						style={{
							backgroundColor,
						}}
					>
						{props.value}
					</div>
				)}
			</div>
		</ConfigProvider>
	);
};

export default CSelect;
