import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../useAppRedux";
import { selectSaver } from "../../slices/backendSlice";
import { setOriginalVideoSrc } from "../../slices/guideSlice";

const useRefreshVideo = () => {
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);
	const id = useAppSelector((state) => state.guide.value?.guide?.id);
	return async () => {
		// Refresh all the sources in video
		if (id) {
			const newGuideData = await saver.guides.data.get(id);
			if (newGuideData) {
				const originalVideoSrc = newGuideData.video.originalSrc;
				const generatedVideoSrc = newGuideData.video.generatedSrc;
				console.log("SETTING NEW VIDEO SRC");
				if (originalVideoSrc) dispatch(setOriginalVideoSrc(originalVideoSrc));
				if (generatedVideoSrc) dispatch(setOriginalVideoSrc(generatedVideoSrc));
			}
		}
	};
};

export default useRefreshVideo;
