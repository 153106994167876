import {
	AnalyticsForNewUser,
	AnalyticsForSignupWorkflow,
	CreateOrganizationDetails,
} from "@giga-user-fern/api/types/api/resources/organizations";
import { saver } from "@gigauser/common/src/network/saver";
import React, { useEffect, useReducer, useState } from "react";
import { FormState } from "@gigauser/common/src/layouts/Form/Form";
import { useEffectOnce } from "@gigauser/common/src/hooks/useEffectOnce";
import { useNavigate } from "react-router-dom";
import TitledInput from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import { isValidPersonalDetails } from "../PersonalDetailsForm/PersonalDetailsForm";
import AuthFormLayout from "../AuthFormLayout/AuthFormLayout";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import FileUpload from "@gigauser/common/src/ui/inputs/file/FileUpload/FileUpload";
import MCQColorPicker, {
	MCQColorOption,
} from "@gigauser/common/src/ui/inputs/MCQColorPicker/MCQColorPicker";

// function CreateOrganizationForm({
// 	organizationDetails,
// 	setOrganizationDetails,
// 	setCurrentTab,
// }: {
// 	organizationDetails: CreateOrganizationDetails;
// 	setOrganizationDetails: React.Dispatch<CreateOrganizationDetails>;
// 	setCurrentTab?: React.Dispatch<"analytic">;
// }) {
// 	return (
// 		<>
// 			{setCurrentTab && (
// 				<button onClick={() => setCurrentTab("analytic")}>Go Back</button>
// 			)}
// 			<h2>Create Workspace</h2>
// 			<div>
// 				<label>Workspace Name</label>
// 				<input
// 					type="text"
// 					value={organizationDetails.name}
// 					onChange={(e) =>
// 						setOrganizationDetails({
// 							...organizationDetails,
// 							name: e.target.value,
// 						})
// 					}
// 				/>
// 			</div>
// 			<br />
// 			<div>
// 				<label>Brand Color</label>
// 				<br />
// 				<input
// 					type="color"
// 					value={organizationDetails.brandColor}
// 					onChange={(e) =>
// 						setOrganizationDetails({
// 							...organizationDetails,
// 							brandColor: e.target.value,
// 						})
// 					}
// 				/>
// 			</div>
// 			<br />
// 			<div>
// 				<label>Brand Logo</label>
// 				<br />
// 				<input
// 					type="file"
// 					accept=".png,.svg"
// 					onChange={(e) => {
// 						if (e.target.files) {
// 							const fileReader = new FileReader();
// 							fileReader.onload = (event) => {
// 								setOrganizationDetails({
// 									...organizationDetails,
// 									logoFile: event.target?.result as string,
// 								});
// 							};
// 							fileReader.readAsDataURL(e.target.files[0]);
// 						}
// 					}}
// 				/>
// 				<img src={organizationDetails.logoFile} alt="No Logo Selected" />
// 			</div>
// 			<br />
// 			<div>
// 				<label>
// 					Can anyone with your org domain join this workspace without approval?
// 				</label>
// 				<input
// 					type="checkbox"
// 					checked={organizationDetails.openDoor}
// 					onChange={(e) =>
// 						setOrganizationDetails({
// 							...organizationDetails,
// 							openDoor: e.target.checked,
// 						})
// 					}
// 				/>
// 			</div>
// 			<br />
// 			<div>
// 				<label>Can any one other than your org domain join?</label>
// 				<input
// 					type="checkbox"
// 					checked={organizationDetails.canExternalMemberJoin}
// 					onChange={(e) =>
// 						setOrganizationDetails({
// 							...organizationDetails,
// 							canExternalMemberJoin: e.target.checked,
// 						})
// 					}
// 				/>
// 			</div>
// 		</>
// 	);
// }

type CreateOrganizationProps = {
	personalDetails: AnalyticsForNewUser;
};

const CreateOrganization: React.FC<CreateOrganizationProps> = (props) => {
	const navigate = useNavigate();

	const [logoFile, setLogoFile] = useState<File | null>(null);
	const [submitting, setSubmitting] = useState(false);

	useEffectOnce(() => {
		//the user must have come here via URL directly without filling personal details
		if (!isValidPersonalDetails(props.personalDetails)) {
			navigate("/welcome/personal-details");
		}
	});

	const onChangeLogo = (file: File) => {
		setLogoFile(file);
		const fileReader = new FileReader();
		fileReader.onload = (event) => {
			dispatchFormChange({
				key: "logoFile",
				value: event.target?.result as string,
			});
			// setOrganizationDetails({
			// 	...organizationDetails,
			// 	logoFile: event.target?.result as string,
			// });
		};
		fileReader.readAsDataURL(file);
	};

	var initialFormState: CreateOrganizationDetails = {
		name: "",
		brandColor: "#000000",
		openDoor: false,
		canExternalMemberJoin: false,
		logoFile: "",
	};

	const onSubmitCreateOrganization = async () => {
		if (formState.name === "") {
			console.log("formState: ", formState);
			alert("Enter all details!");
		}
		else {
			console.log("formState: ", formState);

			const resp = await saver.createOrganization({
				organizationDetails: formState,
				analyticalData: {
					userRelatedDetails: props.personalDetails,
					orgRelatedDetails: {},
				},
			});
			if (resp.ok) {
				localStorage.setItem("organization_id", resp.body.id);
				window.location.replace("/home");
			} else {
				alert("Error while creating org!");
			}
		}

		setSubmitting(false);
	};

	const formStateReducer = (
		formState: CreateOrganizationDetails,
		action: {
			key: keyof CreateOrganizationDetails;
			value: string | boolean;
		},
	) => {
		var formStateCopy = { ...formState };

		//@ts-ignore
		formStateCopy[action.key] = action.value;
		return formStateCopy;
	};

	const [formState, dispatchFormChange] = useReducer(
		formStateReducer,
		initialFormState,
	);

	return (
		<AuthFormLayout
			stepNumber={2}
			title="Create a new team"
			subtitle="A team is a shared workspace with your collaborators"
		>
			<div className="CreateOrganization">
				<TitledInput
					id="name"
					label="Team Name"
					placeHolder="Eg: Acme, Acme Marketing"
					value={formState.name}
					onChange={(e: any) =>
						dispatchFormChange({ key: "name", value: e.target.value })
					}
				></TitledInput>

				<div className="AuthForm-section">
					<div className="AuthForm-title">Company or team logo</div>
					<div className="AuthForm-subtitle">
						This will be used to brand your videos
					</div>

					<FileUpload
						fileTypes={[".png, .svg"]}
						file={logoFile}
						onChange={onChangeLogo}
					/>
				</div>

				<div className="AuthForm-section space-down">
					<div className="AuthForm-title">Brand Color</div>
					<div className="AuthForm-subtitle">
						We'll create a custom video template for you!
					</div>

					<MCQColorPicker
						color={(formState.brandColor || "#d43f8d") as MCQColorOption}
						setColor={(c) => {
							dispatchFormChange({
								key: "brandColor",
								value: c,
							});
						}}
					/>
				</div>

				<Cutton
					disabled={!formState.name}
					isLoading={submitting}
					onClick={() => {
						setSubmitting(true)
						onSubmitCreateOrganization()
					}}
				>
					Continue to Clueso →
				</Cutton>
			</div>
		</AuthFormLayout>
	);

	// return (
	// 	<>
	// 		<br />
	//
	//
	// 		<br />
	// 		<br />

	// 		<br />
	//
	// 		)}
	// 		<br />

	// 		{currentTab === "orgDetails" && (
	// 			<button
	// 				onClick={}
	// 			>
	// 				Create Workspace
	// 			</button>
	// 		)}

	// 		<div>
	// 			{navigateToJoinWorkspace && (
	// 				<button
	// 					onClick={() => {
	// 						window.location.replace("/workspace/join");
	// 					}}
	// 				>
	// 					Join Workspace
	// 				</button>
	// 			)}
	// 		</div>
	// 	</>
	// );
};

export default CreateOrganization;
