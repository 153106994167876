import React from "react";
import { useState } from "react";
import folder_color from "@gigauser/common/src/assets/legacy_assets/icons/folder_color.svg";
import right_arrow from "@gigauser/common/src/assets/legacy_assets/icons/right_arrow.svg";
import Clickout from "@gigauser/common/src/layouts/Clickout/Clickout";
import { Collection } from "@gigauser/common/src/core/types/collections";
import logger from "@gigauser/common/src/utils/logger";
import { Icon } from "@chakra-ui/react";
import gradientFolderIcon from "@gigauser/common/src/assets/svgs/home/gradientFolderIcon";
import folderIcon from "@gigauser/common/src/assets/svgs/home/folderIcon";

type MoveCollectionProps = {
	collection: Collection;
	setDisable: React.Dispatch<React.SetStateAction<boolean>>;
	setCurrentParent: (x: Collection) => void;
};

const MoveFolder: React.FC<MoveCollectionProps> = ({
	collection,
	setDisable,
	setCurrentParent,
}) => {
	const [isHovering, setIsHovering] = useState(false);
	const [isSelected, setIsSelected] = useState(false);

	const handleMouseOver = () => {
		setIsHovering(true);
	};

	const handleMouseOut = () => {
		setIsHovering(false);
	};

	const onSelect = () => {
		logger.debug("Inside move folder for");
		setIsSelected(!isSelected);
		setDisable(isSelected);
	};

	return (
		<div
			className="move-file"
			onMouseOver={handleMouseOver}
			onMouseOut={handleMouseOut}
			style={{
				backgroundColor: isSelected ? "#4f46e5" : "",
				color: isSelected ? "white" : "",
				zIndex: isSelected ? 5 : 0,
			}}
			onClick={() => {
				setCurrentParent(collection);
			}}
		>
			<Clickout closeFunction={() => setIsSelected(false)}>
				<div className="move-file-name">
					<img
						src={folder_color}
						className={`file-icon ${isSelected ? "selected" : ""}`}
					></img>
					<div className="file-name">{collection.name}</div>
				</div>
			</Clickout>
			<img
				className={`move-right-arrow ${isSelected ? "selected" : ""}`}
				src={right_arrow}
				color={isSelected ? "white" : ""}
				style={{ filter: isSelected ? "$filter_white" : "" }}
			></img>
		</div>
	);
};
export default MoveFolder;
