import React, { useEffect, useRef } from "react";
import "./TextEditorPlatform.css";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { TextEditor } from "@gigauser/common/src/components/formats/RichText/TextEditor";
import {
	selectAiEnhancingArticle,
	selectAutoUpdatingGuide,
	selectGuidePreview,
	selectPlainDoc,
	selectTrimmingVideo,
	setAiEnhancingArticle,
	setPlainDoc,
} from "@gigauser/common/src/redux/slices/guideSlice";
import { setUnsavedChanges } from "@gigauser/common/src/redux/slices/guideSlice";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import useSaveGuide from "@gigauser/common/src/redux/hooks/FileHooks/useSaveGuide";
import useFetchGuidePreviewAndCheck from "@gigauser/common/src/redux/hooks/FileHooks/useFetchGuidePreviewAndCheck";
import useConvertToGif from "./ConvertToGif/useConvertToGif";
import { Crop } from "@giga-user-fern/api/types/api/resources/video";
import useBackendBusy from "@gigauser/common/src/redux/hooks/FileHooks/useBackendBusy";
import { useToast } from "@chakra-ui/react";

type TextEditorPlatformProps = {
	visible: boolean;
};

const TextEditorPlatform: React.FC<TextEditorPlatformProps> = (props) => {
	const convertToGif = useConvertToGif("platform");
	const plainDoc = useAppSelector(selectPlainDoc);
	const id = useAppSelector((state) => state.guide.value.guide?.id);
	const dispatch = useAppDispatch();
	const saveGuide = useSaveGuide();
	const fetchGuidePreviewAndCheck = useFetchGuidePreviewAndCheck();
	const scrollContainerRef = useRef<HTMLDivElement | null>(null);
	const aiEnhanceArticle = useAppSelector(selectAiEnhancingArticle);
	const [enhancing, setEnhancing] = React.useState(aiEnhanceArticle);
	const autoUpdateGuide = useAppSelector(selectAutoUpdatingGuide);
	const [autoUpdating, setAutoUpdating] = React.useState(autoUpdateGuide);
	const trimVideo = useAppSelector(selectTrimmingVideo);
	const [trimming, setTrimming] = React.useState(trimVideo);
	const editorRef = useRef<any>(null);
	const isBackendBusy = useBackendBusy();
	const toast = useToast();

	const guidePreview = useAppSelector(selectGuidePreview);

	if (!plainDoc) throw new Error("Couldn't load guideData from redux!");

	const saver = useAppSelector(selectSaver);

	const doc = useRef<any>(plainDoc.data);

	const setDoc = (newJSON: any) => {
		doc.current = newJSON;
		dispatch(setPlainDoc({ data: doc.current, version: "2023-03-12" }));
		dispatch(setUnsavedChanges(true));
	};

	const onEnhance = async () => {
		if (isBackendBusy) {
			toast({
				title: `${isBackendBusy} is in progress!`,
				description: "Please wait for it to finish and then try again.",
				status: "warning",
				duration: 4000,
				isClosable: true,
				position: "top",
			});
			return false;
		}

		setEnhancing(true);

		const resp = await saveGuide(true);

		if (resp && id) {
			dispatch(setAiEnhancingArticle(true));
			saver.guides.enhance.article({ guideId: id }).then((resp) => {
				if (!resp.ok) {
					if (resp.error.error === "UsageLimitExceededError") {
						dispatch(setAiEnhancingArticle(false));
						setEnhancing(false);
					}
				}
			});
			setTimeout(() => fetchGuidePreviewAndCheck(), 3000);
		} else {
			setEnhancing(false);
		}
	};

	useEffect(() => {
		if (trimVideo && !trimming) setTrimming(true);

		if (
			(enhancing && !aiEnhanceArticle) ||
			(autoUpdating && !autoUpdateGuide) ||
			(trimming && !trimVideo)
		) {
			if (enhancing) setEnhancing(false);
			if (autoUpdating) setAutoUpdating(false);
			if (trimming) setTrimming(false);

			if (editorRef.current) {
				editorRef.current.setContent(plainDoc.data);
			}
		}
	}, [aiEnhanceArticle, autoUpdateGuide, trimVideo]);

	return (
		<div
			className="TextEditorPlatform style-scroll-square"
			style={{ visibility: props.visible ? "visible" : "hidden" }}
			ref={scrollContainerRef}
		>
			<div className="platform-texteditor-container ">
				{doc.current ? (
					<TextEditor
						ref={editorRef}
						initialContent={doc.current as any}
						stringHandler="html"
						save={setDoc}
						editable={true && !enhancing && !guidePreview?.deleted}
						enhanceLoading={enhancing}
						onEnhance={onEnhance}
						scrollContainerRef={scrollContainerRef}
						fixToolbar
						appTheme="gigauser-light"
						convertToGif={convertToGif}
					></TextEditor>
				) : (
					<TextEditor
						ref={editorRef}
						stringHandler="html"
						save={setDoc}
						editable={true && !enhancing && !guidePreview?.deleted}
						enhanceLoading={enhancing}
						onEnhance={onEnhance}
						scrollContainerRef={scrollContainerRef}
						appTheme="gigauser-light"
						fixToolbar
						convertToGif={convertToGif}
					></TextEditor>
				)}
			</div>
		</div>
	);
};
export default TextEditorPlatform;
