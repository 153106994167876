import React from "react";
import "../PaymentResultPopup/PaymentResultPopup.css";
import Cutton from "../../ui/buttons/Cutton/Cutton";
import { useAppDispatch } from "../../redux";
import { closeModal } from "../../layouts/CModal/modalSlice";

type PaymentResultPopupProps = {
	success: boolean;
};

const PaymentResultPopup: React.FC<PaymentResultPopupProps> = (props) => {
	const dispatch = useAppDispatch();
	return (
		<div className="PaymentResultPopup">
			<div className="PaymentResultMessage">
				{/* {props.success
					? "Your Subscription was successful, welcome to Clueso Pro!"
					: "Payment Unsuccessful, please try again!"}
				<br /> In case you have any questions, feel free to reach out to us at{" "}
				<span
					className="PaymentResultMessageEmail"
					onClick={() => {
						window.open("mailto:founders@clueso.io", "_blank");
					}}
				>
					founders@clueso.io
				</span> */}
				<div className="SubscribePopup PreUpdate LimitPopup">
					<h1>
						{props.success ? "Welcome to Clueso Pro!" : "Payment Unsuccessful"}
					</h1>

					<p>
						{props.success
							? "Your subscription was successful"
							: "Please try again"}
						.
					</p>
					<p>
						In case you have any questions, feel free to reach out to us at{" "}
						<span
							className="PaymentResultMessageEmail"
							onClick={() => {
								window.open("mailto:founders@clueso.io", "_blank");
							}}
						>
							founders@clueso.io
						</span>{" "}
						or book a meeting with us{" "}
						<span
							className="PaymentResultMessageEmail"
							onClick={() => {
								window.open("https://calendly.com/clueso_intro/neel", "_blank");
							}}
						>
							here
						</span>
						.
					</p>
				</div>
			</div>
			<Cutton onClick={() => dispatch(closeModal())} rank="primary">
				Continue
			</Cutton>
		</div>
	);
};
export default PaymentResultPopup;
