import React, { useRef, useState } from "react";
import Icon from "../../Icon/Icon";
import move_dots from "../../../assets/svgs/move_dots";
import {
	CTableColumn,
	CTableProps,
	getNormalizedColumns,
	MOVE_WIDTH,
} from "./CTable";
import trashIcon from "../../../assets/svgs/trashSimpleIcon";
import CTableGap from "./CTableGap";

export type CTableRowProps = {
	index: number;
	columns: CTableColumn[];
	entries: React.ReactNode[];

	onClick?: () => void;
	onContextMenu?: (event: React.MouseEvent<HTMLDivElement>) => void;

	onDrop?: (droppedItem: any) => void;
	onDelete?: () => void;
	onReorder?: (item: CTableRowItem, precedingItem?: CTableRowItem) => void;
	item: CTableRowItem; //Used for drag and drop. If not available, pass any random thing.

	icons?: CTableRowIcon[];
};

export type CTableRowItem = {
	type: any;
} & any;

export type CTableRowIcon = {
	icon: (color: string) => JSX.Element;
	hoverTip?: string;
	onClick: () => void;
};

const CTableRow: React.FC<CTableRowProps> = (props) => {
	const columns = getNormalizedColumns(props.columns);
	const dragImageRef = useRef<HTMLDivElement>(null);

	const [isOnDrop, setIsOnDrop] = useState<boolean>(false); //true if something is being dropped on the folder
	const [isBeingDragged, setIsBeingDragged] = useState<boolean>(false);

	const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
		if (props) e.dataTransfer?.setData("text", JSON.stringify(props.item));
		setIsBeingDragged(true);
		if (dragImageRef.current) {
			// set the custom drag image
			dragImageRef.current.style.visibility = "visible";
			e.dataTransfer.setDragImage(dragImageRef.current, 0, 0);
		}
	};

	const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
		if (dragImageRef.current) dragImageRef.current.style.visibility = "hidden";
		setIsBeingDragged(false);
	};

	const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
		setIsOnDrop(false);
		if (dragImageRef.current) dragImageRef.current.style.visibility = "visible";
		const data = JSON.parse(e.dataTransfer.getData("text"));
		if (props.onDrop) props.onDrop(data);
	};

	const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		setIsOnDrop(false);
	};

	const onDragOverCapture = (e: React.DragEvent<HTMLDivElement>) => {
		// if(data.entry.id!==props.entry.entry.id)
		if (props.item.type !== "Collection") return;
		setIsOnDrop(true);
	};

	const onDelete = (e: any) => {
		if (props.onDelete) props.onDelete();
		e.stopPropagation();
		e.preventDefault();
	};

	const isDragNDroppable = props.onReorder || props.onDrop;

	return (
		<>
			{props.index === 0 && props.onReorder && (
				<CTableGap onReorder={props.onReorder} />
			)}

			<div
				className="custom-drag-image"
				ref={isDragNDroppable ? dragImageRef : undefined}
			>
				{props.entries[0]}
			</div>

			<div
				className={`CTable-row 
                    ${isDragNDroppable ? "dragNDroppable" : "notDragNDroppable"}
                    ${isOnDrop ? "onDrop" : ""}
                    ${isBeingDragged ? "beingDragged" : "notBeingDragged"}
                    ${props.onClick ? "clickable" : "non-clickable"}
                    ${props.onDelete ? "deletable" : "non-deletable"}
                    
                `}
				onDragStart={onDragStart}
				onDragEnd={onDragEnd}
				onClick={props.onClick}
				onDrop={onDrop}
				onDragOver={onDragOver}
				onDragOverCapture={onDragOverCapture}
				onDragLeave={onDragLeave}
				draggable
				onContextMenu={(e) => {
					e.preventDefault();
					props.onContextMenu?.(e);
				}}
			>
				<div className="CTable-row-columns">
					<div
						className="gigauser-row-dots"
						style={{
							width: `${MOVE_WIDTH}%`,
						}}
					>
						<Icon
							style={{ visibility: props.onReorder ? "visible" : "hidden" }}
							className="CTable-td-move CTable-td-hover-icon"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								props.onContextMenu?.(e);
							}}
						>
							{move_dots}
						</Icon>
					</div>

					{props.entries.map((entry, index) => (
						<div
							className="CTable-td"
							style={{
								width: `${columns[index].colWidth}%` ?? "auto",
							}}
							key={index}
						>
							{entry}
						</div>
					))}

					<div className="CTable-row-trash">
						{props.icons?.length
							? props.icons.map((icon, index) => (
									<Icon
										key={index}
										className="CTable-td-link CTable-td-hover-icon"
										hoverTip={icon.hoverTip}
										hideHoverTipArrow
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											icon.onClick();
										}}
									>
										{icon.icon("#B1B5C9")}
									</Icon>
								))
							: null}

						{props.onDelete ? (
							<Icon
								className="CTable-td-trash CTable-td-hover-icon"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									props.onDelete ? props.onDelete() : null;
								}}
							>
								{trashIcon("#DC6060")}
							</Icon>
						) : null}
					</div>
				</div>
			</div>
			{props.onReorder && (
				<CTableGap onReorder={props.onReorder} precedingItem={props.item} />
			)}
		</>
	);
};
export default CTableRow;
