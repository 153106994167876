import React, { useEffect, useState } from "react";
import { Item } from "@gigauser/common/src/types/files";
import "./SearchBox.css";
import SearchInput from "./SearchInput/SearchInput";
import SearchResults from "./SearchResults/SearchResults";
import fuzzysort from "fuzzysort";
import { Device } from "@gigauser/common/src/types/devices";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { useEffectOnce } from "@gigauser/common/src/hooks/useEffectOnce";
import { useAppSelector } from "@gigauser/common/src/redux";
import { GigaUserApi } from "@giga-user-fern/api";
import { GuidePreview } from "@giga-user-fern/api/types/api/resources/guides";
import logger from "@gigauser/common/src/utils/logger";
import { selectSaver } from "@gigauser/common/src/redux/slices/backendSlice";
import TitledInput from "@gigauser/common/src/ui/inputs/TitledInput/TitledInput";
import CSearch from "@gigauser/common/src/ui/inputs/CSearch/CSearch";

type SearchBoxProps = {};

type Searchable = {
	id: GigaUserApi.Id;
	name: string;
	description?: string;
	item: Item;
};

export type SearchResult = {
	highlightedName: (string | JSX.Element)[] | null;
	searchable: Searchable;
};

const SearchBox: React.FC<SearchBoxProps> = () => {
	const [searchString, setSearchString] = useState<string>("");

	const [initLoading, setInitLoading] = useState<boolean>(true);
	const [searchInProgress, setSearchInProgress] = useState<boolean>(false);

	const [filesToSearch, setFilesToSearch] = useState<Searchable[]>([]);
	const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

	const saver = useAppSelector(selectSaver);

	const runSearch = (val: string) => {
		setSearchInProgress(true);

		logger.debug("filesToSearch: ", filesToSearch);
		logger.debug("searching for: ", val, searchString);

		if (!filesToSearch.length) {
			//files not loaded yet
			logger.debug("files not loaded yet!");
			return;
		}

		const fuzzyResults = fuzzysort.go(val, filesToSearch, { key: "name" });

		const searchResults: SearchResult[] = fuzzyResults.map((res) => {
			return {
				highlightedName: fuzzysort.highlight(res, (m, i) => (
					<span className="bolden" key={i}>
						{m}
					</span>
				)),
				// highlightedName: fuzzysort.highlight(res) as string,
				searchable: res.obj,
			};
		});

		logger.debug("searchResults: ", searchResults);

		// const fileResults: ObjectUser[] = results.map(res => res.obj as ObjectUser)

		// const test = results.map(result =>fuzzysort.highlight(result))
		// logger.debug("highlighted: ", test)

		// logger.debug("results: ", results)
		setSearchResults(searchResults);
		setSearchInProgress(false);
		// return fileResults;
	};

	useEffectOnce(() => {
		setInitLoading(true);

		saver
			.fetchAllGuidePreviews(false)
			.then((guidesResp) => {
				logger.debug("got all response: ", guidesResp);

				saver.fetchAllCollections().then((collsResp) => {
					var _filesToSearch: Searchable[] = [];

					guidesResp.map((i) => {
						if(!i.deleted){
						_filesToSearch.push({
							id: i.id,
							name: i.header.name,
							description: i.header.description,
							item: {
								type: "Guide",
								entry: i,
							},
						});}
					});

					collsResp.map((i) => {
						if(!i.deleted){
						_filesToSearch.push({
							id: i.id,
							name: i.name,
							description: i.description,
							item: {
								type: "Collection",
								entry: i,
							},
						});}
					});

					setFilesToSearch(_filesToSearch);
				});
			})
			.catch((err: any) => {
				logger.error("couldn't getall: ", err);
			})
			.finally(() => {
				setInitLoading(false);
			});
	});

	useEffect(() => {
		if (!initLoading) {
			//done with initial loading. Maybe want to run a search?
			logger.debug("Done with init Loading!");
			runSearch(searchString);
		}
	}, [initLoading]);

	const { device } = useWindowSize();

	return (
		<div className={`searchBox ${device}`}>
			{
				initLoading?
				"Loading..."
				:

				<>
					<SearchInput
						loaded={filesToSearch.length > 0}
						setSearchString={setSearchString}
						runSearch={runSearch}
					></SearchInput>

					<SearchResults
						loaded={filesToSearch.length > 0}
						searchInProgress={searchInProgress}
						searchResults={searchResults}
                        searchString={searchString}
					></SearchResults>
				</>
			}
		</div>
	);
};
export default SearchBox;
