import { GigaUserApi } from "@giga-user-fern/api";
import {
	AnalyticsForNewUser,
	AnalyticsForSignupWorkflow,
	OrganizationWithListOfUsers,
} from "@giga-user-fern/api/types/api/resources/organizations";
import { saver } from "@gigauser/common/src/network/saver";
import { useEffect, useState } from "react";
import { FormState } from "@gigauser/common/src/layouts/Form/Form";
import PersonalDetailsForm, {
	isValidPersonalDetails,
} from "../PersonalDetailsForm/PersonalDetailsForm";
import AuthFormLayout from "../AuthFormLayout/AuthFormLayout";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import OrLine from "../components/OrLine/OrLine";
import newTeamIcon from "@gigauser/common/src/assets/svgs/newTeamIcon";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import "./JoinOrganization.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "@gigauser/common/src/hooks/useEffectOnce";
import { useAppDispatch } from "@gigauser/common/src/redux";
import { showErrorMessagePopup } from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";

const Id = GigaUserApi.Id;

type JoinOrganizationProps = {
	personalDetails: AnalyticsForNewUser;
	listOfEligibleOrganizations: OrganizationWithListOfUsers[];
};

const JoinOrganization: React.FC<JoinOrganizationProps> = (props) => {
	const navigate = useNavigate();
	const [submitting, setSubmitting] = useState(false);
	const dispatch = useAppDispatch();

	useEffectOnce(() => {
		//the user must have come here via URL directly without filling personal details
		if (!isValidPersonalDetails(props.personalDetails)) {
			navigate("/welcome/personal-details");
		}
	});

	const { listOfEligibleOrganizations } = props;

	const [selectedOrg, setSelectedOrg] = useState<OrganizationWithListOfUsers>(
		listOfEligibleOrganizations[0],
	);

	const onSubmitJoinOrganization = async () => {
		console.log("selectedOrg: ", selectedOrg);
		if (selectedOrg) {
			setSubmitting(true);
			const resp = await saver.joinOrganization({
				organizationDetails: {
					orgId: Id(selectedOrg.id),
				},
				analyticalData: {
					userRelatedDetails: props.personalDetails,
					orgRelatedDetails: {},
				},
			});
			console.log("resp: ", resp);
			if (resp.ok) {
				localStorage.setItem("organization_id", resp.body.id);
				// navigate("/home");
				window.location.replace("/home");
			} else if (resp.error.error === "UsageLimitExceededError") {
				alert(
					`Seat limit exceeded! The limit is ${resp.error.content.limitValue}`,
				);
			} else {
				console.error("Error while joining org!", resp);
			}

			setSubmitting(false);
		} else {
			console.log("No selected org", selectedOrg, listOfEligibleOrganizations);
		}
	};

	const getOrgPreview = (
		org: OrganizationWithListOfUsers,
	):
		| {
				name: string;
				rem: number;
				preview: string;
		  }
		| undefined => {
		if (org.creators.length > 0) {
			var p1 = org.creators[0];
			let n1 = p1.name || p1.email.split("@")[0];
			let rem = Math.max(listOfEligibleOrganizations[0].filledSeats - 1, 0);

			return {
				name: n1,
				rem: rem,
				preview: `${n1} ${rem > 0 ? `+ ${rem} more` : ""}`,
			};
		}
	};

	const p1 = getOrgPreview(listOfEligibleOrganizations[0]);

	return (
		<AuthFormLayout
			stepNumber={2}
			title="Join a team"
			subtitle={`Your ${
				listOfEligibleOrganizations.length == 1
					? listOfEligibleOrganizations[0].name
					: ""
			} colleagues are already on Clueso!`}
		>
			<div className="JoinOrganization thin-scroll">
				<div
					className="OrgCheckboxesGrid "
					style={{
						display:
							listOfEligibleOrganizations.length > 1 ? undefined : "none",
					}}
				>
					{listOfEligibleOrganizations.length > 1 &&
						listOfEligibleOrganizations.map((org, i) => {
							const isActive = selectedOrg.id === org.id;
							const preview = getOrgPreview(org)?.preview;

							const a = (
								<div
									className={`OrgCheckBox  ${isActive ? "active-org" : "inactive-org"}`}
									onClick={() => {
										setSelectedOrg(org);
									}}
								>
									<div className={`radio-button`}></div>

									<div className="OrgDetails">
										<div className="OrgName">{org.name}</div>
										{preview && (
											<div className="OrgPreview">with {preview}</div>
										)}
									</div>
								</div>
							);

							return a;
						})}
				</div>

				<Cutton
					isLoading={submitting}
					loadingText="Joining..."
					rank="primary"
					fullWidth
					onClick={() => {
						onSubmitJoinOrganization();
					}}
				>
					{`Join ${selectedOrg.name} `}
				</Cutton>

				<div className="JoinOrganization-text">
					{listOfEligibleOrganizations.length == 1 &&
						p1?.preview &&
						`with ${p1.preview}`}
				</div>

				<OrLine />

				<Cutton
					fullWidth
					rank="tertiary"
					leftIcon={plusIcon("white")}
					onClick={() => {
						navigate("/welcome/create-team");
					}}
				>
					Create a new team
				</Cutton>
			</div>
		</AuthFormLayout>
	);

	return (
		<>
			{/* {currentTab === "orgDetails" && (
				<button
					disabled={!selectedOrg}
					onClick={}
				>
					{!selectedOrg ? "Select an Organization" : "Join Organization"}
				</button>
			)} */}
		</>
	);
};

function JoinOrganizationForm({
	selectedOrg,
	setSelectedOrg,
	listOfEligibleOrganizations,
	setCurrentTab,
}: {
	selectedOrg: string | undefined;
	setSelectedOrg: React.Dispatch<string>;
	listOfEligibleOrganizations: OrganizationWithListOfUsers[];
	setCurrentTab?: React.Dispatch<"analytical">;
}) {
	return (
		<>
			{setCurrentTab && (
				<button onClick={() => setCurrentTab("analytical")}>Back</button>
			)}
			<br />
			<h2>Join Workspace</h2>
			<select
				value={selectedOrg}
				onChange={(e) => {
					console.log(e);
					setSelectedOrg(e.target.value);
				}}
			>
				<option disabled selected>
					Select an Workspace
				</option>
				{listOfEligibleOrganizations.map((org, i) => (
					<option
						key={`${i}-${org.id}`}
						value={org.id}
						disabled={org.filledSeats === org.maxSeats}
					>
						{org.name} - User Count: {org.filledSeats}/{org.maxSeats}
					</option>
				))}
			</select>
			<br />
		</>
	);
}
export default JoinOrganization;
