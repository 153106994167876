import { useContext } from "react";
import { CMenuContext } from "@gigauser/common/src/contexts";
import { Item } from "@gigauser/common/src/types/files";
import edit from "@gigauser/common/src/assets/legacy_assets/icons/edit.svg";
import pin from "@gigauser/common/src/assets/legacy_assets/icons/pin.svg";

import move from "@gigauser/common/src/assets/legacy_assets/icons/move.svg";
import trash from "@gigauser/common/src/assets/legacy_assets/icons/trash.svg";

import { useAppDispatch } from "@gigauser/common/src/redux";
import { openOverlay } from "@gigauser/common/src/layouts/Overlay/overlaySlice";
import EditItemDetails from "./EditItemDetails/EditItemDetails";

import FileExplorer from "../../pages/home/features/FileExplorer/FileExplorer";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import DeletePopup from "../../pages/home/features/files/DeletePopup/DeletePopup";
import logger from "@gigauser/common/src/utils/logger";
import usePinGuide from "@gigauser/common/src/redux/hooks/FileHooks/usePinGuide";
import useSetCollectionVisibility from "@gigauser/common/src/redux/hooks/FileHooks/useSetCollectionVisibility";
import { saver } from "@gigauser/common/src/network/saver";
import {
	addGuideToCollection,
	deleteCollectionFrontend,
	deleteGuideFrontend,
} from "@gigauser/common/src/redux/slices/collectionDataSlice";
import eyeClosedIcon from "@gigauser/common/src/assets/svgs/eyeClosedIcon";
import copyIcon from "@gigauser/common/src/assets/svgs/copyIcon";
import moveFolderIcon from "@gigauser/common/src/assets/svgs/moveFolderIcon";
import trashIcon from "@gigauser/common/src/assets/svgs/trashSimpleIcon";
import penIcon from "@gigauser/common/src/assets/svgs/screenshot/penIcon";
import { openModal } from "@gigauser/common/src/layouts/CModal/modalSlice";
import folderIcon from "@gigauser/common/src/assets/svgs/home/folderIcon";
import { useToast } from "@chakra-ui/react";
import pinIcon from "@gigauser/common/src/assets/svgs/pinIcon";
import useConfirmDelete from "@gigauser/common/src/hooks/useConfirmDelete/useConfirmDelete";
import { GuidePreview } from "@giga-user-fern/api/types/api/resources/guides";
import { Collection } from "@giga-user-fern/api/types/api/resources/collections";

const useItemContextMenu: () => (file: Item, X: number, Y: number) => void =
	() => {
		const cMenuContext = useContext(CMenuContext);
		const { showContextMenu } = cMenuContext;
		const dispatch = useAppDispatch();
		const pinGuide = usePinGuide();
		const setCollectionVisibility = useSetCollectionVisibility();
		const toast = useToast();
		const confirmDelete = useConfirmDelete();

		return (file: Item, X: number, Y: number) => {
			logger.debug("contextMenu!");

			const fileType = file.type;

			var name: string;

			if (fileType === "Collection") {
				name = file.entry.name;
			} else if (fileType === "Guide") {
				name = file.entry.header.name;
			}

			showContextMenu({
				show: true,
				menuArray: [
					{
						icon: penIcon,
						label: `Edit title & description`,
						handler: () => {
							dispatch(
								openModal({
									heading: {
										icon: penIcon(),
										title: `Edit ${fileType === "Collection" ? "Folder" : "Project"} `,
										data: name,
									},
									component: <EditItemDetails file={file} />,
								}),
							);
						},
					},
					...(fileType === "Collection"
						? [
							{
								icon: file.entry.private ? eyeClosedIcon : eyeClosedIcon,
								label: `Make ${file.entry.private ? "public" : "private"} `,
								handler: () => {
									setCollectionVisibility(file.entry, !file.entry.private);
								},
							},
						]
						: []),
					...(fileType === "Guide"
						? [
							{
								icon: copyIcon,
								label: "Duplicate",
								handler: async () => {
									const duplicateItemPromise = new Promise<boolean>(
										async (resolve, reject) => {
											try {
												const resp = await saver.guides.duplicate(
													file.entry.id,
												);
												if (resp.ok) {
													window.open("/guide/" + resp.body.id);
													dispatch(addGuideToCollection(resp.body));
												}

												resolve(true);
											} catch {
												reject(false);
											}
										},
									);

									toast.promise(duplicateItemPromise, {
										success: { title: `Duplicated successfully.` },
										error: {
											title: "Unexpected error while duplicating item",
											description: "Please try again later.",
										},
										loading: { title: "Duplicating ..." },
									});
								},
							},
						]
						: []),
					...(fileType === "Guide"
						? [
							{
								icon: pinIcon,
								label: file.entry.isPinned ? "Unpin" : "Pin as preview",
								handler: () => {
									pinGuide(file.entry.isPinned ? false : true, file.entry);
								},
							},
						]
						: []),

					{
						icon: moveFolderIcon,
						label: "Move",
						handler: () => {
							dispatch(
								openModal({
									heading: {
										icon: moveFolderIcon(),
										title: "Move",
										data: name,
									},
									component: (
										<FileExplorer
											action="move"
											item={file}
										// object={
										//     {objectData: file, fileType:fileType} as DocumentInfoProps
										// }
										// disabledItems={fileType==="Writing"?["Writing"]:["Reading", "Writing"]}
										/>
									),
								}),
							);
						},
					},

					{ type: "separator" },
					{
						icon: trashIcon,
						type: "danger",
						label: "Delete",
						handler: () => {
							const onDeleteGuide = (guide: GuidePreview) => {
								const submitDeleteFunction = async (
									resolve: any,
									reject: any,
								) => {
									// deleteResult = await saver.deleteGuide(item.entry.id)
									const guidePreview = { ...guide };
									guidePreview.header = {
										...guidePreview.header,
										published: false,
									};
									guidePreview.deleted = true;
									guidePreview.isPinned = false;

									const deleteResult =
										await saver.guides.preview.update(guidePreview);

									if (deleteResult) {
										resolve(true);
									} else {
										reject(false);
									}
								};

								confirmDelete(
									() => {
										const submitDeletePromise = new Promise<boolean>(
											submitDeleteFunction,
										);
										toast.promise(submitDeletePromise, {
											success: { title: `Deleted 1 item successfully.` },
											error: {
												title: "Unexpected error while deleting item",
												description: "Please try again later.",
											},
											loading: { title: "Deleting ..." },
										});
										submitDeletePromise
											.then(() => {
												dispatch(deleteGuideFrontend(guide));
											})
											.catch(() => {
												logger.error("Error deleting guide");
											});
									},
									{
										item: guide.header.name,
									},
								);
							};

							const onDeleteCollection = (c: Collection) => {
								const submitDeletePromise = new Promise<boolean>(
									async (resolve, reject) => {
										const deleteResult = await saver.collections.delete(c.id);

										if (deleteResult) {
											resolve(true);
										} else {
											reject(false);
										}
									},
								);

								confirmDelete(
									() => {
										toast.promise(submitDeletePromise, {
											success: { title: `Deleted 1 item successfully.` },
											error: {
												title: "Unexpected error while deleting item",
												description: "Please try again later.",
											},
											loading: { title: "Deleting ..." },
										});
										submitDeletePromise
											.then(() => {
												dispatch(deleteCollectionFrontend(c));
											})
											.catch(() => {
												logger.error("Error deleting guide");
											});
									},
									{
										item: c.name,
									},
								);
							};

							if (file.type === "Collection") {
								onDeleteCollection(file.entry);
							} else {
								onDeleteGuide(file.entry);
							}
						},
					},
				],
				X: X,
				Y: Y,
			});
		};
	};

export default useItemContextMenu;
