const pinIcon = (color: string = "currentColor") => {
	return (
		<svg
			fill={color}
			height="100%"
			width="100%"
			version="1.1"
			id="XMLID_222_"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
		>
			<g id="pin">
				<path
					d="M23.7,11.3l-11-11l-1.4,1.4l1.4,1.4L7.4,8.4c-1.9,0.1-3.6,0.9-5,2.3l-0.7,0.7l4.7,4.7l-6.1,6.2l1.4,1.4l6.2-6.2l4.8,4.9
		l0.7-0.7c1.4-1.3,2.2-3.1,2.3-5l5.3-5.3l1.3,1.3L23.7,11.3z M13.6,15.8v0.4c0,1.2-0.3,2.3-1,3.3l-8.1-8.1c1-0.7,2.1-1.1,3.2-1.1
		h0.4L14,4.4l5.5,5.5L13.6,15.8z"
				/>
			</g>
		</svg>
	);
};

export default pinIcon;
