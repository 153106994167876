import React, { useState } from "react";
import "./AdminList.css"; // Make sure to create this CSS file
import { Id, User, UserInOrg, UserRole } from "@giga-user-fern/api/types/api";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import trashIcon from "@gigauser/common/src/assets/svgs/trashIcon";
import Cutton from "@gigauser/common/src/ui/buttons/Cutton/Cutton";
import plusIcon from "@gigauser/common/src/assets/svgs/plusIcon";
import CTable, {
	CTableColumn,
} from "@gigauser/common/src/ui/table/CTable/CTable";
import CTableRow, {
	CTableRowProps,
} from "@gigauser/common/src/ui/table/CTable/CTableRow";
import UserPreview, {
	guessFullNameFromEmail,
} from "@gigauser/common/src/ui/users/UserPreview/UserPreview";
import { Flex } from "@chakra-ui/react";
import useConfirmDelete from "@gigauser/common/src/hooks/useConfirmDelete/useConfirmDelete";
import { CreatorInCurrentOrganization } from "@giga-user-fern/api/types/api/resources/organizations";
import CSelect from "@gigauser/common/src/ui/select/CSelect";
import { capitalizeFirstLetter } from "@gigauser/common/src/overlays/VoicePicker/VoicePicker";

type AdminListUserType =
	| User
	| { name?: string; email: string; role: "viewer" };

export type AdminListTab = "active" | "pending";

type AdminListProps = {
	self: User | null;
	activeUsers: CreatorInCurrentOrganization[];
	pendingUsers: UserInOrg[];
	deleteUser: (email: string) => void;
	createUser: () => void;
	changeUserRole: (userId: Id, role: UserRole) => void;
	currentTab: AdminListTab;
	setCurrentTab: (tab: AdminListTab) => void;
};

const AdminList = ({
	activeUsers,
	pendingUsers,
	deleteUser,
	createUser,
	changeUserRole,
	self,
	currentTab,
	setCurrentTab,
}: AdminListProps) => {
	const confirmDelete = useConfirmDelete();

	const columns: CTableColumn[] = [
		{
			colName: "User",
			colWidth: 40,
		},
		{
			colName: currentTab === "active" ? "Joined" : "Invited",
			colWidth: 25,
		},
		{
			colName: "Role",
			colWidth: 25,
		},
		{
			colName: "Projects",
			colWidth: 10,
		},
	];

	const onDeleteUser = (user: UserInOrg) => {
		confirmDelete(() => deleteUser(user.email), {
			item: user.name ?? guessFullNameFromEmail(user.email),
			action: "remove",
			description:
				"Are you sure you want to remove this user? This will not delete any of their data.",
		});
	};

	const reorderedActiveUsers = [
		...activeUsers.filter((user) => user.email === self?.email),
		...activeUsers.filter((user) => user.email !== self?.email),
	];

	const rows: React.ReactElement<CTableRowProps>[] = (
		currentTab == "active" ? reorderedActiveUsers : pendingUsers
	).map((user, index) => {
		const numberOfProjects =
			"numberOfProjects" in user ? user.numberOfProjects : 0;
		const entries = [
			<>
				<UserPreview
					user={{
						firstName: user.name,
						email: user.email,
						pictureUrl: user.pictureUrl,
					}}
					highlight={self?.email === user.email ? "(Me)" : ""}
				/>
			</>,
			<>
				{user.signupDate.toLocaleDateString("en-US", {
					year: "numeric",
					month: "long",
					day: "numeric",
				})}
			</>,
			<div className="role-select">
				<CSelect
					disabled={self?.role !== "admin" || self?.email === user.email}
					value={capitalizeFirstLetter(user.role)}
					theme={user.role === "admin" ? "red" : "blue"}
					onChange={(newRole: UserRole) => {
						changeUserRole(user.userId, newRole);
					}}
					options={[
						{ value: "admin", label: "Admin" },
						{ value: "editor", label: "Editor" },
					]}
				/>
			</div>,
			<>{numberOfProjects}</>,
		];

		return (
			<CTableRow
				key={user.email}
				columns={columns}
				index={index}
				item={user}
				entries={entries}
				onDelete={
					self?.role === "admin"
						? () => {
								onDeleteUser(user);
							}
						: undefined
				}
			/>
		);
	});

	//deleteUser

	const allTabs: AdminListTab[] = ["active", "pending"];
	const tabs = allTabs.map((tab) => {
		return (
			<Flex
				className={`AdminList-section ${currentTab === tab ? "activeTab" : "inactiveTab"} `}
				alignItems={"center"}
				onClick={() => setCurrentTab(tab)}
			>
				<div className="AdminList-header">
					{tab === "active" ? "Active members" : "Pending Invites"}
				</div>
				<div className="AdminList-number">
					{tab === "active" ? activeUsers.length : pendingUsers.length} users
				</div>
			</Flex>
		);
	});

	return (
		<div className="AdminList">
			<Flex
				className="ByLine"
				alignItems={"center"}
				justifyContent={"space-between"}
			>
				<Flex alignItems={"center"} gap={"0.5rem"}>
					{tabs}
				</Flex>

				<Flex>
					<Cutton
						leftIcon={plusIcon("white")}
						onClick={createUser}
						disabled={self?.role !== "admin"}
					>
						Invite User
					</Cutton>
				</Flex>
			</Flex>

			<div
				className="AdminListTable-container"
				style={{
					display: currentTab === "active" ? "block" : "none",
				}}
			>
				<CTable columns={columns}>{rows}</CTable>
			</div>

			<div
				className="AdminListTable-container"
				style={{
					display: currentTab === "pending" ? "block" : "none",
				}}
			>
				<CTable columns={columns}>{rows}</CTable>
			</div>
		</div>
	);
};

export default AdminList;
