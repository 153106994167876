import React, { useEffect, useState } from "react";
import TitledInput from "../../ui/inputs/TitledInput/TitledInput";
import { Language } from "@giga-user-fern/api/types/api";
import { saver } from "../../network/saver";
import { getFlag } from "../../utils/voices/voiceUtils";
import Clickout from "../../layouts/Clickout/Clickout";
import "./LanguageInput.css";

type LanguageInputProps = {
	onlyTranscriptable?: boolean;
	currentLanguage: Language;
	setCurrentLanguage: (l: Language) => void;
	description?: string;
};

const LanguageInput: React.FC<LanguageInputProps> = (props) => {
	const [allLanguages, setAllLanguages] = useState<Language[]>([]);
	const [listedLanguages, setListedLanguages] = useState<Language[]>([]);
	const [typingValue, setTypingValue] = useState<string>("");

	const onChangeTypingValue = (e: any) => {
		setTypingValue(e.target.value);
	};

	const initAllLanguages = async () => {
		var allLanguages = await saver.getAllLanguages();

		if (props.onlyTranscriptable) {
			allLanguages = allLanguages.filter((l) => l.transcriptable);
		}

		setAllLanguages(allLanguages);
		setListedLanguages(allLanguages);
	};

	const setCurrentLanguage = (e: any) => {
		const languageName = e.target.value;
		const language = allLanguages.find((l) => l.name === languageName);
		if (language) props.setCurrentLanguage(language);
		// return language;
	};

	useEffect(() => {
		initAllLanguages();
	}, []);

	return (
		<div className="LanguageInput">
			<TitledInput
				id="language"
				label="Language"
				value={props.currentLanguage.name}
				onChange={setCurrentLanguage}
				options={listedLanguages
					// .sort((a, b) => a.name.localeCompare(b.name))
					.map((l) => {
						const flag = getFlag(l.languageId);
						return {
							text: l.name,
							icon: flag.flag,
						};
					})}
			></TitledInput>

			{props.description ? (
				<div className="LanguageUploadInfo">{props.description}</div>
			) : null}
		</div>
	);
};
export default LanguageInput;
