import React from "react";
import CTableGap from "./CTableGap";
import CTableRow, { CTableRowProps } from "./CTableRow";
import "./CTable.css";
import { Size } from "../../../types/sizes";

export type CTableProps = {
	columns: CTableColumn[];
	children: React.ReactElement<CTableRowProps>[];
	size?: Size;
};

export type CTableColumn = {
	colName: string;
	colWidth?: number;
};

export const MOVE_WIDTH = 5;
export const ICONS_WIDTH = 8;

export const getNormalizedColumns = (
	columns: CTableColumn[],
): CTableColumn[] => {
	const newColumns: CTableColumn[] = columns.map((col, index) => {
		const newColWidth = col.colWidth
			? (col.colWidth / 100) * (100 - MOVE_WIDTH - ICONS_WIDTH)
			: undefined;

		return {
			...col,
			colWidth: newColWidth,
		};
	});

	return newColumns;
};

const CTable: React.FC<CTableProps> = (props) => {
	const columns = getNormalizedColumns(props.columns);

	return (
		<div className={`CTable ${props.size}`}>
			<div className="CTableHeader">
				<div
					className="CTable-th hide-name"
					style={{
						width: `${MOVE_WIDTH}%`,
					}}
				>
					.
				</div>
				{columns.map((col, index) => {
					return (
						<div
							className={`CTable-th show-name`}
							style={{
								width: col.colWidth ? `${col.colWidth}%` : "auto",
							}}
						>
							{col.colName}
						</div>
					);
				})}
				<div
					className="CTable-th CTable-th-delete hide-name"
					style={{
						width: `${ICONS_WIDTH}%`,
					}}
				>
					.
				</div>
			</div>

			{props.children}
		</div>
	);
};
export default CTable;
