import { Item } from "@gigauser/common/src/types/files";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import {
	showErrorMessagePopup,
	showLoadingMessagePopup,
	showMessagePopup,
} from "../../../layouts/MessagePopup/messagePopupSlice";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import { Collection } from "@giga-user-fern/api/types/api/resources/collections";
import {
	reorderCollectionsFrontend,
	reorderGuidesFrontend,
} from "@gigauser/common/src/redux/slices/collectionDataSlice";
import logger from "../../../utils/logger";
import { selectSaver } from "../../slices/backendSlice";
import { useToast } from "@chakra-ui/react";

type useReorderItemParams =
	| {
		type: "Collection";
		succeedingItem: Collection;
		precedingItem?: Collection;
	}
	| {
		type: "Guide";
		succeedingItem: GuidePreview;
		precedingItem?: GuidePreview;
	};

const useReorderItem: () => (items: useReorderItemParams) => Promise<boolean> =
	() => {
		const dispatch = useAppDispatch();
		const saver = useAppSelector(selectSaver);
		const toast = useToast();

		return async (items: useReorderItemParams) => {
			const { precedingItem, succeedingItem, type } = items;

			try {
				if (type == "Collection") {
					const resp = saver.collections.reorder(succeedingItem, precedingItem);

					console.log("items to reorder ", succeedingItem, precedingItem)

					dispatch(
						reorderCollectionsFrontend({
							succeedingCollection: succeedingItem,
							precedingCollection: precedingItem,
						}),
					);
				} else {
					// console.log("------------useReorderItem-----------")
					// console.log("preceeding item: ", precedingItem?.guide)
					// console.log("suceeding item: ", succeedingItem.guide)
					// console.log("-------------------------------------")

					const resp = saver.guides.reorder({ succeedingGuideId: items.succeedingItem.id, precedingGuideId: items.precedingItem?.id });

					dispatch(
						reorderGuidesFrontend({
							succeedingGuide: succeedingItem,
							precedingGuide: precedingItem,
						}),
					);
				}

				toast({
					title: "Reordered 1 item successfully.",
					status: "success",
				});

				return true;
			} catch (e) {
				logger.error("Error while reordering item: ", e);
				toast({
					title: "Error while reordering item.",
					status: "error",
				});
				return false;
			}
		};
	};

export default useReorderItem;
