import { EnvironmentContextValue } from "../../../contexts";
import { useAppDispatch, useAppSelector } from "@gigauser/common/src/redux";
import { Item, rootCollection } from "@gigauser/common/src/types/files";

import {
	moveItemFrontend,
	selectExploreCollectionData,
} from "@gigauser/common/src/redux/slices/collectionDataSlice";
import {
	showErrorMessagePopup,
	showLoadingMessagePopup,
	showMessagePopup,
} from "@gigauser/common/src/layouts/MessagePopup/messagePopupSlice";
import { GigaUserApi } from "@giga-user-fern/api";
import logger from "../../../utils/logger";
import { selectSaver } from "../../slices/backendSlice";
import { useToast } from "@chakra-ui/react";

type MoveItemInput = {
	item: Item;
	newParentID?: GigaUserApi.Id;
};

const useMoveItem: (
	env: EnvironmentContextValue,
) => (input: MoveItemInput) => Promise<boolean> = (env) => {
	//newParentID is currentParent.objectID
	//folder is objectData
	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);
	const toast = useToast();

	return async (input: MoveItemInput) => {
		const { item } = input;
		var newParentID = input.newParentID;

		if (item.entry.id === newParentID) {
			//trying to move a folder within the same folder itself
			logger.debug("trying to move a folder within the same folder itself");
			return false;
		}

		console.log("file: ", item);
		console.log("newParentID: ", newParentID);

		if (newParentID === rootCollection.id) {
			newParentID = undefined;
		}

		const moveItemPromise = new Promise<boolean>(async (resolve, reject) => {
			try {
				var res;
				if (item.type === "Collection") {
					res = await saver.collections.move(item.entry, newParentID);
				} else if (item.type === "Guide") {
					res = await saver.guides.move({
						guideId: item.entry.id,
						newParentId: newParentID,
					});
				}
				console.log("res: ", res);
				resolve(true);
			} catch (e) {
				console.error("error!", e);
				reject(false);
			}
		});

		toast.promise(moveItemPromise, {
			success: { title: `Moved 1 ${item.type} successfully.` },
			error: {
				title: "Unexpected error while moving item",
				description: "Please try again later.",
			},
			loading: { title: "Working ..." },
		});

		moveItemPromise.then((res) => {
			dispatch(
				moveItemFrontend({
					item: item,
					newParentID: newParentID || rootCollection.id,
				}),
			);
		});

		return moveItemPromise;
	};
};

export default useMoveItem;
