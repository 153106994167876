import React, { useState } from "react";
import { useAppDispatch } from "../../../redux";
import { showErrorMessagePopup } from "../../../layouts/MessagePopup/messagePopupSlice";
import { CTableRowItem } from "./CTableRow";

type CTableGapProps = {
	onReorder: (item: CTableRowItem, precedingItem?: CTableRowItem) => void;
	precedingItem?: CTableRowItem;
};

const CTableGap: React.FC<CTableGapProps> = (props) => {
	const [isOnDrop, setIsOnDrop] = useState<boolean>(false); //true if something is being dropped on the folder
	const dispatch = useAppDispatch();

	const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const onDragOverCapture = (e: React.DragEvent<HTMLDivElement>) => {
		// if(data.entry.id!==props.entry.entry.id)
		setIsOnDrop(true);
	};

	const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		setIsOnDrop(false);
	};

	const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
		var succeedingItem = JSON.parse(e.dataTransfer.getData("text"));
		const succeedingType =
			"type" in succeedingItem ? succeedingItem.type : undefined;
		const precedingType =
			props.precedingItem && "type" in props.precedingItem
				? props.precedingItem.type
				: undefined;
		if (succeedingType === "Guide") {
			succeedingItem.entry.lastModified = new Date(succeedingItem.entry.lastModified)
		}

		console.log("items: ", succeedingItem, props.precedingItem);

		setIsOnDrop(false);

		if (succeedingType && precedingType && succeedingType == precedingType) {

			props.onReorder(succeedingItem, props.precedingItem);
		}
		else if (succeedingType && !precedingType) {
			//moving to top. hope they're moving correctly.
			props.onReorder(succeedingItem);
		}
		else {
			return;
		}
	};

	return (
		<div
			className={`CTableGap ${isOnDrop ? "onDrop" : ""}`}
			onDragOver={onDragOver}
			onDragOverCapture={onDragOverCapture}
			onDragLeave={onDragLeave}
			onDrop={onDrop}
		>
			<div className="hr">.</div>
		</div>
	);
};
export default CTableGap;
