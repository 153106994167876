import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../useAppRedux";
import { selectTrimmingVideo } from "../../slices/guideSlice";
import {
	closeMessagePopup,
	showLoadingMessagePopup,
	showMessagePopup,
} from "../../../layouts/MessagePopup/messagePopupSlice";
import useInitGuideEditor from "./useInitGuideEditor";
import { useToast } from "@chakra-ui/react";
import { openOverlay } from "../../../layouts/Overlay";
import { UsageLimitExceededErrorBody } from "@giga-user-fern/api/types/api";
import LimitPopup from "../../../overlays/LimitPopup/LimitPopup";
import { openModal } from "../../../layouts/CModal/modalSlice";
import { title } from "process";
import crownIcon from "../../../assets/svgs/crownIcon";
import { selectAnalytics } from "../../slices/backendSlice";

interface LimitDictionary {
	[key: string]: {
		action: string;
		description: string;
	};
}

export const limit_dictionary: LimitDictionary = {
	export_video_allowed: {
		action: "export",
		description:
			"You have reached the limit for video exports. Upgrade to export more.",
	},
	generate_video_allowed: {
		action: "generate speech",
		description:
			"You have reached the limit for speech generations. Upgrade to generate more.",
	},
	ai_rewrite_videos_allowed: {
		action: "rewrite",
		description:
			"You have reached the limit for AI rewrites. Upgrade to use this feature.",
	},
	ai_rewrite_articles_allowed: {
		action: "rewrite",
		description:
			"You have reached the limit for AI rewrites. Upgrade to use this feature.",
	},
};

const useShowError = () => {
	const dispatch = useAppDispatch();
	const toast = useToast()
	const analytics = useAppSelector(selectAnalytics)
	const showUsageLimitError = (errorBody: UsageLimitExceededErrorBody) => {
		analytics.captureEvent({eventName: "UsageLimitExceededErrorShown", value: errorBody})
		dispatch(
			openModal({
				heading: {
					title: `Upgrade your plan to ${limit_dictionary[errorBody.limitName]?.action ?? "use this feature"}`,
					icon: crownIcon(),
				},
				component: <LimitPopup limitName={errorBody.limitName} />,
			}),
		);
	};

	const showActionNotPermittedError = () => {
		toast({
			status: "error",
			title: "Action not permitted!",
			description: "You do not have the necessary permissions to perform this action. Please contact the administrator to request the appropriate role."
		})
	}
	return { showUsageLimitError, showActionNotPermittedError };
};

export default useShowError;
