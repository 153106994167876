import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@gigauser/common/src/redux";
import { EnvironmentContextValue } from "../../../contexts";

import { createCollectionFrontend } from "@gigauser/common/src/redux/slices/collectionDataSlice";

import {
	Collection,
	CollectionInput,
} from "@gigauser/common/src/core/types/collections";
import logger from "../../../utils/logger";
import { selectSaver } from "../../slices/backendSlice";

const useCreateCollection: (
	env: EnvironmentContextValue,
) => (collection: CollectionInput) => Promise<Collection | false> = (env) => {
	// This hook returns a function that allows the consumer to create a given collection
	// given a collection input.
	// In web, this means updating the path in the app state and also changing the route

	// Env.navigator is a hook (useNavigate in web)
	// Navigate is a function that will actually be able to navigate

	const dispatch = useAppDispatch();
	const saver = useAppSelector(selectSaver);
	// We must update collectionPath based on ID given in the core function

	return async (collection: CollectionInput) => {
		// We first create updated folderPath state
		try {
			logger.debug("creating collection: ", collection);

			const collection_copy = { ...collection };
			if (collection.parentId == "Collection_root") {
				collection_copy.parentId = undefined;
			}

			const newCollection = await saver.collections.create(collection_copy);

			if (newCollection) {
				dispatch(createCollectionFrontend(newCollection));
				return newCollection;
			} else {
				return false;
			}
		} catch (e) {
			logger.debug("error: ", e);
			return false;
		}
	};
};

export default useCreateCollection;
