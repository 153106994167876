import trashIcon from "../../assets/svgs/trashSimpleIcon";
import restoreIcon from "../../assets/svgs/restoreIcon";

import { closeModal, openModal } from "../../layouts/CModal/modalSlice";
import Cutton from "../../ui/buttons/Cutton/Cutton";
import { useAppDispatch } from "../../redux";
import "./ConfirmDelete.css";
import { capitalizeFirstLetter } from "../../overlays/VoicePicker/VoicePicker";

type DeleteModalOptions = {
	item?: string;
	action?: "delete" | "remove" | "restore";
	title?: string;
	description?: string;
};

const useConfirmDelete: () => (
	onDelete: () => void,
	options: DeleteModalOptions,
) => void = () => {
	const dispatch = useAppDispatch();

	return (onDelete: () => void, options: DeleteModalOptions) => {
		const { description, title, item, action } = options;

		dispatch(
			openModal({
				heading: {
					title: ` ${action ? capitalizeFirstLetter(action) : "Delete"} ${item ?? "Item"}`,
					icon: action === "restore" ? restoreIcon() : trashIcon(),
				},
				component: (
					<div className="ConfirmDelete">
						<p>
							{description
								? description
								: `Are you sure you want to ${action ?? "delete"} ${item ?? "this item"} ? `}
						</p>

						<div className={`confirm-delete-buttons`}>
							<Cutton
								fullWidth
								rank="tertiary"
								onClick={() => {
									dispatch(closeModal());
								}}
							>
								Cancel
							</Cutton>
							<Cutton
								theme={action !== "restore" ? "danger" : undefined}
								fullWidth
								rank="primary"
								loadingText="Working ..."
								onClick={() => {
									onDelete();
									dispatch(closeModal());
								}}
							>
								{action ? capitalizeFirstLetter(action) : "Delete"}
							</Cutton>
						</div>
					</div>
				),
			}),
		);
	};
};

export default useConfirmDelete;
